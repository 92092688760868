import React, { FC } from "react";
import { useParams } from "react-router-dom";
import AppointmentTechnologistNoteEditDialogContainer from "../AppointmentTechnologistNoteEditDialogContainer";
import AppointmentTechnologistNoteCard from "../AppointmentTechnologistNoteCard";
import useAppointmentTechnologistNoteEditDialogContainer from "./useAppointmentTechnologistNoteEditDialogContainer";

export type AppointmentTechnologistNoteCardContainerProps = {
  text: string;
  refetch: () => void;
};

const AppointmentTechnologistNoteCardContainer: FC<
  AppointmentTechnologistNoteCardContainerProps
> = ({ text, refetch }) => {
  const { appointmentId } = useParams();

  const { handleOpen, props } =
    useAppointmentTechnologistNoteEditDialogContainer({
      handleEditNote: refetch,
      appointmentId,
      text,
    });

  return (
    <>
      <AppointmentTechnologistNoteCard
        text={text}
        handleAddClick={handleOpen}
      />
      <AppointmentTechnologistNoteEditDialogContainer {...props} />
    </>
  );
};

export default AppointmentTechnologistNoteCardContainer;
