import React, { FC } from "react";
import Button from "../../Unknown/Button";
import Card from "../../Unknown/Card";
import CardContent from "../../Unknown/CardContent";
import CardHeader from "../../Unknown/CardHeader";
import AppointmentFileAddDialog from "../AppointmentFileAddDialog";
import AppointmentFileGrid from "../AppointmentFileGrid";
import { InitialAppointmentFile } from "./types";
import useAppointmentFileAddDialog from "./useAppointmentFileAddDialog";
import useAppointmentFiles from "./useAppointmentFiles";
import useTranslations from "./useTranslations";

export type AppointmentFileGridContainerProps = {
  initialFiles: InitialAppointmentFile[];
  appointmentId: string;
};

const AppointmentFileGridContainer: FC<AppointmentFileGridContainerProps> = ({
  initialFiles,
  appointmentId,
}) => {
  const { translations } = useTranslations();
  const { title, btnAddFiles } = translations;

  const { files, handleAddFiles } = useAppointmentFiles({ initialFiles });

  const { handleOpen, props: appointmentFileAddDialogProps } =
    useAppointmentFileAddDialog({ appointmentId, handleAddFiles });

  return (
    <>
      <Card>
        <CardHeader
          title={title}
          action={
            <Button variant="contained" onClick={handleOpen}>
              {btnAddFiles}
            </Button>
          }
        />
        <CardContent sx={{ pt: 0 }}>
          <AppointmentFileGrid files={files} />
        </CardContent>
      </Card>
      <AppointmentFileAddDialog {...appointmentFileAddDialogProps} />
    </>
  );
};

export default AppointmentFileGridContainer;
