import { defineMessages } from "react-intl";

const messages = defineMessages({
  btnPatientEdit: {
    id: "Patient.PatientInformationDrawer.btnPatientEdit",
    defaultMessage: "Edit",
  },
  btnPatientCancel: {
    id: "Patient.PatientInformationDrawer.btnPatientCancel",
    defaultMessage: "Cancel",
  },
  btnPatientSave: {
    id: "Patient.PatientInformationDrawer.btnPatientSave",
    defaultMessage: "Save",
  },
});

export default messages;
