import React, { FC, ReactNode } from "react";
import Grid from "../../Unknown/Grid";
import Card from "../../Unknown/Card";
import CardContent from "../../Unknown/CardContent";
import CardHeader from "../../Unknown/CardHeader";
import InformationGridItem from "../../Unknown/InformationGridItem";
import { Patient } from "./types";
import usePatientInformationGridItems from "./usePatientInformationGridItems";

export type PatientCardProps = {
  patient: Patient;
  title: string;
  actions?: { id: string; node: ReactNode }[];
};

const PatientCard: FC<PatientCardProps> = ({ patient, title, actions }) => {
  const informationGridItems = usePatientInformationGridItems(patient);

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <Grid container wrap="nowrap" direction="row" spacing={3}>
            {actions?.map(({ id, node }) => (
              <Grid item key={id}>
                {node}
              </Grid>
            ))}
          </Grid>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <Grid container spacing={3}>
          {informationGridItems.map(({ id, ...informationGridItem }) => (
            <Grid item xs={6} key={id}>
              <InformationGridItem item={informationGridItem} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PatientCard;
