import { FormikConfig } from "formik";
import { useCallback, useMemo } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useValidationSchema from "./useValidationSchema";
import useTranslations from "./useTranslations";

interface FormValues {
  text: string;
}

type FormikProps = FormikConfig<FormValues>;

type InitialValues = FormikConfig<FormValues>["initialValues"];
type SubmitHandler = FormikConfig<FormValues>["onSubmit"];

const useFormikProps = (params: {
  setErrorMessage: (value: string | null) => void;
  handleEditNote: (values: FormValues) => void | Promise<void>;
  handleClose: () => void;
  initialValue: string;
  appointmentId?: string | null;
}): FormikProps => {
  const {
    setErrorMessage,
    handleEditNote,
    handleClose,
    appointmentId,
    initialValue,
  } = params;

  const { translations } = useTranslations();
  const { defaultError } = translations;

  const editAppointmentTechnologistNote = useFirebaseAppFunction(
    "editAppointmentTechnologistNote",
  );

  const initialValues = useMemo<InitialValues>(() => {
    return {
      text: initialValue,
    };
  }, [initialValue]);

  const validationSchema = useValidationSchema();

  const onSubmit = useCallback<SubmitHandler>(
    async (values, { resetForm }) => {
      try {
        if (!appointmentId) {
          throw new Error(defaultError);
        }

        const { text } = values;

        await editAppointmentTechnologistNote({
          appointmentId,
          text,
        });

        await handleEditNote({ text });

        handleClose();
        resetForm();
      } catch (error) {
        setErrorMessage((error as Error).message);
      }
    },
    [
      appointmentId,
      defaultError,
      editAppointmentTechnologistNote,
      handleEditNote,
      handleClose,
      setErrorMessage,
    ],
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useFormikProps;
