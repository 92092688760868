import { minutesInHour } from "date-fns";
import { useIntl } from "react-intl";
import calcHoursLeft from "./calcHoursLeft";
import messages from "./messages";

const useFormattedValue = (minutesLeft: number | null): string | null => {
  const intl = useIntl();

  if (minutesLeft === null) return null;

  const isLessThenHour =
    typeof minutesLeft === "number" && minutesLeft < minutesInHour;

  if (isLessThenHour) {
    return intl.formatMessage(messages.minutes, { value: minutesLeft });
  }

  const hoursLeft = calcHoursLeft(minutesLeft);

  return intl.formatMessage(messages.hours, { value: hoursLeft });
};

export default useFormattedValue;
