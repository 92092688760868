import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    tableRoot: {
      backgroundColor: palette.common.white,
    },
  };
});

export default useStyles;
