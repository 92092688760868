import { Box } from "@mui/system";
import React, { FC } from "react";
import AvailabilityCalendarMonth, {
  AvailabilityCalendarMonthProps,
} from "../../Unknown/AvailabilityCalendarMonth";
import AvailabilityCalendarTimeSlots, {
  AvailabilityCalendarTimeSlotsProps,
} from "../../Unknown/AvailabilityCalendarTimeSlots";
import Grid from "../../Unknown/Grid";
import StatusDialog from "../../Unknown/StatusDialog";
import useActionButtons from "./useActionButtons";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";
import { AppointmentOffering } from "../AppointmentCardsContainer/types";
import Typography from "../../Unknown/Typography";
import TextField from "../../Unknown/TextField";

export type AppointmentBookAdditionalViewsDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void | Promise<void>;
  isLoading?: boolean;
  errorMessage?: string | null;
  calendarProps: AvailabilityCalendarMonthProps;
  timePickerProps: AvailabilityCalendarTimeSlotsProps;
  offerings: AppointmentOffering[];
  overriddenTime: string | null;
  onTimeOverride: (time: string | null) => void;
};

const AppointmentBookAdditionalViewsDialog: FC<
  AppointmentBookAdditionalViewsDialogProps
> = ({
  isOpen,
  isLoading,
  errorMessage,
  calendarProps,
  timePickerProps,
  offerings,
  overriddenTime,
  onTimeOverride,
  handleClose,
  handleSubmit,
}) => {
  const { calendarRoot } = useStyles();

  const { translations } = useTranslations();

  const isSubmitDisabled = !timePickerProps.selectedTimeSlot && !overriddenTime;

  const isDaySelected = !!calendarProps.value;

  const actionButtons = useActionButtons({
    handleClose,
    handleSubmit,
    isLoading,
    errorMessage,
    isSubmitDisabled,
  });

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      isLoading={isLoading}
      errorMessage={errorMessage}
      actionButtons={actionButtons}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        sx: {
          maxWidth: 800,
        },
      }}
    >
      <Grid container spacing={4} mb={4}>
        {offerings.map((offering) => {
          return (
            <Grid key={offering.id} item xs={12} sm={6} md={4}>
              <Typography variant="body2">{offering.modality_name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {offering.acc}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Grid container direction="row" wrap="nowrap" spacing={6}>
        <Grid item>
          <Box className={calendarRoot}>
            <AvailabilityCalendarMonth {...calendarProps} />
          </Box>
          <Box mt={4}>
            <TextField
              label={translations.overrideTimeLabel}
              value={overriddenTime || ""}
              onChange={({ target }) => onTimeOverride(target.value)}
              disabled={!isDaySelected}
              fullWidth
              type="time"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </Grid>
        <Grid item maxWidth={300}>
          <AvailabilityCalendarTimeSlots {...timePickerProps} />
        </Grid>
      </Grid>
    </StatusDialog>
  );
};

export default AppointmentBookAdditionalViewsDialog;
