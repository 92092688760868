import { addMilliseconds, parseISO } from "date-fns";
import { useMemo } from "react";
import { getTimezoneOffsetMillis } from "../../../common/dateHelpers";
import { AppointmentFileGridContainerProps } from "../../AppointmentFile/AppointmentFileGridContainer";
import { Appointment } from "./types";

type InitialFile = AppointmentFileGridContainerProps["initialFiles"][number];

const useAppointmentFileGridContainer = (appointment: Appointment | null) => {
  const props = useMemo<AppointmentFileGridContainerProps | null>(() => {
    if (!appointment) return null;

    const { appointment_files, center } = appointment;

    const initialFiles = appointment_files.map<InitialFile>((file) => {
      const { id, name, url, created_at } = file;

      const centerTimezoneOffsetMillis = getTimezoneOffsetMillis(
        center.timezone,
      );
      const createdAtUTC = parseISO(created_at);

      const uploadedAt = addMilliseconds(
        createdAtUTC,
        centerTimezoneOffsetMillis,
      );

      return {
        id,
        name,
        downloadUrl: url,
        uploadedAt,
      };
    });

    return {
      initialFiles,
      appointmentId: appointment.id,
    };
  }, [appointment]);

  return props;
};

export default useAppointmentFileGridContainer;
