import { useCallback, useState } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { AppointmentMarkPriorityDialogProps } from "../AppointmentMarkPriorityDialog";
import useTranslations from "./useTranslations";

const useMarkAppointmentPriorityDialog = ({
  appointmentId,
  onSuccess,
}: {
  appointmentId: string;
  onSuccess: () => Promise<void>;
}): {
  handleOpen: () => void;
  props: AppointmentMarkPriorityDialogProps;
} => {
  const { markPriorityTranslations } = useTranslations();
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const markAppointmentAsNoShow = useFirebaseAppFunction(
    "markAppointmentPriority",
  );

  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleOpen = () => setIsOpen(true);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setErrorMessage(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await runAsyncFunction(markAppointmentAsNoShow, {
        appointmentId,
      });

      if (data.status === "error") {
        const { code } = data.error;

        switch (code) {
          case "STATUS_NOT_SUPPORTED": {
            throw new Error(markPriorityTranslations.statusNotSupportedError);
          }
          default: {
            throw new Error(markPriorityTranslations.defaultError);
          }
        }
      }

      handleClose();
      await onSuccess();
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [
    appointmentId,
    markPriorityTranslations.statusNotSupportedError,
    markPriorityTranslations.defaultError,
    runAsyncFunction,
    markAppointmentAsNoShow,
    handleClose,
    onSuccess,
  ]);

  return {
    handleOpen,
    props: {
      isOpen,
      handleClose,
      handleSubmit,
      isLoading,
      errorMessage,
    },
  };
};

export default useMarkAppointmentPriorityDialog;
