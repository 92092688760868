import { defineMessages } from "react-intl";

export default defineMessages({
  labelName: {
    id: "Patient.PatientCard.labelName",
    defaultMessage: "Name",
  },
  labelMrn: {
    id: "Patient.PatientCard.labelMrn",
    defaultMessage: "Patient id",
  },
  labelPhone: {
    id: "Patient.PatientCard.labelPhone",
    defaultMessage: "Phone",
  },
  labelEmail: {
    id: "Patient.PatientInformationCard.labelEmail",
    defaultMessage: "Email",
  },
  labelBirthDate: {
    id: "Patient.PatientCard.labelBirthDate",
    defaultMessage: "Date of birth",
  },
  labelLegalGuardian: {
    id: "Patient.PatientInformationCard.labelLegalGuardian",
    defaultMessage: "Legal guardian",
  },
});
