import React, { FC, useMemo } from "react";
import StatusDialog, { StatusDialogProps } from "../../Unknown/StatusDialog";
import Typography from "../../Unknown/Typography";
import useTranslations from "./useTranslations";

export type AppointmentReportUnexpectedFindingsDialogProps = {
  isOpened: boolean;
  filename: string;
  isLoading?: boolean;
  errorMessage?: string | null;
  handleClose: () => void;
  handleSubmit: () => void | Promise<void>;
};

const AppointmentReportUnexpectedFindingsDialog: FC<
  AppointmentReportUnexpectedFindingsDialogProps
> = ({
  isOpened,
  filename,
  isLoading,
  errorMessage,
  handleClose,
  handleSubmit,
}) => {
  const { translations } = useTranslations();

  const actionButtons = useMemo<StatusDialogProps["actionButtons"]>(() => {
    return [
      {
        text: translations.btnCancel,
        key: "cancel",
        disabled: isLoading,
        onClick: handleClose,
      },
      {
        text: translations.btnSubmit,
        key: "submit",
        color: "primary",
        disabled: isLoading || !!errorMessage,
        onClick: handleSubmit,
      },
    ];
  }, [
    errorMessage,
    handleClose,
    handleSubmit,
    isLoading,
    translations.btnCancel,
    translations.btnSubmit,
  ]);

  return (
    <StatusDialog
      open={isOpened}
      title={translations.title}
      isLoading={isLoading}
      errorMessage={errorMessage}
      actionButtons={actionButtons}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          maxWidth: 500,
        },
      }}
    >
      <Typography>
        {translations.descriptionStart}{" "}
        <Typography component="span" fontWeight={600}>
          {filename}
        </Typography>{" "}
        {translations.descriptionEnd}
      </Typography>
    </StatusDialog>
  );
};

export default AppointmentReportUnexpectedFindingsDialog;
