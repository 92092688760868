import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemPatientName, AppointmentRowItem } from "../types";
import PatientNamePreview from "../../../Patient/PatientNamePreview";

export type ColumnPatientTranslations = {
  columnPatientName: string;
};

const getColumnPatientName = (params: {
  columnItem: AppointmentColumnItemPatientName;
  translations: ColumnPatientTranslations;
  onNameClick?: (id: string) => void;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations, onNameClick } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    headerName: translations.columnPatientName,
    valueGetter: ({ row }) => {
      const { appointmentPatient } = row;

      if (!appointmentPatient) return "";

      const { firstName, lastName } = appointmentPatient;

      const fullName = [lastName, firstName].filter(Boolean).join(", ");

      return fullName;
    },
    renderCell: ({ row }) => {
      const { appointmentPatient, patient } = row;

      if (!patient || !appointmentPatient) return null;

      const { firstName, lastName } = appointmentPatient;

      const { mrn } = patient;
      const fullName = [lastName, firstName].filter(Boolean).join(", ");

      return (
        <PatientNamePreview
          fullName={fullName}
          onNameClick={onNameClick ? () => onNameClick(mrn) : undefined}
        />
      );
    },
  };
};

export default getColumnPatientName;
