import useTranslations from "./useTranslations";

const usePatientEditFormContainer = (params: {
  patientId?: string;
  isEmailShown: boolean;
  isPhoneShown: boolean;
}) => {
  const { patientId, isEmailShown, isPhoneShown } = params;

  const { formLabelTranslations } = useTranslations();

  if (!patientId) {
    return null;
  }

  return {
    translations: formLabelTranslations,
    patientId,
    namePrefix: "information",
    isEmailShown,
    isPhoneShown,
  };
};

export default usePatientEditFormContainer;
