import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import { PatientFormPreviewGridForm } from "../types";

type ColumnTranslations = {
  columnExpiresAt: string;
};

type ColumnValue = {
  expiresAt: string;
};

const getColumnFormType = (params: {
  translations: ColumnTranslations;
  headerClassName?: string;
}): GridColDef<PatientFormPreviewGridForm, ColumnValue> => {
  const { translations, headerClassName } = params;

  return {
    field: "expiresAt",
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    headerName: translations.columnExpiresAt,
    headerClassName,
    valueGetter: (getterParams) => {
      const { expiresAt } = getterParams.row;
      return { expiresAt };
    },
    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      return (
        <Typography variant="body2">{renderParams.value.expiresAt}</Typography>
      );
    },
  };
};

export default getColumnFormType;
