import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "AppointmentReport.AppointmentReportResendDialog.title",
    defaultMessage: "Resend Report",
  },
  report: {
    id: "AppointmentReport.AppointmentReportResendDialog.report",
    defaultMessage: "The report",
  },
  willBeSentTo: {
    id: "AppointmentReport.AppointmentReportResendDialog.willBeSentTo",
    defaultMessage: "will be sent to",
  },
  at: {
    id: "AppointmentReport.AppointmentReportResendDialog.willBeSentTo",
    defaultMessage: "at",
  },
  btnCancel: {
    id: "AppointmentReport.AppointmentReportResendDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnSubmit: {
    id: "AppointmentReport.AppointmentReportResendDialog.btnSubmit",
    defaultMessage: "Resend report",
  },
});

export default messages;
