import React, { FC } from "react";
import { Offering } from "../../Center/CenterSettingsContainer/types";
import DataGrid from "../../Unknown/DataGrid";
import useGridColumns from "./useGridColumns";
import useStyles from "./useStyles";

export type CenterOfferingsViewProps = {
  offerings: Offering[];
  currencyCode: string;
  onArchiveClick: (offeringId: string) => void;
  onEditClick: (offeringId: string) => void;
};

const CenterOfferingsView: FC<CenterOfferingsViewProps> = ({
  offerings,
  currencyCode,
  onArchiveClick,
  onEditClick,
}) => {
  const { root } = useStyles();
  const { columns } = useGridColumns({
    currencyCode,
    onArchiveClick,
    onEditClick,
  });

  return (
    <DataGrid
      autoHeight
      getRowHeight={() => "auto"}
      className={root}
      hideFooter
      columns={columns}
      rows={offerings}
      hideFooterRowCount
      disableRowSelectionOnClick
      initialState={{
        sorting: { sortModel: [{ field: "name", sort: "asc" }] },
      }}
      sortingOrder={["desc", "asc"]}
    />
  );
};

export default CenterOfferingsView;
