import React, { FC } from "react";
import { useAppContext } from "../../Unknown/AppContext";
import useRows from "./useRows";
import AppointmentOfferingTableGrid from "../AppointmentOfferingTableGrid";
import { DashboardAppointmentOffering } from "../AppointmentOfferingTableContainer/getAppointmentOfferings";
import { ShowReadSwitchProps } from "../AppointmentOfferingTableTopBar";

type AppointmentOfferingRoleTableProps = {
  role: string | undefined;
  appointmentOfferings: DashboardAppointmentOffering[];
  isLoading: boolean;
  showReadProps: ShowReadSwitchProps;
};

const AppointmentOfferingRoleTable: FC<AppointmentOfferingRoleTableProps> = ({
  role,
  appointmentOfferings,
  isLoading,
  showReadProps,
}) => {
  const {
    databaseUserData: { centerIds },
  } = useAppContext();

  const rows = useRows(appointmentOfferings);

  const localeFormat = centerIds.length === 1 ? "center" : "client";

  if (role !== "radiologist") return null;

  return (
    <AppointmentOfferingTableGrid
      columnsConfig={{
        columnItems: [
          { field: "alert", isDeliveryShown: true },
          { field: "status" },
          { field: "patient", localeFormat },
          { field: "time", localeFormat },
          { field: "location" },
          { field: "offering" },
          { field: "notes" },
          { field: "btnView" },
          { field: "btnOptions" },
        ],
      }}
      rows={rows}
      isLoading={isLoading}
      showReadProps={showReadProps}
    />
  );
};

export default AppointmentOfferingRoleTable;
