import { useFormikContext } from "formik";
import React, { FC } from "react";
import InformationDrawer from "../../Unknown/InformationDrawer";
import useInformationDrawerActionButtons from "./useInformationDrawerActionButtons";
import CustomerMainCards, {
  CustomerMainCardsProps,
} from "../CustomerMainCards";

export type CustomerInformationDrawerProps = {
  isOpen: boolean;
  isEditMode: boolean;
  handleClose: () => void;
  handleEditModeChange: (value: boolean) => void;
} & CustomerMainCardsProps;

const CustomerInformationDrawer: FC<CustomerInformationDrawerProps> = ({
  isOpen,
  isEditMode,
  isLoading,
  error,
  data,
  handleClose,
  handleEditModeChange,
}) => {
  const { isSubmitting, handleSubmit, resetForm } = useFormikContext();

  const informationDrawerActionButtons = useInformationDrawerActionButtons({
    isEditMode,
    isButtonsDisabled: isSubmitting || !!isLoading,
    handleEditModeChange,
    handleSubmit,
    resetForm,
  });

  const handleDialogClose = () => {
    handleEditModeChange(false);
    handleClose();
  };

  return (
    <InformationDrawer
      isOpen={isOpen}
      handleClose={handleDialogClose}
      actionButtons={informationDrawerActionButtons}
    >
      <CustomerMainCards
        isEditMode={isEditMode}
        isLoading={isLoading}
        error={error}
        data={data}
      />
    </InformationDrawer>
  );
};

export default CustomerInformationDrawer;
