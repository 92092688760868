import React, { FC } from "react";
import { useFormikContext } from "formik";
import AppointmentOfferingModifyForm, {
  AppointmentOfferingModifyFormProps,
} from "../AppointmentOfferingModifyForm";
import StatusDialog from "../../Unknown/StatusDialog";
import useTranslations from "./useTranslations";
import useActionButtons from "./useActionButtons";
import { AppointmentOfferingModifyFormValues } from "../AppointmentOfferingModifyForm/types";

export type AppointmentOfferingModifyDialogProps = {
  isOpened: boolean;
  offerings: AppointmentOfferingModifyFormProps["offerings"];
  labelTranslations: AppointmentOfferingModifyFormProps["labelTranslations"];
  handleClose: () => void;
  errorMessage?: string | null;
  isLoading?: boolean;
};

const AppointmentOfferingModifyDialog: FC<
  AppointmentOfferingModifyDialogProps
> = ({
  isOpened,
  offerings,
  labelTranslations,
  handleClose,
  errorMessage,
  isLoading,
}) => {
  const { title } = useTranslations();

  const { handleSubmit, isSubmitting, isValid } =
    useFormikContext<AppointmentOfferingModifyFormValues>();

  const actionButtons = useActionButtons({
    handleClose,
    handleSubmit,
    isLoading: isSubmitting || isLoading,
    errorMessage,
    isSubmitDisabled: !isValid,
  });

  return (
    <StatusDialog
      title={title}
      isLoading={isSubmitting || isLoading}
      errorMessage={errorMessage}
      actionButtons={actionButtons}
      open={isOpened}
      fullWidth
      PaperProps={{ sx: { maxWidth: 800 } }}
    >
      <AppointmentOfferingModifyForm
        offerings={offerings}
        labelTranslations={labelTranslations}
      />
    </StatusDialog>
  );
};

export default AppointmentOfferingModifyDialog;
