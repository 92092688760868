export const acceptTypes: Record<string, string[]> = {
  "image/*": [
    ".avif",
    ".bmp",
    ".gif",
    ".ico",
    ".jpeg",
    ".jpg",
    ".png",
    ".svg",
    ".tif",
    ".tiff",
    ".webp",
  ],
  "application/pdf": [".pdf"],
};

export const validFormats = Object.values(acceptTypes || {})
  .map((value) => value.join(", "))
  .join(", ");

export const maxFilesCount = 5;

const bytesPerMegabyte = 1024 * 1024;
export const maxFileSizeMegaBytes = 5;
export const maxFileSizeBytes = maxFileSizeMegaBytes * bytesPerMegabyte;
