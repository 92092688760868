import { CenterServiceAppointmentCardGridProps } from "../../CenterService/CenterServiceAppointmentCardGrid";
import { Appointment } from "./types";

type CenterServices = CenterServiceAppointmentCardGridProps["centerServices"];

const useCenterServiceAppointmentCardGrid = (params: {
  appointment: Appointment;
  onReadStudyClick: ({ id, url }: { id: string; url: string }) => void;
  onEditStudyClick: ({ url }: { url: string }) => void;
  onModifyOfferingClick: (id: string) => void;
  onAddAdditionalViewClick: (id: string) => void;
}): CenterServiceAppointmentCardGridProps => {
  const { appointment, ...props } = params;

  const offerings: CenterServices = appointment.offerings.map((offering) => {
    const { id, name, side, acc, modalityName, status, pacsUrl } = offering;
    return {
      id,
      name,
      side,
      type: "offering",
      isAdditionalViews: appointment.isAdditionalViews,
      appointmentStatus: appointment.status,
      studies: [
        {
          id,
          modalityName,
          status,
          acc,
          pacsUrl,
        },
      ],
    };
  });

  const packages: CenterServices = appointment.packages.map((packageItem) => {
    const { id, name } = packageItem;

    const studies = packageItem.offerings.map((offering) => ({
      id: offering.id,
      modalityName: offering.modalityName,
      acc: offering.acc,
      status: offering.status,
      pacsUrl: offering.pacsUrl,
    }));

    return {
      id,
      name,
      studies,
      appointmentStatus: appointment.status,
      type: "package",
    };
  });

  return {
    centerServices: [...offerings, ...packages],
    ...props,
  };
};

export default useCenterServiceAppointmentCardGrid;
