import { useMemo } from "react";
import { useIntl } from "react-intl";
import objectKeys from "../common/objectKeys";

type IntlMessages = Record<
  string,
  {
    id: string;
    defaultMessage: string;
  }
>;

const useMessagesTranslations = <T extends IntlMessages>(
  messages: T,
): Record<keyof T, string> => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    return objectKeys(messages).reduce(
      (acc, key) => ({ ...acc, [key]: formatMessage(messages[key]) }),
      {} as Record<keyof T, string>,
    );
  }, [formatMessage, messages]);
};

export default useMessagesTranslations;
