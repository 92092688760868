import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemTime, AppointmentRowItem } from "../types";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnTimeTranslations = {
  columnTime: string;
};

const getColumnTime = (params: {
  columnItem: AppointmentColumnItemTime;
  translations: ColumnTimeTranslations;
}): GridColDef<AppointmentRowItem, Date, string> => {
  const { columnItem, translations } = params;
  const { field, localeFormat } = columnItem;

  return {
    field,
    disableColumnMenu: true,
    flex: 0.5,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnTime,
    type: "dateTime",
    valueGetter: (getterParams) => {
      const { startedAtCenterTimezone } = getterParams.row;

      return startedAtCenterTimezone;
    },
    valueFormatter: ({ value }) => {
      const formattedTime = value
        .toTimeString()
        .toLocaleLowerCase()
        .replace(/\s/, "");

      return formattedTime;
    },
    renderCell: ({ row }) => {
      const { startedAtCenterTimezone, center } = row;
      const { timezone, locale } = center;

      const centerLocale = localeFormat === "center" ? locale : undefined;

      const formattedTime = startedAtCenterTimezone.toLocaleTimeString(
        centerLocale,
        {
          hour: "numeric",
          minute: "numeric",
        },
      );

      const timeZoneAbbr = formatInTimeZone(
        startedAtCenterTimezone,
        timezone || "UTC",
        "zzz",
      ).toLocaleUpperCase();

      return `${formattedTime} (${timeZoneAbbr})`;
    },
  };
};

export default getColumnTime;
