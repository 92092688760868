import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemCustomer, AppointmentRowItem } from "../types";

export type ColumnCustomerTranslations = {
  columnCustomer: string;
};

const getColumnCustomer = (params: {
  columnItem: AppointmentColumnItemCustomer;
  translations: ColumnCustomerTranslations;
}): GridColDef<AppointmentRowItem, string | null> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnCustomer,
    valueGetter: ({ row }) => {
      const { appointmentCustomer } = row;

      if (!appointmentCustomer) return "";

      const { firstName, lastName, customerType } = appointmentCustomer;

      return customerType === "legal_guardian"
        ? `${lastName}, ${firstName}`
        : null;
    },
  };
};

export default getColumnCustomer;
