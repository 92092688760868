import { defineMessages, useIntl } from "react-intl";

export const messages = defineMessages({
  male: {
    id: "Patient.EditForm.male",
    defaultMessage: "Male",
  },
  female: {
    id: "Patient.EditForm.female",
    defaultMessage: "Female",
  },
  preferNotToAnswer: {
    id: "Patient.EditForm.preferNotToAnswer",
    defaultMessage: "Prefer not to answer",
  },
});

export const useSexTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = {
    male: formatMessage(messages.male),
    female: formatMessage(messages.female),
    prefer_not_to_answer: formatMessage(messages.preferNotToAnswer),
  };

  return translations;
};

export const useSexOptions = () => {
  const { formatMessage } = useIntl();

  const options = [
    {
      value: "male",
      label: formatMessage(messages.male),
    },
    {
      value: "female",
      label: formatMessage(messages.female),
    },
    {
      value: "prefer_not_to_answer",
      label: formatMessage(messages.preferNotToAnswer),
    },
  ];

  return options;
};
