import React, { FC, useState } from "react";
import DataGrid, { GridSortItem } from "../../Unknown/DataGrid";
import useDashboardColumns, {
  DashboardColumnsParams,
} from "./useDashboardColumns";
import { AppointmentOfferingRowItem } from "./types";
import useStyles from "./useStyles";
import AppointmentOfferingTableTopBar, {
  ShowReadSwitchProps,
} from "../AppointmentOfferingTableTopBar";
import { PAGE_SIZE_OPTIONS } from "../../../common/constants";

type AppointmentOfferingsTableGridProps = {
  columnsConfig: DashboardColumnsParams;
  rows: AppointmentOfferingRowItem[];
  isLoading?: boolean;
  showReadProps: ShowReadSwitchProps;
};

const initialSortingModel: GridSortItem[] = [{ field: "time", sort: "asc" }];

const AppointmentOfferingsTableGrid: FC<AppointmentOfferingsTableGridProps> = ({
  columnsConfig,
  rows,
  isLoading,
  showReadProps,
}) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });
  const [sortModel, setSortModel] =
    useState<GridSortItem[]>(initialSortingModel);
  const { root } = useStyles();

  const columns = useDashboardColumns(columnsConfig);

  const onSortModelChange = (newModel: GridSortItem[]) => {
    setSortModel(newModel);
  };

  return (
    <DataGrid
      autoHeight
      getRowHeight={() => "auto"}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      loading={isLoading}
      className={root}
      sortModel={sortModel}
      pagination
      onSortModelChange={onSortModelChange}
      paginationModel={paginationModel}
      onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
      slots={{
        toolbar: AppointmentOfferingTableTopBar,
      }}
      slotProps={{
        toolbar: { showReadProps },
      }}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
    />
  );
};

export default AppointmentOfferingsTableGrid;
