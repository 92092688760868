import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      defaultError: formatMessage(commonMessages.defaultError),
      btnAddPackage: formatMessage(messages.btnAddPackage),
      editModalTitle: formatMessage(messages.editModalTitle),
      addModalTitle: formatMessage(messages.addModalTitle),
    };
  }, [formatMessage]);

  return translations;
};
export default useTranslations;
