import { useCallback, useMemo, useState } from "react";
import { getIn, useFormikContext } from "formik";
import getFormNames from "../CustomerEditForm/getFormNames";
import sleep from "../../../common/sleep";
import { useUIContext } from "../../Unknown/UIContext";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";

const useConfirmationCode = (params: {
  customerId: string;
  namePrefix?: string;
}) => {
  const { customerId, namePrefix } = params;
  const { initialValues, values } = useFormikContext();

  const { setAlert } = useUIContext();

  const sendCodeCustomerToUpdateEmail = useFirebaseAppFunction(
    "sendCodeCustomerToUpdateEmail",
  );

  const [isCodeSent, setIsCodeSent] = useState(false);

  const [isSending, setIsSending] = useState(false);

  const formNames = getFormNames(namePrefix);

  const isConfirmationCodeShown = useMemo(() => {
    const emailValue = getIn(values, formNames.email);
    const initialEmailValue = getIn(initialValues, formNames.email);
    return emailValue !== initialEmailValue;
  }, [formNames.email, initialValues, values]);

  const handleSendCodeClick = useCallback(async () => {
    try {
      setIsCodeSent(true);
      setIsSending(true);
      await sleep(2000);
      await sendCodeCustomerToUpdateEmail({ customerId });
    } catch (error) {
      setAlert({
        severity: "error",
        isShown: true,
        message: (error as Error).message,
      });
    } finally {
      setIsSending(false);
    }
  }, [customerId, sendCodeCustomerToUpdateEmail, setAlert]);

  return {
    isConfirmationCodeShown,
    isSending,
    isCodeSent,
    handleSendCodeClick,
  };
};

export default useConfirmationCode;
