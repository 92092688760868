import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "AuditLog.AppointmentAuditLogDialog.title",
    defaultMessage: "Audit Log",
  },
  btnClose: {
    id: "AuditLog.AppointmentAuditLogDialog.btnClose",
    defaultMessage: "Close",
  },
});

export default messages;
