import Big from "big.js";
import { Appointment } from "../../Appointment/AppointmentCardsContainer/types";

const calcRemainingBalance = (appointment: Appointment): number => {
  const remainingBalance = appointment
    ? new Big(appointment.total_cost_amount)
        .minus(appointment.discount_amount || 0)
        .minus(appointment.amount_paid)
        .toNumber()
    : 0;

  return remainingBalance;
};

export default calcRemainingBalance;
