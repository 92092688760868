import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { Appointment } from "../AppointmentCardsContainer/types";

interface CheckIsCanCheckInParams {
  appointment: Appointment | null;
  role?: DatabaseEnum["center_user_role"];
}

const checkIsCanCheckIn = ({
  appointment,
  role,
}: CheckIsCanCheckInParams): boolean => {
  return !!(
    appointment &&
    role &&
    ["technologist", "super_admin", "regional_admin", "receptionist"].includes(
      role,
    ) &&
    ["intake_complete"].includes(appointment.status)
  );
};

export default checkIsCanCheckIn;
