import React, { FC } from "react";
import PatientCard, { PatientCardProps } from "../PatientCard";
import { FormsStatus } from "./types";
import useActions from "./useActions";
import useTranslations from "./useTranslations";

export type PatientCardContainerProps = {
  patient: PatientCardProps["patient"];
  formsStatus?: FormsStatus;
  isGuardianRequired?: boolean;
};

const PatientCardContainer: FC<PatientCardContainerProps> = ({
  patient,
  formsStatus,
  isGuardianRequired,
}) => {
  const { translations } = useTranslations();
  const { title } = translations;

  const actions = useActions({ formsStatus, isGuardianRequired });

  return <PatientCard patient={patient} title={title} actions={actions} />;
};

export default PatientCardContainer;
