import { defineMessages } from "react-intl";

const messages = defineMessages({
  columnDateTime: {
    id: "Appointment.AppointmentsPreviewGrid.columnDateTime",
    defaultMessage: "Date/Time",
  },
  columnCenter: {
    id: "Appointment.AppointmentsPreviewGrid.columnCenter",
    defaultMessage: "Location",
  },
  columnCenterServices: {
    id: "Appointment.AppointmentsPreviewGrid.columnCenterServices",
    defaultMessage: "Offering",
  },
  btnView: {
    id: "Appointment.AppointmentsPreviewGrid.btnView",
    defaultMessage: "View",
  },
});

export default messages;
