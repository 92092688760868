import * as Yup from "yup";
import { useMemo } from "react";
import useLabelTranslations from "./useLabelTranslations";
import { maxDurationMinutes } from "./constants";

const useValidationSchema = () => {
  const labelTranslations = useLabelTranslations();

  const schema = useMemo(() => {
    const { labelDurationMinutes } = labelTranslations;

    return Yup.object().shape({
      durationMinutes: Yup.number()
        .label(labelDurationMinutes)
        .positive()
        .max(maxDurationMinutes)
        .required(),
    });
  }, [labelTranslations]);

  return schema;
};

export default useValidationSchema;
