import { useMemo } from "react";
import { GridColDef } from "../../Unknown/DataGrid";
import getColumnOptions from "./columns/getColumnOptions";
import getColumnUploadedAt from "./columns/getColumnUploadedAt";
import getColumnName from "./columns/getColumnName";
import { AppointmentFile } from "./types";
import useTranslations from "./useTranslations";

const useColumns = (params: {
  hiddenHeaderSeparatorRoot: string;
  uploadedAtTimeRoot: string;
}): GridColDef<AppointmentFile>[] => {
  const { hiddenHeaderSeparatorRoot, uploadedAtTimeRoot } = params;

  const { translations } = useTranslations();

  const columns = useMemo(() => {
    const { headerName, headerUploadedAt, btnDownload } = translations;

    return [
      getColumnName({ translations: { headerName } }),
      getColumnUploadedAt({
        translations: { headerUploadedAt },
        headerClassName: hiddenHeaderSeparatorRoot,
        uploadedAtTimeClassName: uploadedAtTimeRoot,
      }),
      getColumnOptions({
        translations: { btnDownload },
        headerClassName: hiddenHeaderSeparatorRoot,
      }),
    ];
  }, [hiddenHeaderSeparatorRoot, translations, uploadedAtTimeRoot]);

  return columns;
};

export default useColumns;
