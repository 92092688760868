import React, { FC } from "react";
import Grid from "../../Unknown/Grid";
import useTranslations from "./useTranslations";
import getInformationGridItems from "./getInformationGridItems";
import InformationGridItem from "../../Unknown/InformationGridItem";
import { useRaceTranslations } from "../PatientEditForm/useRaceOptions";
import { useSexTranslations } from "../PatientEditForm/useSexOptions";

export type PatientInformationCardProps = {
  mrn: string;
  birthDate?: string;
  phoneNumber?: {
    url: string;
    styled: string;
  };
  email?: string;
  address?: string;
  race?: string;
  sex?: string;
  legalGuardian?: {
    firstName: string;
    lastName: string;
    onClick: () => void;
  };
};

const PatientInformationCard: FC<PatientInformationCardProps> = ({
  mrn,
  birthDate,
  phoneNumber,
  email,
  address,
  legalGuardian,
  race,
  sex,
}) => {
  const translations = useTranslations();
  const raceTranslations = useRaceTranslations();
  const sexTranslations = useSexTranslations();

  const informationGridItems = getInformationGridItems({
    mrn,
    birthDate,
    phoneNumber,
    email,
    address,
    legalGuardian,
    translations,
    race,
    sex,
    raceTranslations,
    sexTranslations,
  });

  return (
    <Grid container spacing={3}>
      {informationGridItems.map((informationGridItem, idx) => {
        return (
          <Grid
            item
            xs={idx + 1 === informationGridItems.length ? true : 6}
            key={informationGridItem.id}
          >
            <InformationGridItem item={informationGridItem} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PatientInformationCard;
