import * as Yup from "yup";
import { POSTAL_CODE_REG_EXP } from "../../../common/constants";
import { CenterAddressFormValues } from "../CenterAddressForm";

export type CenterAddressValidationTranslations = {
  requiredField: string;
  maxStringLengthError: string;
  postalCodeIsInvalid: string;
  labelPostalCode: string;
};

const MIN_POSTAL_CODE_LEN = 5;
const MAX_POSTAL_CODE_LEN = 20;

const getCenterAddressFormSchema = (params: {
  translations: CenterAddressValidationTranslations;
  maxStringLength: number;
}) => {
  const { translations, maxStringLength } = params;

  const {
    requiredField,
    maxStringLengthError,
    postalCodeIsInvalid,
    labelPostalCode,
  } = translations;

  const requiredString = Yup.string()
    .trim()
    .max(maxStringLength, maxStringLengthError)
    .required(requiredField);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Yup.object().shape<Record<keyof CenterAddressFormValues, any>>({
    primaryAddress: requiredString,
    secondaryAddress: Yup.string(),
    city: requiredString,
    postalCode: Yup.string()
      .label(labelPostalCode)
      .trim()
      .required()
      .min(MIN_POSTAL_CODE_LEN)
      .max(MAX_POSTAL_CODE_LEN)
      .matches(new RegExp(`^${POSTAL_CODE_REG_EXP}$`), postalCodeIsInvalid),
    stateIsoCode: requiredString,
    countryIsoCode: requiredString,
  });
};

export default getCenterAddressFormSchema;
