import * as Yup from "yup";
import {
  EMAIL_REGEXP_STR,
  MAX_STRING_LEN,
  MIN_PHONE_NUMBER_LENGTH,
} from "../../../common/constants";
import { CenterContactInformationFormValues } from "../CenterContactInformationForm";

export type CenterContactInformationValidationTranslations = {
  requiredField: string;
  maxStringLengthError: string;
  phoneNumberInvalidError: string;
  faxNumberInvalidError: string;
  emailInvalidError: string;
  labelFax: string;
  labelPhone: string;
};

const getCenterContactInformationFormSchema = (params: {
  translations: CenterContactInformationValidationTranslations;
  countryCode: string;
}) => {
  const { translations } = params;
  const {
    requiredField,
    maxStringLengthError,
    emailInvalidError,
    labelFax,
    labelPhone,
  } = translations;

  const phone = Yup.string()
    .trim()
    .label(labelPhone)
    .min(MIN_PHONE_NUMBER_LENGTH)
    .required(requiredField)
    .test(function testDate(value) {
      if (!value) {
        return this.createError({ message: requiredField });
      }

      return true;
    });

  const fax = Yup.string()
    .trim()
    .label(labelFax)
    .test(function testDate(value) {
      if (!value) {
        return true;
      }

      return true;
    });

  const email = Yup.string()
    .trim()
    .max(MAX_STRING_LEN, maxStringLengthError)
    .matches(new RegExp(`^${EMAIL_REGEXP_STR}$`), emailInvalidError)
    .required(requiredField);

  return Yup.object().shape<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<keyof CenterContactInformationFormValues, any>
  >({ phone, fax, email });
};

export default getCenterContactInformationFormSchema;
