import { useCallback, useState } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useTranslations from "./useTranslations";
import { CustomerLogInDialogProps } from "../../Customer/CustomerLogInDialog";
import { Appointment } from "./types";
import { DashboardAppointment } from "../AppointmentTableContainer/getAppointments";

const useAppointmentCustomerLogInDialog = (): {
  handleOpen: (appointment: Appointment | DashboardAppointment | null) => void;
  props: CustomerLogInDialogProps;
} => {
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const getCenterAppCustomerAuthCode = useFirebaseAppFunction(
    "getCenterAppCustomerAuthCode",
  );

  const { customerLoginTranslations } = useTranslations();

  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [customerName, setCustomerName] = useState<string>("");

  const fetchData = useCallback(
    async (appointmentData: Appointment | DashboardAppointment | null) => {
      if (!appointmentData) return;

      setIsLoading(true);
      try {
        const { appointment_customer_details } = appointmentData;
        const { customer_id, first_name, last_name } =
          appointment_customer_details[0] || {};

        setCustomerName(`${first_name} ${last_name}`);

        const { data } = await runAsyncFunction(getCenterAppCustomerAuthCode, {
          customerId: customer_id || "",
          appointmentId: appointmentData.id,
        });

        if (data.status === "error") {
          const { code } = data.error;

          switch (code) {
            case "USER_NOT_FOUND": {
              throw new Error(
                customerLoginTranslations.errorCustomerNotDefined,
              );
            }
            default: {
              throw new Error(customerLoginTranslations.defaultError);
            }
          }
        }

        setConfirmationCode(data.data.authCode);
      } catch (error) {
        setErrorMessage((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    },
    [customerLoginTranslations, runAsyncFunction, getCenterAppCustomerAuthCode],
  );

  const handleOpen = useCallback(
    async (appointmentData: Appointment | DashboardAppointment | null) => {
      setIsOpen(true);
      await fetchData(appointmentData);
    },
    [fetchData],
  );

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage(null);
  };

  return {
    handleOpen,
    props: {
      isOpen,
      handleClose,
      isLoading,
      errorMessage,
      customerName,
      confirmationCode,
    },
  };
};

export default useAppointmentCustomerLogInDialog;
