import React, { FC } from "react";
import AppointmentNoteAddDialogContainer from "../AppointmentNoteAddDialogContainer";
import AppointmentNoteListCard from "../AppointmentNoteListCard";
import { AppointmentNote } from "../AppointmentNoteListCard/types";
import { Offering, Center, Appointment } from "./types";
import useAppointmentNodeAddDialogContainer from "./useAppointmentNodeAddDialogContainer";
import useNotes from "./useNotes";

export type AppointmentNoteListCardContainerProps = {
  initialNotes: AppointmentNote[];
  offerings: Offering[];
  center: Center | null;
  appointment: Appointment | null;
};

const AppointmentNoteListCardContainer: FC<
  AppointmentNoteListCardContainerProps
> = ({ initialNotes, offerings, center, appointment }) => {
  const { notes, handleAddNote } = useNotes({
    initialNotes,
    offerings,
    center,
  });

  const { handleOpen, props } = useAppointmentNodeAddDialogContainer({
    offerings,
    handleAddNote,
    appointment,
  });

  return (
    <>
      <AppointmentNoteListCard notes={notes} handleAddClick={handleOpen} />
      <AppointmentNoteAddDialogContainer {...props} />
    </>
  );
};

export default AppointmentNoteListCardContainer;
