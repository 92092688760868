import React, { FC } from "react";
import Button from "../../Unknown/Button";
import Card from "../../Unknown/Card";
import CardContent from "../../Unknown/CardContent";
import CardHeader from "../../Unknown/CardHeader";
import Grid from "../../Unknown/Grid";
import AppointmentNoteListCardItem from "../AppointmentNoteListCardItem";
import { AppointmentNote } from "./types";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";

export type AppointmentNoteListCardProps = {
  notes: AppointmentNote[];
  handleAddClick: () => void;
};

const AppointmentNoteListCard: FC<AppointmentNoteListCardProps> = ({
  notes,
  handleAddClick,
}) => {
  const { noteListRoot } = useStyles();

  const { translations } = useTranslations();

  const { title, btnAdd } = translations;

  const minHeight = !notes.length ? 250 : undefined;

  return (
    <Card sx={{ minHeight }}>
      <CardHeader
        title={title}
        action={
          <Button variant="contained" onClick={handleAddClick}>
            {btnAdd}
          </Button>
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          spacing={2}
          className={noteListRoot}
        >
          {notes.map((note) => {
            const {
              id,
              createdAtCenterTimezone,
              locale,
              description,
              appointmentOffering,
              centerUser,
            } = note;

            const createdDate =
              createdAtCenterTimezone.toLocaleDateString(locale);

            return (
              <Grid item key={id}>
                <AppointmentNoteListCardItem
                  createdBy={`${centerUser.firstName} ${centerUser.lastName}`}
                  createdDate={createdDate}
                  description={description}
                  modalityName={appointmentOffering?.modalityName}
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentNoteListCard;
