import { Theme, lighten } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    activeRoot: {
      backgroundColor: lighten(palette.primary.main, 0.9),
    },
  };
});

export default useStyles;
