import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      tabCenterInfo: formatMessage(messages.tabCenterInfo),
      tabOfferings: formatMessage(messages.tabOfferings),
      tabPackages: formatMessage(messages.tabPackages),
      tabAddOns: formatMessage(messages.tabAddOns),
      tabRegister: formatMessage(messages.tabRegister),
      registerCenterSuccessMessage: formatMessage(
        messages.registerCenterSuccessMessage,
      ),
      registerCenterErrorMessage: formatMessage(
        messages.registerCenterErrorMessage,
      ),
    };
  }, [formatMessage]);

  return translations;
};
export default useTranslations;
