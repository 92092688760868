import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import { CenterOperatingHoursValidationTranslations } from "./getCenterOperatingHoursFormSchema";
import messages from "./messages";

const useFormTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
    };

    const validationTranslations: CenterOperatingHoursValidationTranslations = {
      requiredField: formatMessage(commonMessages.requiredField),
      toMinutesAtMinimumError: formatMessage(messages.toMinutesAtMinimumError),
      timeInvalidError: formatMessage(messages.timeInvalidError),
    };

    return { translations, validationTranslations };
  }, [formatMessage]);

  return result;
};
export default useFormTranslations;
