import { defineMessages } from "react-intl";

const messages = defineMessages({
  showReadLabel: {
    id: "Appointment.AppointmentOfferingTableTopBar.showReadLabel",
    defaultMessage: "Show read",
  },
  findNameLabel: {
    id: "Appointment.AppointmentOfferingTableTopBar.findNameLabel",
    defaultMessage: "Find name",
  },
});

export default messages;
