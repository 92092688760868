import React, { FC } from "react";
import { useFormikContext } from "formik";
import FormikTextField from "../../Unknown/FormikTextField";
import useTranslations from "./useTranslations";

export type CenterDiscountFormValues = {
  amount: number;
};

const CenterDiscountForm: FC = () => {
  const translations = useTranslations();

  const { isSubmitting } = useFormikContext<CenterDiscountFormValues>();

  return (
    <FormikTextField
      fullWidth
      type="number"
      name="amount"
      label={translations.labelAmount}
      disabled={isSubmitting}
      required
      autoComplete="off"
    />
  );
};

export default CenterDiscountForm;
