import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "AppointmentNote.AppointmentTechnologistNoteCard.title",
    defaultMessage: "Technologist Notes",
  },
  btnEdit: {
    id: "AppointmentNote.AppointmentTechnologistNoteCard.btnEdit",
    defaultMessage: "Edit",
  },
});
