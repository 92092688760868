import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      btnDay: formatMessage(messages.btnDay),
      btnWeek: formatMessage(messages.btnWeek),
      btnMonth: formatMessage(messages.btnMonth),
      btnBlockTime: formatMessage(messages.btnBlockTime),
    };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
