import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  ReactNode,
} from "react";
import AlertTitle from "../AlertTitle";
import Dialog from "../Dialog";
import Alert, { AlertProps } from "../Alert";

const UIContext = createContext<UIContextProps>({} as UIContextProps);

export interface AlertParams {
  isShown: boolean;
  severity?: AlertProps["severity"];
  message?: string;
  description?: string;
}

interface UIContextProps {
  setAlert: Dispatch<SetStateAction<AlertParams>>;
}

type UIContextProviderProps = {
  children: ReactNode;
};

export const useUIContext = () => useContext(UIContext);

export const UIContextProvider: React.FC<UIContextProviderProps> = ({
  children,
}) => {
  const [alert, setAlert] = useState<AlertParams>({
    isShown: false,
    severity: "error",
    message: "",
  });

  const handleClose = () => {
    setAlert((prev) => ({ ...prev, isShown: false }));
  };

  return (
    <UIContext.Provider value={{ setAlert }}>
      {children}
      <Dialog open={alert.isShown}>
        <Alert severity={alert.severity} onClose={handleClose}>
          <AlertTitle>{alert.message}</AlertTitle>
          {alert.description}
        </Alert>
      </Dialog>
    </UIContext.Provider>
  );
};
