import React, { FC } from "react";
import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import Button from "../../Unknown/Button";
import Paper from "../../Unknown/Paper";
import { View } from "../TimeSlotCalendarGrid/helpers";
import formatSwitcherDate from "./formatSwitcherDate";
import {
  ArrowBackIosOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from "../../Unknown/Icons";
import useStyles from "./useStyles";

type TimeSlotCalendarDateSwitcherProps = {
  start: Date;
  end: Date;
  view: View;
  onPrevClick: () => void | Promise<void>;
  onNextClick: () => void | Promise<void>;
  isDisabled?: boolean;
};

const TimeSlotCalendarDateSwitcher: FC<TimeSlotCalendarDateSwitcherProps> = ({
  start,
  end,
  view,
  onPrevClick,
  onNextClick,
  isDisabled,
}) => {
  const { btnRoot, dateRoot } = useStyles();
  const text = formatSwitcherDate({ start, end, view });

  return (
    <Box display="flex">
      <Button
        variant="contained"
        onClick={onPrevClick}
        className={btnRoot}
        disabled={isDisabled}
      >
        <ArrowBackIosOutlinedIcon fontSize="inherit" />
      </Button>
      <Paper className={dateRoot}>
        <Typography variant="body2">{text}</Typography>
      </Paper>
      <Button
        variant="contained"
        onClick={onNextClick}
        className={btnRoot}
        disabled={isDisabled}
      >
        <ArrowForwardIosOutlinedIcon fontSize="inherit" />
      </Button>
    </Box>
  );
};

export default TimeSlotCalendarDateSwitcher;
