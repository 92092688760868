import React, { FC } from "react";
import PatientsTable from "../../Patient/PatientsTable";
import { useAppContext } from "../AppContext";
import Box from "../Box";
import CircularProgress from "../CircularProgress";
import Typography from "../Typography";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";
import PatientInformationDrawerContainer from "../../Patient/PatientInformationDrawerContainer";
import usePatientInformationDrawerContainer from "./usePatientInformationDrawerContainer";
import CustomerInformationDrawerContainer from "../../Customer/CustomerInformationDrawerContainer";
import useCustomerInformationDrawerContainer from "./useCustomerInformationDrawerContainer";

const SearchScreen: FC = () => {
  const { patientsSearch } = useAppContext();
  const { isLoading, patients, searchValue } = patientsSearch;
  const { searching, noResults, title } = useTranslations({ searchValue });
  const { tableRoot } = useStyles();

  const {
    isOpen: isPatientInformationDrawerContainerOpen,
    props: patientInformationDrawerContainerProps,
  } = usePatientInformationDrawerContainer();

  const customerInformationDrawerContainerProps =
    useCustomerInformationDrawerContainer();

  if (isLoading) {
    return (
      <Box
        width={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress />
        <Box mt={3}>
          <Typography variant="overline">{searching}</Typography>
        </Box>
      </Box>
    );
  }

  if (!patients?.length) {
    return (
      <Box width={1} display="flex" justifyContent="center" alignItems="center">
        <Box mt={3}>
          <Typography variant="overline">{noResults}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h2">{title}</Typography>
      <Box mt={5} className={tableRoot}>
        <PatientsTable patients={patients} />
      </Box>
      {isPatientInformationDrawerContainerOpen && (
        <PatientInformationDrawerContainer
          {...patientInformationDrawerContainerProps}
        />
      )}
      <CustomerInformationDrawerContainer
        {...customerInformationDrawerContainerProps}
      />
    </Box>
  );
};

export default SearchScreen;
