import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      headingName: formatMessage(messages.headingName),
      headingType: formatMessage(messages.headingType),
      headingPrice: formatMessage(messages.headingPrice),
      typeStat: formatMessage(messages.typeStat),
      btnArchive: formatMessage(messages.btnArchive),
      btnEdit: formatMessage(messages.btnEdit),
    };
  }, [formatMessage]);

  return translations;
};
export default useTranslations;
