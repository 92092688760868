import { Formik } from "formik";
import React, { FC } from "react";
import CenterOperatingHoursForm, {
  CenterOperatingHoursFormValues,
} from "../CenterOperatingHoursForm";
import { OperatingHours } from "../CenterSettingsContainer/types";
import CenterSettingsFormikDialog from "../CenterSettingsFormikDialog";
import useCenterOperatingHoursForm from "./useCenterOperatingHoursForm";
import useTranslations from "./useTranslations";

export type CenterOperatingHoursFormikProps = {
  isOpened: boolean;
  onModalClose: () => void;
  centerId: string;
  centerOperatingHoursData: OperatingHours;
  handlePostSubmit: (
    values: CenterOperatingHoursFormValues,
  ) => void | Promise<void>;
};

const CenterOperatingHoursFormik: FC<CenterOperatingHoursFormikProps> = ({
  isOpened,
  onModalClose,
  centerId,
  centerOperatingHoursData,
  handlePostSubmit,
}) => {
  const { title } = useTranslations();
  const { formikConfig } = useCenterOperatingHoursForm({
    centerId,
    centerOperatingHoursData,
    handlePostSubmit,
  });

  return (
    <Formik {...formikConfig}>
      <CenterSettingsFormikDialog
        title={title}
        isOpened={isOpened}
        onClose={onModalClose}
      >
        <CenterOperatingHoursForm />
      </CenterSettingsFormikDialog>
    </Formik>
  );
};

export default CenterOperatingHoursFormik;
