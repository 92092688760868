import { useMemo } from "react";
import * as yup from "yup";
import useTranslations from "./useTranslations";

const useValidationSchema = () => {
  const { translations } = useTranslations();

  const validationSchema = useMemo(() => {
    const { labelNotes } = translations;

    return yup.object().shape({
      text: yup.string().trim().required().label(labelNotes),
    });
  }, [translations]);

  return validationSchema;
};

export default useValidationSchema;
