import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import { CustomerEditFormTranslations } from "../CustomerEditForm/types";
import messages from "./messages";
import { ValidationSchemaErrorTranslations } from "./types";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
      errorCustomerNotFound: formatMessage(messages.errorCustomerNotFound),
      btnEdit: formatMessage(messages.btnEdit),
      errorEmailInUse: formatMessage(messages.errorEmailInUse),
      errorConfirmationCodeIsInvalid: formatMessage(
        messages.errorConfirmationCodeIsInvalid,
      ),
    };

    const formLabelTranslations: CustomerEditFormTranslations = {
      labelFirstName: formatMessage(messages.labelFirstName),
      labelLastName: formatMessage(messages.labelLastName),
      labelPhoneNumber: formatMessage(messages.labelPhoneNumber),
      labelEmail: formatMessage(messages.labelEmail),
      labelConfirmationCode: formatMessage(messages.labelConfirmationCode),
    };

    const validationSchemaTranslations: ValidationSchemaErrorTranslations = {
      errorEmailInvalid: formatMessage(messages.errorEmailInvalid),
    };

    return {
      translations,
      formLabelTranslations,
      validationSchemaTranslations,
    };
  }, [formatMessage]);
  return result;
};
export default useTranslations;
