import React, { FC } from "react";
import { useFormikContext } from "formik";
import StatusDialog from "../../Unknown/StatusDialog";
import useTranslations from "./useTranslations";
import { FormValues } from "../../AppointmentNote/AppointmentNoteAddForm/types";
import useActionButtons from "./useActionButtons";
import FormikTextField from "../../Unknown/FormikTextField";

export type AppointmentNoteAddDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  errorMessage?: string | null;
};

const AppointmentNoteAddDialog: FC<AppointmentNoteAddDialogProps> = ({
  isOpen,
  errorMessage,
  handleClose,
}) => {
  const { translations } = useTranslations();

  const { isSubmitting, resetForm, handleSubmit } =
    useFormikContext<FormValues>();

  const onClose = () => {
    handleClose();
    resetForm();
  };

  const actionButtons = useActionButtons({
    handleClose: onClose,
    handleSubmit,
    isLoading: isSubmitting,
    errorMessage,
  });

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      actionButtons={actionButtons}
      errorMessage={errorMessage}
      isLoading={isSubmitting}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          maxWidth: 800,
        },
      }}
    >
      <FormikTextField
        name="text"
        label={translations.labelNotes}
        fullWidth
        multiline
        disabled={isSubmitting}
      />
    </StatusDialog>
  );
};

export default AppointmentNoteAddDialog;
