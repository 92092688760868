import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentColumnItemPayments,
  AppointmentRowItem,
  AppointmentRowItemPayment,
} from "../types";
import Box from "../../../Unknown/Box";
import Typography from "../../../Unknown/Typography";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnTranslations = {
  columnPayments: string;
  providerStripe: string;
  providerCash: string;
  providerUnknown: string;
};

const providers: Record<string, keyof ColumnTranslations> = {
  stripe: "providerStripe",
  cash: "providerCash",
  unknown: "providerUnknown",
};

const normalizePayment = ({
  payment,
  translations,
}: {
  payment: AppointmentRowItemPayment;
  translations: ColumnTranslations;
}) => {
  const { method, amount, confirmedAt, transactionId } = payment;

  const providerTranslationKey = method ? providers[method] : "";
  const providerTranslation = providerTranslationKey
    ? translations[providerTranslationKey]
    : "";

  const providerText =
    method === "stripe" && transactionId
      ? `${providerTranslation} (${transactionId})`
      : providerTranslation;

  return {
    providerText,
    amount,
    confirmedAt,
  };
};

const getColumnPayments = (params: {
  columnItem: AppointmentColumnItemPayments;
  translations: ColumnTranslations;
}) => {
  const { columnItem, translations } = params;

  const { field } = columnItem;

  const column: GridColDef<AppointmentRowItem, string> = {
    field,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnPayments,
    valueGetter: ({ row }) => {
      const { payments } = row;

      const formattedPayments = payments.map((payment) => {
        const { amount, confirmedAt, providerText } = normalizePayment({
          payment,
          translations,
        });

        return [amount, confirmedAt, providerText].join(" ");
      });

      return formattedPayments.join(";");
    },
    renderCell: ({ row }) => {
      const { payments } = row;

      if (!payments?.length) return null;

      return (
        <Box display="flex" flexDirection="column" gap={3}>
          {payments.map((payment) => {
            const { providerText, confirmedAt, amount } = normalizePayment({
              payment,
              translations,
            });

            return (
              <Box key={payment.transactionId}>
                <Typography variant="body2" color="inherit">
                  {amount}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {confirmedAt}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {providerText}
                </Typography>
              </Box>
            );
          })}
        </Box>
      );
    },
  };

  return column;
};

export default getColumnPayments;
