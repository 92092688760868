import React, { FC } from "react";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import Button from "../../Unknown/Button";

type CenterInformationSettingsBlockData = {
  key: string;
  value: string;
};

export type CenterInformationSettingsBlockProps = {
  title: string;
  buttonText: string;
  onClick?: () => void;
  data: string | CenterInformationSettingsBlockData[];
};
const CenterInformationSettingsBlock: FC<
  CenterInformationSettingsBlockProps
> = ({ title, buttonText, onClick, data }) => {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item container direction="row" alignItems="center">
        <Grid item xs>
          {typeof data === "string" ? (
            <Typography variant="body2">{data}</Typography>
          ) : (
            <Grid container spacing={2} direction="column">
              {data.map(({ key, value }) => {
                if (!value) return null;

                return (
                  <Grid item key={key}>
                    <Typography
                      sx={{ fontWeight: 500 }}
                      display="inline"
                      variant="body2"
                    >
                      {key}
                      {": "}
                    </Typography>
                    <Typography display="inline" variant="body2">
                      {value}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
        <Grid item xs display="flex" alignItems="center">
          <Button
            variant="outlined"
            sx={{ width: 65, px: 3, py: 1 }}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CenterInformationSettingsBlock;
