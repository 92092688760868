import { useCallback, useMemo } from "react";
import { FormConfig, InitialValues, SubmitHandler } from "./types";
import useValidationSchema from "./useValidationSchema";
import { AppointmentOfferingAdditionalViewFormValues } from "../AppointmentOfferingAdditionalViewForm/types";
import useTranslations from "./useTranslations";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";

const useAppointmentAdditionalViewContainer = (params: {
  appointmentId: string;
  offeringId: string;
  handleSubmit?: (
    values: AppointmentOfferingAdditionalViewFormValues,
  ) => void | Promise<void>;
  handleError: (msg: string | null) => void;
}): FormConfig => {
  const { offeringId, appointmentId, handleSubmit, handleError } = params;

  const markAppointmentOfferingAdditionalView = useFirebaseAppFunction(
    "markAppointmentOfferingAdditionalView",
  );

  const { defaultError } = useTranslations();

  const initialValues = useMemo<InitialValues>(() => {
    return {
      durationMinutes: "",
    };
  }, []);

  const validationSchema = useValidationSchema();

  const onSubmit = useCallback<SubmitHandler>(
    async (values) => {
      try {
        const { durationMinutes } = values;

        if (typeof durationMinutes !== "number") {
          throw new Error(defaultError);
        }

        await markAppointmentOfferingAdditionalView({
          appointmentId,
          offeringId,
          durationMinutes,
        });

        if (!handleSubmit) return;

        await handleSubmit(values);
      } catch (error) {
        handleError(defaultError);
      }
    },
    [
      appointmentId,
      offeringId,
      defaultError,
      handleSubmit,
      handleError,
      markAppointmentOfferingAdditionalView,
    ],
  );

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useAppointmentAdditionalViewContainer;
