import addMilliseconds from "date-fns/addMilliseconds";
import addMinutes from "date-fns/addMinutes";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import getDate from "date-fns/getDate";
import getMonth from "date-fns/getMonth";
import max from "date-fns/max";
import min from "date-fns/min";
import format from "date-fns/format";
import { OperatingHours, OperatingHoursDayKey } from "./types";

const endOfDayUtcMinutes = 60 * 24;

const getIntervalOperatingHours = (params: {
  startDateTimezone: Date;
  endDateTimezone: Date;
  operatingHours: OperatingHours;
  timezoneOffsetMillis: number;
}) => {
  const {
    startDateTimezone,
    endDateTimezone,
    operatingHours,
    timezoneOffsetMillis,
  } = params;

  const eachDateOfInterval = eachDayOfInterval({
    start: startDateTimezone,
    end: endDateTimezone,
  });

  const intervalData = eachDateOfInterval
    .map((date) => {
      const dayKey = format(date, "i") as OperatingHoursDayKey;

      const dayOperatingHours = operatingHours[dayKey];

      if (!dayOperatingHours) return null;

      const startOfDateTimezone = addMilliseconds(date, timezoneOffsetMillis);

      const startOfOperatingDayTimezone = addMinutes(
        startOfDateTimezone,
        dayOperatingHours.fromMinutes,
      );

      const startLimited = max([
        startOfOperatingDayTimezone,
        startDateTimezone,
      ]);

      const endOfOperatingDayTimezone = addMinutes(
        startOfDateTimezone,
        endOfDayUtcMinutes,
      );

      const endLimited = min([endOfOperatingDayTimezone, endDateTimezone]);

      return {
        day: getDate(startLimited),
        month: getMonth(startLimited),
        startOfOperatingDayTimezone: startLimited,
        endOfOperatingDayTimezone: endLimited,
      };
    })
    .filter(Boolean);

  return intervalData as NonNullable<(typeof intervalData)[number]>[];
};

export default getIntervalOperatingHours;
