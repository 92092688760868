import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "../../Unknown/DataGrid";
import useTranslations from "./useTranslations";
import Box from "../../Unknown/Box";
import Button from "../../Unknown/Button";
import { Offering } from "../../Center/CenterSettingsContainer/types";

const useGridColumns = (params: {
  currencyCode: string;
  onArchiveClick: (columnId: string) => void;
  onEditClick: (columnId: string) => void;
}) => {
  const { currencyCode, onArchiveClick, onEditClick } = params;
  const translations = useTranslations();

  const { formatNumber } = useIntl();

  const columns = useMemo<GridColDef<Offering>[]>(() => {
    return [
      {
        field: "name",
        headerName: translations.headingName,
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: "price",
        headerName: translations.headingPrice,
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
        valueFormatter: (formatterParams: GridValueFormatterParams<number>) => {
          const { value } = formatterParams;

          if (!value && value !== 0) return "";

          const formattedPrice = formatNumber(value, {
            style: "currency",
            currency: currencyCode,
          }).replace(".00", "");

          return formattedPrice;
        },
      },
      {
        field: "durationMinutes",
        headerName: translations.headingDuration,
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
        valueFormatter: (formatterParams: GridValueFormatterParams<number>) => {
          const { value } = formatterParams;

          return `${value || 0} ${translations.durationMinutes}`;
        },
      },
      {
        field: "isPrivate",
        headerName: translations.headingIsPrivate,
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
        valueGetter: (getterParams) => {
          return getterParams.row.visibility === "private";
        },
        valueFormatter: (
          formatterParams: GridValueFormatterParams<boolean>,
        ) => {
          const { value } = formatterParams;

          return value ? translations.privateTrue : translations.privateFalse;
        },
      },
      {
        field: "btnArchive",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (renderParams: GridRenderCellParams) => {
          return (
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="text"
                size="small"
                color="error"
                onClick={() => onArchiveClick(renderParams.row.id)}
              >
                {translations.btnArchive}
              </Button>
            </Box>
          );
        },
      },
      {
        field: "btnEdit",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (renderParams: GridRenderCellParams) => {
          return (
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="text"
                size="small"
                onClick={() => onEditClick(renderParams.row.id)}
              >
                {translations.btnEdit}
              </Button>
            </Box>
          );
        },
      },
    ];
  }, [currencyCode, formatNumber, onArchiveClick, onEditClick, translations]);

  return { columns };
};

export default useGridColumns;
