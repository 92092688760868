import { useCallback, useMemo, useState } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { OfferingsData } from "../../Center/CenterSettingsContainer/types";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterOfferingArchiveDialogProps } from "../CenterOfferingArchiveDialog";
import useTranslations from "./useTranslations";

type Offering = Pick<OfferingsData["offerings"][number], "id" | "name">;

type ArchiveFormHookParams = {
  isOpened: boolean;
  offeringId: string | null;
  onClose: () => void;
  offerings: Offering[];
  refetchCenterData?: () => void | Promise<void>;
};

type ArchiveFormHookResult = {
  archiveDialogProps: CenterOfferingArchiveDialogProps;
};

const useArchiveModal = (
  params: ArchiveFormHookParams,
): ArchiveFormHookResult => {
  const { isOpened, offeringId, onClose, offerings, refetchCenterData } =
    params;

  const { defaultError } = useTranslations();

  const { setAlert } = useUIContext();

  const [isLoading, setIsLoading] = useState(false);

  const archiveCenterOffering = useFirebaseAppFunction("archiveCenterOffering");

  const name = useMemo(() => {
    const offering = offerings.find(
      (currentOffering) => currentOffering.id === offeringId,
    );

    return offering?.name || "";
  }, [offeringId, offerings]);

  const onSubmit = useCallback(async () => {
    try {
      if (!offeringId) return;

      setIsLoading(true);

      await archiveCenterOffering({ id: offeringId });

      if (refetchCenterData) await refetchCenterData();

      onClose();
    } catch (error) {
      setAlert({ isShown: true, severity: "error", message: defaultError });
    } finally {
      setIsLoading(false);
    }
  }, [
    archiveCenterOffering,
    defaultError,
    offeringId,
    onClose,
    refetchCenterData,
    setAlert,
  ]);

  return {
    archiveDialogProps: {
      isOpen: isOpened,
      name,
      onSubmit,
      onClose,
      isLoading,
    },
  };
};

export default useArchiveModal;
