import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelName: {
    id: "AddOn.CenterAddOnFormik.labelName",
    defaultMessage: "Name",
  },
  labelPrice: {
    id: "AddOn.CenterAddOnFormik.labelPrice",
    defaultMessage: "Price",
  },
  labelStat: {
    id: "AddOn.CenterAddOnFormik.labelStat",
    defaultMessage: "Is STAT add-on",
  },
});

export default messages;
