import { useMemo } from "react";
import getColumnFormType from "./columns/getColumnFormType";
import { PatientFormPreviewGridForm } from "./types";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";
import getColumnCompletedAt from "./columns/getColumnCompletedAt";
import getColumnExpiresAt from "./columns/getColumnExpiresAt";
import useColumnBtnDownload from "./columns/useColumnBtnDownload";
import { GridColDef } from "../../Unknown/DataGrid";

const useColumns = () => {
  const translations = useTranslations();
  const { hiddenSeparators, shownSeparators } = useStyles();

  const columnBtnView = useColumnBtnDownload({
    translations,
    headerClassName: hiddenSeparators,
  });

  return useMemo<GridColDef<PatientFormPreviewGridForm>[]>(() => {
    const formType = getColumnFormType({
      translations,
      headerClassName: shownSeparators,
    });
    const completedAt = getColumnCompletedAt({
      translations,
      headerClassName: shownSeparators,
    });
    const expiresAt = getColumnExpiresAt({
      translations,
      headerClassName: shownSeparators,
    });

    return [formType, completedAt, expiresAt, columnBtnView];
  }, [translations, columnBtnView, shownSeparators]);
};

export default useColumns;
