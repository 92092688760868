import { FormikConfig } from "formik";
import { useCallback, useMemo } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterOperatingHoursFormValues } from "../CenterOperatingHoursForm";
import { OperatingHours } from "../CenterSettingsContainer/types";
import getCenterOperatingHoursFormSchema from "./getCenterOperatingHoursFormSchema";
import getFormikInitialValues from "./getFormikInitialValues";
import normalizeFormikValues from "./normalizeFormikValues";
import useFormTranslations from "./useFormTranslations";

export type CenterOperatingHoursFormHookParams = {
  centerId: string;
  centerOperatingHoursData: OperatingHours;
  handlePostSubmit?: (
    values: CenterOperatingHoursFormValues,
  ) => Promise<void> | void;
};

type CenterOperatingHoursFormikConfig =
  FormikConfig<CenterOperatingHoursFormValues>;

type CenterOperatingHoursFormHookResult = {
  formikConfig: CenterOperatingHoursFormikConfig;
};

const useCenterOperatingHoursForm = (
  params: CenterOperatingHoursFormHookParams,
): CenterOperatingHoursFormHookResult => {
  const { centerId, centerOperatingHoursData, handlePostSubmit } = params;
  const { weekOperatingHours, centerTimezoneOffsetMillis } =
    centerOperatingHoursData;

  const { setAlert } = useUIContext();

  const updateCenterOperatingHours = useFirebaseAppFunction(
    "updateCenterOperatingHours",
  );

  const { translations, validationTranslations } = useFormTranslations();

  const initialValues = useMemo<
    CenterOperatingHoursFormikConfig["initialValues"]
  >(() => {
    const result = getFormikInitialValues(weekOperatingHours);
    return result;
  }, [weekOperatingHours]);

  const validationSchema = useMemo(() => {
    const result = getCenterOperatingHoursFormSchema({
      translations: validationTranslations,
    });
    return result;
  }, [validationTranslations]);

  const onSubmit = useCallback<CenterOperatingHoursFormikConfig["onSubmit"]>(
    async (values) => {
      try {
        const normalizedOperatingHours = normalizeFormikValues(
          values,
          centerTimezoneOffsetMillis,
        );

        await updateCenterOperatingHours({
          centerId,
          operatingHours: normalizedOperatingHours,
        });

        if (handlePostSubmit) {
          await handlePostSubmit(values);
        }
      } catch (e) {
        setAlert({
          message: translations.defaultError,
          isShown: true,
          severity: "error",
        });
      }
    },
    [
      centerId,
      centerTimezoneOffsetMillis,
      handlePostSubmit,
      setAlert,
      translations.defaultError,
      updateCenterOperatingHours,
    ],
  );

  return {
    formikConfig: {
      initialValues,
      validationSchema,
      onSubmit,
    },
  };
};

export default useCenterOperatingHoursForm;
