import compareAsc from "date-fns/compareAsc";
import { GridColDef, GridComparatorFn } from "../../../Unknown/DataGrid";
import { SearchPatientItem } from "../useRows";

type ColumnDateBirthTranslations = {
  titleDateBirth: string;
};

type ColumnDateBirthParams = {
  translations: ColumnDateBirthTranslations;
};

const sortComparator: GridComparatorFn<SearchPatientItem["dateBirth"]> = (
  currentValue,
  compareValue,
) => {
  return compareAsc(currentValue, compareValue);
};

const getColumnDateBirth = ({
  translations,
}: ColumnDateBirthParams): GridColDef<SearchPatientItem> => {
  return {
    field: "dateBirth",
    disableColumnMenu: true,
    headerName: translations.titleDateBirth,
    sortComparator,
    minWidth: 170,
    valueFormatter: (formatterParams) => {
      return formatterParams.value.toLocaleDateString();
    },
  };
};

export default getColumnDateBirth;
