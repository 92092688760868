import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Center.CenterAddressFormik.title",
    defaultMessage: "Edit Address",
  },
  postalCodeIsInvalid: {
    id: "Center.CenterAddressFormik.postalCodeIsInvalid",
    defaultMessage: "ZIP code is invalid",
  },
  labelPostalCode: {
    id: "Center.CenterAddressFormik.labelPostalCode",
    defaultMessage: "ZIP",
  },
});

export default messages;
