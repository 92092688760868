import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentColumnItemPatientPhone,
  AppointmentRowItem,
} from "../types";
import PatientPhone from "../../../Patient/PatientPhone";

export type ColumnPatientPhoneTranslations = {
  columnPatientPhone: string;
};

const getColumnPatientPhone = (params: {
  columnItem: AppointmentColumnItemPatientPhone;
  translations: ColumnPatientPhoneTranslations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    headerName: translations.columnPatientPhone,
    valueGetter: ({ row }) => {
      const { appointmentPatient } = row;

      if (!appointmentPatient) return "";

      const { phoneNumber } = appointmentPatient;

      return phoneNumber;
    },
    renderCell: ({ row }) => {
      const { appointmentPatient, patient } = row;

      if (!patient || !appointmentPatient) return null;

      const { phoneNumber } = appointmentPatient;

      return <PatientPhone phoneNumber={phoneNumber} />;
    },
  };
};

export default getColumnPatientPhone;
