import React from "react";
import { intervalToDuration } from "date-fns";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemPatient, AppointmentRowItem } from "../types";
import PatientPreview from "../../../Patient/PatientPreview";

export type ColumnPatientTranslations = {
  columnPatient: string;
  years: string;
};

const now = new Date();

const getColumnPatient = (params: {
  columnItem: AppointmentColumnItemPatient;
  translations: ColumnPatientTranslations;
  onNameClick?: (id: string) => void;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations, onNameClick } = params;
  const { field, localeFormat, isEmailShown } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    headerName: translations.columnPatient,
    valueGetter: ({ row }) => {
      const { appointmentPatient, center } = row;

      if (!appointmentPatient) return "";

      const { locale } = center;
      const { firstName, lastName, dateOfBirth, phoneNumber, email } =
        appointmentPatient;

      const fullName = `${lastName}, ${firstName}`;

      if (!dateOfBirth) return "";

      const { years } = intervalToDuration({
        start: dateOfBirth,
        end: now,
      });

      const centerLocale = localeFormat === "center" ? locale : undefined;

      const fields = [
        `${fullName} (${years} ${translations.years})`,
        dateOfBirth.toLocaleDateString(centerLocale),
        phoneNumber,
        email,
      ].filter((value) => !!value);

      return fields.join(" ");
    },
    renderCell: ({ row }) => {
      const { appointmentPatient, patient, center } = row;

      if (!patient || !appointmentPatient) return null;

      const { locale } = center;
      const { firstName, lastName, dateOfBirth, phoneNumber, email } =
        appointmentPatient;

      if (!dateOfBirth) return null;

      const { mrn } = patient;
      const fullName = `${lastName}, ${firstName}`;

      return (
        <PatientPreview
          fullName={fullName}
          dateOfBirth={dateOfBirth}
          phoneNumber={phoneNumber}
          locale={localeFormat === "center" ? locale : undefined}
          email={isEmailShown ? email : undefined}
          onNameClick={onNameClick ? () => onNameClick(mrn) : undefined}
        />
      );
    },
  };
};

export default getColumnPatient;
