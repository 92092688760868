import isAfter from "date-fns/isAfter";
import isDate from "date-fns/isDate";
import isBefore from "date-fns/isBefore";
import * as Yup from "yup";
import {
  EMAIL_REGEXP_STR,
  MAX_STRING_LEN,
  PHONE_NUMBER_REGEXP_STR,
} from "../../../common/constants";
import { PatientEditFormTranslations } from "../PatientEditForm/types";
import {
  confirmationCodeLength,
  maxBirthDate,
  minBirthDate,
} from "./constants";
import { ValidationSchemaErrorTranslations } from "./types";

const getValidationSchema = (params: {
  translations: {
    labels: PatientEditFormTranslations;
    errors: ValidationSchemaErrorTranslations;
  };
  initialEmailValue?: string;
  isEmailShown?: boolean;
  isPhoneShown?: boolean;
}) => {
  const { translations, initialEmailValue, isEmailShown, isPhoneShown } =
    params;
  const { labels, errors } = translations;

  const { errorEmailInvalid, errorDateInvalid, errorDateMin, errorDateMax } =
    errors;

  const optionalString = Yup.string().trim().max(MAX_STRING_LEN);
  const requiredString = optionalString.required();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const informationShape: Record<string, any> = {
    firstName: requiredString.label(labels.labelFirstName),
    lastName: requiredString.label(labels.labelLastName),
    birthDate: requiredString
      .label(labels.labelBirthDate)
      .test(function testDate(value) {
        if (!value) return true;

        if (!isDate(value)) {
          this.createError({ message: errorDateInvalid });
        }

        const dateValue = new Date(value);

        if (isBefore(dateValue, minBirthDate)) {
          return this.createError({ message: errorDateMin });
        }

        if (isAfter(dateValue, maxBirthDate)) {
          return this.createError({ message: errorDateMax });
        }

        return true;
      }),
    primaryAddress: optionalString.label(labels.labelPrimaryAddress),
    secondaryAddress: optionalString.label(labels.labelSecondaryAddress),
    city: optionalString.label(labels.labelCity),
    postalCode: optionalString.label(labels.labelPostalCode),
    stateIsoCode: optionalString.label(labels.labelStateIsoCode),
    countryIsoCode: optionalString.label(labels.labelCountryIsoCode),
    confirmationCode: Yup.string()
      .label(labels.labelConfirmationCode)
      .trim()
      .min(confirmationCodeLength)
      .max(confirmationCodeLength)
      .when("email", (email, field) =>
        initialEmailValue && email !== initialEmailValue
          ? field.required()
          : field,
      ),
    race: optionalString.label(labels.labelRace),
    sex: optionalString.label(labels.labelSex),
  };

  if (isEmailShown) {
    informationShape.email = requiredString
      .matches(new RegExp(`^${EMAIL_REGEXP_STR}$`), errorEmailInvalid)
      .label(labels.labelEmail);
  }

  if (isPhoneShown) {
    informationShape.phoneNumber = Yup.string()
      .trim()
      .matches(PHONE_NUMBER_REGEXP_STR)
      .required()
      .label(labels.labelPhoneNumber);
  }

  const schema = Yup.object().shape({
    information: Yup.object().shape(informationShape),
  });

  return schema;
};

export default getValidationSchema;
