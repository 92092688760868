import { useCallback, useMemo, useState } from "react";
import { AppointmentOfferingModifyContainerProps } from "../../AppointmentOffering/AppointmentOfferingModifyContainer";
import { AppointmentOffering } from "./types";
import {
  AppointmentOfferingModifyFormValues,
  OfferingOption,
} from "../../AppointmentOffering/AppointmentOfferingModifyForm/types";
import useCenterOfferingsData from "./useCenterOfferingsData";

const useAppointmentOfferingModifyContainer = (params: {
  handleSubmit: (
    values: AppointmentOfferingModifyFormValues,
  ) => void | Promise<void>;
  appointmentOfferings: AppointmentOffering[];
  appointmentId: string;
  centerId: string;
}): {
  props: AppointmentOfferingModifyContainerProps | null;
  isOpened: boolean;
  onOpenClick: (id: string) => void;
} => {
  const { handleSubmit, appointmentOfferings, appointmentId, centerId } =
    params;

  const [selectedOffering, setSelectedOffering] =
    useState<AppointmentOffering | null>(null);

  const { isLoading, data, refetch } = useCenterOfferingsData();

  const offeringOptions = useMemo<OfferingOption[]>(() => {
    if (!data) return [];

    return data.map((centerOffering) => ({
      id: centerOffering.id,
      name: centerOffering.name,
      modalityName: centerOffering.modality_name,
      scanSide: centerOffering.scan_side,
    }));
  }, [data]);

  const onOpenClick = useCallback(
    (id: string) => {
      const offering = appointmentOfferings.find(
        (appointmentOffering) => appointmentOffering.offeringId === id,
      );

      if (!offering) return;

      setSelectedOffering(offering);
      refetch({
        centerId,
        price: offering.price,
        durationMinutes: offering.durationMinutes,
      });
    },
    [appointmentOfferings, centerId, refetch],
  );

  const onCloseClick = () => setSelectedOffering(null);

  const isOpened = !!selectedOffering;

  return {
    isOpened,
    onOpenClick,
    props: selectedOffering
      ? {
          appointmentId,
          offeringId: selectedOffering.offeringId,
          isOpened,
          onModalClose: onCloseClick,
          initialOffering: {
            id: selectedOffering.centerOfferingId,
            side: selectedOffering.side,
            modalityName: selectedOffering.modalityName,
          },
          handleSubmit,
          offerings: offeringOptions,
          isLoading,
        }
      : null,
  };
};

export default useAppointmentOfferingModifyContainer;
