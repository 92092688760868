import React, { FC, useMemo, useCallback } from "react";
import { useFormikContext } from "formik";
import FormikTextField, {
  FormikTextFieldProps,
} from "../../Unknown/FormikTextField";
import Grid from "../../Unknown/Grid";
import MenuItem from "../../Unknown/MenuItem";
import useTranslations from "./useTranslations";
import {
  getAllCountries,
  getStatesOfCountry,
} from "../../../common/regionHelpers";

const defaultTextFieldProps: Partial<FormikTextFieldProps> = {
  fullWidth: true,
  required: true,
  autoComplete: "off",
};

export type CenterAddressFormValues = {
  primaryAddress: string;
  secondaryAddress: string;
  city: string;
  postalCode: string;
  stateIsoCode: string;
  countryIsoCode: string;
};

const inputSpacing = 4;

const CenterAddressForm: FC = () => {
  const translations = useTranslations();

  const countries = getAllCountries();

  const { values, isSubmitting, setFieldValue, handleChange } =
    useFormikContext<CenterAddressFormValues>();

  const states = useMemo(() => {
    if (!values.countryIsoCode) return [];
    const stateOptions = getStatesOfCountry(values.countryIsoCode);
    return stateOptions;
  }, [values.countryIsoCode]);

  const onCountryChange = useCallback<
    NonNullable<FormikTextFieldProps["onChange"]>
  >(
    (event) => {
      setFieldValue("stateIsoCode", "");
      handleChange(event);
    },
    [handleChange, setFieldValue],
  );

  return (
    <Grid container spacing={inputSpacing} direction="row">
      <Grid item container spacing={inputSpacing}>
        <Grid item flexGrow={1} flexBasis={1} width="30%">
          <FormikTextField
            {...defaultTextFieldProps}
            name="primaryAddress"
            disabled={isSubmitting}
            label={translations.labelPrimaryAddress}
          />
        </Grid>
        <Grid item flexGrow={1} flexBasis={1} width="30%">
          <FormikTextField
            {...defaultTextFieldProps}
            name="secondaryAddress"
            label={translations.labelSecondaryAddress}
            disabled={isSubmitting}
            required={false}
          />
        </Grid>
        <Grid item flexGrow={1} flexBasis={1} width="30%">
          <FormikTextField
            {...defaultTextFieldProps}
            name="city"
            label={translations.labelCity}
            disabled={isSubmitting}
          />
        </Grid>
      </Grid>
      <Grid item spacing={inputSpacing} container>
        <Grid item flexGrow={1} flexBasis={1} width="30%">
          <FormikTextField
            {...defaultTextFieldProps}
            name="postalCode"
            label={translations.labelPostalCode}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item flexGrow={1} flexBasis={1} width="30%">
          <FormikTextField
            {...defaultTextFieldProps}
            name="stateIsoCode"
            label={translations.labelState}
            disabled={isSubmitting}
            select
          >
            {states.map((state) => (
              <MenuItem key={state.isoCode} value={state.isoCode}>
                {state.name}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item flexGrow={1} flexBasis={1} width="30%">
          <FormikTextField
            {...defaultTextFieldProps}
            name="countryIsoCode"
            label={translations.labelCountry}
            disabled={isSubmitting}
            onChange={onCountryChange}
            select
          >
            {countries.map((country) => (
              <MenuItem key={country.isoCode} value={country.isoCode}>
                {country.name}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CenterAddressForm;
