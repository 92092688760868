import React, { FC } from "react";
import DateTypographyHeader from "../DateTypographyHeader";
import useTranslations from "./useTranslations";
import Typography from "../Typography";

export type DashboardHeaderProps = {
  role?: string;
  date: Date;
};

const DashboardHeader: FC<DashboardHeaderProps> = ({ role, date }) => {
  const { titleCenterServicesToRead, titleAdmin } = useTranslations();

  switch (role) {
    case "receptionist":
    case "technologist":
      return <DateTypographyHeader date={date} />;
    case "radiologist":
      return <Typography variant="h2">{titleCenterServicesToRead}</Typography>;
    case "regional_admin":
    case "super_admin":
      return <Typography variant="h2">{titleAdmin}</Typography>;
    default:
      return null;
  }
};

export default DashboardHeader;
