import { Formik } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import PatientInformationDrawer from "../PatientInformationDrawer";
import useEditMode from "./useEditMode";
import useNormalizedPatientDrawerData from "./useNormalizedPatientDrawerData";
import usePatientData from "./usePatientData";
import usePatientFormik from "./usePatientFormik";

export type PatientInformationDrawerContainerProps = {
  patientMrn?: string;
  isOpen: boolean;
  handleClose: () => void;
  handleCustomerClick: (customerId: string) => void;
  handleAppointmentClick: (customerId: string) => void;
  handlePostSubmit?: () => void | Promise<void>;
};

const PatientInformationDrawerContainer: FC<
  PatientInformationDrawerContainerProps
> = ({
  patientMrn,
  isOpen,
  handleClose,
  handleCustomerClick,
  handlePostSubmit,
  handleAppointmentClick,
}) => {
  const {
    isLoading,
    error,
    data: patient,
    refetch,
  } = usePatientData(patientMrn);

  const isPatientContactFieldsShown = useMemo(() => {
    if (!patient) return false;
    return !patient.legal_guardian_id;
  }, [patient]);

  const normalizedPatientData = useNormalizedPatientDrawerData({
    patient,
    handleCustomerClick,
    handleAppointmentClick,
    isEmailShown: isPatientContactFieldsShown,
    isPhoneShown: isPatientContactFieldsShown,
  });

  const { isEditMode, handleEditModeChange } = useEditMode();

  const onSubmitSuccess = useCallback(async () => {
    await refetch();

    handleEditModeChange(false);

    if (handlePostSubmit) await handlePostSubmit();
  }, [handleEditModeChange, handlePostSubmit, refetch]);

  const { formikConfig: patientFormikConfig } = usePatientFormik({
    patient,
    isEmailShown: isPatientContactFieldsShown,
    isPhoneShown: isPatientContactFieldsShown,
    onSubmitSuccess,
  });

  return (
    <Formik {...patientFormikConfig}>
      <PatientInformationDrawer
        isOpen={isOpen}
        handleClose={handleClose}
        isLoading={isLoading}
        error={error}
        data={normalizedPatientData}
        isEditMode={isEditMode}
        handleEditModeChange={handleEditModeChange}
      />
    </Formik>
  );
};

export default PatientInformationDrawerContainer;
