import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerInformationDrawerContainerProps } from "../../Customer/CustomerInformationDrawerContainer";

const useCustomerDrawerContainer =
  (): CustomerInformationDrawerContainerProps => {
    const { role, customerId } = useParams();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
      navigate(`/${role}/`);
    }, [navigate, role]);

    const handlePatientClick = useCallback(
      (patientMrn: string) => {
        navigate(`/${role}/dashboard/patient/${patientMrn}`);
      },
      [navigate, role],
    );

    return {
      customerId,
      isOpen: !!customerId,
      handleClose,
      handlePatientClick,
    };
  };

export default useCustomerDrawerContainer;
