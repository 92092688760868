import {
  getCountryByCode,
  getStateByCodeAndCountry,
} from "../../../common/regionHelpers";
import { SettingsCenter } from "./getSettingsCenterData";
import { Address } from "./types";

const normalizeAddress = (center: SettingsCenter): Address => {
  const primaryAddressLine = center.center_addresses.find(
    (addr) => addr.type === "primary",
  );

  const secondaryAddressLine = center.center_addresses.find(
    (addr) => addr.type === "secondary",
  );

  const stateData = getStateByCodeAndCountry({
    countryCode: center.country || "",
    stateCode: center.state || "",
  });

  const countryData = getCountryByCode(center.country || "");

  return {
    primaryAddressLine: primaryAddressLine?.address || "",
    secondaryAddressLine: secondaryAddressLine?.address,
    city: center.city,
    postalCode: center.postal_code,
    stateIsoCode: center.state || "",
    stateName: stateData?.name || "",
    countryIsoCode: center.country,
    countryName: countryData?.name || "",
  };
};

export default normalizeAddress;
