import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import { Package } from "../../../Center/CenterSettingsContainer/types";
import Box from "../../../Unknown/Box";
import Button, { ButtonProps } from "../../../Unknown/Button";

type Translations = {
  buttonText: string;
};

const getDefaultBtnColumn = (params: {
  field: string;
  color: ButtonProps["color"];
  translations: Translations;
  onClick: (id: string) => Promise<void> | void;
}) => {
  const { field, color, translations, onClick } = params;

  const { buttonText } = translations;

  const result: GridColDef<Package> = {
    field,
    headerName: "",
    sortable: false,
    disableColumnMenu: true,
    renderCell: (renderParams) => {
      return (
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="text"
            size="small"
            color={color}
            onClick={() => onClick(renderParams.row.id)}
          >
            {buttonText}
          </Button>
        </Box>
      );
    },
  };

  return result;
};
export default getDefaultBtnColumn;
