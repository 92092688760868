import { useMemo } from "react";
import { useIntl } from "react-intl";
import { GridColDef } from "../../Unknown/DataGrid";
import useTranslations from "./useTranslations";
import { Package } from "../../Center/CenterSettingsContainer/types";
import getNameColumn from "./columns/getNameColumn";
import getIsPrivateColumn from "./columns/getIsPrivateColumn";
import getPriceColumn from "./columns/getPriceColumn";
import getOfferingsColumn from "./columns/getOfferingsColumn";
import getDefaultBtnColumn from "./columns/getDefaultBtnColumn";

const useGridColumns = (params: {
  currencyCode: string;
  onArchiveClick: (columnId: string) => void;
  onEditClick: (columnId: string) => void;
}) => {
  const { currencyCode, onArchiveClick, onEditClick } = params;
  const translations = useTranslations();

  const { formatNumber } = useIntl();

  const columns = useMemo<GridColDef<Package>[]>(() => {
    const nameColumn = getNameColumn({
      translations: {
        headerName: translations.headingName,
      },
    });

    const isPrivateColumn = getIsPrivateColumn({
      translations: {
        headerName: translations.headingIsPrivate,
        privateTrue: translations.privateTrue,
        privateFalse: translations.privateFalse,
      },
    });

    const priceColumn = getPriceColumn({
      translations: {
        headerName: translations.headingPrice,
      },
      formatNumber: (num) =>
        formatNumber(num, {
          style: "currency",
          currency: currencyCode,
        }).replace(".00", ""),
    });

    const offeringsColumn = getOfferingsColumn({
      translations: {
        headerName: translations.headingOfferings,
      },
    });

    const btnArchiveColumn = getDefaultBtnColumn({
      field: "btnArchive",
      color: "error",
      translations: {
        buttonText: translations.btnArchive,
      },
      onClick: onArchiveClick,
    });

    const btnEditColumn = getDefaultBtnColumn({
      field: "btnEdit",
      color: "primary",
      translations: {
        buttonText: translations.btnEdit,
      },
      onClick: onEditClick,
    });

    return [
      nameColumn,
      isPrivateColumn,
      priceColumn,
      offeringsColumn,
      btnArchiveColumn,
      btnEditColumn,
    ];
  }, [currencyCode, formatNumber, onArchiveClick, onEditClick, translations]);

  return { columns };
};

export default useGridColumns;
