import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = (params: { searchValue: string }) => {
  const { searchValue } = params;
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      searching: formatMessage(messages.searching),
      noResults: formatMessage(messages.noResults),
      title: formatMessage(messages.title, { searchValue }),
    };
  }, [formatMessage, searchValue]);
  return translations;
};
export default useTranslations;
