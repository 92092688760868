import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

export type CenterServicesAppointmentPreviewTranslations = {
  leftSide: string;
  rightSide: string;
};

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations =
    useMemo<CenterServicesAppointmentPreviewTranslations>(() => {
      return {
        leftSide: formatMessage(messages.leftSide),
        rightSide: formatMessage(messages.rightSide),
      };
    }, [formatMessage]);
  return translations;
};
export default useTranslations;
