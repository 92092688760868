import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      labelPrimaryAddress: formatMessage(messages.labelPrimaryAddress),
      labelSecondaryAddress: formatMessage(messages.labelSecondaryAddress),
      labelCity: formatMessage(messages.labelCity),
      labelPostalCode: formatMessage(messages.labelPostalCode),
      labelState: formatMessage(messages.labelState),
      labelCountry: formatMessage(messages.labelCountry),
    };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
