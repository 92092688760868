import { useCallback, useState } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { TimeSlotDeletionConfirmationDialogProps } from "../TimeSlotDeletionConfirmationDialog";
import useTranslations from "./useTranslations";

type TimeSlotDeleteData = { id: string; name: string };

type TimeSlotDeleteDialogResult = TimeSlotDeletionConfirmationDialogProps & {
  onOpen: (timeSlotDeleteData: TimeSlotDeleteData) => void;
};

const useTimeSlotDeleteDialog = (params: {
  onTimeSlotDelete: (args: { id: string }) => void;
}): TimeSlotDeleteDialogResult => {
  const { onTimeSlotDelete } = params;
  const { setAlert } = useUIContext();

  const { translations } = useTranslations();
  const { defaultError } = translations;

  const deleteCenterTimeSlot = useFirebaseAppFunction("deleteCenterTimeSlot");
  const [timeSlotDeleteData, setTimeSlotDeleteData] =
    useState<TimeSlotDeleteData>({
      id: "",
      name: "",
    });

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onOpen: TimeSlotDeleteDialogResult["onOpen"] = (value) => {
    setTimeSlotDeleteData(value);
    setIsOpen(true);
  };

  const onClose = () => setIsOpen(false);

  const onSubmit: TimeSlotDeletionConfirmationDialogProps["onSubmit"] =
    useCallback(async () => {
      try {
        setIsLoading(true);
        await deleteCenterTimeSlot({ id: timeSlotDeleteData.id });

        onTimeSlotDelete({ id: timeSlotDeleteData.id });
        onClose();
      } catch (error) {
        setAlert({ isShown: true, severity: "error", message: defaultError });
      } finally {
        setIsLoading(false);
      }
    }, [
      defaultError,
      deleteCenterTimeSlot,
      timeSlotDeleteData.id,
      onTimeSlotDelete,
      setAlert,
    ]);

  return {
    name: timeSlotDeleteData.name,
    isOpen,
    onClose,
    onSubmit,
    isLoading,
    onOpen,
  };
};

export default useTimeSlotDeleteDialog;
