import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      labelId: formatMessage(messages.labelId),
      labelBirthDate: formatMessage(messages.labelBirthDate),
      labelPhoneNumber: formatMessage(messages.labelPhoneNumber),
      labelEmail: formatMessage(messages.labelEmail),
      labelAddress: formatMessage(messages.labelAddress),
      labelLegalGuardian: formatMessage(messages.labelLegalGuardian),
      labelRace: formatMessage(messages.labelRace),
      labelSex: formatMessage(messages.labelSex),
    };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
