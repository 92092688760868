import { SystemConfig } from "../../../hooks/useSystemConfig";
import { SettingsCenter } from "./getSettingsCenterData";
import { Package, PackageOffering, PackagesData } from "./types";

const normalizePackages = (
  center: SettingsCenter,
  systemConfig: SystemConfig,
): PackagesData => {
  const centerConfig = center.center_configs[0];

  const currencyCode =
    centerConfig?.default_currency_code || systemConfig.default_currency_code;

  const discountAmount =
    centerConfig?.discount_amount ?? systemConfig.discount_amount;

  const packages = center.center_packages.map<Package>((packageItem) => {
    const {
      id,
      name,
      visibility,
      price,
      center_offerings,
      deposit_amount,
      description,
      modality_name,
    } = packageItem;

    const offerings = center_offerings.map<PackageOffering>((offering) => {
      if (!offering.offering_id) throw new Error("Missing offering_id");
      return {
        id: offering.offering_id,
        name: offering.name,
        price: offering.price,
        side: offering.scan_side,
      };
    });

    return {
      id,
      name,
      visibility,
      price,
      offerings,
      depositAmount: deposit_amount,
      description,
      modalityName: modality_name,
    };
  });

  const offerings = center.center_offerings
    .map<PackagesData["offerings"][number]>((offering) => {
      const {
        name,
        visibility,
        price,
        deposit_amount,
        scan_side,
        center_package_id,
      } = offering;

      const id = offering.offering_id;

      if (!id) throw new Error("Missing offering id");

      return {
        id,
        name,
        visibility,
        price,
        scan_side,
        deposit_amount,
        center_package_id: center_package_id || undefined,
      };
    })
    .filter((offering) => offering.scan_side === "single");

  return { packages, currencyCode, minPrice: discountAmount, offerings };
};

export default normalizePackages;
