import React from "react";
import AuditLogItem from "../AuditLogItem";
import Grid from "../../Unknown/Grid";
import { AuditLogListProps } from "./types";
import useTranslations from "./useTranslations";
import getAuditLogChangedBy from "./getAuditLogChangedBy";
import getAuditLogChangedAt from "./getAuditLogChangedAt";
import getAuditLogChangeEvent from "./getAuditLogChangeEvent";

const AuditLogList = ({ auditLogs, timezone }: AuditLogListProps) => {
  const { systemTitle, ...translations } = useTranslations();
  return (
    <Grid container flexDirection="column" gap={4}>
      {auditLogs.map((auditLog, index) => {
        const withUnderline = index !== auditLogs.length - 1;

        const changedBy = getAuditLogChangedBy(auditLog, systemTitle);

        const changedAt = getAuditLogChangedAt(auditLog, timezone);

        const changeEvent = getAuditLogChangeEvent(auditLog);

        const changeTitle = changeEvent && translations[changeEvent];

        return (
          <AuditLogItem
            key={auditLog.id}
            changedAt={changedAt}
            changedBy={changedBy}
            changedTitle={changeTitle}
            changedFields={auditLog.fields}
            withUnderline={withUnderline}
          />
        );
      })}
    </Grid>
  );
};

export default AuditLogList;
