import { defineMessages } from "react-intl";

export default defineMessages({
  titleCenterServicesToRead: {
    id: "Unknown.DashboardHeader.titleCenterServicesToRead",
    defaultMessage: "Studies To Read",
  },
  titleAdmin: {
    id: "Unknown.DashboardHeader.titleAdmin",
    defaultMessage: "Admin Dashboard",
  },
});
