import { useMemo } from "react";
import { useIntl } from "react-intl";
import { getLocaleCurrencySymbol } from "../../../common/currencyHelpers";
import messages from "./messages";
import { Translations } from "./types";

const useTranslations = (params: {
  currencyCode: string;
  offeringsPrice: number;
}) => {
  const { currencyCode, offeringsPrice } = params;
  const { formatMessage, formatNumber, locale } = useIntl();

  const translations: Translations = useMemo(() => {
    const currencySymbol = getLocaleCurrencySymbol(currencyCode, locale);

    const price = formatNumber(offeringsPrice, {
      style: "currency",
      currency: currencyCode,
    }).replace(".00", "");

    return {
      visibilityPrivate: formatMessage(messages.visibilityPrivate),
      visibilityPublic: formatMessage(messages.visibilityPublic),
      textPrice: formatMessage(messages.textPrice, { price }),
      helperPrice: formatMessage(messages.helperPrice),
      currencySymbol,
    };
  }, [currencyCode, formatMessage, formatNumber, locale, offeringsPrice]);
  return translations;
};
export default useTranslations;
