import { CenterOperatingHoursDayInputValues } from "../CenterOperatingHoursDayInput";
import { CenterOperatingHoursFormValues } from "../CenterOperatingHoursForm";
import {
  OperatingHours,
  OperatingHoursWeekDay,
} from "../CenterSettingsContainer/types";

const days: OperatingHoursWeekDay[] = ["1", "2", "3", "4", "5", "6", "7"];

const getFormikInitialValues = (
  weekOperatingHours: OperatingHours["weekOperatingHours"],
): CenterOperatingHoursFormValues => {
  const operatingHours = days.reduce((acc, centerOperatingHoursItemKey) => {
    const centerOperatingHoursItem =
      weekOperatingHours[centerOperatingHoursItemKey];

    const { fromMinutesAtCenterTZDate, toMinutesAtCenterTZDate } =
      centerOperatingHoursItem || {};

    const isOpen = !!centerOperatingHoursItem;

    const formikDay: CenterOperatingHoursDayInputValues = {
      isOpen,
      fromMinutesAt: fromMinutesAtCenterTZDate || null,
      toMinutesAt: toMinutesAtCenterTZDate || null,
    };

    return {
      ...acc,
      [centerOperatingHoursItemKey]: formikDay,
    };
  }, {} as CenterOperatingHoursFormValues["operatingHours"]);

  return { operatingHours };
};

export default getFormikInitialValues;
