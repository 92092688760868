import React, { FC } from "react";
import { PAGE_SIZE } from "../../../common/constants";
import DataGrid from "../../Unknown/DataGrid";
import { initialGridState } from "./constants";
import Box from "../../Unknown/Box";
import { AppointmentFile } from "./types";
import useColumns from "./useColumns";
import useStyles from "./useStyles";

export type AppointmentFileGridProps = {
  files: AppointmentFile[];
};

const AppointmentFileGrid: FC<AppointmentFileGridProps> = ({ files }) => {
  const { root, hiddenHeaderSeparatorRoot, uploadedAtTimeRoot } = useStyles();

  const columns = useColumns({ hiddenHeaderSeparatorRoot, uploadedAtTimeRoot });

  return (
    <Box sx={{ width: "100%", display: "grid" }}>
      <DataGrid
        autoHeight
        getRowHeight={() => "auto"}
        className={root}
        columns={columns}
        hideFooterRowCount
        rows={files}
        hideFooter
        disableRowSelectionOnClick
        pageSizeOptions={[PAGE_SIZE]}
        initialState={initialGridState}
        sortingOrder={["desc", "asc"]}
      />
    </Box>
  );
};

export default AppointmentFileGrid;
