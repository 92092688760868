import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { SupabaseClient } from "@supabase/supabase-js";

export type SupabaseCenterUser = DatabaseRow<"center_users"> & {
  center_user_roles: DatabaseRow<"center_user_roles">[];
  center_user_configs: DatabaseRow<"center_user_configs">[];
};

const supabaseDatabaseUserDataRequest = `
*,
center_user_roles (*),
center_user_configs (*)
`;

const getSupabaseCenterUserData = async (params: {
  supabase: SupabaseClient;
  userId: string;
}): Promise<SupabaseCenterUser> => {
  const { supabase, userId } = params;

  const { data, error } = await supabase
    .from<SupabaseCenterUser>("center_users")
    .select(supabaseDatabaseUserDataRequest)
    .eq("id", userId)
    .limit(1)
    .limit(1, { foreignTable: "center_user_configs" })
    .single();

  if (error) {
    throw new Error("Unknown error");
  }

  return data;
};

export default getSupabaseCenterUserData;
