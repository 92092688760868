import React, { FC } from "react";
import Box from "../../Unknown/Box";
import useStyles from "./useStyles";
import Switch from "../../Unknown/Switch";
import FormControlLabel from "../../Unknown/FormControlLabel";
import useTranslations from "./useTranslations";
import { GridToolbarQuickFilter } from "../../Unknown/DataGrid";

export interface ShowReadSwitchProps {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
}

export interface AppointmentOfferingTableTopBarProps {
  showReadProps: ShowReadSwitchProps;
}

const AppointmentOfferingTableTopBar: FC<
  AppointmentOfferingTableTopBarProps
> = ({ showReadProps }) => {
  const { toolbarRoot } = useStyles();
  const { showReadLabel, findNameLabel } = useTranslations();

  return (
    <Box
      className={toolbarRoot}
      p={2}
      pl={3}
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex" justifyContent="flex-start">
        <FormControlLabel
          control={<Switch size="small" {...showReadProps} />}
          label={showReadLabel}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <GridToolbarQuickFilter placeholder={findNameLabel} />
      </Box>
    </Box>
  );
};

export default AppointmentOfferingTableTopBar;
