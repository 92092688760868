export const centerRequest = `
id,
timezone,
center_operating_hours (
  day_of_week_number,
  from_minutes_at,
  to_minutes_at
),
center_configs (
  appointment_checkout_duration_seconds,
  appointment_checkout_max_repeat_count,
  minimum_time_before_booking_seconds
)
`;

export const centerTimeSlotsRequest = `
type,
center_id,
expires_at,
started_at,
ended_at
`;

export const defaultCalendarMonth = new Date();
