import { useMemo } from "react";
import { useIntl } from "react-intl";
import { getLocaleCurrencySymbol } from "../../../common/currencyHelpers";

const useTranslations = (params: { currencyCode: string }) => {
  const { currencyCode } = params;
  const { locale } = useIntl();

  const currencySymbol = getLocaleCurrencySymbol(currencyCode, locale);

  const translations = useMemo(() => {
    return {
      currencySymbol,
    };
  }, [currencySymbol]);
  return translations;
};
export default useTranslations;
