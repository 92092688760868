import React, { FC, MutableRefObject } from "react";
import Box from "../Box";
import useStyles from "./useStyles";
import TextField from "../TextField";
import InputAdornment from "../InputAdornment";
import { ClearOutlinedIcon, SearchOutlinedIcon } from "../Icons";
import IconButton from "../IconButton";
import Tooltip from "../Tooltip";
import useElementPosition from "../../../hooks/useElementPosition";

type Position = {
  startOffsetLeft: number;
  openedOffsetLeft: number;
  openedWidth: number;
  openedOffsetHeight: number;
};

export type NavBarSearchInputProps = {
  position: Position;
  inputRef: MutableRefObject<HTMLElement | null>;
  inputBoxRef: MutableRefObject<HTMLDivElement | null>;
  isOpen: boolean;
  value: string;
  setValue: (value: string) => void | Promise<void>;
  tooltipText: string;
};

const NavBarSearchInput: FC<NavBarSearchInputProps> = ({
  position,
  inputRef,
  inputBoxRef,
  isOpen,
  value,
  setValue,
  tooltipText,
}) => {
  const [rootRef, rootPosition] = useElementPosition();

  const {
    textFieldRoot,
    questionRoot,
    clearButtonRoot,
    tooltipTooltip,
    tooltipArrow,
  } = useStyles();

  const containerSx = {
    transition: "width .5s, left .5s, visibility .5s",
    position: "absolute",
    zIndex: 10,
    top: (position.openedOffsetHeight - rootPosition.offsetHeight) / 4,
    visibility: isOpen ? "visible" : "hidden",
    width: isOpen ? position.openedWidth : 0,
    left: isOpen ? position.openedOffsetLeft : position.startOffsetLeft,
  };

  return (
    <Box ref={rootRef} sx={containerSx}>
      <Box ref={inputBoxRef}>
        <TextField
          inputRef={inputRef}
          className={textFieldRoot}
          fullWidth
          value={value}
          onChange={(event) => setValue(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip
                  title={tooltipText}
                  arrow
                  classes={{ tooltip: tooltipTooltip, arrow: tooltipArrow }}
                >
                  <Box className={questionRoot}>?</Box>
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {value && (
                  <IconButton
                    onClick={() => setValue("")}
                    className={clearButtonRoot}
                    edge="end"
                  >
                    <ClearOutlinedIcon color="error" />
                  </IconButton>
                )}
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default NavBarSearchInput;
