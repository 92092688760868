import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import { CenterContactInformationValidationTranslations } from "./getCenterContactInformationFormSchema";
import messages from "./messages";

const useFormTranslations = (params: { maxStringLength: number }) => {
  const { maxStringLength } = params;

  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
    };

    const validationTranslations: CenterContactInformationValidationTranslations =
      {
        requiredField: formatMessage(commonMessages.requiredField),
        maxStringLengthError: formatMessage(
          commonMessages.maxStringLengthError,
          {
            value: maxStringLength,
          },
        ),
        phoneNumberInvalidError: formatMessage(
          messages.phoneNumberInvalidError,
        ),
        faxNumberInvalidError: formatMessage(messages.faxNumberInvalidError),
        emailInvalidError: formatMessage(messages.emailInvalidError),
        labelPhone: formatMessage(messages.labelPhone),
        labelFax: formatMessage(messages.labelFax),
      };

    return { translations, validationTranslations };
  }, [formatMessage, maxStringLength]);

  return result;
};
export default useFormTranslations;
