import Big from "big.js";

const calcTotalPrice = ({
  totalCostAmount,
  discountAmount,
}: {
  totalCostAmount?: number | null;
  discountAmount?: number | null;
}): number => {
  if (!totalCostAmount) return 0;

  return new Big(totalCostAmount).minus(discountAmount || 0).toNumber();
};

export default calcTotalPrice;
