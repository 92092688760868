import { pascalCase } from "change-case";

const getStatusTranslation = (
  status: string,
  translations: Record<string, string>,
) => {
  const statusTranslationKey = `status${pascalCase(status)}`;

  if (statusTranslationKey in translations) {
    return translations[statusTranslationKey];
  }

  const defaultTranslationKey = "statusUnknown";

  if (defaultTranslationKey in translations) {
    return translations[defaultTranslationKey];
  }

  return "";
};

export default getStatusTranslation;
