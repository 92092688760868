import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

const availableModals = [
  "address",
  "contactInformation",
  "operatingHours",
  "discount",
] as const;

type OpenedModal = (typeof availableModals)[number] | null;

const useInfoSettingsOpenModals = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [openedModal, setOpenedModal] = useState<OpenedModal | null>(() => {
    const modal = searchParams.get("modal");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (availableModals.includes(modal as any)) return modal as OpenedModal;

    return null;
  });

  const onModalChange = useCallback(
    (modal: OpenedModal) => {
      setOpenedModal(modal);
      setSearchParams((prev) => {
        if (modal) {
          return { ...prev, modal };
        }

        prev.delete("modal");
        return prev;
      });
    },
    [setSearchParams],
  );

  return { openedModal, onModalChange };
};

export default useInfoSettingsOpenModals;
