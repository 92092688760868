import React, { FC, ReactNode } from "react";
import { useFormikContext } from "formik";
import CenterSettingsDialog, {
  CenterSettingsDialogProps,
} from "../CenterSettingsDialog";
import useTranslations from "./useTranslations";
import CircularProgress from "../../Unknown/CircularProgress";

export type CenterSettingsFormikDialogProps = {
  isOpened: boolean;
  title: string;
  onClose: () => void;
  children: ReactNode;
  maxWidth?: CenterSettingsDialogProps["maxWidth"];
};

const CenterSettingsFormikDialog: FC<CenterSettingsFormikDialogProps> = ({
  isOpened,
  title,
  onClose,
  children,
  maxWidth,
}) => {
  const { btnCancel, btnSave } = useTranslations();
  const { handleSubmit, resetForm, isSubmitting } = useFormikContext();

  const onCancelClick = () => {
    onClose();
    resetForm();
  };

  const onSaveClick = () => {
    handleSubmit();
  };

  const actionButtons = [
    {
      text: btnCancel,
      props: { onClick: onCancelClick, disabled: isSubmitting },
    },
    {
      text: btnSave,
      props: {
        onClick: onSaveClick,
        disabled: isSubmitting,
        startIcon: isSubmitting ? <CircularProgress size={20} /> : null,
      },
    },
  ];

  return (
    <CenterSettingsDialog
      open={isOpened}
      title={title}
      onClose={onCancelClick}
      actionButtons={actionButtons}
      maxWidth={maxWidth}
    >
      {children}
    </CenterSettingsDialog>
  );
};

export default CenterSettingsFormikDialog;
