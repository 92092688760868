import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      labelPhone: formatMessage(messages.labelPhone),
      labelFax: formatMessage(messages.labelFax),
      labelEmail: formatMessage(messages.labelEmail),
    };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
