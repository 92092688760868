import { useCallback, useState } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useFirebaseStorageUploader from "../../../hooks/useFirebaseStorageUploader";
import { AppointmentFile } from "./types";

const useForm = (params: {
  appointmentId: string;
  files: File[];
  handleClose: () => void;
  handleAddFiles: (files: AppointmentFile[]) => void | Promise<void>;
}) => {
  const { appointmentId, files, handleClose, handleAddFiles } = params;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const firebaseStorageUploader = useFirebaseStorageUploader();
  const addAppointmentFiles = useFirebaseAppFunction("addAppointmentFiles");

  const handleSubmit = useCallback(async () => {
    try {
      setErrorMessage(null);
      setIsLoading(true);

      const path = `appointment/${appointmentId}/files`;

      const filesData = await Promise.all(
        files.map(async (file) => {
          const { downloadUrl } = await firebaseStorageUploader({
            path,
            file,
          });

          const fileData = {
            name: file.name,
            url: downloadUrl,
          };

          return fileData;
        }),
      );

      const { data } = await addAppointmentFiles({
        appointmentId,
        files: filesData,
      });

      const addFilesParams = data.files.map((file) => {
        const { id, name, url } = file;

        return {
          id,
          name,
          downloadUrl: url,
          uploadedAt: new Date(),
        };
      });

      handleAddFiles(addFilesParams);

      handleClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [
    addAppointmentFiles,
    appointmentId,
    files,
    firebaseStorageUploader,
    handleAddFiles,
    handleClose,
  ]);

  return {
    isLoading,
    errorMessage,
    handleSubmit,
  };
};

export default useForm;
