import format from "date-fns/format";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { View } from "../TimeSlotCalendarGrid/helpers";

const useSearchCalendarParams = () => {
  const [search, setSearch] = useSearchParams();

  const initialCalendarSearchParams = useMemo(() => {
    const initialDate = search.get("date") || format(new Date(), "yyyy-MM-dd");

    const initialView = (search.get("view") as View) || "week";

    return { initialDate, initialView };
  }, [search]);

  const setSearchCalendarParams = (params: { date?: string; view?: View }) => {
    const { date, view } = params;

    if (date || view) {
      setSearch((prev) => {
        const newParams: Record<string, string> = {};

        if (date) newParams.date = date;
        if (view) newParams.view = view;

        return Object.assign(newParams, prev);
      });
    }
  };

  return { initialCalendarSearchParams, setSearchCalendarParams };
};

export default useSearchCalendarParams;
