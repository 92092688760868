import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Button from "../../../Unknown/Button";
import { PatientFormPreviewGridForm } from "../types";
import useFirebaseAppFunction from "../../../../hooks/useFirebaseAppFunction";
import useDefaultErrorWrapper from "../../../../hooks/useDefaultErrorWrapper";

type ColumnTranslations = {
  btnTitle: string;
};
type ColumnValue = {
  patientId: string;
  fileName: string;
};

const useColumnBtnDownload = (params: {
  translations: ColumnTranslations;
  headerClassName?: string;
}): GridColDef<PatientFormPreviewGridForm, ColumnValue> => {
  const { translations, headerClassName } = params;
  const { btnTitle } = translations;
  const getPatientFormSignedUrl = useFirebaseAppFunction(
    "getPatientFormSignedUrl",
  );
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const onClick = async (patientId: string, fileName: string) => {
    const { data } = await runAsyncFunction(getPatientFormSignedUrl, {
      fileName,
      patientId,
    });
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = data.url;
    link.setAttribute("download", fileName);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return {
    field: "btnDownload",
    headerName: "",
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    headerClassName,
    valueGetter: (getterParams) => {
      const { patientId, fileName } = getterParams.row;
      return { patientId, fileName };
    },
    renderCell: (renderParams) => {
      if (!renderParams.value) return null;
      const { patientId, fileName } = renderParams.value;

      return (
        <Button
          sx={{ fontSize: 13 }}
          onClick={() => onClick(patientId, fileName)}
        >
          {btnTitle}
        </Button>
      );
    },
  };
};

export default useColumnBtnDownload;
