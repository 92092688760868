import React from "react";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import Box from "../../Unknown/Box";
import AuditLogItemFieldsEditedBlock from "../AuditLogItemFieldsEditedBlock";

export interface AuditLogItemProps {
  changedBy: string;
  changedAt: string;
  changedTitle?: string;
  changedFields?: string[] | null;
  withUnderline?: boolean;
}

const AuditLogItem = ({
  changedAt,
  changedBy,
  changedTitle,
  changedFields,
  withUnderline,
}: AuditLogItemProps) => {
  return (
    <Grid container flexDirection="column">
      <Typography
        variant="subtitle2"
        lineHeight={1.43}
        letterSpacing={0.17}
        pb={0.6}
      >
        {`${changedBy} — ${changedAt}`}
      </Typography>
      <Grid container flexDirection="column">
        {changedTitle && (
          <Typography variant="body2" lineHeight={1.43} letterSpacing={0.17}>
            {changedTitle}
          </Typography>
        )}
        {changedFields && (
          <AuditLogItemFieldsEditedBlock changedFields={changedFields} />
        )}
      </Grid>
      {withUnderline && (
        <Box
          pt={2}
          sx={{
            borderBottom: `1px dashed`,
            borderBottomColor: "alpha.25",
          }}
        />
      )}
    </Grid>
  );
};

export default AuditLogItem;
