import React, { FC, useState } from "react";
import { Formik } from "formik";
import AppointmentNoteAddDialog, {
  AppointmentNoteAddDialogProps,
} from "../AppointmentNoteAddDialog";
import useFormikProps from "./useFormikProps";
import useTranslations from "./useTranslations";
import { FormValues } from "../AppointmentNoteAddForm/types";

export type AppointmentNoteAddDialogContainerProps = {
  isOpen: boolean;
  appointmentId: string | null;
  offerings: AppointmentNoteAddDialogProps["offerings"];
  handleClose: () => void;
  handleAddNote: (values: FormValues & { id: string }) => void | Promise<void>;
};

const AppointmentNoteAddDialogContainer: FC<
  AppointmentNoteAddDialogContainerProps
> = ({ isOpen, offerings, appointmentId, handleClose, handleAddNote }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { translations } = useTranslations();

  const formikProps = useFormikProps({
    setErrorMessage,
    handleAddNote,
    handleClose,
    appointmentId,
  });

  return (
    <Formik {...formikProps}>
      <AppointmentNoteAddDialog
        isOpen={isOpen}
        handleClose={handleClose}
        offerings={offerings}
        errorMessage={errorMessage}
        formTranslations={translations}
      />
    </Formik>
  );
};

export default AppointmentNoteAddDialogContainer;
