import { OfferingVisibility, PackageVisibility } from "./types";

export const allowedOfferingVisibility: OfferingVisibility[] = [
  "public",
  "only_package",
  "private",
];

export const allowedPackageVisibility: PackageVisibility[] = [
  "public",
  "private",
];
