import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import parseISO from "date-fns/parseISO";
import { SearchPatient } from "../../Unknown/AppContext/getSearchPatient";
import { formatInternational } from "../../../common/phoneNumberHelpers";

type SearchPatientItemAppointment = {
  id: string;
  centerName: string;
  startedAt: Date;
};

export type SearchPatientItem = {
  id: string;
  details: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    formattedMobilePhoneNumber: string;
    countryCode: string;
    url: string;
  };
  dateBirth: Date;
  appointments: SearchPatientItemAppointment[];
};

const TMP_COUNTRY_CODE = "US";

const convertAppointmentPatientDetailsToTableItem = (
  appointmentPatientDetails: SearchPatient["appointment_patient_details"][number],
): SearchPatientItemAppointment => {
  const { appointment_id, appointment } = appointmentPatientDetails;
  const { name } = appointment.center;
  const { started_at } = appointment.center_time_slot;

  return {
    id: appointment_id,
    centerName: name,
    startedAt: parseISO(started_at),
  };
};

const useRows = (patients: SearchPatient[]) => {
  const { search } = useLocation();
  const { role } = useParams();

  const data = useMemo<SearchPatientItem[]>(() => {
    return patients?.map((patient) => {
      const countryCode = TMP_COUNTRY_CODE;

      const formattedMobilePhoneNumber = formatInternational({
        phoneNumber: patient.mobile_phone_number,
      });

      const url = `/${role}/search/patient/${patient.mrn}${search}`;

      return {
        id: patient.id,
        details: {
          firstName: patient.first_name,
          lastName: patient.last_name,
          email: patient.email,
          phoneNumber: patient.mobile_phone_number || "",
          formattedMobilePhoneNumber: formattedMobilePhoneNumber || "",
          countryCode,
          url,
        },
        dateBirth: parseISO(patient.date_birth),
        appointments: patient.appointment_patient_details.map(
          convertAppointmentPatientDetailsToTableItem,
        ),
      };
    });
  }, [patients, role, search]);

  return data;
};

export default useRows;
