import React, { FC } from "react";
import Grid from "../../Unknown/Grid";
import useTranslations, { DayTranslations } from "./useTranslations";
import CenterOperatingHoursDayInput, {
  CenterOperatingHoursDayInputValues,
} from "../CenterOperatingHoursDayInput";

const days = ["1", "2", "3", "4", "5", "6", "7"] as const;

export type OperatingHoursWeekDayKeyFormik = (typeof days)[number];

export type CenterOperatingHoursFormValues = {
  operatingHours: Record<
    OperatingHoursWeekDayKeyFormik,
    CenterOperatingHoursDayInputValues
  >;
};

const inputSpacing = 3;

const dayTranslationKeys: Record<
  OperatingHoursWeekDayKeyFormik,
  keyof DayTranslations
> = {
  "1": "longMonday",
  "2": "longTuesday",
  "3": "longWednesday",
  "4": "longThursday",
  "5": "longFriday",
  "6": "longSaturday",
  "7": "longSunday",
};

const CenterOperatingHoursForm: FC = () => {
  const { translations, dayTranslations } = useTranslations();

  return (
    <Grid container spacing={inputSpacing} direction="row">
      <Grid item container spacing={inputSpacing}>
        {days.map((dayKey) => {
          const dayNameKey = dayTranslationKeys[dayKey];
          const dayName = dayTranslations[dayNameKey];

          return (
            <CenterOperatingHoursDayInput
              key={dayKey}
              nameKey={`operatingHours.${dayKey}`}
              translations={{
                labelOpen: translations.labelOpen,
                toText: translations.toText,
                labelTime: translations.labelTime,
                dayName,
              }}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default CenterOperatingHoursForm;
