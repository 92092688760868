import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import { maxFilesCount, maxFileSizeMegaBytes, validFormats } from "./constants";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
      title: formatMessage(messages.title),
      btnSubmit: formatMessage(messages.btnSubmit),
      btnCancel: formatMessage(messages.btnCancel),
      filesHelperText: formatMessage(messages.filesHelperText),
      labelFiles: formatMessage(messages.labelFiles),
      errorMaxSize: formatMessage(messages.errorMaxSize, {
        value: maxFileSizeMegaBytes,
      }),
      errorMaxCount: formatMessage(messages.errorMaxCount, {
        value: maxFilesCount,
      }),
      errorFormatInvalid: formatMessage(messages.errorFormatInvalid, {
        value: validFormats,
      }),
    };

    return { translations };
  }, [formatMessage]);

  return result;
};
export default useTranslations;
