export const centerOfferingsRequest = `
  id,
  center_id,
  price,
  duration_minutes,
  modality_name,
  scan_side,
  visibility,
  name
`;
