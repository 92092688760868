import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemBookedDate, AppointmentRowItem } from "../types";

export type ColumnBookedDateTranslations = {
  columnBookedDate: string;
};

const getColumnDate = (params: {
  columnItem: AppointmentColumnItemBookedDate;
  translations: ColumnBookedDateTranslations;
}): GridColDef<AppointmentRowItem, Date> => {
  const { columnItem, translations } = params;
  const { field, localeFormat } = columnItem;

  return {
    field,
    disableColumnMenu: true,
    headerName: translations.columnBookedDate,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    flex: 0.5,
    type: "date",
    valueGetter: ({ row }) => {
      return row.createdAtCenterTimezone;
    },
    renderCell: ({ row }) => {
      const { createdAtCenterTimezone, center } = row;
      const { locale } = center;
      const centerLocale = localeFormat === "center" ? locale : undefined;

      const formattedDate =
        createdAtCenterTimezone.toLocaleDateString(centerLocale);

      return formattedDate;
    },
  };
};

export default getColumnDate;
