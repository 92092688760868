import * as Yup from "yup";
import {
  CenterOfferingFormValues,
  LabelTranslationsKey,
} from "../CenterOfferingForm/types";

const getCenterOfferingFormSchema = (params: {
  labelTranslations: Record<LabelTranslationsKey, string>;
  maxStringLength: number;
  maxDescriptionLength: number;
  maxDurationMinutes: number;
  minPrice: number;
  maxPrice: number;
}) => {
  const {
    labelTranslations,
    maxStringLength,
    maxDescriptionLength,
    maxDurationMinutes,
    minPrice,
    maxPrice,
  } = params;

  const requiredString = Yup.string().trim().max(maxStringLength).required();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Yup.object().shape<Record<keyof CenterOfferingFormValues, any>>({
    name: requiredString.label(labelTranslations.labelName),
    modalityName: requiredString.label(labelTranslations.labelModalityName),
    description: Yup.string()
      .label(labelTranslations.labelDescription)
      .trim()
      .required()
      .max(maxDescriptionLength),
    scanSide: requiredString.label(labelTranslations.labelScanSide),
    visibility: requiredString.label(labelTranslations.labelVisibility),
    durationMinutes: Yup.number()
      .integer()
      .label(labelTranslations.labelDurationMinutes)
      .positive()
      .max(maxDurationMinutes)
      .required(),
    price: Yup.number()
      .label(labelTranslations.labelPrice)
      .positive()
      .moreThan(minPrice)
      .lessThan(maxPrice)
      .required(),
    depositAmount: Yup.number()
      .label(labelTranslations.labelDepositAmount)
      .positive()
      .lessThan(Yup.ref("price"))
      .required(),
  });
};

export default getCenterOfferingFormSchema;
