import React, { FC } from "react";
import CustomerEditForm from "../CustomerEditForm";
import { CustomerEditFormTranslations } from "../CustomerEditForm/types";
import useConfirmationCode from "./useConfirmationCode";

export type CustomerEditFormContainerProps = {
  translations: CustomerEditFormTranslations;
  customerId: string;
  isOtherPerson?: boolean;
  namePrefix?: string;
  isPhoneShown?: boolean;
};

const CustomerEditFormContainer: FC<CustomerEditFormContainerProps> = ({
  translations,
  customerId,
  namePrefix,
  isPhoneShown,
  isOtherPerson,
}) => {
  const {
    isConfirmationCodeShown,
    isSending,
    isCodeSent,
    handleSendCodeClick,
  } = useConfirmationCode({ customerId, namePrefix });

  return (
    <CustomerEditForm
      translations={translations}
      namePrefix={namePrefix}
      isPhoneShown={isPhoneShown}
      isConfirmationCodeShown={isConfirmationCodeShown && !isOtherPerson}
      isSendingCode={isSending}
      isSendCodeDisabled={isCodeSent}
      handleSendCodeClick={handleSendCodeClick}
    />
  );
};

export default CustomerEditFormContainer;
