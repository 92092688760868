import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      title: formatMessage(messages.title),
      defaultError: formatMessage(commonMessages.defaultError),
    };

    return translations;
  }, [formatMessage]);

  return result;
};
export default useTranslations;
