import React, { FC } from "react";
import Box from "../../Unknown/Box";
import Link from "../../Unknown/Link";
import Typography from "../../Unknown/Typography";
import useStyles from "./useStyles";

type PatientEmailProps = {
  email: string;
};

const PatientEmail: FC<PatientEmailProps> = ({ email }) => {
  const { linkTypographyRoot } = useStyles();

  return (
    <Box overflow="hidden" textOverflow="ellipsis">
      <Typography
        variant="body2"
        href={`mailto:${email}`}
        component={Link}
        className={linkTypographyRoot}
        overflow="hidden"
        textOverflow="ellipsis"
        title={email}
        display="block"
      >
        {email}
      </Typography>
    </Box>
  );
};

export default PatientEmail;
