import { defineMessages } from "react-intl";

const messages = defineMessages({
  tabCenterInfo: {
    id: "Center.CenterSettingsContainer.tabCenterInfo",
    defaultMessage: "Center information",
  },
  tabOfferings: {
    id: "Center.CenterSettingsContainer.tabOfferings",
    defaultMessage: "Offerings",
  },
  tabPackages: {
    id: "Center.CenterSettingsContainer.tabPackages",
    defaultMessage: "Packages",
  },
  tabAddOns: {
    id: "Center.CenterSettingsContainer.tabAddOns",
    defaultMessage: "Add-ons",
  },
  tabRegister: {
    id: "Center.CenterSettingsContainer.tabRegister",
    defaultMessage: "Register center",
  },
  registerCenterSuccessMessage: {
    id: "Center.CenterSettingsContainer.registerCenterSuccessMessage",
    defaultMessage: "Center Registered Successfully",
  },
  registerCenterErrorMessage: {
    id: "Center.CenterSettingsContainer.registerCenterErrorMessage",
    defaultMessage: "Failed to register the center",
  },
});

export default messages;
