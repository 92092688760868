import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { Appointment } from "../AppointmentCardsContainer/types";

interface CheckIsScreeningFormButtonShownParams {
  appointment: Appointment | null;
  role?: DatabaseEnum["center_user_role"];
}

const checkIsScreeningFormButtonShown = ({
  appointment,
  role,
}: CheckIsScreeningFormButtonShownParams): boolean => {
  if (!appointment) return false;

  switch (role) {
    case "technologist":
      return (
        ["checked_in"].includes(appointment.status) &&
        !appointment.appointment_forms.find(
          (appointmentForm) => appointmentForm.type === "screening",
        )?.center_user_signed_at
      );
    default:
      return false;
  }
};

export default checkIsScreeningFormButtonShown;
