import React, { FC } from "react";
import { useFormikContext } from "formik";
import AppointmentOfferingAdditionalViewForm, {
  AppointmentOfferingAdditionalViewFormProps,
} from "../AppointmentOfferingAdditionalViewForm";
import StatusDialog from "../../Unknown/StatusDialog";
import useTranslations from "./useTranslations";
import useActionButtons from "./useActionButtons";
import { AppointmentOfferingAdditionalViewFormValues } from "../AppointmentOfferingAdditionalViewForm/types";

export type AppointmentOfferingAdditionalViewDialogProps = {
  isOpened: boolean;
  labelTranslations: AppointmentOfferingAdditionalViewFormProps["labelTranslations"];
  handleClose: () => void;
  errorMessage?: string | null;
};

const AppointmentOfferingAdditionalViewDialog: FC<
  AppointmentOfferingAdditionalViewDialogProps
> = ({ isOpened, labelTranslations, handleClose, errorMessage }) => {
  const { title } = useTranslations();

  const { handleSubmit, isSubmitting, isValid } =
    useFormikContext<AppointmentOfferingAdditionalViewFormValues>();

  const actionButtons = useActionButtons({
    handleClose,
    handleSubmit,
    isLoading: isSubmitting,
    errorMessage,
    isSubmitDisabled: !isValid,
  });

  return (
    <StatusDialog
      title={title}
      isLoading={isSubmitting}
      errorMessage={errorMessage}
      actionButtons={actionButtons}
      open={isOpened}
      fullWidth
      PaperProps={{ sx: { maxWidth: 500 } }}
    >
      <AppointmentOfferingAdditionalViewForm
        labelTranslations={labelTranslations}
      />
    </StatusDialog>
  );
};

export default AppointmentOfferingAdditionalViewDialog;
