import React, { useState, SyntheticEvent, useCallback } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import Button from "../../Unknown/Button";
import Box from "../../Unknown/Box";
import useTranslations from "./useTranslations";
import CircularProgress from "../../Unknown/CircularProgress";

interface StripePaymentElementProps {
  onError: (message: string) => void;
}

const StripePaymentElement: React.FC<StripePaymentElementProps> = ({
  onError,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { appointmentId, role } = useParams();
  const translations = useTranslations();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (event: SyntheticEvent) => {
      setIsLoading(true);
      try {
        event.preventDefault();

        if (!stripe || !elements) {
          return;
        }

        const result = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/${role}/appointment/${appointmentId}`,
          },
        });

        if (result.error) {
          throw new Error(result.error.message);
        }
      } catch (error) {
        if (error instanceof Error) {
          onError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [appointmentId, elements, onError, role, stripe],
  );

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Box mt={3}>
        <Button
          fullWidth
          variant="contained"
          disabled={(!stripe && !elements) || isLoading}
          type="submit"
          size="large"
          startIcon={
            !!isLoading && <CircularProgress size={20} color="inherit" />
          }
        >
          {translations.submit}
        </Button>
      </Box>
    </form>
  );
};

export default StripePaymentElement;
