import React, { FC } from "react";
import DataGrid from "../../Unknown/DataGrid";
import Box from "../../Unknown/Box";
import { AppointmentReport } from "./types";
import useColumns from "./useColumns";
import useStyles from "./useStyles";

export type ReportGridProps = {
  reports: AppointmentReport[];
  onResendClick: (id: string) => void;
  onConfirmReceiptClick: (id: string) => void;
  onUnexpectedFindingsClick: (id: string) => void;
};

const ReportGrid: FC<ReportGridProps> = ({
  reports,
  onResendClick,
  onConfirmReceiptClick,
  onUnexpectedFindingsClick,
}) => {
  const { root, hiddenHeaderSeparatorRoot } = useStyles();

  const columns = useColumns({
    hiddenHeaderSeparatorRoot,
    onResendClick,
    onUnexpectedFindingsClick,
    onConfirmReceiptClick,
  });

  return (
    <Box sx={{ width: "100%", display: "grid" }}>
      <DataGrid
        autoHeight
        hideFooter
        getRowHeight={() => "auto"}
        className={root}
        columns={columns}
        hideFooterRowCount
        rows={reports}
        disableRowSelectionOnClick
        sortingOrder={["desc", "asc"]}
      />
    </Box>
  );
};

export default ReportGrid;
