import React, { FC } from "react";
import DataGrid from "../../Unknown/DataGrid";
import Box from "../../Unknown/Box";
import { CenterService } from "./types";
import useColumns from "./useColumns";
import useStyles from "./useStyles";

export type CenterServiceAppointmentCardGridProps = {
  centerServices: CenterService[];
  onModifyOfferingClick: (id: string) => void;
  onAddAdditionalViewClick: (id: string) => void;
  onReadStudyClick: ({ id, url }: { id: string; url: string }) => void;
  onEditStudyClick: ({ url }: { url: string }) => void;
};

const CenterServiceAppointmentCardGrid: FC<
  CenterServiceAppointmentCardGridProps
> = ({
  centerServices,
  onModifyOfferingClick,
  onAddAdditionalViewClick,
  onReadStudyClick,
  onEditStudyClick,
}) => {
  const { root } = useStyles();
  const columns = useColumns({
    onModifyOfferingClick,
    onAddAdditionalViewClick,
    onReadStudyClick,
    onEditStudyClick,
  });

  return (
    <Box sx={{ width: "100%", display: "grid" }}>
      <DataGrid
        autoHeight
        getRowHeight={() => "auto"}
        className={root}
        columns={columns}
        hideFooter
        hideFooterRowCount
        rows={centerServices}
        disableRowSelectionOnClick
        initialState={{
          sorting: { sortModel: [{ field: "name", sort: "asc" }] },
        }}
        sortingOrder={["desc", "asc"]}
      />
    </Box>
  );
};

export default CenterServiceAppointmentCardGrid;
