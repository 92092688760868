import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Package.CenterPackageArchiveDialog.title",
    defaultMessage: "Archive Package",
  },
  description: {
    id: "Package.CenterPackageArchiveDialog.description",
    defaultMessage:
      "Are you sure you want to archive the <b>{name}</b> package? Once archived, you will no longer be able to sell this package.",
  },
  btnCancel: {
    id: "Package.CenterPackageArchiveDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnArchive: {
    id: "Package.CenterPackageArchiveDialog.btnArchive",
    defaultMessage: "Archive",
  },
});

export default messages;
