import React, { FC, useEffect } from "react";
import { useUIContext } from "../../Unknown/UIContext";
import { defaultCalendarMonth } from "./constants";
import { Appointment, Center } from "./types";
import useAvailableTimeSlots from "./useAvailableTimeSlots";
import useCalendarView from "./useCalendarView";
import useCenterTimeSlots from "./useCenterTimeSlots";
import useRescheduleCalendar from "./useRescheduleCalendar";
import useRescheduleTimePicker from "./useRescheduleTimePicker";
import useSubmitDialog from "./useSubmitDialog";
import AppointmentBookAdditionalViewsDialog from "../AppointmentBookAdditionalViewsDialog";

export type AppointmentBookAdditionalViewsDialogContainerProps = {
  isOpen: boolean;
  appointment: Appointment | null;
  center: Center | null;
  handleClose: () => void;
  handleChangeAppointmentStartedAt: (
    startCenterTimezone: Date,
  ) => void | Promise<void>;
};

const AppointmentBookAdditionalViewsDialogContainer: FC<
  AppointmentBookAdditionalViewsDialogContainerProps
> = ({
  isOpen,
  appointment,
  center,
  handleClose,
  handleChangeAppointmentStartedAt,
}) => {
  const { setAlert } = useUIContext();

  const {
    selectedTime,
    onTimeChange,
    selectedDate,
    onDateChange,
    selectedMonth,
    onMonthChange,
    resetForm,
    overriddenTime,
    onTimeOverride,
  } = useCalendarView(defaultCalendarMonth);

  const { isLoading, errorMessage, data, refetch } = useCenterTimeSlots({
    center,
    monthDate: selectedMonth,
  });

  const availableTimeSlots = useAvailableTimeSlots({
    monthDate: selectedMonth,
    center,
    appointment,
    centerTimeSlots: data,
  });

  useEffect(() => {
    if (!errorMessage) return;

    setAlert({
      isShown: true,
      severity: "error",
      message: errorMessage,
    });
  }, [setAlert, errorMessage]);

  const calendarProps = useRescheduleCalendar({
    selectedDate,
    selectedMonth,
    isLoading,
    center,
    onDateChange,
    onMonthChange,
    refetchCenterTimeSlots: refetch,
  });

  const timePickerProps = useRescheduleTimePicker({
    availableTimeSlots,
    selectedDate,
    selectedTime,
    onTimeChange,
  });

  const submitAppointmentRescheduleProps = useSubmitDialog({
    appointment,
    center,
    selectedDate,
    selectedTime,
    overriddenTime,
    handleClose,
    resetForm,
    handleChangeAppointmentStartedAt,
    onTimeOverride,
  });

  if (!appointment) return null;

  return (
    <AppointmentBookAdditionalViewsDialog
      {...submitAppointmentRescheduleProps}
      isOpen={isOpen}
      calendarProps={calendarProps}
      timePickerProps={timePickerProps}
      offerings={appointment.offerings || []}
      overriddenTime={overriddenTime}
      onTimeOverride={onTimeOverride}
    />
  );
};

export default AppointmentBookAdditionalViewsDialogContainer;
