import { defineMessages } from "react-intl";

const messages = defineMessages({
  visibilityPrivate: {
    id: "Package.CenterPackageForm.visibilityPrivate",
    defaultMessage: "Private",
  },
  visibilityPublic: {
    id: "Package.CenterPackageForm.visibilityPublic",
    defaultMessage: "Public",
  },
  textPrice: {
    id: "Package.CenterPackageForm.textPrice",
    defaultMessage: "Price of offerings: {price}",
  },
  helperPrice: {
    id: "Package.CenterPackageForm.helperPrice",
    defaultMessage: "If no price is set this price is applied",
  },
});

export default messages;
