import React, { FC } from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import Box from "../Box";
import Typography from "../Typography";
import useStyles from "./useStyles";

export type DragDropFileFieldBaseProps = {
  rootProps: DropzoneRootProps;
  inputProps: DropzoneInputProps;
  helperText?: string;
};

const DragDropFileFieldBase: FC<DragDropFileFieldBaseProps> = ({
  rootProps,
  inputProps,
  helperText,
}) => {
  const { helperTextRoot, dropzoneRoot } = useStyles();

  return (
    <Box {...rootProps} className={dropzoneRoot}>
      <input {...inputProps} />
      {helperText && (
        <Typography variant="body2" className={helperTextRoot}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default DragDropFileFieldBase;
