import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelDurationMinutes: {
    id: "AppointmentAdditionalView.AppointmentAdditionalViewContainer.labelDurationMinutes",
    defaultMessage: "Duration of additional views",
  },
});

export default messages;
