import { defineMessages } from "react-intl";

const messages = defineMessages({
  btnDay: {
    id: "TimeSlotCalendar.TimeSlotCalendarHeader.btnDay",
    defaultMessage: "Day",
  },
  btnWeek: {
    id: "TimeSlotCalendar.TimeSlotCalendarHeader.btnWeek",
    defaultMessage: "Week",
  },
  btnMonth: {
    id: "TimeSlotCalendar.TimeSlotCalendarHeader.btnMonth",
    defaultMessage: "Month",
  },
  btnBlockTime: {
    id: "Calendar.CalendarHeader.btnBlockTime",
    defaultMessage: "Block time",
  },
});

export default messages;
