import parseISO from "date-fns/parseISO";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatInternational } from "../../../common/phoneNumberHelpers";
import { PatientCardContainerProps } from "../../Patient/PatientCardContainer";
import { Appointment } from "./types";
import getPatientFormsStatus from "../../PatientForm/getPatientFormsStatus";

const usePatientCardContainer = (
  appointment: Appointment | null,
): PatientCardContainerProps | null => {
  const navigate = useNavigate();
  const { role, appointmentId } = useParams();

  const props = useMemo(() => {
    if (!appointment) return null;

    const {
      first_name,
      last_name,
      mobile_phone_number,
      email,
      date_birth,
      patient,
    } = appointment.appointment_patient_details[0];

    const customerDetails = appointment.appointment_customer_details[0];

    const phoneNumber = mobile_phone_number
      ? {
          url: mobile_phone_number,
          styled: formatInternational({
            phoneNumber: mobile_phone_number,
          }),
        }
      : undefined;

    const birthDate = parseISO(date_birth).toLocaleDateString();

    const legalGuardian =
      customerDetails?.type === "legal_guardian"
        ? {
            firstName: customerDetails.first_name,
            lastName: customerDetails.last_name,
            onClick: () =>
              navigate(
                `/${role}/appointment/${appointmentId}/customer/${customerDetails.customer_id}`,
              ),
          }
        : undefined;

    const { patient_forms: patientForms } = patient;
    const formsStatus = getPatientFormsStatus(patientForms);

    const result: PatientCardContainerProps = {
      patient: {
        firstName: first_name,
        lastName: last_name,
        mrn: patient.mrn,
        phoneNumber,
        email: email || undefined,
        birthDate,
        legalGuardian,
        onPatientClick: () =>
          navigate(
            `/${role}/appointment/${appointmentId}/patient/${patient.mrn}`,
          ),
      },
      isGuardianRequired: customerDetails?.type === "legal_guardian",
      formsStatus,
    };

    return result;
  }, [appointment, appointmentId, navigate, role]);

  return props;
};

export default usePatientCardContainer;
