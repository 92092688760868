import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      title: formatMessage(messages.title),
      labelExamReason: formatMessage(messages.labelExamReason),
      labelSurgicalHistory: formatMessage(messages.labelSurgicalHistory),
      labelCancerHistory: formatMessage(messages.labelCancerHistory),
      labelReferringPhysician: formatMessage(messages.labelReferringPhysician),
      labelReferringPhysicianName: formatMessage(
        messages.labelReferringPhysicianName,
      ),
      labelReferringPhysicianPostalCode: formatMessage(
        messages.labelReferringPhysicianPostalCode,
      ),
      labelReferringPhysicianEmail: formatMessage(
        messages.labelReferringPhysicianEmail,
      ),
      labelReferringPhysicianPhoneNumber: formatMessage(
        messages.labelReferringPhysicianPhoneNumber,
      ),
      labelHeightAndWeight: formatMessage(messages.labelHeightAndWeight),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
