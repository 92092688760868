import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";
import { LicenseInfo } from "@mui/x-license-pro";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { ThemeProvider as GlobalThemeProvider } from "@mui/system";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";

import "@aws-amplify/ui-react/styles.css";

import { Provider } from "react-supabase";
import { Amplify } from "@aws-amplify/core";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import theme from "../../../common/theme";
import firebaseApp from "../../../common/firebaseApp";
import FirebaseSDKProviders from "../FirebaseSDKProviders";
import GlobalStyles from "../GlobalStyles";
import IntlProvider from "../IntlProvider";
import Root from "../Root";
import amplifyConfig from "../../../common/amplifyConfig";
import supabaseClient from "../../../common/supabaseClient";
import { AuthContext } from "../../Auth/AuthContext";
import { UIContextProvider } from "../UIContext";
import Head from "../../Head";

Amplify.configure(amplifyConfig);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || "");

const App: React.FC = () => {
  return (
    <FirebaseAppProvider firebaseApp={firebaseApp}>
      <FirebaseSDKProviders>
        <Provider value={supabaseClient}>
          <GlobalThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <StyledEngineProvider injectFirst>
                <Router basename={process.env.PUBLIC_URL || "/"}>
                  <IntlProvider>
                    <ThemeProvider theme={theme}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Head />
                        <GlobalStyles />
                        <CssBaseline />
                        <UIContextProvider>
                          <AuthContext>
                            <Root />
                          </AuthContext>
                        </UIContextProvider>
                      </LocalizationProvider>
                    </ThemeProvider>
                  </IntlProvider>
                </Router>
              </StyledEngineProvider>
            </ThemeProvider>
          </GlobalThemeProvider>
        </Provider>
      </FirebaseSDKProviders>
    </FirebaseAppProvider>
  );
};

export default App;
