import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemStatus, AppointmentRowItem } from "../types";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnStatusTranslations = {
  columnStatus: string;
  statusCheckout: string;
  statusIncomplete: string;
  statusAbandoned: string;
  statusConfirmed: string;
  statusCanceled: string;
  statusIntakeComplete: string;
  statusCheckedIn: string;
  statusNoShow: string;
  statusStudiesUnread: string;
  statusStudiesRead: string;
  statusReportsSent: string;
  statusReportsError: string;
};

const statuses: Record<
  DatabaseEnum["appointment_status"],
  keyof ColumnStatusTranslations
> = {
  checkout: "statusCheckout",
  incomplete: "statusIncomplete",
  abandoned: "statusAbandoned",
  confirmed: "statusConfirmed",
  canceled: "statusCanceled",
  intake_complete: "statusIntakeComplete",
  checked_in: "statusCheckedIn",
  no_show: "statusNoShow",
  studies_unread: "statusStudiesUnread",
  studies_read: "statusStudiesRead",
  reports_sent: "statusReportsSent",
  reports_error: "statusReportsError",
};

const getColumnStatus = (params: {
  columnItem: AppointmentColumnItemStatus;
  translations: ColumnStatusTranslations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;

  return {
    field: columnItem.field,
    disableColumnMenu: true,
    headerName: translations.columnStatus,
    flex: 0.5,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    valueGetter: ({ row }) => {
      const { status } = row;
      const statusTranslationKey = status in statuses ? statuses[status] : null;
      const statusTranslation = statusTranslationKey
        ? translations[statusTranslationKey]
        : "";

      return statusTranslation;
    },
  };
};

export default getColumnStatus;
