import React, { FC } from "react";
import DataGrid from "../../Unknown/DataGrid";
import { AppointmentPayment } from "./types";
import useColumns from "./useColumns";
import useStyles from "./useStyles";
import Box from "../../Unknown/Box";

export type AppointmentPaymentGridProps = {
  payments: AppointmentPayment[];
};

const AppointmentPaymentGrid: FC<AppointmentPaymentGridProps> = ({
  payments,
}) => {
  const { root } = useStyles();
  const columns = useColumns();

  return (
    <Box sx={{ width: "100%", display: "grid" }}>
      <DataGrid
        autoHeight
        getRowHeight={() => "auto"}
        className={root}
        columns={columns}
        hideFooter
        hideFooterRowCount
        rows={payments}
        disableRowSelectionOnClick
        initialState={{
          sorting: { sortModel: [{ field: "confirmedAt", sort: "desc" }] },
        }}
        sortingOrder={["desc", "asc"]}
      />
    </Box>
  );
};

export default AppointmentPaymentGrid;
