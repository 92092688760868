import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemPatientSex, AppointmentRowItem } from "../types";

export type ColumnPatientSexTranslations = {
  columnPatientSex: string;
  sexOptions: Record<string, string>;
};

const getColumnPatientSex = (params: {
  columnItem: AppointmentColumnItemPatientSex;
  translations: ColumnPatientSexTranslations;
}): GridColDef<AppointmentRowItem, string | null> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    headerName: translations.columnPatientSex,
    valueGetter: ({ row }) => {
      if (!row.patient?.sex) return null;

      const formattedValue = translations.sexOptions[row.patient.sex];

      return formattedValue;
    },
  };
};

export default getColumnPatientSex;
