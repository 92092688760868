import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = (params: { dateString: string }) => {
  const { dateString } = params;

  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      title: formatMessage(messages.title, { dateString }),
    };
  }, [formatMessage, dateString]);

  return translations;
};
export default useTranslations;
