import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

export type DayTranslations = {
  longMonday: string;
  longTuesday: string;
  longWednesday: string;
  longThursday: string;
  longFriday: string;
  longSaturday: string;
  longSunday: string;
};

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      labelOpen: formatMessage(messages.labelOpen),
      toText: formatMessage(messages.toText),
      labelTime: formatMessage(messages.labelTime),
    };

    const dayTranslations: DayTranslations = {
      longMonday: formatMessage(commonMessages.longMonday),
      longTuesday: formatMessage(commonMessages.longTuesday),
      longWednesday: formatMessage(commonMessages.longWednesday),
      longThursday: formatMessage(commonMessages.longThursday),
      longFriday: formatMessage(commonMessages.longFriday),
      longSaturday: formatMessage(commonMessages.longSaturday),
      longSunday: formatMessage(commonMessages.longSunday),
    };

    return { translations, dayTranslations };
  }, [formatMessage]);
  return result;
};
export default useTranslations;
