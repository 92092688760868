import { useMemo } from "react";
import * as yup from "yup";
import useTranslations from "./useTranslations";

const useValidationSchema = ({
  remainingBalance = 0,
}: {
  remainingBalance?: number;
}) => {
  const { translations } = useTranslations();

  const validationSchema = useMemo(() => {
    const { methodIsRequired } = translations;

    return yup.object().shape({
      paymentMethodId: yup.string().trim().required(methodIsRequired),
      amount: yup
        .number()
        .positive(translations.invalidAmount)
        .max(remainingBalance, translations.invalidAmount)
        .required(translations.invalidAmount),
    });
  }, [remainingBalance, translations]);

  return validationSchema;
};

export default useValidationSchema;
