import { GridColDef } from "../../../Unknown/DataGrid";
import { CenterService } from "../types";

type ColumnValue = string;

type ColumnTranslations = {
  headerName: string;
  sideLeft: string;
  sideRight: string;
};

type ColumnParams = {
  translations: ColumnTranslations;
};

const getSideTranslationKey = (
  side?: string,
): keyof ColumnTranslations | null => {
  switch (side) {
    case "left":
      return "sideLeft";
    case "right":
      return "sideRight";
    default:
      return null;
  }
};

const getColumnName = ({
  translations,
}: ColumnParams): GridColDef<CenterService, ColumnValue> => {
  const { headerName } = translations;

  return {
    field: "name",
    disableColumnMenu: true,
    flex: 1,
    headerName,
    sortable: false,
    valueGetter: (getterParams) => {
      const { name, side } = getterParams.row;

      const sideTranslationKey = getSideTranslationKey(side);

      return sideTranslationKey
        ? `${name} (${translations[sideTranslationKey]})`
        : name;
    },
  };
};

export default getColumnName;
