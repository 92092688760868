import { useMemo } from "react";
import * as yup from "yup";
import useTranslations from "./useTranslations";

const useValidationSchema = () => {
  const { translations } = useTranslations();

  const validationSchema = useMemo(() => {
    const { labelDescription, labelOffering } = translations;

    return yup.object().shape({
      description: yup.string().trim().required().label(labelDescription),
      offeringId: yup.string().trim().optional().label(labelOffering),
    });
  }, [translations]);

  return validationSchema;
};

export default useValidationSchema;
