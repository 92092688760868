import React, { FC } from "react";
import Button from "../../Unknown/Button";
import Card from "../../Unknown/Card";
import CardContent from "../../Unknown/CardContent";
import CardHeader from "../../Unknown/CardHeader";
import useTranslations from "./useTranslations";
import AppointmentPaymentGrid from "../AppointmentPaymentGrid";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import useStyles from "./useStyles";
import { AppointmentPayment } from "../AppointmentPaymentGrid/types";

export type AppointmentNoteListCardProps = {
  isPaymentButtonShown: boolean;
  remainingBalance: string;
  totalPrice: string;
  payments: AppointmentPayment[];
  handleAddClick: () => void;
};

const AppointmentPaymentListCard: FC<AppointmentNoteListCardProps> = ({
  isPaymentButtonShown,
  totalPrice,
  remainingBalance,
  payments,
  handleAddClick,
}) => {
  const { translations } = useTranslations();
  const classes = useStyles();
  const { title, btnAdd } = translations;

  return (
    <Card sx={{ minHeight: 250 }}>
      <CardHeader
        title={title}
        action={
          isPaymentButtonShown && (
            <Button variant="contained" onClick={handleAddClick}>
              {btnAdd}
            </Button>
          )
        }
      />
      <CardContent sx={{ pt: 0 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography classes={{ root: classes.labelRoot }}>
              {translations.totalPrice}
            </Typography>
            <Typography variant="body2">{totalPrice}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography classes={{ root: classes.labelRoot }}>
              {translations.outstandingBalance}
            </Typography>
            <Typography variant="body2">{remainingBalance}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography classes={{ root: classes.labelRoot }}>
              {translations.title}
            </Typography>
          </Grid>
          <Grid item xs={12} mt={-4}>
            <AppointmentPaymentGrid payments={payments} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentPaymentListCard;
