import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemPatientId, AppointmentRowItem } from "../types";

export type ColumnPatientIdTranslations = {
  columnPatientId: string;
};

const getColumnPatientId = (params: {
  columnItem: AppointmentColumnItemPatientId;
  translations: ColumnPatientIdTranslations;
}): GridColDef<AppointmentRowItem, string | null> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    headerName: translations.columnPatientId,
    valueGetter: ({ row }) => {
      return row.patient?.mrn || null;
    },
  };
};

export default getColumnPatientId;
