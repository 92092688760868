import React, { FC } from "react";
import IconButton from "../IconButton";
import { MoreVertIcon } from "../Icons";
import CircularProgress from "../CircularProgress";
import Menu from "../Menu";
import MenuItem from "../MenuItem";
import Typography from "../Typography";
import Box from "../Box";

type Option = {
  id?: string;
  text: string;
  isLoading?: boolean;
  onClick: () => Promise<void> | void;
};

export type SimpleOptionsMenuProps = {
  options: Option[];
};

const SimpleOptionsMenu: FC<SimpleOptionsMenuProps> = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="options-button"
        aria-controls={open ? "options-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="options-menu"
        MenuListProps={{
          "aria-labelledby": "options-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options.map(({ id, text, isLoading, onClick }) => (
          <MenuItem
            key={id || text}
            sx={{ alignItems: "center" }}
            onClick={async () => {
              if (isLoading) return;
              await onClick();
              handleClose();
            }}
          >
            <Typography variant="button" color="primary.main">
              {text}
            </Typography>
            {isLoading && (
              <Box ml={2}>
                <CircularProgress size={18} />
              </Box>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SimpleOptionsMenu;
