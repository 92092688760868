import { Formik } from "formik";
import React, { FC } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Dialog from "../../Unknown/Dialog";
import { TimeSlotAddFormValues } from "../TimeSlotAddDialogForm";
import TimeSlotAddDialogFormik from "../TimeSlotAddDialogFormik";
import useValidationSchema from "./useValidationSchema";

export type TimeSlotAddDialogProps = {
  title: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void | Promise<void>;
  onSubmit: (values: TimeSlotAddFormValues) => void | Promise<void>;
  initialValues: TimeSlotAddFormValues;
  onDeleteClick?: () => void;
  centerTimeZone?: string;
};

const TimeSlotAddDialog: FC<TimeSlotAddDialogProps> = ({
  title,
  description,
  isOpen,
  onClose,
  onSubmit,
  initialValues,
  onDeleteClick,
  centerTimeZone,
}) => {
  const validationSchema = useValidationSchema({ timeZone: centerTimeZone });

  return (
    <Dialog onClose={onClose} open={isOpen} disableRestoreFocus maxWidth="sm">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimeSlotAddDialogFormik
            title={title}
            description={description}
            onClose={onClose}
            onDeleteClick={onDeleteClick}
          />
        </LocalizationProvider>
      </Formik>
    </Dialog>
  );
};

export default TimeSlotAddDialog;
