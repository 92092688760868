import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      headingName: formatMessage(messages.headingName),
      headingPrice: formatMessage(messages.headingPrice),
      headingDuration: formatMessage(messages.headingDuration),
      headingIsPrivate: formatMessage(messages.headingIsPrivate),
      durationMinutes: formatMessage(messages.durationMinutes),
      privateTrue: formatMessage(messages.privateTrue),
      privateFalse: formatMessage(messages.privateFalse),
      btnArchive: formatMessage(messages.btnArchive),
      btnEdit: formatMessage(messages.btnEdit),
    };
  }, [formatMessage]);

  return translations;
};
export default useTranslations;
