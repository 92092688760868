import { FormValues } from "./types";

export const baseFormInitialValues: FormValues = {
  information: {
    firstName: "",
    lastName: "",
    email: "",
    confirmationCode: "",
  },
};

export const confirmationCodeLength = 6;
