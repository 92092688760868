import { defineMessages } from "react-intl";

const messages = defineMessages({
  titleDetails: {
    id: "Patient.PatientsTable.titleDetails",
    defaultMessage: "Name",
  },
  titleDateBirth: {
    id: "Patient.PatientsTable.titleDateBirth",
    defaultMessage: "Date Of Birth",
  },
  titleAppointments: {
    id: "Patient.PatientsTable.titleAppointments",
    defaultMessage: "Appointments",
  },
});

export default messages;
