import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import { CenterAddressValidationTranslations } from "./getCenterAddressFormSchema";
import messages from "./messages";

const useFormTranslations = (params: { maxStringLength: number }) => {
  const { maxStringLength } = params;

  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
    };

    const validationTranslations: CenterAddressValidationTranslations = {
      requiredField: formatMessage(commonMessages.requiredField),
      maxStringLengthError: formatMessage(commonMessages.maxStringLengthError, {
        value: maxStringLength,
      }),
      postalCodeIsInvalid: formatMessage(messages.postalCodeIsInvalid),
      labelPostalCode: formatMessage(messages.labelPostalCode),
    };

    return { translations, validationTranslations };
  }, [formatMessage, maxStringLength]);

  return result;
};
export default useFormTranslations;
