import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "AppointmentNote.AppointmentNoteListCard.title",
    defaultMessage: "Notes",
  },
  btnAdd: {
    id: "AppointmentNote.AppointmentNoteListCard.btnAdd",
    defaultMessage: "Add note",
  },
});
