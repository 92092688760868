import { defineMessages } from "react-intl";

const messages = defineMessages({
  sideLeft: {
    id: "TimeSlot.TimeSlotCalendarScreen.sideLeft",
    defaultMessage: "Left",
  },
  sideRight: {
    id: "TimeSlot.TimeSlotCalendarScreen.sideRight",
    defaultMessage: "Right",
  },
  patientDetailsIsNotAvailable: {
    id: "TimeSlot.TimeSlotCalendarScreen.patientDetailsIsNotAvailable",
    defaultMessage: "Details not available",
  },
  errorAppointmentUndefined: {
    id: "TimeSlot.TimeSlotCalendarScreen.errorAppointmentUndefined",
    defaultMessage: "Appointment is not defined",
  },
  errorPatientDetailsUndefined: {
    id: "TimeSlot.TimeSlotCalendarScreen.errorPatientDetailsUndefined",
    defaultMessage: "Patient details is not defined",
  },
  errorTimeSlotIsAlreadyTaken: {
    id: "TimeSlot.TimeSlotCalendarScreen.errorTimeSlotIsAlreadyTaken",
    defaultMessage: "Time slot is already taken",
  },
  addTitle: {
    id: "TimeSlot.TimeSlotCalendarScreen.addTitle",
    defaultMessage: "Add Event",
  },
  addDescription: {
    id: "TimeSlot.TimeSlotCalendarScreen.addDescription",
    defaultMessage: "DO NOT USE TO BOOK VACATIONS",
  },
  editTitle: {
    id: "TimeSlot.TimeSlotCalendarScreen.editTitle",
    defaultMessage: "Edit Event",
  },
});

export default messages;
