import { defineMessages } from "react-intl";

const messages = defineMessages({
  authError: {
    id: "Auth.AuthContext.authError",
    defaultMessage: "Authentication error. Clear browser cache and try again",
  },
});

export default messages;
