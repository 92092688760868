import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerInformationDrawerContainerProps } from "../../Customer/CustomerInformationDrawerContainer";

const useCustomerInformationDrawerContainer = ({
  isOtherPerson,
  refetch,
}: {
  isOtherPerson?: boolean;
  refetch: () => Promise<void>;
}): CustomerInformationDrawerContainerProps => {
  const { role, appointmentId, customerId } = useParams();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(`/${role}/appointment/${appointmentId}`);
  }, [appointmentId, navigate, role]);

  const handlePatientClick = useCallback(
    (patientMrn: string) => {
      navigate(`/${role}/appointment/${appointmentId}/patient/${patientMrn}`);
    },
    [appointmentId, navigate, role],
  );

  const handlePostSubmit = async () => {
    await refetch();
  };

  return {
    customerId,
    isOtherPerson,
    isOpen: !!customerId,
    handlePostSubmit,
    handleClose,
    handlePatientClick,
  };
};

export default useCustomerInformationDrawerContainer;
