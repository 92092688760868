import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemCreatedAt, AppointmentRowItem } from "../types";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnCreatedAtTranslations = {
  columnCreatedAt: string;
};

const getColumnCreatedAt = (params: {
  columnItem: AppointmentColumnItemCreatedAt;
  translations: ColumnCreatedAtTranslations;
}): GridColDef<AppointmentRowItem, Date> => {
  const { columnItem, translations } = params;
  const { field, localeFormat } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 1,
    headerName: translations.columnCreatedAt,
    type: "dateTime",
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    valueGetter: ({ row }) => {
      const { createdAtCenterTimezone } = row;

      return createdAtCenterTimezone;
    },
    renderCell: ({ row }) => {
      const { center, createdAtCenterTimezone } = row;

      if (!createdAtCenterTimezone) return null;

      const { timezone, locale } = center;

      const centerTimezone = timezone || "UTC";

      const formattedDate = createdAtCenterTimezone.toLocaleDateString(locale);

      const centerLocale = localeFormat === "center" ? locale : undefined;

      const formattedTime = createdAtCenterTimezone
        .toLocaleTimeString(centerLocale, {
          hour: "numeric",
          minute: "numeric",
        })
        .toLocaleLowerCase()
        .replace(/\s/, "");

      const formattedTimezone = formatInTimeZone(
        createdAtCenterTimezone,
        centerTimezone,
        "zzz",
      ).toLocaleUpperCase();

      return `${formattedDate} ${formattedTime} (${formattedTimezone})`;
    },
  };
};

export default getColumnCreatedAt;
