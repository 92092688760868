import { defineMessages } from "react-intl";

const messages = defineMessages({
  tooltipText: {
    id: "Unknown.NavBarSearchButton.tooltipText",
    defaultMessage:
      "Enter numbers and dates without slashes, hyphens, or spaces",
  },
});

export default messages;
