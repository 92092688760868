import { useFormikContext } from "formik";
import React, { FC } from "react";
import InformationDrawer from "../../Unknown/InformationDrawer";
import PatientMainCards, { PatientMainCardsProps } from "../PatientMainCards";
import useInformationDrawerActionButtons from "./useInformationDrawerActionButtons";

export type PatientInformationDrawerProps = {
  isOpen: boolean;
  handleClose: () => void;
  isEditMode: boolean;
  handleEditModeChange: (value: boolean) => void;
} & PatientMainCardsProps;

const PatientInformationDrawer: FC<PatientInformationDrawerProps> = ({
  isOpen,
  handleClose,
  isEditMode,
  handleEditModeChange,
  isLoading,
  error,
  data,
}) => {
  const { isSubmitting, handleSubmit, resetForm } = useFormikContext();

  const informationDrawerActionButtons = useInformationDrawerActionButtons({
    isEditMode,
    handleEditModeChange,
    isButtonsDisabled: isSubmitting || !!isLoading,
    handleSubmit,
    resetForm,
  });

  return (
    <InformationDrawer
      isOpen={isOpen}
      handleClose={handleClose}
      actionButtons={informationDrawerActionButtons}
    >
      <PatientMainCards
        isEditMode={isEditMode}
        isLoading={isLoading}
        error={error}
        data={data}
      />
    </InformationDrawer>
  );
};

export default PatientInformationDrawer;
