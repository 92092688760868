import { defineMessages } from "react-intl";

const messages = defineMessages({
  searching: {
    id: "Unknown.SearchScreen.searching",
    defaultMessage: "Searching...",
  },
  noResults: {
    id: "Unknown.SearchScreen.noResults",
    defaultMessage:
      "No results returned. Please adjust your search term or check for errors.",
  },
  title: {
    id: "Unknown.SearchScreen.title",
    defaultMessage: 'Search results for "{searchValue}"',
  },
});

export default messages;
