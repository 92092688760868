import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      title: formatMessage(messages.title),
      tooltipRequiresGuardian: formatMessage(messages.tooltipRequiresGuardian),
      formsStatusValid: formatMessage(messages.formsStatusValid),
      formsStatusNotPopulated: formatMessage(messages.formsStatusNotPopulated),
      formsStatusExpired: formatMessage(messages.formsStatusExpired),
    };

    return { translations };
  }, [formatMessage]);

  return result;
};

export default useTranslations;
