import React, { FC, useMemo } from "react";
import format from "date-fns/format";
import Typography from "../Typography";
import useTranslations from "./useTranslations";

type DateTypographyHeaderProps = {
  date: Date;
};

const HEADER_DATE_FORMAT = "E, LLL dd yyyy";

const DateTypographyHeader: FC<DateTypographyHeaderProps> = ({ date }) => {
  const dateString = useMemo(() => {
    return format(date, HEADER_DATE_FORMAT);
  }, [date]);
  const { title } = useTranslations({ dateString });

  return <Typography variant="h2">{title}</Typography>;
};

export default DateTypographyHeader;
