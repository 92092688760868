import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { SupabaseClient } from "@supabase/supabase-js";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import { formatClientTimezoneToUTC_ISO } from "../../../common/dateHelpers";

type CenterOffering = Pick<DatabaseRow<"center_offerings">, "name">;

type AppointmentCenter = Pick<
  DatabaseRow<"centers">,
  "name" | "region" | "timezone"
> & {
  center_configs: Pick<
    DatabaseRow<"center_configs">,
    | "priority_report_deadline_hours"
    | "report_delivery_deadline_hours"
    | "stat_report_deadline_hours"
  >[];
};
type AppointmentTimeSlot = Pick<DatabaseRow<"center_time_slots">, "started_at">;

type Patient = Pick<DatabaseRow<"patients">, "mrn">;

type AppointmentPatientDetails = Pick<
  DatabaseRow<"appointment_patient_details">,
  "first_name" | "last_name" | "date_birth" | "mobile_phone_number"
> & {
  patient: Patient;
};

type Appointment = DatabaseRow<"appointments"> & {
  appointment_patient_details: AppointmentPatientDetails[];
  time_slot: AppointmentTimeSlot;
  center: AppointmentCenter;
  appointment_technologist_notes: DatabaseRow<"appointment_technologist_notes">[];
  appointment_patient_details_form: Pick<
    DatabaseRow<"appointment_patient_details_form">,
    "exam_reason"
  >[];
  appointment_offerings: {
    center_offering: Pick<DatabaseRow<"center_offerings">, "duration_minutes">;
  }[];
};

export type DashboardAppointmentOffering = Pick<
  DatabaseRow<"appointment_offerings">,
  | "side"
  | "status"
  | "id"
  | "has_unexpected_findings"
  | "is_receipt_confirmed"
  | "pacs_url"
> & {
  center_offering: CenterOffering;
  appointment: Appointment;
};

const supabaseAppointmentOfferingsRequest = `
id,
side,
status,
has_unexpected_findings,
is_receipt_confirmed,
pacs_url,
center_offering: center_offering_id (
  name
),
appointment:appointment_id!inner (
  id,
  is_stat,
  status,
  has_priority,
  ready_to_read_at,
  is_additional_views,
  appointment_patient_details (
    first_name,
    last_name,
    date_birth,
    mobile_phone_number,
    patient:patient_id (
      mrn
    )
  ),
  time_slot:time_slot_id!inner (
    started_at
  ),
  center:center_id!inner (
    name,
    region,
    timezone,
    center_configs (
      report_delivery_deadline_hours,
      stat_report_deadline_hours,
      priority_report_deadline_hours
    )
  ),
  appointment_offerings (
    id,
    center_offering: center_offering_id (
      duration_minutes
    )
  ),
  appointment_technologist_notes (*),
  appointment_patient_details_form (
    id,
    exam_reason
  )
)
`;

const today = new Date();

const getAppointmentOfferings = async (params: {
  supabase: SupabaseClient;
  centerIds: string[];
  isReadShown: boolean;
  showReadStudiesDays: number;
  timezone?: string | null;
}) => {
  const { supabase, centerIds, isReadShown, showReadStudiesDays, timezone } =
    params;

  const startOfDate = formatClientTimezoneToUTC_ISO(
    subDays(startOfDay(today), showReadStudiesDays),
    timezone,
  );
  const endOfDate = formatClientTimezoneToUTC_ISO(endOfDay(today), timezone);

  const dateFilter = `and(started_at.gte.${startOfDate},started_at.lte.${endOfDate})`;

  const statusFilter = isReadShown ? ["read"] : ["unread", "reading"];

  let query = supabase
    .from<DashboardAppointmentOffering>("appointment_offerings")
    .select(supabaseAppointmentOfferingsRequest)
    .order("id")
    .in("status", statusFilter);

  if (isReadShown) {
    query = query.or(dateFilter, { foreignTable: "appointment.time_slot" });
  }

  if (centerIds.length) {
    query = query.in(
      "appointment.center_id" as keyof DashboardAppointmentOffering,
      centerIds,
    );
  }

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export default getAppointmentOfferings;
