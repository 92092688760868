import useFormSchema from "./useFormSchema";
import {
  CenterPackageFormHookParams,
  CenterPackageFormHookResult,
} from "./types";

const useCenterPackageForm = (
  params: CenterPackageFormHookParams,
): CenterPackageFormHookResult => {
  const { initialValues, initialTouched, minPrice, handleSubmit } = params;

  const validationSchema = useFormSchema({ minPrice });

  return {
    formikConfig: {
      enableReinitialize: true,
      initialValues,
      initialTouched,
      validationSchema,
      onSubmit: handleSubmit,
    },
  };
};

export default useCenterPackageForm;
