import React from "react";
import { capitalCase } from "change-case";
import { GridColDef } from "../../../Unknown/DataGrid";
import { CenterServicesAppointmentPreviewTranslations } from "../../../CenterService/CenterServicesAppointmentPreview/useTranslations";
import { CenterService } from "../../../CenterService/CenterServicesAppointmentPreview";
import {
  AppointmentColumnItemCenterServices,
  AppointmentRowItem,
  AppointmentRowItemOffering,
  AppointmentRowItemPackage,
} from "../types";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import Typography from "../../../Unknown/Typography";

export type ColumnCenterServicesTranslations = {
  columnOffering: string;
};

const sides: Record<
  string,
  keyof CenterServicesAppointmentPreviewTranslations
> = {
  left: "leftSide",
  right: "rightSide",
};

const getCenterServices = ({
  offerings,
  packages,
}: {
  offerings: AppointmentRowItemOffering[];
  packages: AppointmentRowItemPackage[];
}): CenterService[] => {
  const offeringCenterServices = offerings.map((offering) => ({
    name: offering.name,
    side: offering.side,
    cost: offering.cost,
  }));

  const packageCenterServices = packages.map((packageItem) => ({
    name: packageItem.name,
    cost: packageItem.cost,
  }));

  return [...offeringCenterServices, ...packageCenterServices];
};

const getColumnCenterServices = (params: {
  columnItem: AppointmentColumnItemCenterServices;
  translations: ColumnCenterServicesTranslations;
  centerServiceTranslations: CenterServicesAppointmentPreviewTranslations;
}): GridColDef<AppointmentRowItem> => {
  const { columnItem, translations, centerServiceTranslations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnOffering,
    valueGetter: ({ row }) => {
      const { offerings, packages } = row;

      const services = getCenterServices({ offerings, packages });

      return services
        .map(({ name, cost, side }) => {
          const formattedName = capitalCase(name).replace(/\s+/g, "");

          const sideTranslationKey = side ? sides[side] : "";

          const sideTranslation = sideTranslationKey
            ? `(${centerServiceTranslations[sideTranslationKey]})`
            : "";

          return `${formattedName}${sideTranslation}^${cost}`;
        })
        .join(";");
    },
    renderCell: ({ value }) => {
      return (
        <Typography variant="body2" style={{ wordBreak: "break-all" }}>
          {value}
        </Typography>
      );
    },
  };
};

export default getColumnCenterServices;
