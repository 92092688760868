import { formatInternational } from "../../../common/phoneNumberHelpers";
import { Customer, CustomerInformationDrawerData } from "./types";

const getPatientInformationCardProps = (params: {
  customer: Customer;
  handlePatientClick: (mrn: string) => void;
}) => {
  const { customer, handlePatientClick } = params;
  const { mobile_phone_number, email } = customer;

  const patients = customer.patients?.map((patient) => ({
    firstName: patient.first_name,
    lastName: patient.last_name,
    onClick: () => handlePatientClick(patient.mrn),
  }));

  const customerInformation: CustomerInformationDrawerData["customerInformationCardProps"] =
    { email, patients };

  if (mobile_phone_number) {
    const styled = formatInternational({
      phoneNumber: mobile_phone_number,
    });
    customerInformation.phoneNumber = {
      url: mobile_phone_number,
      styled,
    };
  }

  return customerInformation;
};

export default getPatientInformationCardProps;
