import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerInformationDrawerContainerProps } from "../../Customer/CustomerInformationDrawerContainer";

const useCustomerInformationDrawerContainer =
  (): CustomerInformationDrawerContainerProps => {
    const { role, customerId, search } = useParams();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
      navigate(`/${role}/search${search}`);
    }, [navigate, role, search]);

    const handlePatientClick = useCallback(
      (patientMrn: string) => {
        navigate(`/${role}/search/patient/${patientMrn}${search}`);
      },
      [navigate, role, search],
    );

    return {
      customerId,
      isOpen: !!customerId,
      handleClose,
      handlePatientClick,
    };
  };

export default useCustomerInformationDrawerContainer;
