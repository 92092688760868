import React from "react";
import compareAsc from "date-fns/compareAsc";
import { GridColDef, GridComparatorFn } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import { Appointment } from "../types";
import Box from "../../../Unknown/Box";

type ColumnTranslations = {
  columnDateTime: string;
};

type ColumnValue = {
  startedAtUTC: Date;
  startedAt: Date;
  centerLocale: string;
};

export type ColumnTimeTranslations = {
  columnDateTime: string;
};

const sortComparator: GridComparatorFn<ColumnValue> = (
  currentValue,
  compareValue,
) => {
  return compareAsc(currentValue.startedAtUTC, compareValue.startedAtUTC);
};

const getColumnDateTime = (params: {
  translations: ColumnTranslations;
  classNames: {
    timeRoot: string;
  };
  localeFormat: "center" | "client";
}): GridColDef<Appointment, ColumnValue> => {
  const { translations, classNames, localeFormat } = params;

  const { columnDateTime } = translations;
  const { timeRoot } = classNames;

  return {
    field: "dateTime",
    type: "string",
    disableColumnMenu: true,
    headerName: columnDateTime,
    sortComparator,
    flex: 1,
    valueGetter: (getterParams) => {
      const { timeSlot, center } = getterParams.row;
      const { startedAtUTC, startedAt } = timeSlot;

      const { locale } = center;
      return {
        startedAt,
        startedAtUTC,
        centerLocale: locale,
      };
    },

    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      const { startedAt, centerLocale } = renderParams.value;

      const locale = localeFormat === "center" ? centerLocale : undefined;

      const formattedDate = startedAt.toLocaleDateString(locale);
      const formattedTime = startedAt
        .toLocaleTimeString(locale, { hour: "numeric", minute: "numeric" })
        .toLocaleLowerCase()
        .replace(/\s/, "");

      return (
        <Box>
          <Typography variant="body2">{formattedDate}</Typography>
          <Typography className={timeRoot}>{formattedTime}</Typography>
        </Box>
      );
    },
  };
};

export default getColumnDateTime;
