import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import AuthenticatedLayout from "../AuthenticatedLayout";
import TimeSlotCalendarScreen from "../../TimeSlot/TimeSlotCalendarScreen";
import DashboardScreen from "../DashboardScreen";
import PrivateRoleRoute from "../PrivateRoleRoute";
import UndefinedRoleRoute from "../UndefinedRoleRoute";
import CenterSettingsScreen from "../../Center/CenterSettingsScreen";
import SearchScreen from "../SearchScreen";
import AppointmentScreen from "../../Appointment/AppointmentScreen";

const AuthenticatedRoot: FC = () => {
  const roleRouters = [
    {
      path: ":role/appointment/:appointmentId/patient/:patientMrn",
      Element: AppointmentScreen,
    },
    {
      path: ":role/appointment/:appointmentId/customer/:customerId",
      Element: AppointmentScreen,
    },
    {
      path: ":role/appointment/:appointmentId",
      Element: AppointmentScreen,
    },
    { path: ":role/calendar", Element: TimeSlotCalendarScreen },
    { path: ":role/dashboard/patient/:patientMrn", Element: DashboardScreen },
    { path: ":role/dashboard/customer/:customerId", Element: DashboardScreen },
    {
      path: ":role/admin/:settingsTab?",
      Element: CenterSettingsScreen,
    },
    { path: ":role/search/patient/:patientMrn", Element: SearchScreen },
    { path: ":role/search/customer/:customerId", Element: SearchScreen },
    { path: ":role/search", Element: SearchScreen },
    { path: ":role/", Element: DashboardScreen },
  ];

  return (
    <Routes>
      {roleRouters.map((roleRouter) => (
        <Route
          path={roleRouter.path}
          element={
            <PrivateRoleRoute>
              <AuthenticatedLayout>
                <roleRouter.Element />
              </AuthenticatedLayout>
            </PrivateRoleRoute>
          }
          key={roleRouter.path}
        />
      ))}
      <Route path="*" element={<UndefinedRoleRoute />} />
    </Routes>
  );
};

export default AuthenticatedRoot;
