import { useMemo } from "react";
import { StatusDialogProps } from "../../Unknown/StatusDialog";
import useTranslations from "./useTranslations";

const useActionButtons = (params: { handleClose: () => void }) => {
  const { handleClose } = params;

  const { translations } = useTranslations();

  const { btnClose } = translations;

  const actionButtons = useMemo<StatusDialogProps["actionButtons"]>(() => {
    return [
      {
        text: btnClose,
        key: "cancel",
        onClick: handleClose,
      },
    ];
  }, [btnClose, handleClose]);

  return actionButtons;
};

export default useActionButtons;
