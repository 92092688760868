import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(() => {
  return {
    descriptionRoot: {
      textTransform: "uppercase",
    },
  };
});

export default useStyles;
