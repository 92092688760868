import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemLocationZip, AppointmentRowItem } from "../types";

export type ColumnStatusTranslations = {
  columnLocationZip: string;
};

const getColumnLocationZip = (params: {
  columnItem: AppointmentColumnItemLocationZip;
  translations: ColumnStatusTranslations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;

  return {
    field: columnItem.field,
    disableColumnMenu: true,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnLocationZip,
    valueGetter: ({ row }) => {
      const {
        center: { postalCode },
      } = row;

      return postalCode;
    },
  };
};

export default getColumnLocationZip;
