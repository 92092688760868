import useTranslations from "./useTranslations";
import { Address } from "../CenterSettingsContainer/types";
import { CenterInformationSettingsBlockProps } from "../CenterInformationSettingsBlock";

const formatAddress = (address: Address) => {
  const {
    primaryAddressLine,
    secondaryAddressLine,
    city,
    postalCode,
    stateIsoCode,
    countryIsoCode,
  } = address;

  const addressString = [
    primaryAddressLine,
    secondaryAddressLine,
    `${city} ${postalCode} ${stateIsoCode}`,
    countryIsoCode,
  ]
    .filter(Boolean)
    .join(", ");

  return addressString;
};

const useAddressBlockProps = (params: {
  address: Address;
  onClick?: () => void;
}): CenterInformationSettingsBlockProps => {
  const { address, onClick } = params;
  const { headerAddress, btnEdit } = useTranslations();

  const addressString = formatAddress(address);

  return {
    title: headerAddress,
    buttonText: btnEdit,
    onClick,
    data: addressString,
  };
};

export default useAddressBlockProps;
