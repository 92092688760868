import { ButtonProps } from "@mui/material";
import React, { FC, ReactNode } from "react";
import Button from "../Button";
import Drawer from "../Drawer";
import Grid from "../Grid";
import IconButton from "../IconButton";
import { ArrowForwardIosOutlinedIcon } from "../Icons";

type ActionButton = { text: string; props: ButtonProps };

export type InformationDrawerProps = {
  isOpen: boolean;
  handleClose: () => void;
  children?: ReactNode;
  actionButtons?: ActionButton[];
};

const InformationDrawer: FC<InformationDrawerProps> = ({
  isOpen,
  handleClose,
  children,
  actionButtons,
}) => {
  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <Grid
        container
        direction="column"
        width={640}
        flexWrap="nowrap"
        minHeight="100%"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          py={3}
          px={5}
          alignItems="center"
        >
          <Grid item xs>
            <IconButton onClick={handleClose}>
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </Grid>
          {actionButtons &&
            actionButtons.map((actionButton) => (
              <Grid item key={actionButton.text}>
                <Button
                  sx={{ paddingY: 1, paddingX: 3 }}
                  {...actionButton.props}
                >
                  {actionButton.text}
                </Button>
              </Grid>
            ))}
        </Grid>
        <Grid item flexGrow={1} bgcolor="background.default" overflow="overlay">
          {children}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default InformationDrawer;
