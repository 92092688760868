import { defineMessages } from "react-intl";

const messages = defineMessages({
  btnSave: {
    id: "Center.CenterSettingsFormikDialog.btnSave",
    defaultMessage: "Save",
  },
  btnCancel: {
    id: "Center.CenterSettingsFormikDialog.btnCancel",
    defaultMessage: "Cancel",
  },
});

export default messages;
