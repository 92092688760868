import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Appointment.AppointmentScreeningFormDialog.title",
    defaultMessage: "Patient Screening Form",
  },
});

export default messages;
