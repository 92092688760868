import { useMemo } from "react";
import { GridColDef } from "../../Unknown/DataGrid";
import useColumnOptions from "./columns/useColumnOptions";
import getColumnStatus from "./columns/getColumnStatus";
import getColumnName from "./columns/getColumnName";
import getColumnAlert from "./columns/getColumnAlert";
import { AppointmentReport } from "./types";
import useTranslations from "./useTranslations";

const useColumns = (params: {
  hiddenHeaderSeparatorRoot: string;
  onResendClick: (id: string) => void;
  onUnexpectedFindingsClick: (id: string) => void;
  onConfirmReceiptClick: (id: string) => void;
}): GridColDef<AppointmentReport>[] => {
  const {
    hiddenHeaderSeparatorRoot,
    onResendClick,
    onConfirmReceiptClick,
    onUnexpectedFindingsClick,
  } = params;

  const { translations } = useTranslations();

  const {
    headerName,
    headerStatus,
    btnDownload,
    btnResend,
    btnUnexpectedFindings,
    btnConfirmUnexpectedFindings,
    statusSent,
    statusUnsent,
    statusError,
    tooltipUnexpectedFindings,
    tooltipReceiptConfirmed,
  } = translations;

  const columnOptions = useColumnOptions({
    translations: {
      btnDownload,
      btnResend,
      btnUnexpectedFindings,
      btnConfirmUnexpectedFindings,
    },
    headerClassName: hiddenHeaderSeparatorRoot,
    onResendClick,
    onConfirmReceiptClick,
    onUnexpectedFindingsClick,
  });

  const columns = useMemo(() => {
    return [
      getColumnAlert({
        translations: { tooltipUnexpectedFindings, tooltipReceiptConfirmed },
        headerClassName: hiddenHeaderSeparatorRoot,
      }),
      getColumnName({ translations: { headerName } }),
      getColumnStatus({
        translations: { headerStatus, statusSent, statusUnsent, statusError },
        headerClassName: hiddenHeaderSeparatorRoot,
      }),
      columnOptions,
    ];
  }, [
    tooltipUnexpectedFindings,
    tooltipReceiptConfirmed,
    hiddenHeaderSeparatorRoot,
    headerName,
    headerStatus,
    statusSent,
    statusUnsent,
    statusError,
    columnOptions,
  ]);

  return columns;
};

export default useColumns;
