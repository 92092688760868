import React, { useState } from "react";
import Box from "../../../Unknown/Box";
import { GridColDef } from "../../../Unknown/DataGrid";
import SimpleOptionsMenu from "../../../Unknown/SimpleOptionsMenu";
import { AppointmentReport } from "../types";
import getOptionalArrayItem from "../../../../common/getOptionalArrayItem";

type ColumnTranslations = {
  btnDownload: string;
  btnResend: string;
  btnUnexpectedFindings: string;
  btnConfirmUnexpectedFindings: string;
};

type ColumnsParams = {
  translations: ColumnTranslations;
  headerClassName: string;
  onResendClick: (id: string) => void;
  onConfirmReceiptClick: (id: string) => void;
  onUnexpectedFindingsClick: (id: string) => void;
};

const useColumnOptions = ({
  headerClassName,
  translations,
  onResendClick,
  onConfirmReceiptClick,
  onUnexpectedFindingsClick,
}: ColumnsParams): GridColDef<AppointmentReport, string> => {
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    btnDownload,
    btnResend,
    btnUnexpectedFindings,
    btnConfirmUnexpectedFindings,
  } = translations;

  return {
    field: "options",
    disableColumnMenu: true,
    headerName: "",
    sortable: false,
    headerClassName,
    width: 80,
    renderCell: ({ row }) => {
      const options = [
        {
          text: btnDownload,
          isLoading: isDownloading,
          onClick: async () => {
            setIsDownloading(true);
            await row.onDownloadClick();
            setIsDownloading(false);
          },
        },
        {
          text: btnResend,
          onClick: () => onResendClick(row.id),
        },
        ...getOptionalArrayItem(!row.hasUnexpectedFindings, {
          text: btnUnexpectedFindings,
          onClick: () => onUnexpectedFindingsClick(row.id),
        }),
        ...getOptionalArrayItem(
          row.hasUnexpectedFindings && !row.isReceiptConfirmed,
          {
            text: btnConfirmUnexpectedFindings,
            onClick: () => onConfirmReceiptClick(row.id),
          },
        ),
      ];

      return (
        <Box display="flex" justifyContent="center" width="100%">
          <SimpleOptionsMenu options={options} />
        </Box>
      );
    },
  };
};

export default useColumnOptions;
