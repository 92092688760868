import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import commonMessages from "../../../common/messages";

export type WeekDayTranslations = {
  shortMonday: string;
  shortTuesday: string;
  shortWednesday: string;
  shortThursday: string;
  shortFriday: string;
  shortSaturday: string;
  shortSunday: string;
};

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      headerAddress: formatMessage(messages.headerAddress),
      headerContactInfo: formatMessage(messages.headerContactInfo),
      headerOperatingHours: formatMessage(messages.headerOperatingHours),
      headerDiscount: formatMessage(messages.headerDiscount),
      btnEdit: formatMessage(messages.btnEdit),
      labelPhone: formatMessage(messages.labelPhone),
      labelFax: formatMessage(messages.labelFax),
      labelEmail: formatMessage(messages.labelEmail),
      dayClosed: formatMessage(messages.dayClosed),

      shortMonday: formatMessage(commonMessages.shortMonday),
      shortTuesday: formatMessage(commonMessages.shortTuesday),
      shortWednesday: formatMessage(commonMessages.shortWednesday),
      shortThursday: formatMessage(commonMessages.shortThursday),
      shortFriday: formatMessage(commonMessages.shortFriday),
      shortSaturday: formatMessage(commonMessages.shortSaturday),
      shortSunday: formatMessage(commonMessages.shortSunday),
    };
  }, [formatMessage]);

  return translations;
};
export default useTranslations;
