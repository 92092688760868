import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { AppointmentMarkNoShowDialogProps } from "../AppointmentMarkNoShowDialog";
import useTranslations from "./useTranslations";

const useAppointmentMarkNoShowDialog = (
  appointmentId: string,
): {
  handleOpen: () => void;
  props: AppointmentMarkNoShowDialogProps;
} => {
  const { markAsNoShowTranslations } = useTranslations();
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const navigate = useNavigate();
  const { role } = useParams();

  const markAppointmentAsNoShow = useFirebaseAppFunction(
    "markAppointmentAsNoShow",
  );

  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleOpen = () => setIsOpen(true);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setErrorMessage(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await runAsyncFunction(markAppointmentAsNoShow, {
        appointmentId,
      });

      if (data.status === "error") {
        const { code } = data.error;

        switch (code) {
          case "STATUS_NOT_SUPPORTED": {
            throw new Error(markAsNoShowTranslations.statusNotSupportedError);
          }
          default: {
            throw new Error(markAsNoShowTranslations.defaultError);
          }
        }
      }

      handleClose();
      navigate(`/${role}`);
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [
    runAsyncFunction,
    markAppointmentAsNoShow,
    appointmentId,
    handleClose,
    navigate,
    role,
    markAsNoShowTranslations.statusNotSupportedError,
    markAsNoShowTranslations.defaultError,
  ]);

  return {
    handleOpen,
    props: {
      isOpen,
      handleClose,
      handleSubmit,
      isLoading,
      errorMessage,
    },
  };
};

export default useAppointmentMarkNoShowDialog;
