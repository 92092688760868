import React, { FC } from "react";
import StatusDialog from "../../Unknown/StatusDialog";
import AppointmentFileAddForm from "../AppointmentFileForm";
import useTranslations from "./useTranslations";
import useActionButtons from "./useActionButtons";
import useForm from "./useForm";
import useFiles from "./useFiles";
import { AppointmentFile } from "./types";

export type AppointmentFileAddDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  appointmentId: string;
  handleAddFiles: (files: AppointmentFile[]) => void | Promise<void>;
};

const AppointmentFileAddDialog: FC<AppointmentFileAddDialogProps> = ({
  isOpen,
  handleClose,
  appointmentId,
  handleAddFiles,
}) => {
  const { translations } = useTranslations();

  const { files, clearFiles, getInputProps, getRootProps } = useFiles();

  const onClose = () => {
    handleClose();
    clearFiles();
  };

  const { isLoading, errorMessage, handleSubmit } = useForm({
    appointmentId,
    files,
    handleClose: onClose,
    handleAddFiles,
  });

  const actionButtons = useActionButtons({
    handleClose: onClose,
    handleSubmit,
    isLoading,
    errorMessage,
    isSubmitDisabled: !files.length,
  });

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      actionButtons={actionButtons}
      errorMessage={errorMessage}
      isLoading={isLoading}
      fullWidth
      PaperProps={{ sx: { maxWidth: 800 } }}
    >
      <AppointmentFileAddForm
        dragDropFileFieldBaseProps={{
          rootProps: getRootProps(),
          inputProps: getInputProps(),
          helperText: translations.filesHelperText,
        }}
        files={files}
      />
    </StatusDialog>
  );
};

export default AppointmentFileAddDialog;
