import { defineMessages } from "react-intl";

const messages = defineMessages({
  years: {
    id: "Patient.PatientPreview.years",
    defaultMessage: "yrs",
  },
});

export default messages;
