import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../AppContext";

const UndefinedRoleRoute: FC = () => {
  const { databaseUserData } = useAppContext();

  return (
    <Navigate replace to={`/${databaseUserData.config.last_used_role}/`} />
  );
};

export default UndefinedRoleRoute;
