import Big from "big.js";

type Config = {
  appointment_checkout_duration_seconds: number;
  appointment_checkout_max_repeat_count: number;
  minimum_time_before_booking_seconds: number;
};

type PartialConfig = { [P in keyof Config]?: Config[P] | undefined | null };

const calcMinBeforeBookingSeconds = (params: {
  systemConfig: Config;
  centerConfig?: PartialConfig | null;
}) => {
  const { systemConfig, centerConfig } = params;

  const appointmentCheckoutDurationSeconds =
    centerConfig?.appointment_checkout_duration_seconds ??
    systemConfig.appointment_checkout_duration_seconds;

  const appointmentCheckoutMaxRepeatCount =
    centerConfig?.appointment_checkout_max_repeat_count ??
    systemConfig.appointment_checkout_max_repeat_count;

  const minimumTimeBeforeBookingSeconds =
    centerConfig?.minimum_time_before_booking_seconds ??
    systemConfig.minimum_time_before_booking_seconds;

  const calculatedMinimumTimeBeforeBookingSeconds = Big(
    appointmentCheckoutDurationSeconds,
  )
    .times(appointmentCheckoutMaxRepeatCount)
    .plus(minimumTimeBeforeBookingSeconds)
    .toNumber();

  return calculatedMinimumTimeBeforeBookingSeconds;
};

export default calcMinBeforeBookingSeconds;
