import { AuditLog } from "./types";

const getAuditLogChangedBy = (auditLog: AuditLog, defaultTitle: string) => {
  let changedBy = defaultTitle;
  if (auditLog.role === "customer" && auditLog.customer) {
    changedBy = `${auditLog.customer.first_name} ${auditLog.customer.last_name}`;
  }
  if (auditLog.role === "center_user" && auditLog.center_user) {
    changedBy = `${auditLog.center_user.first_name} ${auditLog.center_user.last_name}`;
  }
  return changedBy;
};

export default getAuditLogChangedBy;
