import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { SystemConfig } from "../../../hooks/useSystemConfig";
import { SettingsCenter } from "./getSettingsCenterData";
import { Discount } from "./types";

const allowedOfferingVisibility: DatabaseEnum["offering_visibility"][] = [
  "public",
  "only_package",
];

const calcMaxDiscount = (
  centerOfferings: SettingsCenter["center_offerings"],
) => {
  const offeringPrices = centerOfferings
    .filter(({ visibility }) => allowedOfferingVisibility.includes(visibility))
    .map(({ price }) => price);

  return Math.min(...offeringPrices);
};

const normalizeDiscount = (
  center: SettingsCenter,
  systemConfig: SystemConfig,
): Discount => {
  const centerConfig = center.center_configs[0];

  const amount = centerConfig?.discount_amount ?? systemConfig.discount_amount;

  const currencyCode =
    centerConfig?.default_currency_code || systemConfig.default_currency_code;

  const maxDiscount = calcMaxDiscount(center.center_offerings);

  return { amount, currencyCode, maxDiscount };
};

export default normalizeDiscount;
