import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Offering.CenterOfferingArchiveDialog.title",
    defaultMessage: "Archive Offering",
  },
  description: {
    id: "Offering.CenterOfferingArchiveDialog.description",
    defaultMessage:
      "Are you sure you want to archive the <b>{name}</b> offering? Once archived, you will no longer be able to sell this offering.",
  },
  btnCancel: {
    id: "Offering.CenterOfferingArchiveDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnArchive: {
    id: "Offering.CenterOfferingArchiveDialog.btnArchive",
    defaultMessage: "Archive",
  },
});

export default messages;
