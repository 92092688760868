import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
  FC,
  useCallback,
} from "react";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { signInWithCustomToken } from "firebase/auth";
import { useAuth as useFirebaseAuth } from "reactfire";
import { Hub } from "@aws-amplify/core";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { setSupabaseAccessToken } from "../../../hooks/useSupabase";
import { useUIContext } from "../../Unknown/UIContext";
import useTranslations from "./useTranslations";

export type AuthUserData = CognitoPayload & {
  username: string;
};

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  userData: AuthUserData | null;
  signOut: () => void;
  getAuthUserToken: () => Promise<string | null>;
}

type Props = {
  children?: ReactNode;
};

type CognitoPayload = {
  email: string;
  family_name: string;
  given_name: string;
  phone_number: string;
  primaryRole: string;
  rolesString: string;
  sub: string;
  "cognito:username": string;
};

const getAuthUserData = (cognitoUser: CognitoUser): AuthUserData | null => {
  const payload = cognitoUser
    .getSignInUserSession()
    ?.getIdToken()
    .decodePayload() as CognitoPayload | null;

  if (!payload) return null;

  return {
    ...payload,
    username: payload["cognito:username"],
  };
};

const authContext = createContext({} as UseAuth);

export const useAuthContext = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const { authError } = useTranslations();
  const createCustomCenterUserToken = useFirebaseAppFunction(
    "createCustomCenterUserToken",
  );
  const firebaseAuth = useFirebaseAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState<AuthUserData | null>(null);
  const { setAlert } = useUIContext();

  const signOut = useCallback(async () => {
    try {
      setIsLoading(true);
      await Auth.signOut();
      await firebaseAuth.signOut();
      setSupabaseAccessToken("");

      setIsAuthenticated(false);
      setUserData(null);
    } catch (error) {
      setAlert({ isShown: true, severity: "error", message: authError });
    } finally {
      setIsLoading(false);
    }
  }, [authError, firebaseAuth, setAlert]);

  const getAuthUserToken = async (): Promise<string | null> => {
    const currentUser = await Auth.currentAuthenticatedUser();

    return (
      currentUser.getSignInUserSession()?.getIdToken().getJwtToken() || null
    );
  };

  const signInWithTokens = useCallback(
    async (cognitoResult: CognitoUser) => {
      try {
        setIsLoading(true);
        const cognitoPayload = getAuthUserData(cognitoResult);

        if (!cognitoPayload) {
          throw new Error("Auth error");
        }

        const { data } = await createCustomCenterUserToken({
          centerUserId: cognitoPayload.sub,
          email: cognitoPayload.email,
          firstName: cognitoPayload.given_name,
          lastName: cognitoPayload.family_name,
        });

        const { firebaseToken, supabaseToken } = data;

        await signInWithCustomToken(firebaseAuth, firebaseToken);

        setSupabaseAccessToken(supabaseToken);

        setIsAuthenticated(true);
        setUserData(cognitoPayload);
      } catch (error) {
        setAlert({ isShown: true, severity: "error", message: authError });
        setIsAuthenticated(false);
        setUserData(null);
      } finally {
        setIsLoading(false);
      }
    },
    [createCustomCenterUserToken, authError, firebaseAuth, setAlert],
  );

  const signInCognito = useCallback(async () => {
    try {
      setIsLoading(true);
      const currentUser = await Auth.currentAuthenticatedUser();
      const payload = getAuthUserData(currentUser);
      setUserData(payload);
      setIsAuthenticated(true);
    } catch (error) {
      setUserData(null);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        switch (event) {
          case "signIn":
            await signInWithTokens(data);
            break;
          default:
            break;
        }
      },
    );

    if (!isAuthenticated) {
      signInCognito();
    }

    return unsubscribe;
  }, [isAuthenticated, signInCognito, signInWithTokens]);

  return {
    isLoading,
    isAuthenticated,
    userData,
    signOut,
    getAuthUserToken,
  };
};

export const AuthContext: FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
