import { UpdateCenterPatientErrorCode } from "@Shape-Digital/kudzu-data/lib/types/actions";
import { useCallback } from "react";
import useTranslations from "./useTranslations";

const useErrorMessages = () => {
  const { translations } = useTranslations();
  const { defaultError, errorConfirmationCodeIsInvalid, errorEmailInUse } =
    translations;

  const getErrorMessageOnUpdateCustomer = useCallback(
    (errorCode: UpdateCenterPatientErrorCode) => {
      switch (errorCode) {
        case "AUTH_CODE_INVALID": {
          throw new Error(errorConfirmationCodeIsInvalid);
        }
        case "EMAIL_IN_USE": {
          throw new Error(errorEmailInUse);
        }
        default: {
          throw new Error(defaultError);
        }
      }
    },
    [defaultError, errorConfirmationCodeIsInvalid, errorEmailInUse],
  );

  return { getErrorMessageOnUpdateCustomer };
};

export default useErrorMessages;
