import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useLabelTranslations = () => {
  const { formatMessage } = useIntl();

  const labelTranslations = useMemo(() => {
    return {
      labelDurationMinutes: formatMessage(messages.labelDurationMinutes),
    };
  }, [formatMessage]);

  return labelTranslations;
};

export default useLabelTranslations;
