import React from "react";
import Chip from "../../Unknown/Chip";
import IconButton from "../../Unknown/IconButton";
import Tooltip from "../../Unknown/Tooltip";
import { IconColor, Action, DefaultAction } from "./types";

const getIconColors = (color?: IconColor) => {
  switch (color) {
    case "primary": {
      return {
        bgcolor: "primary.main",
        color: "common.white",
      };
    }
    case "error": {
      return {
        bgcolor: "error.main",
        color: "common.white",
      };
    }
    case "success": {
      return {
        bgcolor: "success.main",
        color: "common.white",
      };
    }
    default: {
      return {};
    }
  }
};

const getDefaultActions = (actions: DefaultAction[]): Action[] => {
  return actions
    .map<Action | null>((action) => {
      switch (action.type) {
        case "icon": {
          const { bgcolor, color } = getIconColors(action.color);

          const iconSx = {
            bgcolor,
            color,
            "&:hover": {
              bgcolor,
              color,
            },
            width: 32,
            height: 32,
            fontSize: 10,
          };

          if (action.tooltip) {
            return {
              id: action.id,
              node: (
                <Tooltip title={action.tooltip} arrow>
                  <IconButton disableRipple sx={iconSx}>
                    {action.icon}
                  </IconButton>
                </Tooltip>
              ),
            };
          }
          return {
            id: action.id,
            node: (
              <IconButton disableRipple sx={iconSx}>
                {action.icon}
              </IconButton>
            ),
          };
        }
        case "chip": {
          return {
            id: action.id,
            node: <Chip {...action.chipProps} />,
          };
        }
        default: {
          return null;
        }
      }
    })
    .filter(Boolean) as Action[];
};

export default getDefaultActions;
