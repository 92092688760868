import { AppointmentAuditLogEvent } from "@Shape-Digital/kudzu-data/lib/constants/common";
import { AuditLog } from "./types";

const getAuditLogChangeEvent = (
  auditLog: AuditLog,
): AppointmentAuditLogEvent | undefined => {
  const isAppointmentAuditLog = "appointment_id" in auditLog;
  let changeTitle;
  if (isAppointmentAuditLog) {
    changeTitle = auditLog.event as AppointmentAuditLogEvent;
  }
  return changeTitle;
};

export default getAuditLogChangeEvent;
