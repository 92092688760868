import { useCallback, useEffect, useState } from "react";
import getCustomerData from "./getCustomerData";
import { Customer } from "./types";
import useTranslations from "./useTranslations";
import getAppointmentCustomerData from "./getAppointmentCustomerData";

const useCustomer = ({
  customerId,
  isOtherPerson,
}: {
  customerId?: string;
  isOtherPerson?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { translations } = useTranslations();
  const { defaultError, errorCustomerNotFound } = translations;

  const [data, setData] = useState<Customer | null>(null);

  const [error, setError] = useState<string | null>(null);

  const updateCustomer = useCallback(async () => {
    if (!customerId) return;

    try {
      setIsLoading(true);
      let customer: Customer | null = null;
      try {
        if (isOtherPerson) {
          customer = await getAppointmentCustomerData(customerId);
        }

        if (!isOtherPerson) {
          customer = await getCustomerData(customerId);
        }
      } catch (err) {
        throw new Error(defaultError);
      }

      if (!customer) {
        throw new Error(errorCustomerNotFound);
      }

      setData(customer);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [defaultError, errorCustomerNotFound, customerId, isOtherPerson]);

  useEffect(() => {
    updateCustomer();
  }, [updateCustomer]);

  return { isLoading, error, data, refetch: updateCustomer };
};

export default useCustomer;
