import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import getUniqueStrings from "../../../common/getUniqueStrings";

const userAllowedPages: Record<DatabaseEnum["center_user_role"], string[]> = {
  radiologist: ["dashboard", "calendar", "patient", "appointment", "customer"],
  receptionist: ["dashboard", "calendar", "search", "appointment"],
  technologist: ["dashboard", "calendar", "search", "appointment"],
  regional_admin: ["dashboard", "calendar", "search", "appointment", "admin"],
  super_admin: ["dashboard", "calendar", "search", "appointment", "admin"],
};

const getUserAllowedActionPages = (
  permissions: DatabaseEnum["center_user_role"][],
): string[] => {
  const userAllowedActions = permissions.map(
    (permission) => userAllowedPages[permission],
  );

  const uniqueAllowedActionPages = getUniqueStrings(userAllowedActions.flat());

  return uniqueAllowedActionPages;
};

export default getUserAllowedActionPages;
