import { useMemo } from "react";
import { CustomerInformationDrawerProps } from "../CustomerInformationDrawer";
import getCustomerInformationCardProps from "./getCustomerInformationCardProps";
import useCustomerEditFormContainer from "./useCustomerEditFormContainer";
import { Customer } from "./types";

const useNormalizedCustomerDrawerData = (params: {
  customer: Customer | null;
  isOtherPerson?: boolean;
  handlePatientClick: (patientMrn: string) => void;
}) => {
  const { customer, isOtherPerson, handlePatientClick } = params;

  const customerEditFormContainerProps = useCustomerEditFormContainer({
    customerId: customer?.id,
    isOtherPerson,
  });

  const data = useMemo(() => {
    if (!customer || !customerEditFormContainerProps) return null;

    const result: CustomerInformationDrawerProps["data"] = {
      name: `${customer.last_name}, ${customer.first_name}`,
      customerInformationCardProps: getCustomerInformationCardProps({
        customer,
        handlePatientClick,
      }),
      customerEditFormContainerProps,
      auditLogsData: isOtherPerson
        ? null
        : {
            auditLogs: customer.customer_audit_logs,
            timezone:
              customer.appointment_customer_details[0].appointment.center
                .timezone,
          },
    };

    return result;
  }, [
    customer,
    customerEditFormContainerProps,
    handlePatientClick,
    isOtherPerson,
  ]);

  return data;
};

export default useNormalizedCustomerDrawerData;
