import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentColumnItemPatientAddressLine1,
  AppointmentRowItem,
} from "../types";

export type ColumnPatientAddressLine1Translations = {
  columnPatientAddressLine1: string;
};

const getColumnPatientAddressLine1 = (params: {
  columnItem: AppointmentColumnItemPatientAddressLine1;
  translations: ColumnPatientAddressLine1Translations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    headerName: translations.columnPatientAddressLine1,
    valueGetter: (getterParams) => {
      const { patient } = getterParams.row;

      if (!patient) return "";

      return patient.addressLine1 || "";
    },
  };
};

export default getColumnPatientAddressLine1;
