import { useFormikContext } from "formik";
import { useCallback, useMemo } from "react";
import {
  getAllCountries,
  getStatesOfCountry,
} from "../../../common/regionHelpers";
import { FormikTextFieldProps } from "../../Unknown/FormikTextField";
import { PatientEditFormValues } from "./types";

type ChangeHandler = NonNullable<FormikTextFieldProps["onChange"]>;

const useRegions = (params: {
  countryIsoCodeValue: string;
  stateIsoCodeName: string;
}) => {
  const { countryIsoCodeValue, stateIsoCodeName } = params;
  const { setFieldValue, handleChange } =
    useFormikContext<PatientEditFormValues>();

  const countries = getAllCountries();

  const states = useMemo(() => {
    if (!countryIsoCodeValue) return [];
    const stateOptions = getStatesOfCountry(countryIsoCodeValue);
    return stateOptions;
  }, [countryIsoCodeValue]);

  const onCountryChange = useCallback<ChangeHandler>(
    (event) => {
      setFieldValue(stateIsoCodeName, "");
      handleChange(event);
    },
    [handleChange, setFieldValue, stateIsoCodeName],
  );

  return { countries, states, onCountryChange };
};

export default useRegions;
