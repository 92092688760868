import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      titleInformation: formatMessage(messages.titleInformation),
      titleAppointments: formatMessage(messages.titleAppointments),
      titleAuditLogs: formatMessage(messages.titleAuditLogs),
      viewAuditLogsBtn: formatMessage(messages.viewAuditLogsBtn),
      hideAuditLogsBtn: formatMessage(messages.hideAuditLogsBtn),
    };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
