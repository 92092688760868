import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentColumnItemBookingScenario,
  AppointmentRowItem,
} from "../types";

type Scenario = AppointmentRowItem["appointmentCustomer"]["customerType"];

export type ColumnBookingScenarioTranslations = {
  columnBookingScenario: string;
  scenarioPatient: string;
  scenarioLegalGuardian: string;
  scenarioOtherPerson: string;
};

const scenarios: Record<Scenario, keyof ColumnBookingScenarioTranslations> = {
  patient: "scenarioPatient",
  legal_guardian: "scenarioLegalGuardian",
  other_person: "scenarioOtherPerson",
};

const getColumnBookingScenario = (params: {
  columnItem: AppointmentColumnItemBookingScenario;
  translations: ColumnBookingScenarioTranslations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnBookingScenario,
    valueGetter: (getterParams) => {
      const { appointmentCustomer } = getterParams.row;

      if (!appointmentCustomer) return "";

      const { customerType } = appointmentCustomer;
      const bookingScenarioTranslationKey = scenarios[customerType];
      const bookingScenarioTranslation =
        translations[bookingScenarioTranslationKey];

      return bookingScenarioTranslation;
    },
  };
};

export default getColumnBookingScenario;
