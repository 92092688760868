import React, { FC } from "react";
import PatientEditForm from "../PatientEditForm";
import { PatientEditFormTranslations } from "../PatientEditForm/types";
import useConfirmationCode from "./useConfirmationCode";

export type PatientEditFormContainerProps = {
  translations: PatientEditFormTranslations;
  patientId: string;
  namePrefix?: string;
  isEmailShown?: boolean;
  isPhoneShown?: boolean;
};

const PatientEditFormContainer: FC<PatientEditFormContainerProps> = ({
  translations,
  patientId,
  namePrefix,
  isEmailShown,
  isPhoneShown,
}) => {
  const {
    isConfirmationCodeShown,
    isSending,
    isCodeSent,
    handleSendCodeClick,
  } = useConfirmationCode({ patientId, namePrefix });

  return (
    <PatientEditForm
      translations={translations}
      namePrefix={namePrefix}
      isEmailShown={isEmailShown}
      isPhoneShown={isPhoneShown}
      isConfirmationCodeShown={isConfirmationCodeShown}
      isSendingCode={isSending}
      isSendCodeDisabled={isCodeSent}
      handleSendCodeClick={handleSendCodeClick}
    />
  );
};

export default PatientEditFormContainer;
