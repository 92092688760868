import { useCallback, useEffect, useState } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { AddOn, AddOnsData } from "../../Center/CenterSettingsContainer/types";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterAddOnFormValues } from "../CenterAddOnForm/types";
import { CenterAddOnFormikProps } from "../CenterAddOnFormik";
import { TYPE_STAT } from "../CenterAddOnFormik/constants";
import useTranslations from "./useTranslations";

type EditFormHookParams = {
  isOpened: boolean;
  editedAddOnId: string | null;
  onClose: () => void;
  addOnsData: AddOnsData;
  refetchCenterData?: () => void | Promise<void>;
};

type EditFormHookResult = {
  editFormikProps: CenterAddOnFormikProps | null;
};

const useEditForm = (params: EditFormHookParams): EditFormHookResult => {
  const { isOpened, editedAddOnId, onClose, addOnsData, refetchCenterData } =
    params;
  const { addOns, currencyCode } = addOnsData;

  const updateCenterAddOn = useFirebaseAppFunction("updateCenterAddOn");

  const { titleEditAddOn, defaultError } = useTranslations();

  const { setAlert } = useUIContext();

  const [centerAddOnData, setCenterAddOnData] = useState<AddOn | null>(null);

  useEffect(() => {
    if (!isOpened) return;

    if (!editedAddOnId) {
      setCenterAddOnData(null);
      onClose();
      return;
    }

    const selectedAddOn = addOns.find((addOn) => addOn.id === editedAddOnId);

    if (!selectedAddOn) {
      setCenterAddOnData(null);
      onClose();
      return;
    }
    setCenterAddOnData(selectedAddOn);
  }, [editedAddOnId, addOns, onClose, isOpened]);

  const handleSubmit = useCallback(
    async (values: CenterAddOnFormValues) => {
      try {
        if (!centerAddOnData) throw new Error(defaultError);

        const { name, price, isStat } = values;

        await updateCenterAddOn({
          id: centerAddOnData.id,
          name,
          price,
          type: isStat ? TYPE_STAT : "",
        });

        if (refetchCenterData) await refetchCenterData();

        onClose();
      } catch (error) {
        setAlert({
          message: defaultError,
          isShown: true,
          severity: "error",
        });
      }
    },
    [
      centerAddOnData,
      defaultError,
      onClose,
      refetchCenterData,
      setAlert,
      updateCenterAddOn,
    ],
  );

  return {
    editFormikProps: centerAddOnData
      ? {
          title: titleEditAddOn,
          isOpened,
          onModalClose: onClose,
          centerAddOnData,
          handleSubmit,
          currencyCode,
        }
      : null,
  };
};

export default useEditForm;
