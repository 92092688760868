import React, { FC, useState, MouseEvent } from "react";
import Box from "../Box";
import IconButton from "../IconButton";
import Menu from "../Menu";
import Avatar from "../Avatar";
import NavbarProfileOption, {
  NavbarProfileOptionProps,
} from "../NavbarProfileOption";

export type NavbarProfileProps = {
  options: Array<NavbarProfileOptionProps & { id: string }>;
  activeOptionId?: string;
};

const NavbarProfile: FC<NavbarProfileProps> = ({ options, activeOptionId }) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isOpen = Boolean(anchorElUser);

  return (
    <Box>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar />
      </IconButton>
      <Menu
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpen}
        onClose={handleCloseUserMenu}
        sx={{ mt: 1 }}
      >
        {options.map((option) => (
          <NavbarProfileOption
            key={option.id}
            handleCloseUserMenu={handleCloseUserMenu}
            {...option}
            isActive={activeOptionId === option.id}
          />
        ))}
      </Menu>
    </Box>
  );
};
export default NavbarProfile;
