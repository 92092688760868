import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemIsPast, AppointmentRowItem } from "../types";

const getColumnAlert = (params: {
  columnItem: AppointmentColumnItemIsPast;
}): GridColDef<AppointmentRowItem> => {
  const { columnItem } = params;

  return {
    field: columnItem.field,
    disableColumnMenu: true,
    disableExport: true,
    sortable: true,
    filterable: false,
    hideable: false,
  };
};

export default getColumnAlert;
