import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { SupabaseClient } from "@supabase/supabase-js";

export type SearchPatientRequestParams = {
  email?: string;
  phone?: string;
  name?:
    | { type: "one_word"; value: string }
    | { type: "two_words"; value: readonly [string, string] };
  birthDate?: string[];
};

export type SearchPatientRequestKey = keyof SearchPatientRequestParams;

type SearchPatientCenterTimeSlot = Pick<
  DatabaseRow<"center_time_slots">,
  "started_at"
>;

type SearchPatientCenter = Pick<DatabaseRow<"centers">, "name" | "timezone">;

type SearchPatientAppointment = Pick<DatabaseRow<"appointments">, "status"> & {
  center: SearchPatientCenter;
  center_time_slot: SearchPatientCenterTimeSlot;
};

type SearchPatientAppointmentPatientDetails = Pick<
  DatabaseRow<"appointment_patient_details">,
  "appointment_id"
> & {
  appointment: SearchPatientAppointment;
};

export type SearchPatient = Pick<
  DatabaseRow<"patients">,
  | "id"
  | "first_name"
  | "last_name"
  | "email"
  | "mobile_phone_number"
  | "date_birth"
  | "mrn"
> & {
  appointment_patient_details: SearchPatientAppointmentPatientDetails[];
};

const supabaseSearchPatientRequest = `
id,
first_name,
last_name,
email,
mobile_phone_number,
date_birth,
mrn,
appointment_patient_details (
  appointment_id,
  appointment:appointment_id!inner (
    status,
    center:center_id (
      name,
      timezone
    ),
    center_time_slot:time_slot_id!inner (
      started_at
    )
  )
)
`;

const getSearchPatients = async (
  supabaseClient: SupabaseClient,
  params: SearchPatientRequestParams,
) => {
  if (!Object.keys(params).length) return [];

  let query = supabaseClient
    .from<SearchPatient>("patients")
    .select(supabaseSearchPatientRequest);

  const { email, name, phone, birthDate } = params;

  if (email) {
    query = query.eq("email", email);
  }

  if (phone) {
    query = query.eq("mobile_phone_number", phone);
  }

  if (name) {
    switch (name.type) {
      case "one_word": {
        const nameFilter = [
          `first_name.ilike.%${name.value}%`,
          `last_name.ilike.%${name.value}%`,
        ].join(",");

        query = query.or(nameFilter);
        break;
      }
      case "two_words": {
        const [firstName, secondName] = name.value;

        const nameFilter = [
          `and(first_name.ilike.%${firstName}%,last_name.ilike.%${secondName}%)`,
          `and(first_name.ilike.%${secondName}%,last_name.ilike.%${firstName}%)`,
        ].join(",");

        query = query.or(nameFilter);
        break;
      }
      default: {
        break;
      }
    }
  }

  if (birthDate) {
    const dateBirthFilter = birthDate
      .map((dateISO) => `date_birth.eq.${dateISO}`)
      .join(",");

    query = query.or(dateBirthFilter);
  }

  const { data, error } = await query;
  if (error) throw new Error(error.message);

  return data;
};

export default getSearchPatients;
