import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Center.CenterInformationSettingsTab.title",
    defaultMessage: "Edit Contact Information",
  },
  phoneNumberInvalidError: {
    id: "Center.CenterInformationSettingsTab.phoneNumberInvalidError",
    defaultMessage: "Phone number is invalid. Please use integers only",
  },
  faxNumberInvalidError: {
    id: "Center.CenterInformationSettingsTab.faxNumberInvalidError",
    defaultMessage: "Fax is invalid. Please use integers only",
  },
  emailInvalidError: {
    id: "Center.CenterInformationSettingsTab.emailInvalidError",
    defaultMessage: "Email is invalid",
  },
  labelPhone: {
    id: "Center.CenterInformationSettingsTab.labelPhone",
    defaultMessage: "Phone",
  },
  labelFax: {
    id: "Center.CenterInformationSettingsTab.labelFax",
    defaultMessage: "Fax",
  },
});

export default messages;
