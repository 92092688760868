import { defineMessages } from "react-intl";

const messages = defineMessages({
  viewReceptionist: {
    id: "Unknown.AuthenticatedLayout.viewReceptionist",
    defaultMessage: "Receptionist view",
  },
  viewTechnologist: {
    id: "Unknown.AuthenticatedLayout.viewTechnologist",
    defaultMessage: "Technologist view",
  },
  viewRadiologist: {
    id: "Unknown.AuthenticatedLayout.viewRadiologist",
    defaultMessage: "Radiologist view",
  },
  viewRegionalAdmin: {
    id: "Unknown.AuthenticatedLayout.viewRegionalAdmin",
    defaultMessage: "Regional admin view",
  },
  viewSuperAdmin: {
    id: "Unknown.AuthenticatedLayout.viewSuperAdmin",
    defaultMessage: "Super admin view",
  },
  btnLogOut: {
    id: "Unknown.AuthenticatedLayout.btnLogOut",
    defaultMessage: "Log out",
  },
  addAppointmentError: {
    id: "Unknown.AuthenticatedLayout.addAppointmentError",
    defaultMessage: "Failed to open appointment booking",
  },
  searchLabel: {
    id: "Unknown.AuthenticatedLayout.searchLabel",
    defaultMessage: "Search",
  },
  adminLabel: {
    id: "Unknown.AuthenticatedLayout.adminLabel",
    defaultMessage: "Admin",
  },
  calendarLabel: {
    id: "Unknown.AuthenticatedLayout.calendarLabel",
    defaultMessage: "Calendar",
  },
  addAppointmentLabel: {
    id: "Unknown.AuthenticatedLayout.addAppointmentLabel",
    defaultMessage: "Add appointment",
  },
  dashboardLabel: {
    id: "Unknown.AuthenticatedLayout.dashboardLabel",
    defaultMessage: "Dashboard",
  },
});

export default messages;
