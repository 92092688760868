import React, { FC } from "react";
import { useField } from "formik";
import TimePicker from "../TimePicker";
import TextField, { TextFieldProps } from "../TextField";

type FormikTimePickerProps = {
  name: string;
  textFieldProps: Omit<
    TextFieldProps,
    "value" | "multiline" | "onChange" | "onBlur" | "error" | "helperText"
  >;
};
const FormikTimePicker: FC<FormikTimePickerProps> = ({
  name,
  textFieldProps,
}) => {
  const [, meta, helpers] = useField(name);
  const { touched, error, value } = meta;
  const { setValue, setTouched } = helpers;

  return (
    <TimePicker
      label={textFieldProps.label}
      onChange={(newValue) => setValue(newValue, true)}
      value={value}
      disabled={textFieldProps.disabled}
      InputAdornmentProps={{
        onBlur: () => setTouched(true, true),
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          error={touched && Boolean(error)}
          helperText={touched && error}
          name={name}
        />
      )}
    />
  );
};

export default FormikTimePicker;
