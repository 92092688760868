import * as Yup from "yup";
import { useMemo } from "react";
import useLabelTranslations from "./useLabelTranslations";
import { MAX_STRING_LEN } from "../../../common/constants";

const requiredStringWithMaxLength = Yup.string()
  .trim()
  .max(MAX_STRING_LEN)
  .required();

const requiredString = Yup.string().trim().required();

const useValidationSchema = () => {
  const labelTranslations = useLabelTranslations();

  const schema = useMemo(() => {
    const { labelOffering, labelSide, labelModalityName, labelReason } =
      labelTranslations;

    return Yup.object().shape({
      offering: requiredStringWithMaxLength.label(labelOffering),
      side: Yup.string().when("isDualSide", {
        is: true,
        then: requiredStringWithMaxLength.label(labelSide),
      }),
      modalityName: requiredStringWithMaxLength.label(labelModalityName),
      reason: requiredString.label(labelReason),
    });
  }, [labelTranslations]);

  return schema;
};

export default useValidationSchema;
