export const DEFAULT_CURRENCY_CODE = "USD";
export const DEFAULT_REGION = "us-central1";
export const FUNCTIONS_PREFIX = "centerApp";

export const SUPABASE_TOKEN = "SUPABASE_TOKEN";

export const PHONE_NUMBER_REGEXP_STR = /[0-9]+$/;

export const MAX_STRING_LEN = 100;

export const PAGE_SIZE_OPTIONS = [25, 50, 75, 100];
export const PAGE_SIZE = 25;

export const DEFAULT_LOCALE = "en-Us";

export const EMAIL_REGEXP_STR =
  "([a-zA-Z0-9+._-]+@[a-zA-Z0-9_-]+\\.[a-zA-Z0-9_-]{2,})";

export const POSTAL_CODE_REG_EXP = "[A-Za-z0-9\\-\\s]{5,20}";

export const ADMIN_APPOINTMENTS_LIST_DURATION_DAYS = 7;

export const DEFAULT_COLUMN_MIN_WIDTH = 233;

export const DEFAULT_COUNTRY_CODE = "US";

export const MIN_PHONE_NUMBER_LENGTH = 10;
