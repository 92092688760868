import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "AppointmentNote.AppointmentNoteAddDialog.title",
    defaultMessage: "Add Note",
  },
  btnSubmit: {
    id: "AppointmentNote.AppointmentNoteAddDialog.btnSubmit",
    defaultMessage: "Save",
  },
  btnCancel: {
    id: "AppointmentNote.AppointmentNoteAddDialog.btnCancel",
    defaultMessage: "Cancel",
  },
});

export default messages;
