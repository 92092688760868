import React from "react";
import compareAsc from "date-fns/compareAsc";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import { GridColDef, GridComparatorFn } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import Box from "../../../Unknown/Box";
import { AppointmentOfferingRowItem } from "../types";
import { AppointmentColumnItemTime } from "../../../Appointment/AppointmentTableGrid/types";

type ColumnTranslations = {
  columnDateTime: string;
};

type ColumnValue = {
  startedAtUTC: Date;
  startedAtCenterTimezone: Date;
  centerLocale: string;
  centerTimezone: string;
};

export type ColumnTimeTranslations = {
  columnDateTime: string;
};

const sortComparator: GridComparatorFn<ColumnValue> = (
  currentValue,
  compareValue,
) => {
  return compareAsc(currentValue.startedAtUTC, compareValue.startedAtUTC);
};

const getColumnDateTime = (params: {
  columnItem: AppointmentColumnItemTime;
  translations: ColumnTranslations;
  classNames: {
    timeRoot: string;
  };
}): GridColDef<AppointmentOfferingRowItem, ColumnValue> => {
  const { columnItem, translations, classNames } = params;

  const { field, localeFormat } = columnItem;

  const { columnDateTime } = translations;
  const { timeRoot } = classNames;

  return {
    field,
    disableColumnMenu: true,
    headerName: columnDateTime,
    flex: 1,
    sortComparator,
    getApplyQuickFilterFn: () => null,
    valueGetter: (getterParams) => {
      const { startedAtCenterTimezone, startedAtUTC, center } =
        getterParams.row;
      const { locale, timezone } = center;

      return {
        startedAtCenterTimezone,
        startedAtUTC,
        centerLocale: locale,
        centerTimezone: timezone || "UTC",
      };
    },

    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      const { startedAtCenterTimezone, centerLocale, centerTimezone } =
        renderParams.value;

      const formattedDate =
        startedAtCenterTimezone.toLocaleDateString(centerLocale);

      const locale = localeFormat === "center" ? centerLocale : undefined;

      const formattedTime = startedAtCenterTimezone
        .toLocaleTimeString(locale, { hour: "numeric", minute: "numeric" })
        .toLocaleLowerCase()
        .replace(/\s/, "");

      const timeZoneAbbr = formatInTimeZone(
        startedAtCenterTimezone,
        centerTimezone,
        "zzz",
      ).toLocaleUpperCase();

      return (
        <Box>
          <Typography variant="body2">{formattedDate}</Typography>
          <Typography className={timeRoot}>
            {formattedTime} ({timeZoneAbbr})
          </Typography>
        </Box>
      );
    },
  };
};

export default getColumnDateTime;
