import AddOutlined from "@mui/icons-material/AddOutlined";
import React, { FC } from "react";
import { PackagesData } from "../../Center/CenterSettingsContainer/types";
import Box from "../../Unknown/Box";
import Button from "../../Unknown/Button";
import CenterPackageFormik from "../CenterPackageFormik";
import CenterPackageView from "../CenterPackageView";
import useAddForm from "./useAddForm";
import useOpenedModal from "./useOpenedModal";
import useTranslations from "./useTranslations";
import useEditForm from "./useEditForm";
import useArchiveModal from "./useArchiveModal";
import CenterPackageArchiveDialog from "../CenterPackageArchiveDialog";

export type CenterPackagesSettingsTabProps = {
  packagesData: PackagesData;
  centerId: string;
  refetchCenterData?: () => void | Promise<void>;
};

const CenterPackagesSettingsTab: FC<CenterPackagesSettingsTabProps> = ({
  packagesData,
  centerId,
  refetchCenterData,
}) => {
  const { packages, currencyCode, minPrice, offerings } = packagesData;

  const { btnAddPackage } = useTranslations();

  const {
    id: packageId,
    openedModal,
    onAddClick,
    onEditClick,
    onArchiveClick,
    onModalClose,
  } = useOpenedModal();

  const editFormikProps = useEditForm({
    isOpened: openedModal === "edit",
    editedPackageId: packageId,
    onClose: onModalClose,
    packagesData,
    refetchCenterData,
  });

  const { addFormikProps } = useAddForm({
    isOpened: openedModal === "add",
    onClose: onModalClose,
    centerId,
    currencyCode,
    minPrice,
    refetchCenterData,
    offerings,
  });

  const { archiveDialogProps } = useArchiveModal({
    isOpened: openedModal === "archive",
    packageId,
    onClose: onModalClose,
    packages,
    refetchCenterData,
  });

  return (
    <Box pt={7}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          startIcon={<AddOutlined />}
          onClick={onAddClick}
        >
          {btnAddPackage}
        </Button>
      </Box>

      <Box display="flex" height="100%">
        <Box flexGrow={1} pb={3} overflow="auto">
          <CenterPackageView
            packages={packages}
            currencyCode={currencyCode}
            onArchiveClick={onArchiveClick}
            onEditClick={onEditClick}
          />
        </Box>
      </Box>

      {editFormikProps && <CenterPackageFormik {...editFormikProps} />}
      <CenterPackageFormik {...addFormikProps} />
      <CenterPackageArchiveDialog {...archiveDialogProps} />
    </Box>
  );
};

export default CenterPackagesSettingsTab;
