import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentColumnItemPatientAddressLine2,
  AppointmentRowItem,
} from "../types";

export type ColumnPatientAddressLine2Translations = {
  columnPatientAddressLine2: string;
};

const getColumnPatientAddressLine2 = (params: {
  columnItem: AppointmentColumnItemPatientAddressLine2;
  translations: ColumnPatientAddressLine2Translations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    headerName: translations.columnPatientAddressLine2,
    valueGetter: (getterParams) => {
      const { patient } = getterParams.row;

      if (!patient) return "";

      return patient.addressLine2 || "";
    },
  };
};

export default getColumnPatientAddressLine2;
