import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelAmount: {
    id: "Center.CenterDiscountForm.labelAmount",
    defaultMessage: "Amount",
  },
});

export default messages;
