import React, { FC } from "react";
import { useFormikContext } from "formik";
import FormikTextField from "../../Unknown/FormikTextField";
import Grid from "../../Unknown/Grid";
import { CustomerEditFormTranslations } from "./types";
import { defaultGridItemProps, inputSpacing } from "./constants";
import getFormNames from "./getFormNames";
import Button from "../../Unknown/Button";
import useTranslations from "./useTranslations";
import CircularProgress from "../../Unknown/CircularProgress";
import FormikPhoneInput from "../../Unknown/FormikPhoneInput";
import formatEmail from "../../../common/formatEmail";

export type CustomerEditFormProps = {
  translations: CustomerEditFormTranslations;
  namePrefix?: string;
  isPhoneShown?: boolean;
  isConfirmationCodeShown?: boolean;
  isSendingCode?: boolean;
  isSendCodeDisabled?: boolean;
  handleSendCodeClick?: () => void | Promise<void>;
};

const CustomerEditForm: FC<CustomerEditFormProps> = ({
  translations,
  namePrefix,
  isPhoneShown,
  isConfirmationCodeShown,
  isSendingCode,
  isSendCodeDisabled,
  handleSendCodeClick,
}) => {
  const { btnSendCode } = useTranslations();
  const formNames = getFormNames(namePrefix);

  const { isSubmitting, setFieldValue } = useFormikContext();

  const defaultInputProps = { disabled: isSubmitting, fullWidth: true };

  return (
    <Grid container spacing={inputSpacing} direction="row">
      <Grid item container spacing={inputSpacing}>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            required
            name={formNames.firstName}
            label={translations.labelFirstName}
          />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            required
            name={formNames.lastName}
            label={translations.labelLastName}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={inputSpacing}>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            required
            name={formNames.email}
            label={translations.labelEmail}
            onChange={({ target }) =>
              setFieldValue(formNames.email, formatEmail(target.value))
            }
          />
        </Grid>
        {isPhoneShown && (
          <Grid {...defaultGridItemProps}>
            <FormikPhoneInput
              name={formNames.phoneNumber}
              label={translations.labelPhoneNumber}
              fullWidth
              required
              disabled={isSubmitting}
              autoComplete="off"
            />
          </Grid>
        )}
      </Grid>
      {isConfirmationCodeShown && (
        <Grid
          item
          container
          spacing={inputSpacing}
          direction="row"
          wrap="nowrap"
        >
          <Grid {...defaultGridItemProps}>
            <FormikTextField
              {...defaultInputProps}
              required
              name={formNames.confirmationCode}
              label={translations.labelConfirmationCode}
            />
          </Grid>
          <Grid {...defaultGridItemProps} mt={1.4}>
            <Button
              onClick={handleSendCodeClick}
              variant="contained"
              size="large"
              fullWidth
              startIcon={isSendingCode ? <CircularProgress size={20} /> : null}
              disabled={isSendCodeDisabled || isSendingCode}
            >
              {btnSendCode}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomerEditForm;
