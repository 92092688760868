import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemDate, AppointmentRowItem } from "../types";

export type ColumnDateTranslations = {
  columnDate: string;
};

const getColumnDate = (params: {
  columnItem: AppointmentColumnItemDate;
  translations: ColumnDateTranslations;
}): GridColDef<AppointmentRowItem, Date> => {
  const { columnItem, translations } = params;
  const { field, localeFormat } = columnItem;

  return {
    field,
    disableColumnMenu: true,
    headerName: translations.columnDate,
    flex: 0.5,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    type: "date",
    valueGetter: ({ row }) => {
      const { startedAtCenterTimezone } = row;
      return startedAtCenterTimezone;
    },

    renderCell: ({ row }) => {
      const { startedAtCenterTimezone, center } = row;
      const { locale } = center;

      const centerLocale = localeFormat === "center" ? locale : undefined;

      const formattedDate =
        startedAtCenterTimezone.toLocaleDateString(centerLocale);

      return formattedDate;
    },
  };
};

export default getColumnDate;
