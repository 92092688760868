import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      title: formatMessage(messages.title),
      btnAdd: formatMessage(messages.btnAdd),
    };

    return { translations };
  }, [formatMessage]);

  return result;
};

export default useTranslations;
