import getDate from "date-fns/getDate";
import { useMemo } from "react";
import { AppointmentRescheduleDialogProps } from "../AppointmentRescheduleDialog";
import { AvailableTimeSlots } from "./types";

const useRescheduleTimePicker = (params: {
  availableTimeSlots: AvailableTimeSlots | null;
  selectedDate: Date | null;
  selectedTime: Date | null;
  onTimeChange: (time: Date | null) => void;
}): AppointmentRescheduleDialogProps["timePickerProps"] => {
  const { availableTimeSlots, selectedDate, selectedTime, onTimeChange } =
    params;

  const availableTimeList = useMemo(() => {
    if (!availableTimeSlots || !selectedDate) return [];

    const day = getDate(selectedDate);

    const daySlots = availableTimeSlots[day];

    return daySlots || [];
  }, [availableTimeSlots, selectedDate]);

  return {
    selectedTimeSlot: selectedTime,
    availableTimeSlots: availableTimeList,
    onTimeSlotChange: onTimeChange,
  };
};

export default useRescheduleTimePicker;
