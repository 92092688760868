import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Box from "../../../Unknown/Box";
import Tooltip from "../../../Unknown/Tooltip";
import IconButton from "../../../Unknown/IconButton";
import { AppointmentColumnItemAlert, AppointmentRowItem } from "../types";
import {
  AssignmentLateOutlinedIcon,
  SupervisorAccountOutlinedIcon,
  TimerOutlinedIcon,
  AssignmentTurnedInOutlinedIcon,
  QueueOutlinedIcon,
  ArrowCircleUpIcon,
} from "../../../Unknown/Icons";
import AppointmentTableFilterFlags, {
  AppointmentFlag,
} from "../../AppointmentTableFilterFlags";
import getOptionalArrayItem from "../../../../common/getOptionalArrayItem";
import checkIsFilterEmpty from "../../AppointmentTableFilterFlags/checkIsFilterEmpty";

interface ColumnValue {
  value: string;
  label: string;
}

export type ColumnStatusTranslations = {
  columnAlert: string;
  tooltipStat: string;
  tooltipAdditionalViews: string;
  tooltipUnexpectedFindings: string;
  tooltipReceiptConfirmed: string;
  tooltipHasPriority: string;
  tooltipRequiresGuardian: string;
  containsAny: string;
  containsAll: string;
};

const getColumnAlert = (params: {
  columnItem: AppointmentColumnItemAlert;
  translations: ColumnStatusTranslations;
  headerClassName?: string;
}): GridColDef<AppointmentRowItem, ColumnValue[], string> => {
  const { columnItem, translations, headerClassName } = params;

  return {
    field: columnItem.field,
    headerName: translations.columnAlert,
    headerClassName,
    disableColumnMenu: true,
    sortable: false,
    width: 100,
    type: "singleSelect",
    align: "center",
    valueFormatter: ({ value }) => {
      return value.map(({ label }) => label).join(";");
    },
    valueGetter: ({ row }) => {
      return [
        ...getOptionalArrayItem(
          row.appointmentCustomer?.customerType === "legal_guardian",
          {
            value: AppointmentFlag.requiresParentGuardian,
            label: translations.tooltipRequiresGuardian,
          },
        ),
        ...getOptionalArrayItem(row.isStat, {
          value: AppointmentFlag.isStat,
          label: translations.tooltipStat,
        }),
        ...getOptionalArrayItem(row.isAdditionalViews, {
          value: AppointmentFlag.isAdditionalViews,
          label: translations.tooltipAdditionalViews,
        }),
        ...getOptionalArrayItem(row.hasPriority, {
          value: AppointmentFlag.hasPriority,
          label: translations.tooltipHasPriority,
        }),
        ...getOptionalArrayItem(
          row.hasUnexpectedFindings &&
            !row.isUnexpectedFindingsReceiptConfirmed,
          {
            value: AppointmentFlag.hasUnexpectedFindings,
            label: translations.tooltipUnexpectedFindings,
          },
        ),
        ...getOptionalArrayItem(row.isUnexpectedFindingsReceiptConfirmed, {
          value: AppointmentFlag.isReceiptConfirmed,
          label: translations.tooltipReceiptConfirmed,
        }),
      ];
    },
    filterOperators: [
      {
        label: translations.containsAny,
        value: "contains-any",
        getApplyFilterFn: (filterItem) => {
          if (checkIsFilterEmpty(filterItem)) return null;

          return ({ value }) => {
            return value?.some((flag) => filterItem.value.includes(flag.value));
          };
        },
        InputComponent: AppointmentTableFilterFlags,
      },
      {
        label: translations.containsAll,
        value: "contains-all",
        getApplyFilterFn: (filterItem) => {
          if (checkIsFilterEmpty(filterItem)) return null;

          return ({ value }) => {
            return filterItem.value.every((filterValue: string) =>
              value?.some((flag) => flag.value === filterValue),
            );
          };
        },
        InputComponent: AppointmentTableFilterFlags,
      },
    ],
    renderCell: ({ row }) => {
      const tooltipPopperProps = {
        sx: {
          maxWidth: 100,
        },
      };

      return (
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          {row.appointmentCustomer?.customerType === "legal_guardian" && (
            <Tooltip
              title={translations.tooltipRequiresGuardian}
              arrow
              slotProps={{
                popper: tooltipPopperProps,
              }}
            >
              <IconButton size="small">
                <SupervisorAccountOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {row.isStat && (
            <Tooltip
              title={translations.tooltipStat}
              arrow
              slotProps={{
                popper: tooltipPopperProps,
              }}
            >
              <IconButton size="small">
                <TimerOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {row.isAdditionalViews && (
            <Tooltip
              title={translations.tooltipAdditionalViews}
              arrow
              slotProps={{
                popper: tooltipPopperProps,
              }}
            >
              <IconButton size="small">
                <QueueOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {row.hasPriority && (
            <Tooltip
              title={translations.tooltipHasPriority}
              arrow
              slotProps={{
                popper: tooltipPopperProps,
              }}
            >
              <IconButton size="small">
                <ArrowCircleUpIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {row.hasUnexpectedFindings &&
            !row.isUnexpectedFindingsReceiptConfirmed && (
              <Tooltip
                title={translations.tooltipUnexpectedFindings}
                arrow
                slotProps={{
                  popper: tooltipPopperProps,
                }}
              >
                <IconButton size="small">
                  <AssignmentLateOutlinedIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          {row.isUnexpectedFindingsReceiptConfirmed && (
            <Tooltip
              title={translations.tooltipReceiptConfirmed}
              arrow
              slotProps={{
                popper: tooltipPopperProps,
              }}
            >
              <IconButton size="small">
                <AssignmentTurnedInOutlinedIcon color="success" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
    },
  };
};

export default getColumnAlert;
