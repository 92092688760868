import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import supabaseClient from "../../../common/supabaseClient";

type AppointmentAuditLogCustomer = Pick<
  DatabaseRow<"customers">,
  "first_name" | "last_name"
>;

type AppointmentAuditLogCenterUser = Pick<
  DatabaseRow<"center_users">,
  "first_name" | "last_name"
>;

type Center = Pick<DatabaseRow<"centers">, "timezone">;
type Appointment = Pick<DatabaseRow<"appointments">, "id"> & {
  center: Center;
};

export type AppointmentAuditLog = Pick<
  DatabaseRow<"appointment_audit_logs">,
  "event" | "created_at" | "fields" | "role" | "appointment_id" | "id"
> & {
  customer: AppointmentAuditLogCustomer;
  center_user: AppointmentAuditLogCenterUser;
  appointment: Appointment;
};

const auditLogRequest = `
  id,
  event,
  created_at,
  fields,
  role,
  appointment_id,
  appointment: appointment_id (
    id,
    center: center_id (
      timezone
    )
  ),
  customer: customer_id (
    first_name,
    last_name
  ),
  center_user: center_user_id (
    first_name,
    last_name
  )
`;
const getAppointmentAuditLogs = async (appointmentId: string) => {
  const { data, error } = await supabaseClient
    .from<AppointmentAuditLog>("appointment_audit_logs")
    .select(auditLogRequest)
    .eq("appointment_id", appointmentId)
    .order("created_at", { ascending: false });

  if (error) throw new Error(error.message);

  return data;
};

export default getAppointmentAuditLogs;
