import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsDialog.title",
    defaultMessage: "Mark Report As “Unexpected Findings”",
  },
  descriptionStart: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsDialog.descriptionStart",
    defaultMessage: "Are you sure you want to mark report",
  },
  descriptionEnd: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsDialog.descriptionEnd",
    defaultMessage: "as “unexpected findings”?",
  },
  btnCancel: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnSubmit: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsDialog.btnSubmit",
    defaultMessage: "Confirm",
  },
});

export default messages;
