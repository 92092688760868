import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import NavBarSearchButton, {
  NavBarSearchButtonProps,
} from "../NavBarSearchButton";
import useStyles from "./useStyles";
import CircularProgress from "../CircularProgress";

export type NavbarActionItemLink = {
  itemType: "link";
  to: string;
};

export type NavbarActionItemSearch = {
  itemType: "search";
};

export type NavbarActionItemButton = {
  itemType: "button";
  isLoading: boolean;
  onClick: () => void;
};

export type NavbarActionItem = {
  id: string;
  title: string;
  IconComponent: ReactNode | JSX.Element;
  meta: NavbarActionItemLink | NavbarActionItemSearch | NavbarActionItemButton;
};

export type NavbarActionsProps = {
  actions: NavbarActionItem[];
  activeItemId?: string;
  boxPosition: NavBarSearchButtonProps["boxPosition"];
};

const NavbarActions: FC<NavbarActionsProps> = ({
  actions,
  activeItemId,
  boxPosition,
}) => {
  const { activeRoot } = useStyles();

  return (
    <>
      {actions.map((action) => {
        const { id, title, IconComponent, meta } = action;
        switch (meta.itemType) {
          case "link": {
            return (
              <Button
                startIcon={IconComponent}
                key={id}
                component={Link}
                to={meta.to}
                className={activeItemId === id ? activeRoot : ""}
              >
                {title}
              </Button>
            );
          }
          case "search": {
            return (
              <NavBarSearchButton
                IconComponent={IconComponent}
                key={id}
                title={title}
                className={activeItemId === id ? activeRoot : ""}
                boxPosition={boxPosition}
              />
            );
          }
          case "button": {
            return (
              <Button
                startIcon={
                  meta.isLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    IconComponent
                  )
                }
                key={id}
                disabled={meta.isLoading}
                onClick={meta.onClick}
                className={activeItemId === id ? activeRoot : ""}
              >
                {title}
              </Button>
            );
          }
          default: {
            return null;
          }
        }
      })}
    </>
  );
};

export default NavbarActions;
