import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelPrimaryAddress: {
    id: "Center.CenterAddressForm.labelPrimaryAddress",
    defaultMessage: "Address line 1",
  },
  labelSecondaryAddress: {
    id: "Center.CenterAddressForm.labelSecondaryAddress",
    defaultMessage: "Address line 2",
  },
  labelCity: {
    id: "Center.CenterAddressForm.labelCity",
    defaultMessage: "City",
  },
  labelPostalCode: {
    id: "Center.CenterAddressForm.labelPostalCode",
    defaultMessage: "ZIP",
  },
  labelState: {
    id: "Center.CenterAddressForm.labelState",
    defaultMessage: "State",
  },
  labelCountry: {
    id: "Center.CenterAddressForm.labelCountry",
    defaultMessage: "Country",
  },
});

export default messages;
