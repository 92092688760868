/* eslint-disable @typescript-eslint/no-explicit-any */
import examples from "libphonenumber-js/mobile/examples";
import {
  getExampleNumber,
  getCountries,
  isValidNumberForRegion as isValidNumberForRegionLib,
  isSupportedCountry,
  Metadata,
  parsePhoneNumber,
} from "libphonenumber-js";
import { MIN_PHONE_NUMBER_LENGTH } from "./constants";

const getPhoneNumberExample = (countryCode: string) => {
  if (!getCountries().includes(countryCode as any)) {
    throw new Error("Country code is not available");
  }

  const phoneNumber = getExampleNumber(countryCode as any, examples);

  return phoneNumber;
};

const isValidNumberForRegion = (phone: string, countryCode: string) => {
  if (!isSupportedCountry(countryCode)) {
    throw new Error("Country is not supported");
  }

  return isValidNumberForRegionLib(phone, countryCode as any);
};
const getCountryPossibleNationalLengthList = (countryCode: string) => {
  const metadata = new Metadata();
  if (!isSupportedCountry(countryCode)) {
    throw new Error("Country is not supported");
  }

  metadata.selectNumberingPlan(countryCode as any);

  return metadata.numberingPlan?.possibleLengths();
};

const formatNational = <T extends string | null | undefined>(params: {
  phoneNumber: T;
  countryCode?: string;
}) => {
  const { phoneNumber, countryCode } = params;

  if (!phoneNumber || !countryCode) return phoneNumber;

  try {
    const formatted = parsePhoneNumber(
      phoneNumber,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      countryCode as any,
    ).formatNational();

    return formatted;
  } catch {
    return phoneNumber;
  }
};

const formatInternational = <T extends string | null | undefined>(params: {
  phoneNumber: T;
}) => {
  const { phoneNumber } = params;

  if (!phoneNumber) return phoneNumber;

  const isPrefixIncluded = phoneNumber.startsWith("+");

  try {
    const parsedPhoneNumber = parsePhoneNumber(
      `${isPrefixIncluded ? "" : "+"}${phoneNumber}`,
    );
    const internationalPhoneNumber = parsedPhoneNumber.formatInternational();

    const [countryCode, carrierCode, ...number] =
      internationalPhoneNumber.split(" ");

    if (number.length > 0) {
      return `${countryCode} (${carrierCode}) ${number.join(" ")}`;
    }

    if (carrierCode) {
      return `${countryCode} ${carrierCode}`;
    }

    return phoneNumber;
  } catch {
    return phoneNumber;
  }
};

const getValidPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length < MIN_PHONE_NUMBER_LENGTH) return "";
  return `+${phoneNumber.replace(/\D/g, "")}`;
};

export {
  formatNational,
  getPhoneNumberExample,
  isValidNumberForRegion,
  getCountryPossibleNationalLengthList,
  formatInternational,
  getValidPhoneNumber,
};
