import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Button from "../../../Unknown/Button";
import { Appointment } from "../types";

type ColumnTranslations = {
  btnView: string;
};

const getColumnBtnView = (params: {
  translations: ColumnTranslations;
  onClick: (id: string) => void;
  headerClassName?: string;
}): GridColDef<Appointment> => {
  const { translations, headerClassName, onClick } = params;
  const { btnView } = translations;

  return {
    field: "btnView",
    headerName: "",
    disableColumnMenu: true,
    sortable: false,
    headerClassName,
    renderCell: (renderParams) => {
      return (
        <Button
          sx={{ fontSize: 13 }}
          onClick={() => onClick(renderParams.row.id)}
        >
          {btnView}
        </Button>
      );
    },
  };
};

export default getColumnBtnView;
