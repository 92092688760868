import React, { FC, ReactNode } from "react";
import Card from "../../Unknown/Card";
import CardHeader from "../../Unknown/CardHeader";
import CardContent from "../../Unknown/CardContent";
import CardActions from "../../Unknown/CardActions";
import Dialog, { DialogProps } from "../../Unknown/Dialog";
import DialogContent from "../../Unknown/DialogContent";
import Button, { ButtonProps } from "../../Unknown/Button";

type ActionButton = {
  text: string;
  props: ButtonProps;
};

export type CenterSettingsDialogProps = DialogProps & {
  children: ReactNode;
  title: string;
  actionButtons?: ActionButton[];
};

const CenterSettingsDialog: FC<CenterSettingsDialogProps> = ({
  title,
  children,
  actionButtons = [],
  fullWidth = true,
  maxWidth = "md",
  ...dialogProps
}) => {
  return (
    <Dialog maxWidth={maxWidth} fullWidth={fullWidth} {...dialogProps}>
      <DialogContent sx={{ p: 0 }}>
        <Card>
          <CardHeader
            title={title}
            titleTypographyProps={{ sx: { fontSize: 24 } }}
          />
          <CardContent>{children}</CardContent>
          {actionButtons ? (
            <CardActions sx={{ justifyContent: "flex-end" }}>
              {actionButtons.map(({ text, props }) => (
                <Button key={text} size="small" {...props}>
                  {text}
                </Button>
              ))}
            </CardActions>
          ) : null}
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default CenterSettingsDialog;
