import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      columnDateTime: formatMessage(messages.columnDateTime),
      columnCenter: formatMessage(messages.columnCenter),
      columnCenterServices: formatMessage(messages.columnCenterServices),
      btnView: formatMessage(messages.btnView),
    };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
