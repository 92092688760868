import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentReport } from "../types";

type ColumnTranslations = {
  headerStatus: string;
  statusSent: string;
  statusUnsent: string;
  statusError: string;
};

type ColumnParams = {
  translations: ColumnTranslations;
  headerClassName: string;
};

const getColumтStatus = ({
  translations,
  headerClassName,
}: ColumnParams): GridColDef<AppointmentReport, string> => {
  const { statusError, statusSent, statusUnsent, headerStatus } = translations;
  return {
    field: "status",
    disableColumnMenu: true,
    flex: 1,
    headerName: headerStatus,
    sortable: false,
    headerClassName,
    valueGetter: ({ row }) => {
      const { status } = row;

      const statusTranslations: Record<string, string> = {
        sent: statusSent,
        unsent: statusUnsent,
        error: statusError,
      };

      const statusText = statusTranslations[status] || "";

      return statusText;
    },
  };
};

export default getColumтStatus;
