import { defineMessages } from "react-intl";

const messages = defineMessages({
  sideLeft: {
    id: "Offering.AppointmentOfferingModifyForm.sideLeft",
    defaultMessage: "Left",
  },
  sideRight: {
    id: "Offering.AppointmentOfferingModifyForm.sideRight",
    defaultMessage: "Right",
  },
});

export default messages;
