import { Theme, lighten } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, shape }) => {
  return {
    toolbarRoot: {
      backgroundColor: lighten(palette.primary.main, 0.92),
    },
    datePickerTextFieldRoot: {
      maxWidth: 130,
    },
    datePickerTextFieldInput: {
      backgroundColor: palette.common.white,
      borderRadius: shape.borderRadius,
    },
    datePickerFieldSeparatorRoot: {
      visibility: "hidden",
      width: 0,
    },
  };
});

export default useStyles;
