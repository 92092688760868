import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import { CenterServicesAppointmentPreviewTranslations } from "../../../CenterService/CenterServicesAppointmentPreview/useTranslations";
import CenterServicesAppointmentPreview, {
  normalizeService,
  CenterService,
} from "../../../CenterService/CenterServicesAppointmentPreview";
import {
  AppointmentColumnItemOffering,
  AppointmentRowItem,
  AppointmentRowItemOffering,
  AppointmentRowItemPackage,
} from "../types";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnOfferingTranslations = {
  columnOffering: string;
  columnOfferingStudies: string;
};

const getCenterServices = ({
  offerings,
  packages,
}: {
  offerings: AppointmentRowItemOffering[];
  packages: AppointmentRowItemPackage[];
}): CenterService[] => {
  const offeringCenterServices = offerings.map((offering) => ({
    name: offering.name,
    side: offering.side,
  }));

  const packageCenterServices = packages.map((packageItem) => ({
    name: packageItem.name,
  }));

  return [...offeringCenterServices, ...packageCenterServices];
};

const getColumnOffering = (params: {
  columnItem: AppointmentColumnItemOffering;
  translations: ColumnOfferingTranslations;
  centerServiceTranslations: CenterServicesAppointmentPreviewTranslations;
}): GridColDef<AppointmentRowItem> => {
  const { columnItem, translations, centerServiceTranslations } = params;
  const { field, header } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName:
      header === "offerings"
        ? translations.columnOffering
        : translations.columnOfferingStudies,
    valueGetter: ({ row }) => {
      const { offerings, packages } = row;

      const services = getCenterServices({ offerings, packages });

      return services
        .map((centerService) =>
          normalizeService({
            centerService,
            translations: centerServiceTranslations,
          }),
        )
        .join(";");
    },
    renderCell: ({ row }) => {
      const { offerings, packages } = row;

      const services = getCenterServices({ offerings, packages });

      return <CenterServicesAppointmentPreview centerServices={services} />;
    },
  };
};

export default getColumnOffering;
