import React, { FC } from "react";
import { inputsSpacing } from "./constants";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import DragDropFileFieldBase, {
  DragDropFileFieldBaseProps,
} from "../../Unknown/DragDropFileFieldBase";
import useTranslations from "./useTranslations";
import useStyles from "./useStyles";

export type AppointmentFileAddFormProps = {
  dragDropFileFieldBaseProps: DragDropFileFieldBaseProps;
  files: File[];
};

const AppointmentFileAddForm: FC<AppointmentFileAddFormProps> = ({
  dragDropFileFieldBaseProps,
  files,
}) => {
  const { translations } = useTranslations();
  const { titleFiles } = translations;

  const { titleFilesRoot } = useStyles();

  return (
    <Grid container spacing={inputsSpacing} direction="column">
      <Grid item xs>
        <DragDropFileFieldBase {...dragDropFileFieldBaseProps} />
      </Grid>
      <Grid item xs>
        <Grid item xs>
          <Typography className={titleFilesRoot}>{titleFiles}</Typography>
        </Grid>
        {files.map((file) => (
          <Grid item key={`${file.name}-${file.lastModified}`}>
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {file.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default AppointmentFileAddForm;
