import React, { FC } from "react";
import { Link } from "react-router-dom";
import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import useStyles from "./useStyles";

type PatientPreviewEmailProps = {
  firstName: string;
  lastName: string;
  email: string;
  url: string;
  formattedMobilePhoneNumber: string;
};

const PatientPreviewEmail: FC<PatientPreviewEmailProps> = ({
  firstName,
  lastName,
  email,
  url,
  formattedMobilePhoneNumber,
}) => {
  const { nameRoot, detailsRoot } = useStyles();

  return (
    <Box>
      <Typography
        component={Link}
        to={url}
        variant="body2"
        className={nameRoot}
      >
        {lastName}, {firstName}
      </Typography>

      <Typography variant="body2" className={detailsRoot}>
        {email}
      </Typography>

      <Typography variant="body2" className={detailsRoot}>
        {formattedMobilePhoneNumber}
      </Typography>
    </Box>
  );
};

export default PatientPreviewEmail;
