import addYears from "date-fns/addYears";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";
import subYears from "date-fns/subYears";
import formatISO from "date-fns/formatISO";
import { SearchPatientRequestParams } from "./getSearchPatient";
import { EMAIL_REGEXP_STR } from "../../../common/constants";

const findRegexp = (value: string, regexp: string) => {
  const v = value.match(`(^|\\s+)(${regexp})($|\\s+)`);
  return v?.[0]?.trim();
};

const regexps = {
  email: EMAIL_REGEXP_STR,
  phone: "^\\+[0-9]+$",
  nameTwoWords: "[a-zA-Z]+\\s+[a-zA-Z]+",
  nameOneWords: "[a-zA-Z]+",
};

const formatParts: Record<string, string> = {
  day: "dd",
  month: "MM",
  year: "yy",
};

const getDateFormats = () => {
  const localeFormatParts = new Intl.DateTimeFormat().formatToParts();

  const shortFormat = localeFormatParts.reduce((acc, localeFormatPart) => {
    const partFormat = formatParts[localeFormatPart.type];
    return partFormat ? `${acc}${partFormat}` : acc;
  }, "");

  const longFormat = shortFormat.replace("yy", "yyyy");

  return { shortFormat, longFormat };
};

const getDateFormatByValue = (value: string, formats: string[]) => {
  const format = formats.find((current) => current.length === value.length);
  return format;
};

const normalizeBirthDate = (
  value: string,
  dateFormats: { shortFormat: string; longFormat: string },
) => {
  const { shortFormat, longFormat } = dateFormats;

  const valueFormat = getDateFormatByValue(value, [shortFormat, longFormat]);

  if (!valueFormat) return null;

  const normalizedDate = parse(value, valueFormat, new Date());

  if (!isValid(normalizedDate)) return null;

  const dates = [
    subYears(normalizedDate, 100),
    normalizedDate,
    addYears(normalizedDate, 100),
  ];

  return dates.map((date: Date) => {
    return formatISO(date, { representation: "date" });
  });
};

const normalizeNameTwoWords = (value: string) => {
  const [firstName, secondName] = value.split(" ");
  if (!firstName || !secondName) return null;
  return [firstName, secondName] as const;
};

const checkEmail = (value: string) => {
  return findRegexp(value, regexps.email);
};

const checkPhone = (value: string) => {
  return findRegexp(value, regexps.phone);
};

const checkDate = (value: string) => {
  const dateFormats = getDateFormats();
  const birthDateValue = findRegexp(
    value,
    `\\d{${dateFormats.longFormat.length}}|\\d{${dateFormats.shortFormat.length}}`,
  );

  if (!birthDateValue) return undefined;

  const normalizedBirthDateValue = normalizeBirthDate(
    birthDateValue,
    dateFormats,
  );

  if (!normalizedBirthDateValue) return undefined;

  return normalizedBirthDateValue;
};

const checkName = (value: string) => {
  const nameTwoWordsValue = findRegexp(value, regexps.nameTwoWords);

  if (nameTwoWordsValue) {
    const normalizedNameTwoWordsValue =
      normalizeNameTwoWords(nameTwoWordsValue);
    if (normalizedNameTwoWordsValue) {
      return {
        type: "two_words" as const,
        value: normalizedNameTwoWordsValue,
      };
    }
  }

  const nameOneWordsValue = findRegexp(value, regexps.nameOneWords);

  if (!nameOneWordsValue) return undefined;

  return {
    type: "one_word" as const,
    value: nameOneWordsValue,
  };
};

const convertSearchValueToRequestParams = (
  value: string,
): SearchPatientRequestParams => {
  const requestParams: SearchPatientRequestParams = {};

  const email = checkEmail(value);
  if (email) requestParams.email = email;

  const phone = checkPhone(value);
  if (phone) requestParams.phone = phone;

  const birthDate = checkDate(value);
  if (birthDate) requestParams.birthDate = birthDate;

  const name = checkName(value);
  if (name) requestParams.name = name;

  return requestParams;
};

export default convertSearchValueToRequestParams;
