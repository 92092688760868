import { defineMessages } from "react-intl";

export default defineMessages({
  minutes: {
    id: "Report.ReportDeliveryDeadlineCountdown.minutes",
    defaultMessage:
      "{value, plural, =0 {# minutes} one {# minute} other {# minutes}} to delivery",
  },
  hours: {
    id: "Report.ReportDeliveryDeadlineCountdown.hours",
    defaultMessage:
      "{value, plural, =0 {# hours} one {# hour} other {# hours}} to delivery",
  },
});
