import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentFile } from "../types";
import SimpleOptionsMenu from "../../../Unknown/SimpleOptionsMenu";
import Box from "../../../Unknown/Box";

type ColumnValue = {
  downloadUrl: string;
  filename: string;
};

type ColumnTranslations = {
  btnDownload: string;
};

type ColumnsParams = {
  translations: ColumnTranslations;
  headerClassName: string;
};

const downloadFile = async (url: string, filename: string) => {
  const response = await fetch(url);
  const data = await response.blob();

  const tempLink = document.createElement("a");
  const dataUrl = window.URL.createObjectURL(data);
  tempLink.href = dataUrl;
  tempLink.setAttribute("download", filename);
  tempLink.click();
};

const createOptions = (params: {
  filename: string;
  downloadUrl: string;
  btnDownload: string;
}) => {
  const { filename, downloadUrl, btnDownload } = params;

  const options = [
    {
      id: "download",
      text: btnDownload,
      onClick: async () => downloadFile(downloadUrl, filename),
    },
  ];
  return options;
};

const getColumnAppointments = ({
  translations,
  headerClassName,
}: ColumnsParams): GridColDef<AppointmentFile, ColumnValue> => {
  const { btnDownload } = translations;

  return {
    field: "options",
    disableColumnMenu: true,
    headerName: "",
    sortable: false,
    headerClassName,
    maxWidth: 80,
    valueGetter: (getterParams) => {
      return {
        filename: getterParams.row.name,
        downloadUrl: getterParams.row.downloadUrl,
      };
    },
    renderCell: (renderParams) => {
      const { value } = renderParams;
      if (!value) return null;

      const { filename, downloadUrl } = value;

      const options = createOptions({ btnDownload, filename, downloadUrl });

      return (
        <Box display="flex" justifyContent="center" width="100%">
          <SimpleOptionsMenu options={options} />
        </Box>
      );
    },
  };
};

export default getColumnAppointments;
