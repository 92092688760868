import React, { FC, useMemo } from "react";
import { List, ListItem } from "@mui/material";
import StatusDialog, { StatusDialogProps } from "../../Unknown/StatusDialog";
import Typography from "../../Unknown/Typography";
import useTranslations from "./useTranslations";
import useStyles from "./useStyles";

export type AppointmentCheckInDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void | Promise<void>;
  isLoading?: boolean;
  errorMessage?: string | null;
};

const AppointmentCheckInDialog: FC<AppointmentCheckInDialogProps> = ({
  isOpen,
  handleClose,
  handleSubmit,
  isLoading,
  errorMessage,
}) => {
  const { translations } = useTranslations();
  const { list, listItem } = useStyles();

  const actionButtons = useMemo<StatusDialogProps["actionButtons"]>(() => {
    return [
      {
        text: translations.btnCancel,
        key: "cancel",
        onClick: handleClose,
        disabled: isLoading,
      },
      {
        text: translations.btnSubmit,
        key: "submit",
        onClick: handleSubmit,
        disabled: isLoading || !!errorMessage,
      },
    ];
  }, [
    errorMessage,
    handleClose,
    handleSubmit,
    isLoading,
    translations.btnCancel,
    translations.btnSubmit,
  ]);

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      isLoading={isLoading}
      errorMessage={errorMessage}
      actionButtons={actionButtons}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          maxWidth: 500,
        },
      }}
    >
      <Typography lineHeight={1.75}>{translations.description}</Typography>
      <List sx={list}>
        <ListItem sx={listItem}>
          <Typography lineHeight={1.75}>
            {translations.firstCheckText}
          </Typography>
        </ListItem>
        <ListItem sx={listItem}>
          <Typography lineHeight={1.75}>
            {translations.secondCheckText}
          </Typography>
        </ListItem>
      </List>
    </StatusDialog>
  );
};

export default AppointmentCheckInDialog;
