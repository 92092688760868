import { defineMessages } from "react-intl";

const messages = defineMessages({
  headerName: {
    id: "CenterService.CenterServiceAppointmentCardGrid.headerName",
    defaultMessage: "Offering",
  },
  headerStudies: {
    id: "CenterService.CenterServiceAppointmentCardGrid.headerStudies",
    defaultMessage: "Study/ACC",
  },
  sideLeft: {
    id: "CenterService.CenterServiceAppointmentCardGrid.sideLeft",
    defaultMessage: "Left",
  },
  sideRight: {
    id: "CenterService.CenterServiceAppointmentCardGrid.sideRight",
    defaultMessage: "Right",
  },
  btnAdditionalViewsRequired: {
    id: "CenterService.CenterServiceAppointmentCardGrid.btnAdditionalViewsRequired",
    defaultMessage: "Additional views required",
  },
  btnEdit: {
    id: "CenterService.CenterServiceAppointmentCardGrid.btnEdit",
    defaultMessage: "Edit",
  },
  btnRead: {
    id: "CenterService.CenterServiceAppointmentCardGrid.btnRead",
    defaultMessage: "Read",
  },
  btnContinueReading: {
    id: "CenterService.CenterServiceAppointmentCardGrid.btnContinueReading",
    defaultMessage: "Continue Reading",
  },
});

export default messages;
