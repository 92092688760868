import React, { FC } from "react";
import { useField } from "formik";
import DesktopTimePicker from "../DesktopTimePicker";
import TextField from "../TextField";

export type FormikDesktopTimePickerProps = {
  name: string;
  label?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
};

const FormikDesktopTimePicker: FC<FormikDesktopTimePickerProps> = ({
  name,
  label,
  isDisabled,
  isRequired,
}) => {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;
  const { setValue, setTouched } = helpers;

  return (
    <DesktopTimePicker
      label={label}
      value={field.value}
      onChange={(value) => {
        setTouched(true);
        setValue(value, true);
      }}
      disabled={isDisabled}
      renderInput={(params) => (
        <TextField
          {...params}
          required={isRequired}
          name={field.name}
          error={touched && Boolean(error)}
          helperText={touched && error}
        />
      )}
    />
  );
};

export default FormikDesktopTimePicker;
