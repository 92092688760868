import React from "react";
import { GridFilterInputMultipleSingleSelectProps } from "../../Unknown/DataGrid";
import TextField from "../../Unknown/TextField";
import MenuItem from "../../Unknown/MenuItem";
import FormControl from "../../Unknown/FormControl";
import useTranslations from "./useTranslations";

export enum AppointmentFlag {
  isStat = "is_stat",
  requiresParentGuardian = "requires_parent_guardian",
  isAdditionalViews = "is_additional_views",
  hasPriority = "has_priority",
  hasUnexpectedFindings = "has_unexpected_findings",
  isReceiptConfirmed = "is_receipt_confirmed",
}

const AppointmentTableFilterFlags: React.FC<
  GridFilterInputMultipleSingleSelectProps
> = ({ item, applyValue, focusElementRef }) => {
  const translations = useTranslations();

  const filterOptions = [
    {
      value: AppointmentFlag.isStat,
      label: translations.isStat,
    },
    {
      value: AppointmentFlag.requiresParentGuardian,
      label: translations.requiresParentGuardian,
    },
    {
      value: AppointmentFlag.isAdditionalViews,
      label: translations.additionalViews,
    },
    {
      value: AppointmentFlag.hasPriority,
      label: translations.hasPriority,
    },
    {
      value: AppointmentFlag.hasUnexpectedFindings,
      label: translations.hasUnexpectedFindings,
    },
    {
      value: AppointmentFlag.isReceiptConfirmed,
      label: translations.receiptConfirmed,
    },
  ];

  return (
    <FormControl fullWidth>
      <TextField
        id={`contains-input-${item.id}`}
        value={item.value || []}
        onChange={({ target }) => {
          applyValue({ ...item, value: target.value });
        }}
        type="text"
        variant="standard"
        select
        label={translations.value}
        sx={{ pt: 0.5 }}
        InputLabelProps={{
          shrink: true,
          size: "small",
        }}
        inputRef={focusElementRef}
        size="small"
        SelectProps={{
          multiple: true,
        }}
      >
        {filterOptions.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default AppointmentTableFilterFlags;
