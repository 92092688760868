import { useMemo } from "react";
import { useIntl } from "react-intl";
import { getLocaleCurrencySymbol } from "../../../common/currencyHelpers";
import messages from "./messages";
import { Translations } from "./types";

const useTranslations = (params: { currencyCode: string }) => {
  const { currencyCode } = params;
  const { formatMessage, locale } = useIntl();

  const currencySymbol = getLocaleCurrencySymbol(currencyCode, locale);

  const translations: Translations = useMemo(() => {
    return {
      scanSideSingle: formatMessage(messages.scanSideSingle),
      scanSideDual: formatMessage(messages.scanSideDual),
      visibilityPrivate: formatMessage(messages.visibilityPrivate),
      visibilityPublic: formatMessage(messages.visibilityPublic),
      visibilityOnlyPackage: formatMessage(messages.visibilityOnlyPackage),
      durationMinutesUnit: formatMessage(messages.durationMinutesUnit),
      currencySymbol,
    };
  }, [currencySymbol, formatMessage]);
  return translations;
};
export default useTranslations;
