import { useCallback, useState } from "react";
import { AppointmentScreeningFormContainerDialogProps } from "../../AppointmentForm/AppointmentScreeningFormDialog";
import { Appointment } from "./types";

const useMarkAppointmentPriorityDialog = ({
  appointment,
  isLoading,
  onSuccess,
}: {
  appointment: Appointment | null;
  isLoading: boolean;
  onSuccess: () => Promise<void>;
}): {
  handleOpen: () => void;
  props: AppointmentScreeningFormContainerDialogProps;
} => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    handleOpen,
    props: {
      isOpen,
      handleClose,
      onSuccess,
      appointment,
      isLoading,
    },
  };
};

export default useMarkAppointmentPriorityDialog;
