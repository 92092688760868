import Big from "big.js";
import { Appointment } from "../AppointmentCardsContainer/types";

const calcAdditionalViewsDurationMinutes = (
  offerings: Appointment["appointment_offerings"],
): number => {
  const offeringsDurationBig = offerings.reduce((sum, offering) => {
    return sum.plus(offering.additional_view_duration_minutes || 0);
  }, Big(0));

  return offeringsDurationBig.toNumber();
};

export default calcAdditionalViewsDurationMinutes;
