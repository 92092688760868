import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { isBefore } from "date-fns";
import { FormsStatus } from "../Patient/PatientCardContainer/types";

type PatientForm = Pick<DatabaseRow<"patient_forms">, "type" | "expiration_at">;
export type PatientFormType = "privacy" | "consent";

interface CheckIsPatientFormsValid {
  patientForms: PatientForm[];
  type: PatientFormType;
}

const checkPatientFormStatus = ({
  patientForms,
  type,
}: CheckIsPatientFormsValid): FormsStatus => {
  const existedForm = patientForms?.find(
    (patientForm) => patientForm.type === type,
  );
  if (existedForm) {
    return isBefore(new Date(), new Date(existedForm.expiration_at))
      ? "valid"
      : "expired";
  }

  return "not_populated";
};

export default checkPatientFormStatus;
