import Big from "big.js";
import { minutesInHour } from "date-fns";

const calcHoursLeft = (minutesLeft: number): number => {
  return new Big(minutesLeft)
    .div(minutesInHour)
    .times(2)
    .round()
    .div(2)
    .toNumber();
};

export default calcHoursLeft;
