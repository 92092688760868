import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

const usePatientInformationDrawerContainer = () => {
  const { role, appointmentId, patientMrn } = useParams();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(`/${role}/appointment/${appointmentId}`);
  }, [appointmentId, navigate, role]);

  const handleCustomerClick = useCallback(
    (customerId: string) => {
      navigate(`/${role}/appointment/${appointmentId}/customer/${customerId}`);
    },
    [appointmentId, navigate, role],
  );

  const handleAppointmentClick = useCallback(
    (id: string) => {
      navigate(`/${role}/appointment/${id}`);
    },
    [navigate, role],
  );

  return {
    isOpen: !!patientMrn,
    props: {
      patientMrn,
      isOpen: !!patientMrn,
      handleClose,
      handleCustomerClick,
      handleAppointmentClick,
    },
  };
};

export default usePatientInformationDrawerContainer;
