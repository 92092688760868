import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    submit: formatMessage(messages.submit),
  };
};

export default useTranslations;
