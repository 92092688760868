import { useState } from "react";
import { AppointmentTechnologistNoteEditDialogContainerProps } from "../AppointmentTechnologistNoteEditDialogContainer";

const useAppointmentTechnologistNoteEditDialogContainer = (params: {
  handleEditNote: (params: { text: string }) => void | Promise<void>;
  appointmentId?: string;
  text: string;
}): {
  handleOpen: () => void;
  props: AppointmentTechnologistNoteEditDialogContainerProps;
} => {
  const { appointmentId, text, handleEditNote } = params;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setErrorMessage(null);
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  return {
    handleOpen,
    props: {
      text,
      isOpen,
      appointmentId,
      errorMessage,
      setErrorMessage,
      handleClose,
      handleEditNote,
    },
  };
};

export default useAppointmentTechnologistNoteEditDialogContainer;
