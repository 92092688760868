import React, { FC, useCallback } from "react";
import CenterInformationSettings from "../CenterInformationSettings";
import useInfoSettingsOpenModals from "./useInfoSettingsOpenModals";
import CenterAddressFormik from "../CenterAddressFormik";
import {
  Address,
  ContactInformation,
  Discount,
  OperatingHours,
} from "../CenterSettingsContainer/types";
import CenterContactInformationFormik from "../CenterContactInformationFormik";
import CenterOperatingHoursFormik from "../CenterOperatingHoursFormik";
import CenterDiscountFormik from "../CenterDiscountFormik";

export type CenterInformationSettingsTabProps = {
  centerId: string;
  address: Address;
  contactInformation: ContactInformation;
  operatingHours: OperatingHours;
  discount: Discount;
  refetchCenterData?: () => void | Promise<void>;
};

const CenterInformationSettingsTab: FC<CenterInformationSettingsTabProps> = ({
  centerId,
  address,
  contactInformation,
  operatingHours,
  discount,
  refetchCenterData,
}) => {
  const { openedModal, onModalChange } = useInfoSettingsOpenModals();

  const onModalClose = useCallback(() => {
    onModalChange(null);
  }, [onModalChange]);

  const handlePostSubmit = useCallback(async () => {
    onModalClose();
    if (refetchCenterData) {
      await refetchCenterData();
    }
  }, [onModalClose, refetchCenterData]);

  return (
    <>
      <CenterInformationSettings
        address={address}
        contactInformation={contactInformation}
        operatingHours={operatingHours}
        discount={discount}
        onAddressClick={() => onModalChange("address")}
        onContactInformationClick={() => onModalChange("contactInformation")}
        onOperatingHoursClick={() => onModalChange("operatingHours")}
        onDiscountClick={() => onModalChange("discount")}
      />
      <CenterAddressFormik
        isOpened={openedModal === "address"}
        onModalClose={onModalClose}
        centerId={centerId}
        centerAddressData={address}
        handlePostSubmit={handlePostSubmit}
      />
      <CenterContactInformationFormik
        isOpened={openedModal === "contactInformation"}
        onModalClose={onModalClose}
        centerId={centerId}
        centerContactInformationData={contactInformation}
        handlePostSubmit={handlePostSubmit}
      />
      <CenterOperatingHoursFormik
        isOpened={openedModal === "operatingHours"}
        onModalClose={onModalClose}
        centerId={centerId}
        centerOperatingHoursData={operatingHours}
        handlePostSubmit={handlePostSubmit}
      />
      <CenterDiscountFormik
        isOpened={openedModal === "discount"}
        onModalClose={onModalClose}
        centerId={centerId}
        centerDiscountData={discount}
        handlePostSubmit={handlePostSubmit}
      />
    </>
  );
};

export default CenterInformationSettingsTab;
