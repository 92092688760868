import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import Typography from "../../Unknown/Typography";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      title: formatMessage(messages.title),
      description: formatMessage(messages.description),
      btnCancel: formatMessage(messages.btnCancel),
      btnSubmit: formatMessage(messages.btnSubmit),
      refundWarning: formatMessage(messages.refundWarning, {
        highlightedText: (
          <Typography display="inline" variant="body2" fontWeight={700}>
            {formatMessage(messages.not)}
          </Typography>
        ),
      }),
      refundInfoMessage: formatMessage(messages.refundInfoMessage),
    };

    return { translations };
  }, [formatMessage]);
  return result;
};
export default useTranslations;
