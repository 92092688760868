import React, { FC } from "react";
import Box from "../Box";
import CircularProgress from "../CircularProgress";

const LoadingScreen: FC = () => {
  return (
    <Box
      width={1}
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingScreen;
