import { defineMessages } from "react-intl";

export default defineMessages({
  labelAdded: {
    id: "AppointmentNote.AppointmentNoteListCardItem.labelAdded",
    defaultMessage: "Added by",
  },
  labelOffering: {
    id: "AppointmentNote.AppointmentNoteListCardItem.labelOffering",
    defaultMessage: "Applies to",
  },
});
