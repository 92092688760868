import { defineMessages } from "react-intl";

const messages = defineMessages({
  eventName: {
    id: "TimeSlot.TimeSlotAddDialogFormik.eventName",
    defaultMessage: "Event name",
  },
  startDate: {
    id: "TimeSlot.TimeSlotAddDialogFormik.startDate",
    defaultMessage: "Start date",
  },
  endDate: {
    id: "TimeSlot.TimeSlotAddDialogFormik.endDate",
    defaultMessage: "End date",
  },
  startTime: {
    id: "TimeSlot.TimeSlotAddDialogFormik.startTime",
    defaultMessage: "Start time",
  },
  endTime: {
    id: "TimeSlot.TimeSlotAddDialogFormik.endTime",
    defaultMessage: "End time",
  },
  btnDelete: {
    id: "TimeSlot.TimeSlotAddDialogFormik.btnDelete",
    defaultMessage: "Delete event",
  },
  btnSave: {
    id: "TimeSlot.TimeSlotAddDialogFormik.btnSave",
    defaultMessage: "Save",
  },
  btnCancel: {
    id: "TimeSlot.TimeSlotAddDialogFormik.btnCancel",
    defaultMessage: "Cancel",
  },
});

export default messages;
