import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelOpen: {
    id: "Center.CenterOperatingHoursForm.labelOpen",
    defaultMessage: "Open",
  },
  toText: {
    id: "Center.CenterOperatingHoursForm.toText",
    defaultMessage: "to",
  },
  labelTime: {
    id: "Center.CenterOperatingHoursForm.labelTime",
    defaultMessage: "Time",
  },
});

export default messages;
