import React from "react";
import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import {
  AddCircleOutlineIcon,
  BusinessCenterOutlinedIcon,
  CalendarTodayOutlinedIcon,
  DashboardOutlinedIcon,
  SearchOutlinedIcon,
} from "../Icons";
import { NavbarActionItem } from "../NavbarActions";

type Action = "dashboard" | "calendar" | "search" | "add_appointment" | "admin";

interface NavbarActionsTranslations {
  searchLabel: string;
  dashboardLabel: string;
  adminLabel: string;
  addAppointmentLabel: string;
  calendarLabel: string;
}

const getNavbarAction = ({
  action,
  role,
  isAddAppointmentLoading,
  translations,
  onAddAppointmentClick,
}: {
  action: Action;
  role: string;
  isAddAppointmentLoading: boolean;
  translations: NavbarActionsTranslations;
  onAddAppointmentClick: () => Promise<void>;
}): NavbarActionItem => {
  switch (action) {
    case "dashboard": {
      return {
        id: "dashboard",
        IconComponent: <DashboardOutlinedIcon />,
        title: translations.dashboardLabel,
        meta: { itemType: "link", to: `/${role}` },
      };
    }
    case "calendar": {
      return {
        id: "calendar",
        IconComponent: <CalendarTodayOutlinedIcon />,
        title: translations.calendarLabel,
        meta: { itemType: "link", to: `/${role}/calendar` },
      };
    }
    case "search": {
      return {
        id: "search",
        IconComponent: <SearchOutlinedIcon />,
        title: translations.searchLabel,
        meta: { itemType: "search" },
      };
    }
    case "add_appointment": {
      return {
        id: "add_appointment",
        IconComponent: <AddCircleOutlineIcon />,
        title: translations.addAppointmentLabel,
        meta: {
          itemType: "button",
          isLoading: isAddAppointmentLoading,
          onClick: onAddAppointmentClick,
        },
      };
    }
    case "admin": {
      return {
        id: "admin",
        IconComponent: <BusinessCenterOutlinedIcon />,
        title: translations.adminLabel,
        meta: { itemType: "link", to: `/${role}/admin` },
      };
    }
    default: {
      throw new Error("Action is not defined");
    }
  }
};

const getActionsByRole = (role: DatabaseEnum["center_user_role"]): Action[] => {
  switch (role) {
    case "radiologist":
      return ["dashboard", "calendar", "search"];
    case "receptionist":
    case "technologist":
      return ["dashboard", "calendar", "search", "add_appointment"];
    case "regional_admin":
    case "super_admin":
      return ["dashboard", "calendar", "search", "add_appointment", "admin"];
    default:
      return [];
  }
};

const getRoleActions = ({
  role,
  isAddAppointmentLoading,
  translations,
  onAddAppointmentClick,
}: {
  role: DatabaseEnum["center_user_role"];
  isAddAppointmentLoading: boolean;
  translations: NavbarActionsTranslations;
  onAddAppointmentClick: () => Promise<void>;
}): NavbarActionItem[] => {
  const actionsByRole = getActionsByRole(role);
  return actionsByRole.map((action) =>
    getNavbarAction({
      action,
      role,
      isAddAppointmentLoading,
      translations,
      onAddAppointmentClick,
    }),
  );
};

export default getRoleActions;
