import { defineMessages } from "react-intl";

const messages = defineMessages({
  btnAddOffering: {
    id: "Offering.CenterOfferingsView.btnAddOffering",
    defaultMessage: "Add offering",
  },
  editModalTitle: {
    id: "Offering.CenterOfferingsView.editModalTitle",
    defaultMessage: "Edit offering",
  },
  addModalTitle: {
    id: "Offering.CenterOfferingsView.addModalTitle",
    defaultMessage: "Add offering",
  },
});

export default messages;
