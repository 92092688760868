/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import Box from "../../Unknown/Box";

const SignInScreen: FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      flexDirection="column"
      minHeight="100vh"
    >
      <Authenticator hideSignUp />
    </Box>
  );
};

export default SignInScreen;
