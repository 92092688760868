import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemPatientRace, AppointmentRowItem } from "../types";

export type ColumnPatientRaceTranslations = {
  columnPatientRace: string;
  raceOptions: Record<string, string>;
};

const getColumnPatientRace = (params: {
  columnItem: AppointmentColumnItemPatientRace;
  translations: ColumnPatientRaceTranslations;
}): GridColDef<AppointmentRowItem, string | null> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    headerName: translations.columnPatientRace,
    valueGetter: ({ row }) => {
      if (!row.patient?.race) return null;

      const formattedValue = translations.raceOptions[row.patient.race];

      return formattedValue;
    },
  };
};

export default getColumnPatientRace;
