import { Formik } from "formik";
import React, { FC, useState } from "react";
import useForm from "./useForm";
import useLabelTranslations from "./useLabelTranslations";
import { AppointmentOfferingAdditionalViewFormValues } from "../AppointmentOfferingAdditionalViewForm/types";
import AppointmentOfferingAdditionalViewDialog from "../AppointmentOfferingAdditionalViewDialog";

export type AppointmentOfferingAdditionalViewContainerProps = {
  offeringId: string;
  appointmentId: string;
  isOpened: boolean;
  onModalClose: () => void;
  handleSubmit?: (
    values: AppointmentOfferingAdditionalViewFormValues,
  ) => void | Promise<void>;
};

const AppointmentOfferingAdditionalViewContainer: FC<
  AppointmentOfferingAdditionalViewContainerProps
> = ({ offeringId, appointmentId, isOpened, onModalClose, handleSubmit }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const formikConfig = useForm({
    appointmentId,
    offeringId,
    handleSubmit,
    handleError: setErrorMessage,
  });

  const labelTranslations = useLabelTranslations();

  return (
    <Formik {...formikConfig}>
      <AppointmentOfferingAdditionalViewDialog
        isOpened={isOpened}
        labelTranslations={labelTranslations}
        handleClose={onModalClose}
        errorMessage={errorMessage}
      />
    </Formik>
  );
};

export default AppointmentOfferingAdditionalViewContainer;
