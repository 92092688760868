import { FormikConfig } from "formik";
import { useCallback, useMemo } from "react";
import { MAX_STRING_LEN } from "../../../common/constants";
import {
  getCountryByCode,
  getStateByCodeAndCountry,
} from "../../../common/regionHelpers";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterAddressFormValues } from "../CenterAddressForm";
import { Address } from "../CenterSettingsContainer/types";
import getCenterAddressFormSchema from "./getCenterAddressFormSchema";
import useFormTranslations from "./useFormTranslations";

export type CenterAddressData = {
  primaryAddressLine: string;
  secondaryAddressLine?: string;
  city: string;
  postalCode: string;
  stateIsoCode: string;
  stateName: string;
  countryIsoCode: string;
  countryName: string;
};

export type CenterAddressFormHookParams = {
  centerId: string;
  centerAddressData: Address;
  handlePostSubmit?: (values: CenterAddressFormValues) => Promise<void> | void;
};

type CenterAddressFormikConfig = FormikConfig<CenterAddressFormValues>;

type CenterAddressFormHookResult = {
  formikConfig: CenterAddressFormikConfig;
};

const DEFAULT_COUNTRY_ISO_CODE = "US";

const useCenterAddressForm = (
  params: CenterAddressFormHookParams,
): CenterAddressFormHookResult => {
  const { centerId, centerAddressData, handlePostSubmit } = params;

  const { setAlert } = useUIContext();

  const updateCenterAddress = useFirebaseAppFunction("updateCenterAddress");

  const { translations, validationTranslations } = useFormTranslations({
    maxStringLength: MAX_STRING_LEN,
  });

  const {
    city,
    countryIsoCode,
    postalCode,
    primaryAddressLine,
    secondaryAddressLine,
    stateIsoCode,
  } = centerAddressData;

  const initialValues = useMemo<
    CenterAddressFormikConfig["initialValues"]
  >(() => {
    const countryOption = getCountryByCode(countryIsoCode);

    const stateOption = getStateByCodeAndCountry({
      countryCode: countryIsoCode,
      stateCode: stateIsoCode,
    });

    return {
      primaryAddress: primaryAddressLine,
      secondaryAddress: secondaryAddressLine || "",
      city,
      postalCode,
      countryIsoCode: countryOption ? countryIsoCode : DEFAULT_COUNTRY_ISO_CODE,
      stateIsoCode: stateOption ? stateIsoCode : "",
    };
  }, [
    city,
    countryIsoCode,
    postalCode,
    primaryAddressLine,
    secondaryAddressLine,
    stateIsoCode,
  ]);

  const validationSchema = useMemo(() => {
    return getCenterAddressFormSchema({
      translations: validationTranslations,
      maxStringLength: MAX_STRING_LEN,
    });
  }, [validationTranslations]);

  const onSubmit = useCallback<CenterAddressFormikConfig["onSubmit"]>(
    async (values) => {
      try {
        await updateCenterAddress({ centerId, ...values });
        if (handlePostSubmit) {
          await handlePostSubmit(values);
        }
      } catch (e) {
        setAlert({
          message: translations.defaultError,
          isShown: true,
          severity: "error",
        });
      }
    },
    [
      centerId,
      handlePostSubmit,
      setAlert,
      translations.defaultError,
      updateCenterAddress,
    ],
  );

  return {
    formikConfig: {
      initialValues,
      validationSchema,
      onSubmit,
    },
  };
};

export default useCenterAddressForm;
