import { lighten, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  const colors = {
    general: {
      backgroundColor: palette.primary.main,
      borderColor: palette.primary.main,
      color: palette.common.white,
    },
    closure: {
      backgroundColor: lighten(palette.primary.main, 0.6),
      borderColor: lighten(palette.primary.main, 0.6),
      color: palette.common.black,
    },
    incomplete: {
      backgroundColor: palette.grey[400],
      borderColor: palette.grey[400],
      color: palette.common.white,
    },
    reportsSent: {
      backgroundColor: palette.success.main,
      borderColor: palette.success.main,
      color: palette.common.white,
    },
  };

  return {
    dayCellRoot: {
      height: 100,
    },
    moreLinkRoot: {
      color: palette.primary.main,
    },
    commonEventRoot: {
      paddingLeft: 5,
      paddingRight: 5,
      marginBottom: 4,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    generalEventRoot: {
      ...colors.general,
      "&:hover": { ...colors.general },
    },
    closureEventRoot: {
      ...colors.closure,
      "&:hover": { ...colors.closure },
    },
    incompleteEventRoot: {
      ...colors.incomplete,
      "&:hover": { ...colors.incomplete },
    },
    reportsSentEventRoot: {
      ...colors.reportsSent,
      "&:hover": { ...colors.reportsSent },
    },
  };
});

export default useStyles;
