import React, { FC } from "react";
import Grid from "../../Unknown/Grid";
import CenterInformationSettingsBlock from "../CenterInformationSettingsBlock";
import {
  Address,
  ContactInformation,
  Discount,
  OperatingHours,
} from "../CenterSettingsContainer/types";
import useAddressBlockProps from "./useAddressBlockProps";
import useContactInformationBlockProps from "./useContactInformationBlockProps";
import useDiscountBlockProps from "./useDiscountBlockProps";
import useOperatingHoursBlockProps from "./useOperatingHoursBlockProps";

export type CenterInformationSettingsProps = {
  address: Address;
  contactInformation: ContactInformation;
  operatingHours: OperatingHours;
  discount: Discount;
  onAddressClick?: () => void;
  onContactInformationClick?: () => void;
  onOperatingHoursClick?: () => void;
  onDiscountClick?: () => void;
};

const CenterInformationSettings: FC<CenterInformationSettingsProps> = ({
  address,
  contactInformation,
  operatingHours,
  discount,
  onAddressClick,
  onContactInformationClick,
  onOperatingHoursClick,
  onDiscountClick,
}) => {
  const addressBlockProps = useAddressBlockProps({
    address,
    onClick: onAddressClick,
  });

  const contactInformationBlockProps = useContactInformationBlockProps({
    contactInformation,
    onClick: onContactInformationClick,
  });

  const { weekOperatingHours } = operatingHours;

  const operatingHoursBlockProps = useOperatingHoursBlockProps({
    weekOperatingHours,
    onClick: onOperatingHoursClick,
  });

  const discountBlockProps = useDiscountBlockProps({
    discount,
    onClick: onDiscountClick,
  });

  const blockPropsList = [
    addressBlockProps,
    contactInformationBlockProps,
    operatingHoursBlockProps,
    discountBlockProps,
  ];

  return (
    <Grid container direction="column" spacing={6} py={5}>
      {blockPropsList.map((props) => (
        <Grid item key={props.title}>
          <CenterInformationSettingsBlock {...props} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CenterInformationSettings;
