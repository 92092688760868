import { alpha, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, spacing }) => {
  return {
    noteListRoot: {
      "& > :not(:last-child) > *": {
        paddingBottom: spacing(2),
        borderBottomColor: alpha(palette.common.black, 0.25),
        borderBottomWidth: 1,
        borderBottomStyle: "dashed",
      },
    },
  };
});

export default useStyles;
