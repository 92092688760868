import React, { FC } from "react";
import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";

export type AppointmentNoteListCardItemProps = {
  createdBy: string;
  createdDate: string;
  description: string;
  modalityName?: string;
};

const AppointmentNoteListCardItem: FC<AppointmentNoteListCardItemProps> = ({
  createdBy,
  createdDate,
  description,
  modalityName,
}) => {
  const { headerRoot } = useStyles();

  const { translations } = useTranslations();
  const { labelAdded, labelOffering } = translations;

  const createdByText = `${labelAdded} ${createdBy} — ${createdDate}`;
  const modalityText = modalityName ? `${labelOffering} ${modalityName}` : "";

  return (
    <Box width="100%">
      <Typography className={headerRoot}>{createdByText}</Typography>
      {modalityText && (
        <Typography className={headerRoot}>{modalityText}</Typography>
      )}
      <Typography variant="body2">{description}</Typography>
    </Box>
  );
};

export default AppointmentNoteListCardItem;
