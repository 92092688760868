import { useCallback, useMemo } from "react";
import {
  FormConfig,
  InitialOffering,
  InitialValues,
  SubmitHandler,
} from "./types";
import useValidationSchema from "./useValidationSchema";
import { AppointmentOfferingModifyFormValues } from "../AppointmentOfferingModifyForm/types";
import useTranslations from "./useTranslations";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";

const useAppointmentOfferingModifyContainer = (params: {
  offeringId: string;
  appointmentId: string;
  initialOffering: InitialOffering;
  handleSubmit: (
    values: AppointmentOfferingModifyFormValues,
  ) => void | Promise<void>;
  handleError: (msg: string | null) => void;
}): FormConfig => {
  const {
    offeringId,
    appointmentId,
    initialOffering,
    handleSubmit,
    handleError,
  } = params;

  const updateAppointmentOffering = useFirebaseAppFunction(
    "updateAppointmentOffering",
  );

  const { defaultError } = useTranslations();

  const initialValues = useMemo<InitialValues>(() => {
    return {
      offering: initialOffering.id,
      modalityName: initialOffering.modalityName,
      side: initialOffering.side || "",
      isDualSide: false,
      reason: "",
    };
  }, [initialOffering.id, initialOffering.modalityName, initialOffering.side]);

  const validationSchema = useValidationSchema();

  const onSubmit = useCallback<SubmitHandler>(
    async (values) => {
      try {
        await updateAppointmentOffering({
          appointmentId,
          offeringId,
          newCenterOfferingId: values.offering,
          modalityName: values.modalityName,
          side: values.side,
          reason: values.reason,
        });

        await handleSubmit(values);
      } catch (error) {
        handleError(defaultError);
      }
    },
    [
      offeringId,
      appointmentId,
      defaultError,
      handleError,
      handleSubmit,
      updateAppointmentOffering,
    ],
  );

  return {
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useAppointmentOfferingModifyContainer;
