import React, { FC, ReactNode } from "react";
import Alert from "../Alert";
import Box from "../Box";
import Button, { ButtonProps } from "../Button";
import CircularProgress from "../CircularProgress";
import Dialog, { DialogProps } from "../Dialog";
import DialogActions from "../DialogActions";
import DialogContent from "../DialogContent";
import DialogTitle from "../DialogTitle";

export type StatusDialogActionButton = ButtonProps & {
  text: string;
  key: string;
};

export type StatusDialogProps = DialogProps & {
  children: ReactNode;
  title?: string;
  isLoading?: boolean;
  errorMessage?: string | null;
  actionButtons?: StatusDialogActionButton[];
};

const StatusDialog: FC<StatusDialogProps> = ({
  children,
  title,
  isLoading,
  errorMessage,
  actionButtons,
  ...dialogProps
}) => {
  if (errorMessage) {
    return (
      <Dialog transitionDuration={0} {...dialogProps}>
        {title && <DialogTitle>{title}</DialogTitle>}

        <DialogContent>
          <Alert severity="error">{errorMessage}</Alert>
        </DialogContent>
        {actionButtons && (
          <DialogActions>
            {actionButtons.map(({ text, key, ...props }) => (
              <Button size="small" {...props} key={key}>
                {text}
              </Button>
            ))}
          </DialogActions>
        )}
      </Dialog>
    );
  }

  if (isLoading) {
    return (
      <Dialog transitionDuration={0} {...dialogProps}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </DialogContent>
        {actionButtons && (
          <DialogActions>
            {actionButtons.map(({ text, key, ...props }) => (
              <Button size="small" {...props} key={key}>
                {text}
              </Button>
            ))}
          </DialogActions>
        )}
      </Dialog>
    );
  }

  return (
    <Dialog transitionDuration={0} {...dialogProps}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {children && (
        <DialogContent>
          <Box pt={3}>{children}</Box>
        </DialogContent>
      )}
      {actionButtons && (
        <DialogActions>
          {actionButtons.map(({ text, key, ...props }) => (
            <Button size="small" {...props} key={key}>
              {text}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default StatusDialog;
