import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "AppointmentNote.AppointmentTechnologistNoteEditDialog.title",
    defaultMessage: "Edit Notes",
  },
  btnSubmit: {
    id: "AppointmentNote.AppointmentTechnologistNoteEditDialog.btnSubmit",
    defaultMessage: "Save",
  },
  btnCancel: {
    id: "AppointmentNote.AppointmentTechnologistNoteEditDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  labelNotes: {
    id: "AppointmentNote.AppointmentTechnologistNoteEditDialog.labelNotes",
    defaultMessage: "Notes",
  },
});

export default messages;
