import React from "react";
import isBefore from "date-fns/isBefore";
import startOfDay from "date-fns/startOfDay";
import cn from "classnames";
import isSameDay from "date-fns/isSameDay";
import PickersDay, { PickersDayProps } from "../PickersDay";

import useStyles from "./useStyles";

export type AvailabilityCalendarPickersDayProps = PickersDayProps<Date> & {
  availableDays: number[];
  selectedDate: Date | null;
};

const AvailabilityCalendarPickersDay: React.FC<
  AvailabilityCalendarPickersDayProps
> = ({ availableDays, selectedDate, ...pickersDayProps }) => {
  const { day } = pickersDayProps;

  const classes = useStyles();

  const dateNow = startOfDay(new Date());

  const isFutureDate = !isBefore(day, dateNow);

  const date = day.getDate();

  const isAvailableDay = availableDays.some(
    (availableDay) => availableDay === date,
  );

  const isSelected =
    isAvailableDay && selectedDate ? isSameDay(selectedDate, day) : false;

  const className = cn({
    [classes.futureDate]: isFutureDate,
    [classes.available]: isAvailableDay,
  });

  return (
    <PickersDay
      {...pickersDayProps}
      className={className}
      key={pickersDayProps.key}
      autoFocus={false}
      aria-selected={isSelected}
      selected={isSelected}
      disabled={isAvailableDay ? undefined : true}
    />
  );
};

export default AvailabilityCalendarPickersDay;
