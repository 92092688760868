import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import checkPatientFormStatus, {
  PatientFormType,
} from "./checkPatientFormStatus";
import { FormsStatus } from "../Patient/PatientCardContainer/types";

type PatientForm = Pick<DatabaseRow<"patient_forms">, "type" | "expiration_at">;
const patientFormTypes: PatientFormType[] = ["privacy", "consent"];
const getPatientFormsStatus = (patientForms: PatientForm[]): FormsStatus => {
  const patientFormsStatuses = patientFormTypes.map((patientFormType) => {
    return checkPatientFormStatus({ patientForms, type: patientFormType });
  });
  if (
    patientFormsStatuses.every(
      (patientFormStatus) => patientFormStatus === "valid",
    )
  ) {
    return "valid";
  }
  if (
    patientFormsStatuses.some(
      (patientFormStatus) => patientFormStatus === "not_populated",
    )
  ) {
    return "not_populated";
  }
  return "expired";
};

export default getPatientFormsStatus;
