import { useCallback } from "react";
import { AppointmentRescheduleDialogProps } from "../AppointmentRescheduleDialog";
import { Center } from "./types";
import { getMonthDayList } from "../../../common/dateHelpers";

const useRescheduleCalendar = (params: {
  selectedDate: Date | null;
  defaultCalendarMonth: Date;
  onDateChange: (date: Date | null) => void;
  onMonthChange: (date: Date) => void;
  isLoading: boolean;
  refetchCenterTimeSlots: (monthDate: Date) => Promise<void>;
  center: Center | null;
}): AppointmentRescheduleDialogProps["calendarProps"] => {
  const {
    selectedDate,
    defaultCalendarMonth,
    onDateChange,
    onMonthChange,
    isLoading,
    refetchCenterTimeSlots,
    center,
  } = params;

  const availableDays = getMonthDayList(selectedDate);

  const handleMonthChange = useCallback(
    async (date: Date) => {
      onMonthChange(date);
      await refetchCenterTimeSlots(date);
    },
    [onMonthChange, refetchCenterTimeSlots],
  );

  return {
    availableDays,
    value: selectedDate,
    defaultCalendarMonth,
    onChange: onDateChange,
    onMonthChange: handleMonthChange,
    onYearChange: handleMonthChange,
    loading: isLoading,
    maxDate: center?.dateLimits.maxCenterTimezone,
    views: ["month", "year", "day"],
  };
};

export default useRescheduleCalendar;
