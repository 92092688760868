import React, { FC } from "react";
import Box from "../Box";
import AppBar from "../AppBar";
import Toolbar from "../Toolbar";
import { LogoWithText } from "../Icons";
import NavbarProfile, { NavbarProfileProps } from "../NavbarProfile";
import NavbarActions, { NavbarActionsProps } from "../NavbarActions";
import useElementPosition from "../../../hooks/useElementPosition";

export type NavbarProps = {
  actionsProps: Omit<NavbarActionsProps, "boxPosition">;
  profileProps: NavbarProfileProps;
};

const Navbar: FC<NavbarProps> = ({ actionsProps, profileProps }) => {
  const [boxRef, boxPosition] = useElementPosition();

  return (
    <AppBar position="static" color="inherit">
      <Box px={6} py={4}>
        <Toolbar disableGutters variant="dense">
          <LogoWithText />
          <Box flexGrow={1} justifyContent="center" display="flex">
            <Box ref={boxRef} width="fit-content">
              <NavbarActions {...actionsProps} boxPosition={boxPosition} />
            </Box>
          </Box>
          <NavbarProfile {...profileProps} />
        </Toolbar>
      </Box>
    </AppBar>
  );
};
export default Navbar;
