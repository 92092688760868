import { defineMessages } from "react-intl";

const messages = defineMessages({
  columnFormType: {
    id: "Patient.PatientFormsPreviewGrid.columnFormType",
    defaultMessage: "Form",
  },
  columnCompletedAt: {
    id: "Patient.PatientFormsPreviewGrid.columnCompletedAt",
    defaultMessage: "Completed",
  },
  columnExpiresAt: {
    id: "Patient.PatientFormsPreviewGrid.columnExpiresAt",
    defaultMessage: "Expires",
  },
  btnTitle: {
    id: "Patient.PatientFormsPreviewGrid.btnTitle",
    defaultMessage: "Download",
  },
});

export default messages;
