import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "addOn.CenterAddOnArchiveDialog.title",
    defaultMessage: "Archive Add-On",
  },
  description: {
    id: "addOn.CenterAddOnArchiveDialog.description",
    defaultMessage:
      "Are you sure you want to archive the <b>{name}</b> add-on? Once archived the add-on will not be purchasable by customers.",
  },
  btnCancel: {
    id: "addOn.CenterAddOnArchiveDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnArchive: {
    id: "addOn.CenterAddOnArchiveDialog.btnArchive",
    defaultMessage: "Archive",
  },
});

export default messages;
