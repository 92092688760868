import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelOffering: {
    id: "Offering.AppointmentOfferingModifyContainer.labelOffering",
    defaultMessage: "Offering",
  },
  labelSide: {
    id: "Offering.AppointmentOfferingModifyContainer.labelSide",
    defaultMessage: "Side",
  },
  labelModalityName: {
    id: "Offering.AppointmentOfferingModifyContainer.labelModalityName",
    defaultMessage: "Study Protocol",
  },
  labelReason: {
    id: "Offering.AppointmentOfferingModifyContainer.labelReason",
    defaultMessage: "Reason for change",
  },
});

export default messages;
