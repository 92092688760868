import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
    };

    return { translations };
  }, [formatMessage]);

  return result;
};

export default useTranslations;
