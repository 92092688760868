import { useMemo } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import getFormikInitialValues from "./getFormikInitialValues";
import getValidationSchema from "./getValidationSchema";
import { FormValues, Patient, PatientFormikConfig } from "./types";
import useErrorMessages from "./useErrorMessages";
import useTranslations from "./useTranslations";
import { getValidPhoneNumber } from "../../../common/phoneNumberHelpers";

const convertToStringOrEmpty = (value?: string | number) => {
  return value !== undefined ? `${value}` : "";
};

const usePatientInformationFormik = (params: {
  patient: Patient | null;
  isEmailShown?: boolean;
  isPhoneShown?: boolean;
  onSubmitSuccess?: (values: FormValues) => void | Promise<void>;
}): {
  formikConfig: PatientFormikConfig;
} => {
  const updateCenterPatient = useFirebaseAppFunction("updateCenterPatient");

  const { patient, isEmailShown, isPhoneShown, onSubmitSuccess } = params;

  const { setAlert } = useUIContext();

  const { getErrorMessageOnUpdatePatient } = useErrorMessages();

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const { formLabelTranslations, validationSchemaTranslations } =
    useTranslations();

  const initialValues = useMemo<PatientFormikConfig["initialValues"]>(() => {
    return getFormikInitialValues({
      patient,
      isEmailShown,
      isPhoneShown,
    });
  }, [isEmailShown, isPhoneShown, patient]);

  const onSubmit: PatientFormikConfig["onSubmit"] = async (values) => {
    if (!patient) return;

    const { information } = values;

    const patientId = patient.id;
    const phoneNumber = convertToStringOrEmpty(information.phoneNumber);
    const confirmationCode = convertToStringOrEmpty(
      information.confirmationCode,
    );

    try {
      const { data } = await runAsyncFunction(updateCenterPatient, {
        ...information,
        patientId,
        phoneNumber: getValidPhoneNumber(phoneNumber),
        confirmationCode,
      });

      if (data.status === "error") {
        const msg = getErrorMessageOnUpdatePatient(data.error.code);

        throw new Error(msg);
      }

      if (onSubmitSuccess) await onSubmitSuccess(values);
    } catch (error) {
      setAlert({
        isShown: true,
        severity: "error",
        message: (error as Error).message,
      });
    }
  };

  const validationSchema = useMemo<
    PatientFormikConfig["validationSchema"]
  >(() => {
    return getValidationSchema({
      translations: {
        labels: formLabelTranslations,
        errors: validationSchemaTranslations,
      },
      initialEmailValue: initialValues.information.email,
      isEmailShown,
      isPhoneShown,
    });
  }, [
    formLabelTranslations,
    initialValues.information.email,
    isEmailShown,
    isPhoneShown,
    validationSchemaTranslations,
  ]);

  return {
    formikConfig: {
      enableReinitialize: true,
      initialValues,
      onSubmit,
      validationSchema,
    },
  };
};

export default usePatientInformationFormik;
