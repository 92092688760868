import React, { FC } from "react";
import { useAppContext } from "../../Unknown/AppContext";
import useRows from "./useRows";
import AppointmentsTableGrid from "../AppointmentTableGrid";
import { DashboardAppointment } from "../AppointmentTableContainer/getAppointments";
import { DateRangePickerProps } from "../../Unknown/DateRangePicker";
import useAppointmentCustomerLogInDialog from "../AppointmentCardsContainer/useAppointmentCustomerLogInDialog";
import useAppointmentCheckInDialog from "../AppointmentCardsContainer/useAppointmentCheckInDialog";

type AppointmentTableProps = {
  role: string | undefined;
  appointments: DashboardAppointment[];
  tableKey: string;
  isLoading: boolean;
  dateRangePickerProps?: DateRangePickerProps<Date>;
  refetch: () => void;
};

const AppointmentTable: FC<AppointmentTableProps> = ({
  role,
  appointments,
  isLoading,
  tableKey,
  dateRangePickerProps,
  refetch,
}) => {
  const {
    databaseUserData: { centerIds },
  } = useAppContext();

  const { props: loginDialogProps, handleOpen: handleOpenLoginDialog } =
    useAppointmentCustomerLogInDialog();

  const { props: checkInDialogProps, handleOpen: handleOpenCheckInDialog } =
    useAppointmentCheckInDialog({ onSuccess: refetch });

  const rows = useRows({
    appointments,
    handleOpenLoginDialog,
    handleOpenCheckInDialog,
  });

  const localeFormat = centerIds.length === 1 ? "center" : "client";

  switch (role) {
    case "receptionist":
      return (
        <AppointmentsTableGrid
          columnsConfig={{
            columnItems: [
              { field: "alert" },
              { field: "status" },
              { field: "patient", localeFormat },
              { field: "time", localeFormat },
              { field: "location" },
              { field: "offering", header: "offerings" },
              { field: "btnOptions" },
              { field: "btnView" },
              { field: "isPast" },
            ],
          }}
          initialColumnVisibilityModel={{
            isPast: false,
          }}
          rows={rows}
          loginDialogProps={loginDialogProps}
          checkInDialogProps={checkInDialogProps}
          isLoading={isLoading}
        />
      );
    case "technologist":
      return (
        <AppointmentsTableGrid
          columnsConfig={{
            columnItems: [
              { field: "alert" },
              { field: "status" },
              { field: "patient", localeFormat },
              { field: "time", localeFormat },
              { field: "offering", header: "studies" },
              { field: "btnOptions" },
              { field: "btnView" },
              { field: "isPast" },
            ],
          }}
          initialColumnVisibilityModel={{
            isPast: false,
          }}
          rows={rows}
          loginDialogProps={loginDialogProps}
          checkInDialogProps={checkInDialogProps}
          isLoading={isLoading}
        />
      );
    case "regional_admin":
    case "super_admin":
      return (
        <AppointmentsTableGrid
          columnsConfig={{
            columnItems: [
              { field: "flags" },
              { field: "status" },
              { field: "date", localeFormat },
              { field: "time", localeFormat },
              { field: "location" },
              { field: "locationState" },
              { field: "locationZip" },
              { field: "patientName" },
              { field: "patientAge" },
              { field: "patientDOB" },
              { field: "patientPhone" },
              { field: "patientEmail" },
              { field: "patientAddressLine1" },
              { field: "patientAddressLine2" },
              { field: "patientCity" },
              { field: "patientZip" },
              { field: "patientState" },
              { field: "patientId" },
              { field: "patientRace" },
              { field: "patientSex" },
              { field: "centerServices" },
              { field: "addOns" },
              { field: "appointmentTotal" },
              { field: "bookingScenario" },
              { field: "bookedBy" },
              { field: "bookedDate", localeFormat },
              { field: "bookedTime", localeFormat },
              { field: "customer" },
              { field: "payments" },
              { field: "isPast" },
            ],
          }}
          tableKey={tableKey}
          rows={rows}
          loginDialogProps={loginDialogProps}
          checkInDialogProps={checkInDialogProps}
          isLoading={isLoading}
          dateRangePickerProps={dateRangePickerProps}
          initialColumnVisibilityModel={{
            flags: false,
            bookingScenario: false,
            bookedBy: false,
            bookedDate: false,
            bookedTime: false,
            createdAt: false,
            locationState: false,
            locationZip: false,
            addOns: false,
            patientAddress: false,
            customer: false,
            payments: false,
            patientState: false,
            patientZip: false,
            isPast: false,
            patientAddressLine1: false,
            patientAddressLine2: false,
            patientCity: false,
            patientId: false,
            patientRace: false,
            patientSex: false,
            appointmentTotal: false,
          }}
        />
      );
    case "radiologist":
    default:
      return null;
  }
};

export default AppointmentTable;
