import { useCallback, useState } from "react";
import { InitialAppointmentFile } from "./types";

const useAppointmentFiles = (params: {
  initialFiles: InitialAppointmentFile[];
}) => {
  const { initialFiles } = params;

  const [files, setFiles] = useState(initialFiles);

  const handleAddFiles = useCallback((newFiles: InitialAppointmentFile[]) => {
    setFiles((prev) => [...prev, ...newFiles]);
  }, []);

  return { files, handleAddFiles };
};

export default useAppointmentFiles;
