import { Formik, FormikTouched } from "formik";
import React, { FC } from "react";
import { CenterPackageFormValues } from "../CenterPackageForm/types";
import CenterSettingsFormikDialog from "../../Center/CenterSettingsFormikDialog";
import useForm from "./useForm";
import CenterPackageForm from "../CenterPackageForm";
import useLabelTranslations from "./useLabelTranslations";
import { Offering } from "./types";
import { PackageOffering } from "../../Center/CenterSettingsContainer/types";

export type CenterPackageFormikProps = {
  title: string;
  isOpened: boolean;
  onModalClose: () => void;
  initialValues: CenterPackageFormValues;
  initialTouched?: FormikTouched<CenterPackageFormValues>;
  handleSubmit: (values: CenterPackageFormValues) => void | Promise<void>;
  currencyCode: string;
  minPrice: number;
  offerings: (Offering | PackageOffering)[];
};

const CenterPackageFormik: FC<CenterPackageFormikProps> = ({
  title,
  isOpened,
  onModalClose,
  initialValues,
  initialTouched,
  handleSubmit,
  minPrice,
  currencyCode,
  offerings,
}) => {
  const { formikConfig } = useForm({
    initialValues,
    initialTouched,
    minPrice,
    handleSubmit,
  });

  const labelTranslations = useLabelTranslations();

  return (
    <Formik {...formikConfig}>
      <CenterSettingsFormikDialog
        title={title}
        isOpened={isOpened}
        onClose={onModalClose}
      >
        <CenterPackageForm
          currencyCode={currencyCode}
          labelTranslations={labelTranslations}
          offerings={offerings}
          isPriceChangeable
        />
      </CenterSettingsFormikDialog>
    </Formik>
  );
};

export default CenterPackageFormik;
