import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { Appointment } from "../AppointmentCardsContainer/types";

interface CheckIsCanBeMarkedAsNoShowParams {
  appointment: Appointment | null;
  role?: DatabaseEnum["center_user_role"];
}

const checkIsCanBeMarkedAsNoShow = ({
  appointment,
  role,
}: CheckIsCanBeMarkedAsNoShowParams): boolean => {
  return !!(
    appointment &&
    role &&
    ["technologist", "super_admin", "regional_admin", "receptionist"].includes(
      role,
    ) &&
    ["confirmed", "intake_complete"].includes(appointment.status)
  );
};

export default checkIsCanBeMarkedAsNoShow;
