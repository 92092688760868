import classNames from "classnames";
import { useCallback } from "react";
import { EventContentArg } from "../../Unknown/Calendar";
import { CalendarEventExtendedProp } from "../TimeSlotCalendarScreen/convertTimeSlotsToEvents";
import useStyles from "./useStyles";

const getEventStatus = (event: EventContentArg["event"]) => {
  const { appointmentStatus, timeSlotType } =
    event.extendedProps as CalendarEventExtendedProp;

  if (timeSlotType === "planned_closures") {
    return "planned_closures";
  }

  const specificStatuses = ["incomplete", "checkout", "reports_sent"];

  if (appointmentStatus && specificStatuses.includes(appointmentStatus)) {
    return appointmentStatus;
  }

  return "general";
};

const useEventClassNamesFunc = () => {
  const {
    commonEventRoot,
    generalEventRoot,
    closureEventRoot,
    incompleteEventRoot,
    reportsSentEventRoot,
  } = useStyles();

  const getEventClassNames = useCallback(
    (arg: EventContentArg) => {
      const status = getEventStatus(arg.event);

      const classNamesArgArray: classNames.ArgumentArray = [];

      switch (status) {
        case "incomplete":
        case "checkout": {
          classNamesArgArray.push(incompleteEventRoot);
          break;
        }
        case "reports_sent": {
          classNamesArgArray.push(reportsSentEventRoot);
          break;
        }
        case "planned_closures": {
          classNamesArgArray.push(closureEventRoot);
          break;
        }
        default: {
          classNamesArgArray.push(generalEventRoot);
          break;
        }
      }

      return classNames(commonEventRoot, classNamesArgArray);
    },
    [
      closureEventRoot,
      commonEventRoot,
      generalEventRoot,
      incompleteEventRoot,
      reportsSentEventRoot,
    ],
  );

  return getEventClassNames;
};

export default useEventClassNamesFunc;
