import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    nameText: {
      fontSize: 14,
      color: palette.primary.main,
      cursor: "pointer",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };
});

export default useStyles;
