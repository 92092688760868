import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Center.CenterOperatingHoursFormik.title",
    defaultMessage: "Edit Operating Hours",
  },
  toMinutesAtMinimumError: {
    id: "Center.CenterOperatingHoursFormik.toMinutesAtMinimumError",
    defaultMessage: "Value must be greater than start time",
  },
  timeInvalidError: {
    id: "Center.CenterOperatingHoursFormik.timeInvalidError",
    defaultMessage: "Time is invalid",
  },
});

export default messages;
