import { useMemo } from "react";
import { useIntl } from "react-intl";
import { TimeSlotAddFormTranslations } from "../TimeSlotAddDialogForm";
import messages from "./messages";

type TimeSlotAddDialogTranslations = {
  translations: {
    btnDelete: string;
    btnSave: string;
    btnCancel: string;
  };
  formikTranslations: TimeSlotAddFormTranslations;
};

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translationsResult = useMemo<TimeSlotAddDialogTranslations>(() => {
    const translations: TimeSlotAddDialogTranslations["translations"] = {
      btnSave: formatMessage(messages.btnSave),
      btnDelete: formatMessage(messages.btnDelete),
      btnCancel: formatMessage(messages.btnCancel),
    };

    const formikTranslations: TimeSlotAddDialogTranslations["formikTranslations"] =
      {
        eventName: formatMessage(messages.eventName),
        startDate: formatMessage(messages.startDate),
        endDate: formatMessage(messages.endDate),
        startTime: formatMessage(messages.startTime),
        endTime: formatMessage(messages.endTime),
      };

    return { translations, formikTranslations };
  }, [formatMessage]);

  return translationsResult;
};
export default useTranslations;
