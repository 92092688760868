import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import { SearchPatientItem } from "../useRows";
import AppointmentsPreview from "../../../Appointment/AppointmentsPreview";

type ColumnAppointmentsTranslations = {
  titleAppointments: string;
};

type ColumnsDetailsParams = {
  translations: ColumnAppointmentsTranslations;
};

const getColumnAppointments = ({
  translations,
}: ColumnsDetailsParams): GridColDef<SearchPatientItem> => {
  return {
    field: "appointments",
    disableColumnMenu: true,
    flex: 1,
    headerName: translations.titleAppointments,
    sortable: false,
    renderCell: (renderParams) => (
      <AppointmentsPreview appointments={renderParams.row.appointments} />
    ),
  };
};

export default getColumnAppointments;
