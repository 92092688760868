import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      titleFiles: formatMessage(messages.titleFiles),
    };

    return { translations };
  }, [formatMessage]);

  return result;
};

export default useTranslations;
