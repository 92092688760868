import { addHours, differenceInMinutes } from "date-fns";
import {
  UTCDateTimeToTimezone,
  getTimezoneOffsetMillis,
} from "../../../common/dateHelpers";

const getDeadlineMinutes = ({
  timezone,
  readyToReadAtCenterTimezone,
  reportDeliveryDeadlineHours,
}: {
  timezone: string;
  readyToReadAtCenterTimezone: Date;
  reportDeliveryDeadlineHours: number;
}): number => {
  const now = new Date();
  const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  const nowCenterTimeZone = UTCDateTimeToTimezone(
    utcNow,
    getTimezoneOffsetMillis(timezone),
  );

  const deadlineMinutes = differenceInMinutes(
    addHours(readyToReadAtCenterTimezone, reportDeliveryDeadlineHours),
    nowCenterTimeZone,
  );

  return deadlineMinutes;
};

export default getDeadlineMinutes;
