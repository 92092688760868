import { defineMessages } from "react-intl";

const messages = defineMessages({
  btnCancel: {
    id: "Appointment.AppointmentCardsContainer.btnCancel",
    defaultMessage: "Cancel",
  },
  cash: {
    id: "Appointment.AppointmentCardsContainer.cash",
    defaultMessage: "Cash",
  },
  card: {
    id: "Appointment.AppointmentCardsContainer.card",
    defaultMessage: "Card",
  },
  btnReschedule: {
    id: "Appointment.AppointmentCardsContainer.btnReschedule",
    defaultMessage: "Reschedule",
  },
  btnNoShow: {
    id: "Appointment.AppointmentCardsContainer.btnNoShow",
    defaultMessage: "No show",
  },
  btnLogin: {
    id: "Appointment.AppointmentCardsContainer.btnLogin",
    defaultMessage: "Log in",
  },
  btnCheckIn: {
    id: "Appointment.AppointmentCardsContainer.btnCheckIn",
    defaultMessage: "Check in",
  },
  btnConfirmAppointment: {
    id: "Appointment.AppointmentCardsContainer.btnConfirmAppointment",
    defaultMessage: "Confirm Appointment",
  },
  btnScreeningForm: {
    id: "Appointment.AppointmentCardsContainer.btnScreeningForm",
    defaultMessage: "Screening form",
  },
  btnBookAdditionalViews: {
    id: "Appointment.AppointmentCardsContainer.btnBookAdditionalViews",
    defaultMessage: "Book additional views",
  },
  cancelingAppointmentInPastError: {
    id: "Appointment.AppointmentCardsContainer.cancelingAppointmentInPastError",
    defaultMessage: "Cannot cancel appointment in past",
  },
  statusNotSupportedToCancelError: {
    id: "Appointment.AppointmentCardsContainer.statusNotSupportedToCancelError",
    defaultMessage: "Cannot cancel appointment",
  },
  statusNotSupportedError: {
    id: "Appointment.AppointmentCardsContainer.statusNotSupportedToCancelError",
    defaultMessage: "Cannot mark appointment as no show",
  },
  appointmentNotFound: {
    id: "Appointment.AppointmentCardsContainer.appointmentNotFound",
    defaultMessage: "Appointment not found",
  },
  btnPriority: {
    id: "Appointment.AppointmentCardsContainer.btnPriority",
    defaultMessage: "Priority",
  },
  statusNotSupportedPriorityError: {
    id: "Appointment.AppointmentCardsContainer.statusNotSupportedPriorityError",
    defaultMessage: "Cannot mark this appointment as priority",
  },
  btnViewAuditLogs: {
    id: "Appointment.AppointmentCardsContainer.btnViewAuditLogs",
    defaultMessage: "View audit log",
  },
  errorCustomerNotDefined: {
    id: "Appointment.AppointmentCustomerLogInDialog.errorCustomerNotDefined",
    defaultMessage: "Customer is not defined.",
  },
});

export default messages;
