import { useCallback, useState } from "react";
import { useEffectOnce } from "react-use";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import getAppointmentData from "./getAppointmentData";
import { Appointment } from "./types";
import useTranslations from "./useTranslations";
import supabaseClient from "../../../common/supabaseClient";

const useAppointmentData = (appointmentId?: string) => {
  const { runAsyncFunction } = useDefaultErrorWrapper();
  const { translations } = useTranslations();
  const { appointmentNotFound } = translations;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [data, setData] = useState<Appointment | null>(null);

  const fetch = useCallback(async () => {
    if (!appointmentId) return;

    setIsLoading(true);
    try {
      const appointmentResult = await runAsyncFunction(
        getAppointmentData,
        appointmentId,
      );

      if (!appointmentResult) {
        throw new Error(appointmentNotFound);
      }

      setData(appointmentResult);
    } catch (error) {
      setErrorMessage((error as Error).message);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  }, [appointmentId, appointmentNotFound, runAsyncFunction]);

  useEffectOnce(() => {
    const getAppointment = async () => {
      await fetch();
    };

    getAppointment();

    const subscription = supabaseClient
      .from<DatabaseRow<"appointments">>("appointments")
      .on("UPDATE", (payload) => {
        if (
          payload.new.id === appointmentId &&
          (payload.new.status !== payload.old.status ||
            payload.new.has_priority !== payload.old.has_priority)
        ) {
          getAppointment();
        }
      })
      .subscribe();

    const offerringSubscription = supabaseClient
      .from<DatabaseRow<"appointment_offerings">>("appointment_offerings")
      .on("UPDATE", (payload) => {
        if (
          payload.new.appointment_id === appointmentId &&
          payload.new.status !== payload.old.status
        ) {
          getAppointment();
        }
      })
      .subscribe();

    return () => {
      subscription.unsubscribe();
      offerringSubscription.unsubscribe();
    };
  });

  return {
    isLoading,
    errorMessage,
    data,
    refetch: fetch,
  };
};

export default useAppointmentData;
