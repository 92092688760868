import { alpha, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, shape }) => {
  return {
    helperTextRoot: {
      color: alpha(palette.common.black, 0.5),
    },
    dropzoneRoot: {
      width: "100%",
      backgroundColor: alpha(palette.common.black, 0.05),
      height: 100,
      borderRadius: shape.borderRadius,
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
});

export default useStyles;
