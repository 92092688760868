import { useCallback, useMemo } from "react";
import { useUIContext } from "../../Unknown/UIContext";
import { FormConfig } from "./types";
import { Appointment } from "../../Appointment/AppointmentCardsContainer/types";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useAuthContext } from "../../Auth/AuthContext";
import { AppointmentScreeningFormTranslations } from "../AppointmentScreeningForm/types";

type UseFormikComponentProps = {
  appointment: Appointment | null;
  onSuccess: () => void;
  handleClose: () => void;
};

const useFormikComponent = ({
  appointment,
  onSuccess,
  handleClose,
}: UseFormikComponentProps) => {
  const { userData } = useAuthContext();
  const { setAlert } = useUIContext();

  const { appointment_forms: appointmentForms } = appointment || {};

  const existedAppointmentScreeningForm = appointmentForms?.find(
    (appointmentForm) => appointmentForm.type === "screening",
  );

  const initialValues = useMemo<FormConfig["initialValues"]>(() => {
    return {
      technologistSignature: false,
      patientDoNotHaveSensitiveItemsCheckbox:
        !existedAppointmentScreeningForm?.sensitive_items,
      sensitiveItems: existedAppointmentScreeningForm?.sensitive_items
        ? (existedAppointmentScreeningForm.sensitive_items as Array<
            keyof AppointmentScreeningFormTranslations
          >)
        : null,
      patientSignedAt: existedAppointmentScreeningForm?.patient_signed_at
        ? new Date(
            existedAppointmentScreeningForm.patient_signed_at,
          ).toLocaleDateString()
        : "",
      centerUserNotes: "",
    };
  }, [existedAppointmentScreeningForm]);

  const { runAsyncFunction } = useDefaultErrorWrapper();
  const provideCenterAppAppointmentScreeningForm = useFirebaseAppFunction(
    "provideCenterAppAppointmentScreeningForm",
  );

  const onSubmit = useCallback<FormConfig["onSubmit"]>(
    async (values) => {
      if (!appointment || !userData) {
        return;
      }
      try {
        await runAsyncFunction(provideCenterAppAppointmentScreeningForm, {
          appointmentId: appointment.id,
          sensitiveItems: values.sensitiveItems,
          centerUserNotes: values.centerUserNotes,
          centerUserId: userData.sub,
        });
        handleClose();
        await onSuccess();
      } catch (error) {
        setAlert({
          isShown: true,
          severity: "error",
          message: (error as Error).message,
        });
      }
    },
    [
      setAlert,
      appointment,
      onSuccess,
      handleClose,
      runAsyncFunction,
      provideCenterAppAppointmentScreeningForm,
      userData,
    ],
  );

  return {
    enableReinitialize: true,
    initialValues,
    onSubmit,
  };
};

export default useFormikComponent;
