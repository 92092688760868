import React, { FC, useCallback } from "react";
import { Formik } from "formik";
import CustomerInformationDrawer from "../CustomerInformationDrawer";
import useNormalizedCustomerDrawerData from "./useNormalizedCustomerDrawerData";
import useCustomerFormik from "./useCustomerFormik";
import useEditMode from "./useEditMode";
import useCustomer from "./useCustomer";

export type CustomerInformationDrawerContainerProps = {
  isOtherPerson?: boolean;
  customerId?: string;
  isOpen: boolean;
  handleClose: () => void;
  handlePostSubmit?: () => void | Promise<void>;
  handlePatientClick: (patientMrn: string) => void;
};

const CustomerInformationDrawerContainer: FC<
  CustomerInformationDrawerContainerProps
> = ({
  isOtherPerson,
  customerId,
  isOpen,
  handleClose,
  handlePatientClick,
  handlePostSubmit,
}) => {
  const {
    isLoading,
    error,
    data: customer,
    refetch,
  } = useCustomer({ customerId, isOtherPerson });

  const { isEditMode, handleEditModeChange } = useEditMode();

  const normalizedCustomerData = useNormalizedCustomerDrawerData({
    customer,
    isOtherPerson,
    handlePatientClick,
  });

  const onSubmitSuccess = useCallback(async () => {
    await refetch();

    handleEditModeChange(false);

    if (handlePostSubmit) await handlePostSubmit();
  }, [handleEditModeChange, handlePostSubmit, refetch]);

  const { formikConfig: customerFormikConfig } = useCustomerFormik({
    customer,
    customerId,
    isOtherPerson,
    onSubmitSuccess,
  });

  return (
    <Formik {...customerFormikConfig}>
      <CustomerInformationDrawer
        isOpen={isOpen}
        handleClose={handleClose}
        isLoading={isLoading}
        error={error}
        data={normalizedCustomerData}
        isEditMode={isEditMode}
        handleEditModeChange={handleEditModeChange}
      />
    </Formik>
  );
};

export default CustomerInformationDrawerContainer;
