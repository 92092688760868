import { defineMessages } from "react-intl";

const messages = defineMessages({
  btnAddAddOn: {
    id: "AddOn.CenterAddOnsView.btnAddAddOn",
    defaultMessage: "Add Add-On",
  },
  titleAddAddOn: {
    id: "AddOn.CenterAddOnsView.titleAddAddOn",
    defaultMessage: "Add Add-On",
  },
  titleEditAddOn: {
    id: "AddOn.CenterAddOnsView.titleEditAddOn",
    defaultMessage: "Edit Add-On",
  },
});

export default messages;
