import * as Yup from "yup";
import isValid from "date-fns/isValid";
import { CenterOperatingHoursFormValues } from "../CenterOperatingHoursForm";
import { CenterOperatingHoursDayInputValues } from "../CenterOperatingHoursDayInput";
import { getDayMinutes } from "../../../common/dateHelpers";

export type CenterOperatingHoursValidationTranslations = {
  requiredField: string;
  toMinutesAtMinimumError: string;
  timeInvalidError: string;
};

const days = ["1", "2", "3", "4", "5", "6", "7"] as const;

const getFormSchema = (params: {
  translations: CenterOperatingHoursValidationTranslations;
}) => {
  const { translations } = params;

  const { requiredField, toMinutesAtMinimumError, timeInvalidError } =
    translations;

  const yupDate = Yup.date()
    .nullable()
    .when("isOpen", {
      is: true,
      then: (schema) => {
        return schema.typeError(timeInvalidError).required(requiredField);
      },
      otherwise: (schema) => schema.optional(),
    })

    .test(function checkIsDateValid(value, ctx) {
      const { isOpen } = ctx.parent;

      if (!isOpen || !value) {
        return true;
      }

      if (!isValid(value)) {
        return this.createError({ message: timeInvalidError });
      }

      return true;
    });

  const yupDaySchema = Yup.object().shape<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<keyof CenterOperatingHoursDayInputValues, any>
  >({
    isOpen: Yup.boolean().required(requiredField),
    fromMinutesAt: yupDate,
    toMinutesAt: yupDate.test(function minDate(value, ctx) {
      const { isOpen, fromMinutesAt } = ctx.parent;

      if (
        !isOpen ||
        !fromMinutesAt ||
        !value ||
        !isValid(value) ||
        !isValid(fromMinutesAt)
      ) {
        return true;
      }

      const fromDayMinutes = getDayMinutes(fromMinutesAt);
      const toDayMinutes = getDayMinutes(value);

      if (fromDayMinutes >= toDayMinutes) {
        return this.createError({ message: toMinutesAtMinimumError });
      }

      return true;
    }),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Yup.object().shape<Record<keyof CenterOperatingHoursFormValues, any>>({
    operatingHours: Yup.object().shape(
      days.reduce((acc, dayKey) => ({ ...acc, [dayKey]: yupDaySchema }), {}),
    ),
  });
};

export default getFormSchema;
