import { baseFormInitialValues } from "./constants";
import { Customer, CustomerFormikConfig } from "./types";

const getFormikInitialValues = (params: {
  customer: Customer | null;
  isOtherPerson?: boolean;
}) => {
  const { customer, isOtherPerson } = params;

  if (!customer) return baseFormInitialValues;

  const { first_name, last_name, mobile_phone_number, email } = customer;

  const information: CustomerFormikConfig["initialValues"]["information"] = {
    firstName: first_name,
    lastName: last_name,
    email,
    confirmationCode: "",
  };

  if (!isOtherPerson) {
    information.phoneNumber = mobile_phone_number || "";
  }

  return { information };
};

export default getFormikInitialValues;
