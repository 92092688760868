import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemFullAddOns, AppointmentRowItem } from "../types";
import Box from "../../../Unknown/Box";
import Typography from "../../../Unknown/Typography";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnTranslations = {
  columnFullAddOns: string;
};

const getColumnFullAddOns = (params: {
  columnItem: AppointmentColumnItemFullAddOns;
  translations: ColumnTranslations;
  formatAmount: (cost: number, currencyCode: string) => string;
}) => {
  const { columnItem, translations, formatAmount } = params;

  const { field } = columnItem;

  const column: GridColDef<AppointmentRowItem, string> = {
    field,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnFullAddOns,
    valueGetter: (getterParams) => {
      const { addOns, center } = getterParams.row;
      const { currencyCode } = center;

      const formattedAddOns = addOns.map((addOn) => {
        const { name, cost } = addOn;

        const formattedCost = formatAmount(cost, currencyCode);

        return `${name} - ${formattedCost}`;
      });

      return formattedAddOns.join(";");
    },
    renderCell: ({ row }) => {
      const { addOns } = row;

      if (!addOns?.length) return null;

      return (
        <Box display="flex" flexDirection="column">
          {addOns.map((addOn) => {
            const { name } = addOn;

            return (
              <Typography key={name} variant="body2" color="inherit">
                {name}
              </Typography>
            );
          })}
        </Box>
      );
    },
  };

  return column;
};

export default getColumnFullAddOns;
