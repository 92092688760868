import { useMemo } from "react";
import { AppointmentReportGridContainerProps } from "../../AppointmentReport/AppointmentReportGridContainer";
import { Appointment } from "./types";
import { AppointmentReport } from "../../AppointmentReport/AppointmentReportGrid/types";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";

const useAppointmentReportGridContainer = ({
  appointment,
  refetch,
}: {
  appointment: Appointment | null;
  refetch: () => Promise<void>;
}) => {
  const generateReportSignedUrl = useFirebaseAppFunction(
    "generateReportSignedUrl",
  );

  const props = useMemo<AppointmentReportGridContainerProps | null>(() => {
    if (!appointment) return null;

    const { appointment_reports } = appointment;

    const reports = appointment_reports.map<AppointmentReport>((report) => {
      const onDownloadClick = async () => {
        const {
          data: { downloadUrl },
        } = await generateReportSignedUrl({
          reportId: report.id,
        });

        window.open(downloadUrl);
      };

      return {
        id: report.id,
        name: report.filename,
        status: report.status,
        hasUnexpectedFindings: !!report.has_unexpected_findings,
        isReceiptConfirmed: !!report.is_receipt_confirmed,
        onDownloadClick,
      };
    });

    const patient = appointment.appointment_patient_details[0];

    return {
      reports,
      patientFirstName: patient.first_name,
      patientLastName: patient.last_name,
      patientEmail: patient.email || "",
      appointmentId: appointment.id,
      refetch,
    };
  }, [appointment, generateReportSignedUrl, refetch]);

  return props;
};

export default useAppointmentReportGridContainer;
