import { defineMessages } from "react-intl";

const messages = defineMessages({
  btnAddPackage: {
    id: "Package.CenterPackagesView.btnAddPackage",
    defaultMessage: "Add package",
  },
  editModalTitle: {
    id: "Package.CenterPackagesView.editModalTitle",
    defaultMessage: "Edit package",
  },
  addModalTitle: {
    id: "Package.CenterPackagesView.addModalTitle",
    defaultMessage: "Add package",
  },
});

export default messages;
