import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import { ColumnTranslations } from "./useColumns";

type Translations = {
  columnTranslations: ColumnTranslations;
};

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo<Translations>(() => {
    const columnTranslations = {
      titleDetails: formatMessage(messages.titleDetails),
      titleDateBirth: formatMessage(messages.titleDateBirth),
      titleAppointments: formatMessage(messages.titleAppointments),
    };

    return { columnTranslations };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
