import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import commonMessages from "../../../common/messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      title: formatMessage(messages.title),
      defaultError: formatMessage(commonMessages.defaultError),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
