import { useCallback, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import getCenterTimeSlotsForMonth from "./getCenterTimeSlotsForMonth";
import { Center, CenterTimeSlot } from "./types";
import useTranslations from "./useTranslations";

const useCenterTimeSlots = (params: {
  center: Center | null;
  monthDate: Date;
}) => {
  const { center, monthDate } = params;

  const abortControllerRef = useRef<AbortController | null>(null);

  const { translations } = useTranslations();
  const { defaultError } = translations;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [data, setData] = useState<CenterTimeSlot[] | null>(null);

  const fetch = useCallback(
    async (monthDateValue: Date) => {
      setIsLoading(true);
      try {
        if (abortControllerRef.current) abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();

        if (!center) {
          throw new Error(defaultError);
        }

        let centerTimeSlotsResult: CenterTimeSlot[] | null = null;

        try {
          centerTimeSlotsResult = await getCenterTimeSlotsForMonth({
            centerId: center.id,
            monthDate: monthDateValue,
            abortSignal: null,
          });
        } catch (error) {
          throw new Error(defaultError);
        }

        setData(centerTimeSlotsResult);
      } catch (error) {
        setErrorMessage((error as Error).message);
        setData(null);
      } finally {
        setIsLoading(false);
      }
    },
    [center, defaultError],
  );

  useEffectOnce(() => {
    fetch(monthDate);
  });

  return {
    isLoading,
    errorMessage,
    data,
    refetch: fetch,
  };
};

export default useCenterTimeSlots;
