export const views = {
  month: "dayGridMonth",
  week: "timeGridWeek",
  day: "timeGridDay",
} as const;

export type View = keyof typeof views;

export const fullCalendarViews = {
  dayGridMonth: "month",
  timeGridWeek: "week",
  timeGridDay: "day",
} as const;

export type FullCalendarView = keyof typeof fullCalendarViews;

export const convertViewToFullCalendar = (view?: View) => {
  if (!view) return undefined;
  const fullCalendarView = views[view];
  return fullCalendarView;
};

export const convertFullCalendarViewToView = (
  fullCalendarView: FullCalendarView,
) => {
  const view = fullCalendarViews[fullCalendarView];
  return view;
};

export type EventInput = {
  title: string;
  start: string;
  end: string;
  description?: string;
};

export type DateViewChangeParams = {
  start: Date;
  end: Date;
  view: View;
};
