import React, { FC } from "react";
import { useIntl } from "react-intl";
import AppointmentPaymentAddDialogContainer from "../AppointmentPaymentAddDialogContainer";
import getFormattedPrice from "../../../common/getFormattedPrice";
import { PaymentOptionsData } from "../AppointmentPaymentAddForm";
import { AppointmentPayment } from "../AppointmentPaymentGrid/types";
import AppointmentPaymentListCard from "../AppointmentPaymentListCard";
import { Appointment } from "./types";
import useAppointmentPaymentAddDialogContainer from "./useAppointmentPaymentAddDialogContainer";

export type AppointmentPaymentListCardContainerProps = {
  payments: AppointmentPayment[];
  appointment: Appointment | null;
  paymentOptionsData: PaymentOptionsData;
  currencyCode: string;
  centerLocale: string;
  handleAddPayment: () => void;
};

const AppointmentPaymentListCardContainer: FC<
  AppointmentPaymentListCardContainerProps
> = ({
  payments,
  appointment,
  currencyCode,
  centerLocale,
  paymentOptionsData,
  handleAddPayment,
}) => {
  const { handleOpen, props } = useAppointmentPaymentAddDialogContainer({
    appointment,
    currencyCode,
    centerLocale,
    paymentOptionsData,
    handleAddPayment,
  });
  const intl = useIntl();

  const isPaymentButtonShown = !!appointment?.remainingBalance;

  const totalPrice = getFormattedPrice({
    value: appointment?.totalPrice || 0,
    currencyCode,
    intl,
  });

  const remainingBalance = getFormattedPrice({
    value: appointment?.remainingBalance || 0,
    currencyCode,
    intl,
  });

  return (
    <>
      <AppointmentPaymentListCard
        payments={payments}
        totalPrice={totalPrice}
        remainingBalance={remainingBalance}
        isPaymentButtonShown={isPaymentButtonShown}
        handleAddClick={handleOpen}
      />
      <AppointmentPaymentAddDialogContainer {...props} />
    </>
  );
};

export default AppointmentPaymentListCardContainer;
