import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Translations } from "./types";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations: Translations = useMemo(() => {
    return {
      sideLeft: formatMessage(messages.sideLeft),
      sideRight: formatMessage(messages.sideRight),
    };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
