import { useCallback, useMemo, useState } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { PackagesData } from "../../Center/CenterSettingsContainer/types";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterPackageFormValues } from "../CenterPackageForm/types";
import { CenterPackageFormikProps } from "../CenterPackageFormik";
import useTranslations from "./useTranslations";

type AddFormHookParams = {
  isOpened: boolean;
  onClose: () => void;
  centerId: string;
  minPrice: number;
  currencyCode: string;
  refetchCenterData?: () => void | Promise<void>;
  offerings: PackagesData["offerings"];
};

type AddFormHookResult = {
  addFormikProps: CenterPackageFormikProps;
};

const initialValues: CenterPackageFormValues = {
  name: "",
  modalityName: "",
  offerings: [],
  description: "",
  price: "",
  depositAmount: "",
  visibility: "",
};

const useAddForm = (params: AddFormHookParams): AddFormHookResult => {
  const {
    isOpened,
    onClose,
    centerId,
    minPrice,
    currencyCode,
    refetchCenterData,
    offerings,
  } = params;

  const addCenterPackage = useFirebaseAppFunction("addCenterPackage");

  const { addModalTitle, defaultError } = useTranslations();

  const { setAlert } = useUIContext();

  const [centerPackageData, setCenterPackageData] =
    useState<CenterPackageFormValues>(initialValues);

  const onModalClose = useCallback(() => {
    setCenterPackageData(initialValues);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(
    async (values: CenterPackageFormValues) => {
      try {
        if (!centerPackageData) throw new Error(defaultError);

        const {
          name,
          modalityName,
          offerings: selectedOfferings,
          description,
          visibility,
          price,
          depositAmount,
        } = values;

        if (visibility === "" || price === "" || depositAmount === "") {
          throw new Error(defaultError);
        }

        const offeringIds = selectedOfferings.map(({ id }) => id);

        await addCenterPackage({
          centerId,
          name,
          modalityName,
          offeringIds,
          description,
          visibility,
          price,
          depositAmount,
        });

        if (refetchCenterData) await refetchCenterData();

        onModalClose();
      } catch (error) {
        setAlert({
          message: defaultError,
          isShown: true,
          severity: "error",
        });
      }
    },
    [
      addCenterPackage,
      centerId,
      centerPackageData,
      defaultError,
      onModalClose,
      refetchCenterData,
      setAlert,
    ],
  );

  const filteredOfferings = useMemo(() => {
    return offerings.filter((offering) => !offering.center_package_id);
  }, [offerings]);

  return {
    addFormikProps: {
      title: addModalTitle,
      isOpened,
      onModalClose,
      initialValues,
      handleSubmit,
      currencyCode,
      minPrice,
      offerings: filteredOfferings,
    },
  };
};

export default useAddForm;
