import React, { FC, useEffect } from "react";
import CustomerInformationDrawerContainer from "../../Customer/CustomerInformationDrawerContainer";
import PatientCardContainer from "../../Patient/PatientCardContainer";
import PatientInformationDrawerContainer from "../../Patient/PatientInformationDrawerContainer";
import Alert from "../../Unknown/Alert";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";
import Grid from "../../Unknown/Grid";
import AppointmentActionBar from "../AppointmentActionBar";
import AppointmentCancelDialog from "../AppointmentCancelDialog";
import AppointmentRescheduleDialogContainer from "../AppointmentRescheduleDialogContainer";
import useAppointmentActionsBar from "../AppointmentActionBar/useAppointmentActionsBar";
import useAppointmentCancelDialog from "./useAppointmentCancelDialog";
import useAppointmentData from "./useAppointmentData";
import useAppointmentFileGridContainer from "./useAppointmentFileGridContainer";
import useAppointmentRescheduleDialogContainer from "./useAppointmentRescheduleDialogContainer";
import AppointmentFileGridContainer from "../../AppointmentFile/AppointmentFileGridContainer";
import useCustomerInformationDrawerContainer from "./useCustomerInformationDrawerContainer";
import usePatientCardContainer from "./usePatientCardContainer";
import usePatientInformationDrawerContainer from "./usePatientInformationDrawerContainer";
import AppointmentPreviewCardContainer from "../AppointmentPreviewCardContainer";
import useAppointmentPreviewCardContainer from "./useAppointmentPreviewCardContainer";
import AppointmentNoteListCardContainer from "../../AppointmentNote/AppointmentNoteListCardContainer";
import MedicalInformationCard from "../../MedicalInformation/MedicalInformationCard";
import useMedicalInformationCard from "./useMedicalInformationCard";
import useAppointmentMarkNoShowDialog from "./useAppointmentMarkNoShowDialog";
import useAppointmentNotesContainer from "./useAppointmentNotesContainer";
import useMarkAppointmentPriorityDialog from "./useMarkAppointmentPriorityDialog";
import AppointmentMarkPriorityDialog from "../AppointmentMarkPriorityDialog";
import AppointmentMarkNoShowDialog from "../AppointmentMarkNoShowDialog";
import AppointmentPaymentListCardContainer from "../../AppointmentPayment/AppointmentPaymentListCardContainer";
import useAppointmentPaymentsContainer from "./useAppointmentPaymentsContainer";
import AppointmentBookAdditionalViewsDialogContainer from "../AppointmentBookAdditionalViewsDialogContainer";
import useAppointmentBookAdditionalViewsDialogContainer from "./useAppointmentBookAdditionalViewsDialogContainer";
import useAppointmentScreeningFormContainerDialog from "./useAppointmentScreeningFormContainerDialog";
import AppointmentScreeningFormDialog from "../../AppointmentForm/AppointmentScreeningFormDialog";
import useAppointmentAuditLogDialog from "./useAppointmentAuditLogDialog";
import AppointmentAuditLogDialog from "../../AuditLog/AppointmentAuditLogDialog";
import AppointmentReportGridContainer from "../../AppointmentReport/AppointmentReportGridContainer";
import useAppointmentReportGridContainer from "./useAppointmentReportGridContainer";
import CustomerLogInDialog from "../../Customer/CustomerLogInDialog";
import useAppointmentCustomerLogInDialog from "./useAppointmentCustomerLogInDialog";
import AppointmentCheckInDialog from "../AppointmentCheckInDialog";
import useAppointmentCheckInDialog from "./useAppointmentCheckInDialog";
import useAppointmentTechnologistNotesContainer from "./useAppointmentTechnologistNotesContainer";
import AppointmentTechnologistNoteCardContainer from "../../AppointmentTechnologistNote/AppointmentTechnologistNoteCardContainer";

export type AppointmentCardsContainerProps = {
  appointmentId: string;
};

const AppointmentCardsContainer: FC<AppointmentCardsContainerProps> = ({
  appointmentId,
}) => {
  const { isLoading, errorMessage, data, refetch } =
    useAppointmentData(appointmentId);

  const {
    handleOpen: handleOpenCancelDialog,
    props: appointmentCancelDialogProps,
  } = useAppointmentCancelDialog(data);

  const {
    handleOpen: handleOpenBookAdditionalViewsDialog,
    props: appointmentBookAdditionalViewsDialogContainerProps,
  } = useAppointmentBookAdditionalViewsDialogContainer({
    appointment: data,
    refetch,
  });

  const {
    handleOpen: handleOpenMarkNoShowDialog,
    props: appointmentMarkNoShowDialogProps,
  } = useAppointmentMarkNoShowDialog(appointmentId);

  const {
    handleOpen: handleOpenRescheduleDialog,
    props: appointmentRescheduleDialogContainerProps,
  } = useAppointmentRescheduleDialogContainer({ appointment: data, refetch });

  const {
    handleOpen: handleOpenMarkPriorityDialog,
    props: appointmentMarkPriorityDialogProps,
  } = useMarkAppointmentPriorityDialog({ appointmentId, onSuccess: refetch });
  const {
    handleOpen: handleOpenAppointmentScreeningFromDialog,
    props: appointmentScreeningFormDialogContainerProps,
  } = useAppointmentScreeningFormContainerDialog({
    appointment: data,
    isLoading,
    onSuccess: refetch,
  });

  const {
    handleOpen: handleOpenAppointmentCustomerLogInDialog,
    props: appointmentCustomerLogInDialogProps,
  } = useAppointmentCustomerLogInDialog();

  const {
    handleOpen: handleOpenAppointmentAuditLogDialog,
    props: appointmentAuditLogDialogProps,
  } = useAppointmentAuditLogDialog(appointmentId);

  const {
    handleOpen: handleOpenCheckInDialog,
    props: appointmentCheckInDialogProps,
  } = useAppointmentCheckInDialog({ onSuccess: refetch });

  const handleCheckInClick = () => {
    handleOpenCheckInDialog(appointmentId);
  };

  const appointmentActionBarProps = useAppointmentActionsBar({
    appointment: data,
    handleRescheduleClick: handleOpenRescheduleDialog,
    handleCancelClick: handleOpenCancelDialog,
    handlePriorityClick: handleOpenMarkPriorityDialog,
    handleNoShowClick: handleOpenMarkNoShowDialog,
    handleBookAdditionalViewClick: handleOpenBookAdditionalViewsDialog,
    handleViewAuditLogClick: handleOpenAppointmentAuditLogDialog,
    handleLoginClick: () => handleOpenAppointmentCustomerLogInDialog(data),
    handleCheckInClick,
    handleScreeningFormClick: handleOpenAppointmentScreeningFromDialog,
    refetch,
  });

  const appointmentFileGridContainerProps =
    useAppointmentFileGridContainer(data);

  const appointmentReportGridContainerProps = useAppointmentReportGridContainer(
    { appointment: data, refetch },
  );

  const isOtherPerson =
    data?.appointment_customer_details[0].type === "other_person";

  const customerInformationDrawerContainerProps =
    useCustomerInformationDrawerContainer({ isOtherPerson, refetch });

  const {
    isOpen: isPatientInformationDrawerContainerOpen,
    props: patientInformationDrawerContainerProps,
  } = usePatientInformationDrawerContainer();

  const appointmentPreviewCardContainerProps =
    useAppointmentPreviewCardContainer({
      appointment: data,
      refetch,
    });

  const patientCardContainerProps = usePatientCardContainer(data);

  const medicalInformationCardProps = useMedicalInformationCard(data);

  const appointmentNotesContainerProps = useAppointmentNotesContainer(data);

  const appointmentPaymentsContainerProps = useAppointmentPaymentsContainer({
    appointment: data,
    refetch,
  });

  const appointmentTechnologistNoteContainerProps =
    useAppointmentTechnologistNotesContainer(data, refetch);

  useEffect(() => {
    const updateAppointment = async () => {
      await refetch();
    };

    updateAppointment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  const isAdditionalAppointment = !!data?.is_additional_views;

  if (isLoading && !data) {
    return (
      <Box width={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  if (errorMessage) {
    return (
      <Box width={1} display="flex" justifyContent="center" alignItems="center">
        <Alert severity="error">{errorMessage}</Alert>
      </Box>
    );
  }

  return (
    <>
      <Grid container direction="column" wrap="nowrap" spacing={7}>
        <Grid item xs>
          {!!data && <AppointmentActionBar {...appointmentActionBarProps} />}
        </Grid>
        <Grid
          alignItems="start"
          item
          xs
          container
          spacing={5}
          direction="row"
          wrap="nowrap"
        >
          <Grid
            item
            container
            rowSpacing={5}
            xs={6}
            direction="column"
            wrap="nowrap"
          >
            {patientCardContainerProps && (
              <Grid item xs>
                <PatientCardContainer {...patientCardContainerProps} />
              </Grid>
            )}

            <Grid item xs>
              <AppointmentTechnologistNoteCardContainer
                {...appointmentTechnologistNoteContainerProps}
              />
            </Grid>

            {medicalInformationCardProps && (
              <Grid item xs>
                <MedicalInformationCard {...medicalInformationCardProps} />
              </Grid>
            )}
            {appointmentNotesContainerProps && (
              <Grid item xs>
                <AppointmentNoteListCardContainer
                  {...appointmentNotesContainerProps}
                />
              </Grid>
            )}
            {appointmentFileGridContainerProps && (
              <Grid item xs>
                <AppointmentFileGridContainer
                  {...appointmentFileGridContainerProps}
                />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            rowSpacing={5}
            xs
            direction="column"
            justifyContent="flex-start"
            wrap="nowrap"
          >
            {appointmentPreviewCardContainerProps && (
              <Grid item alignItems="flex-start">
                <AppointmentPreviewCardContainer
                  {...appointmentPreviewCardContainerProps}
                />
              </Grid>
            )}
            {appointmentPaymentsContainerProps && (
              <Grid item alignItems="flex-start">
                <AppointmentPaymentListCardContainer
                  {...appointmentPaymentsContainerProps}
                />
              </Grid>
            )}
            {appointmentReportGridContainerProps && (
              <Grid item alignItems="flex-start">
                <AppointmentReportGridContainer
                  {...appointmentReportGridContainerProps}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <AppointmentCancelDialog {...appointmentCancelDialogProps} />
      <AppointmentMarkPriorityDialog {...appointmentMarkPriorityDialogProps} />
      <AppointmentMarkNoShowDialog {...appointmentMarkNoShowDialogProps} />
      {!isAdditionalAppointment && (
        <AppointmentBookAdditionalViewsDialogContainer
          {...appointmentBookAdditionalViewsDialogContainerProps}
        />
      )}
      <AppointmentScreeningFormDialog
        {...appointmentScreeningFormDialogContainerProps}
      />
      <AppointmentRescheduleDialogContainer
        {...appointmentRescheduleDialogContainerProps}
      />
      {isPatientInformationDrawerContainerOpen && (
        <PatientInformationDrawerContainer
          {...patientInformationDrawerContainerProps}
        />
      )}
      <CustomerInformationDrawerContainer
        {...customerInformationDrawerContainerProps}
      />
      <CustomerLogInDialog {...appointmentCustomerLogInDialogProps} />
      <AppointmentAuditLogDialog {...appointmentAuditLogDialogProps} />
      <AppointmentCheckInDialog {...appointmentCheckInDialogProps} />
    </>
  );
};

export default AppointmentCardsContainer;
