import React, { FC } from "react";
import { useField } from "formik";
import Switch, { SwitchProps } from "../Switch";
import FormControlLabel from "../FormControlLabel";

export type FormikSwitchProps = { name: string; label: string } & SwitchProps;

const FormikSwitch: FC<FormikSwitchProps> = ({ name, label, ...props }) => {
  const [field] = useField(name);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={field.value}
          inputProps={{ "aria-label": "controlled" }}
          {...field}
          {...props}
        />
      }
      label={label}
    />
  );
};

export default FormikSwitch;
