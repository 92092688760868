import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import CircularProgress from "../../Unknown/CircularProgress";
import Typography from "../../Unknown/Typography";
import useTranslations from "./useTranslations";
import messages from "./messages";
import CenterSettingsDialog, {
  CenterSettingsDialogProps,
} from "../../Center/CenterSettingsDialog";

export type CenterOfferingArchiveDialogProps = {
  isOpen: boolean;
  name: string;
  onSubmit: () => void | Promise<void>;
  onClose: () => void;
  isLoading: boolean;
};

const CenterOfferingArchiveDialog: FC<CenterOfferingArchiveDialogProps> = ({
  isOpen,
  name,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const { title, btnCancel, btnArchive } = useTranslations();

  const actionButtons: CenterSettingsDialogProps["actionButtons"] = [
    {
      text: btnCancel,
      props: {
        onClick: onClose,
        disabled: isLoading,
      },
    },
    {
      text: btnArchive,
      props: {
        onClick: onSubmit,
        disabled: isLoading,
        startIcon: isLoading ? <CircularProgress size={20} /> : null,
        color: "error",
      },
    },
  ];

  return (
    <CenterSettingsDialog
      open={isOpen}
      title={title}
      onClose={onClose}
      actionButtons={actionButtons}
      maxWidth="sm"
    >
      <Typography sx={{ wordBreak: "break-word" }}>
        <FormattedMessage
          {...messages.description}
          values={{ name, b: (chunks) => <b>{chunks}</b> }}
        />
      </Typography>
    </CenterSettingsDialog>
  );
};

export default CenterOfferingArchiveDialog;
