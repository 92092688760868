import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "Patient.PatientCardContainer.title",
    defaultMessage: "Patient",
  },
  tooltipRequiresGuardian: {
    id: "Patient.PatientCardContainer.tooltipRequiresGuardian",
    defaultMessage: "Requires parent or guardian",
  },
  formsStatusValid: {
    id: "Patient.PatientCardContainer.formsStatusValid",
    defaultMessage: "Forms valid",
  },
  formsStatusNotPopulated: {
    id: "Patient.PatientCardContainer.formsStatusNotPopulated",
    defaultMessage: "Forms not populated",
  },
  formsStatusExpired: {
    id: "Patient.PatientCardContainer.formsStatusExpired",
    defaultMessage: "Forms expired",
  },
});
