import { FormikConfig } from "formik";
import { useCallback, useMemo } from "react";
import { MAX_STRING_LEN } from "../../../common/constants";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterContactInformationFormValues } from "../CenterContactInformationForm";
import { ContactInformation } from "../CenterSettingsContainer/types";
import getCenterContactInformationFormSchema from "./getCenterContactInformationFormSchema";
import useFormTranslations from "./useFormTranslations";
import { getValidPhoneNumber } from "../../../common/phoneNumberHelpers";

export type CenterContactInformationFormHookParams = {
  centerId: string;
  centerContactInformationData: ContactInformation;
  handlePostSubmit?: (
    values: CenterContactInformationFormValues,
  ) => Promise<void> | void;
};

type CenterContactInformationFormikConfig =
  FormikConfig<CenterContactInformationFormValues>;

type CenterContactInformationFormHookResult = {
  formikConfig: CenterContactInformationFormikConfig;
};

const useCenterContactInformationForm = (
  params: CenterContactInformationFormHookParams,
): CenterContactInformationFormHookResult => {
  const { centerId, centerContactInformationData, handlePostSubmit } = params;

  const { setAlert } = useUIContext();

  const updateCenterContactInformation = useFirebaseAppFunction(
    "updateCenterContactInformation",
  );

  const { translations, validationTranslations } = useFormTranslations({
    maxStringLength: MAX_STRING_LEN,
  });

  const { phone, fax, email } = centerContactInformationData;

  const initialValues = useMemo<
    CenterContactInformationFormikConfig["initialValues"]
  >(() => {
    return {
      phone: phone || "",
      fax: fax || "",
      email: email || "",
    };
  }, [email, fax, phone]);

  const validationSchema = useMemo(() => {
    return getCenterContactInformationFormSchema({
      translations: validationTranslations,
      countryCode: centerContactInformationData.countryCode,
    });
  }, [centerContactInformationData.countryCode, validationTranslations]);

  const onSubmit = useCallback<
    CenterContactInformationFormikConfig["onSubmit"]
  >(
    async (values) => {
      try {
        await updateCenterContactInformation({
          centerId,
          phone: getValidPhoneNumber(values.phone),
          fax: getValidPhoneNumber(values.fax),
          email: values.email,
        });
        if (handlePostSubmit) {
          await handlePostSubmit({
            email: values.email,
            phone: getValidPhoneNumber(values.phone),
            fax: getValidPhoneNumber(values.fax),
          });
        }
      } catch (e) {
        setAlert({
          message: translations.defaultError,
          isShown: true,
          severity: "error",
        });
      }
    },
    [
      centerId,
      handlePostSubmit,
      setAlert,
      translations.defaultError,
      updateCenterContactInformation,
    ],
  );

  return {
    formikConfig: {
      initialValues,
      validationSchema,
      onSubmit,
    },
  };
};

export default useCenterContactInformationForm;
