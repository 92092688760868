import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Appointment.AppointmentCancelDialog.title",
    defaultMessage: "Cancel Appointment",
  },
  description: {
    id: "Appointment.AppointmentCancelDialog.description",
    defaultMessage: "Are you sure you want to cancel this appointment?",
  },
  btnCancel: {
    id: "Appointment.AppointmentCancelDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnSubmit: {
    id: "Appointment.AppointmentCancelDialog.btnSubmit",
    defaultMessage: "Cancel appointment",
  },
  refundInfoMessage: {
    id: "Appointment.AppointmentCancelDialog.refundInfoMessage",
    defaultMessage:
      "The customer will be refunded all payments, including the deposit",
  },
  not: {
    id: "Appointment.AppointmentCancelDialog.not",
    defaultMessage: "not",
  },
  refundWarning: {
    id: "Appointment.AppointmentCancelDialog.refundWarning",
    defaultMessage:
      "The customer will {highlightedText} be refunded any payments, including the deposit.\n\nRefunds can be processed in the appointment's payments section.",
  },
});

export default messages;
