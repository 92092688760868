import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsDialog.title",
    defaultMessage: "Confirm Receipt Of Unexpected Findings",
  },
  description: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsDialog.descriptionStart",
    defaultMessage:
      "Has the patient or their legal guardian been contacted to confirm the receipt of unexpected findings in report",
  },
  btnCancel: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnSubmit: {
    id: "AppointmentReport.AppointmentReportUnexpectedFindingsDialog.btnSubmit",
    defaultMessage: "Confirm",
  },
});

export default messages;
