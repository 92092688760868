import React, { FC, useEffect } from "react";
import { useFormikContext } from "formik";
import FormikTextField from "../../Unknown/FormikTextField";
import Grid from "../../Unknown/Grid";
import MenuItem from "../../Unknown/MenuItem";
import useTranslations from "./useTranslations";
import {
  AppointmentOfferingModifyFormValues,
  LabelTranslationsKey,
  OfferingOption,
} from "./types";
import { formNames, inputSpacing, sides } from "./constants";

export type AppointmentOfferingModifyFormProps = {
  labelTranslations: Record<LabelTranslationsKey, string>;
  offerings: OfferingOption[];
};

const AppointmentOfferingModifyForm: FC<AppointmentOfferingModifyFormProps> = ({
  labelTranslations,
  offerings,
}) => {
  const translations = useTranslations();

  const { values, isSubmitting, dirty, handleChange, setFieldValue } =
    useFormikContext<AppointmentOfferingModifyFormValues>();

  useEffect(() => {
    if (!dirty) return;

    const selectedOffering = offerings.find(
      (offering) => offering.id === values.offering,
    );

    if (!selectedOffering) return;

    const currentOffering = offerings.find(
      ({ id }) => id === selectedOffering.id,
    );

    const isDualSide = currentOffering?.scanSide === "dual";

    if (!isDualSide) {
      setFieldValue(formNames.side, "");
    }

    setFieldValue(formNames.modalityName, selectedOffering.modalityName);
    setFieldValue(formNames.isDualSide, isDualSide);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerings, setFieldValue, values.offering]);

  return (
    <Grid container spacing={inputSpacing} direction="row">
      <Grid item container spacing={inputSpacing}>
        <Grid item xs>
          <FormikTextField
            required
            fullWidth
            disabled={isSubmitting}
            name={formNames.offering}
            label={labelTranslations.labelOffering}
            select
            onChange={handleChange}
          >
            {offerings.map((offering) => (
              <MenuItem key={offering.id} value={offering.id}>
                {offering.name}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item xs>
          {values.isDualSide && (
            <FormikTextField
              required
              fullWidth
              disabled={isSubmitting}
              name={formNames.side}
              label={labelTranslations.labelSide}
              select
            >
              {sides.map((side) => (
                <MenuItem key={side.id} value={side.id}>
                  {side.translationKey ? translations[side.translationKey] : ""}
                </MenuItem>
              ))}
            </FormikTextField>
          )}
        </Grid>
      </Grid>
      <Grid item container spacing={inputSpacing}>
        <Grid item xs>
          <FormikTextField
            required
            fullWidth
            disabled={isSubmitting}
            name={formNames.modalityName}
            label={labelTranslations.labelModalityName}
          />
        </Grid>
        <Grid item xs>
          <FormikTextField
            required
            fullWidth
            disabled={isSubmitting}
            name={formNames.reason}
            label={labelTranslations.labelReason}
            multiline
            maxRows={6}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AppointmentOfferingModifyForm;
