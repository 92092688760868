import React, { FC } from "react";
import Grid from "../../Unknown/Grid";
import useTranslations from "./useTranslations";
import getInformationGridItems from "./getInformationGridItems";
import InformationGridItem from "../../Unknown/InformationGridItem";

export type CustomerInformationCardProps = {
  phoneNumber?: {
    url: string;
    styled: string;
  };
  email?: string;
  patients: {
    firstName: string;
    lastName: string;
    onClick: () => void;
  }[];
};

const CustomerInformationCard: FC<CustomerInformationCardProps> = ({
  phoneNumber,
  email,
  patients,
}) => {
  const translations = useTranslations();

  const informationGridItems = getInformationGridItems({
    phoneNumber,
    email,
    patients,
    translations,
  });

  return (
    <Grid container spacing={3}>
      {informationGridItems.map((informationGridItem) => (
        <Grid item xs={6} key={informationGridItem.id}>
          <InformationGridItem item={informationGridItem} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CustomerInformationCard;
