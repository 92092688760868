import { useMemo, useState } from "react";
import { getTimezoneOffsetMillis } from "../../../common/dateHelpers";
import { useAppContext } from "../../Unknown/AppContext";
import { AppointmentBookAdditionalViewsDialogContainerProps } from "../AppointmentBookAdditionalViewsDialogContainer";
import calcMinBeforeBookingSeconds from "./calcMinBeforeBookingSeconds";
import getDateLimits from "./getDateLimits";
import { Appointment } from "./types";
import calcAdditionalViewsDurationMinutes from "../AppointmentBookAdditionalViewsDialogContainer/calcAdditionalViewsDurationMinutes";

const useAppointmentBookAdditionalViewsDialogContainer = (params: {
  appointment: Appointment | null;
  refetch: () => Promise<void>;
}): {
  handleOpen: () => void;
  props: AppointmentBookAdditionalViewsDialogContainerProps;
} => {
  const { appointment, refetch } = params;

  const { systemConfig } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const center = useMemo(() => {
    if (!appointment) return null;

    const timezoneOffsetMillis = getTimezoneOffsetMillis(
      appointment.center.timezone,
    );

    const centerConfig = appointment.center.center_configs?.[0];

    const minTimeBeforeBookingSeconds = calcMinBeforeBookingSeconds({
      systemConfig,
      centerConfig,
    });

    const dateLimits = getDateLimits({
      timezoneOffsetMillis,
      minTimeBeforeBookingSeconds,
    });

    const operatingHours = appointment.center.center_operating_hours.reduce(
      (acc, day) => {
        const { day_of_week_number, from_minutes_at, to_minutes_at } = day;

        return {
          ...acc,
          [day_of_week_number]: {
            fromMinutes: from_minutes_at,
            toMinutes: to_minutes_at,
          },
        };
      },
      {},
    );

    return {
      id: appointment.center.id,
      dateLimits,
      operatingHours,
      timezoneOffsetMillis,
    };
  }, [appointment, systemConfig]);

  const offerings = useMemo(() => {
    const singleOfferings =
      appointment?.appointment_offerings.filter(
        ({ is_additional_views_required }) => !!is_additional_views_required,
      ) || [];

    const packageOfferings =
      appointment?.appointment_packages
        ?.map(({ appointment_offerings }) => appointment_offerings)
        .flat()
        .filter(
          ({ is_additional_views_required }) => !!is_additional_views_required,
        ) || [];

    return [...singleOfferings, ...packageOfferings];
  }, [appointment?.appointment_offerings, appointment?.appointment_packages]);

  const appointmentProp = useMemo(() => {
    if (!appointment) return null;

    return {
      id: appointment.id,
      durationMinutes: calcAdditionalViewsDurationMinutes(offerings || []),
      offerings,
    };
  }, [appointment, offerings]);

  return {
    handleOpen,
    props: {
      isOpen,
      center,
      appointment: appointmentProp,
      handleClose,
      handleChangeAppointmentStartedAt: refetch,
    },
  };
};

export default useAppointmentBookAdditionalViewsDialogContainer;
