import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, spacing }) => {
  return {
    root: {
      color: "black",
      border: "none",
      "&.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
        {
          borderBottomColor: palette.grey[100],
        },
      "& .MuiDataGrid-cell": {
        padding: spacing(3),
        "&:focus": {
          outline: "none",
        },
        "&:focus-within": {
          outline: "none",
        },
      },
      "& .MuiDataGrid-columnHeader": {
        padding: spacing(3),
        "&:focus": {
          outline: "none",
        },
        "&:focus-within": {
          outline: "none",
        },
      },
      "& .MuiDataGrid-row": {
        backgroundColor: palette.common.white,
        "&:hover": {
          backgroundColor: palette.common.white,
        },
      },
      "& .MuiDataGrid-footerContainer": {
        borderTopColor: "transparent",
      },
    },
    hiddenSeparators: {
      "& .MuiDataGrid-columnSeparator": {
        display: "none",
      },
    },
    shownSeparators: {
      "& .MuiDataGrid-columnSeparator": {
        visibility: "visible",
      },
    },
  };
});

export default useStyles;
