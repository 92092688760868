import { defineMessages } from "react-intl";

const messages = defineMessages({
  titleAppointments: {
    id: "Patient.PatientMainCards.titleAppointments",
    defaultMessage: "Appointments",
  },
  titleInformation: {
    id: "Patient.PatientMainCards.titleInformation",
    defaultMessage: "Information",
  },
  titleForms: {
    id: "Patient.PatientMainCards.titleForms",
    defaultMessage: "Forms",
  },
  titleAuditLogs: {
    id: "Patient.PatientMainCards.titleAuditLogs",
    defaultMessage: "Audit Log",
  },
  viewAuditLogsBtn: {
    id: "Patient.PatientMainCards.viewAuditLogsBtn",
    defaultMessage: "View audit log",
  },
  hideAuditLogsBtn: {
    id: "Patient.PatientMainCards.hideAuditLogsBtn",
    defaultMessage: "Hide audit log",
  },
});

export default messages;
