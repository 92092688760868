import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { Appointment } from "../AppointmentCardsContainer/types";

interface CheckIsCanBeMarkedAsPriorityParams {
  appointment: Appointment | null;
  role?: DatabaseEnum["center_user_role"];
}

const checkIsCanBeMarkedAsPriority = ({
  appointment,
  role,
}: CheckIsCanBeMarkedAsPriorityParams): boolean => {
  if (!appointment || appointment.has_priority) return false;

  const { status } = appointment;

  switch (role) {
    case "super_admin":
    case "regional_admin":
      return (
        ["studies_unread"].includes(status) &&
        !appointment.has_priority &&
        !appointment.is_stat
      );
    case "receptionist":
      return (
        ["checked_in", "studies_unread"].includes(status) &&
        !appointment.has_priority &&
        !appointment.is_stat
      );
    case "technologist":
      return (
        ["studies_unread", "checked_in"].includes(status) &&
        !appointment.has_priority &&
        !appointment.is_stat
      );
    default:
      return false;
  }
};

export default checkIsCanBeMarkedAsPriority;
