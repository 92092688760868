import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Customer.CustomerLogInDialog.title",
    defaultMessage: "Log In Customer",
  },
  description: {
    id: "Customer.CustomerLogInDialog.description",
    defaultMessage: "The login authentication code for",
  },
  is: {
    id: "Customer.CustomerLogInDialog.is",
    defaultMessage: "is:",
  },
  btnClose: {
    id: "Customer.CustomerLogInDialog.btnClose",
    defaultMessage: "Close",
  },
});

export default messages;
