import {
  GridColDef,
  GridValueFormatterParams,
} from "../../../Unknown/DataGrid";
import { Package } from "../../../Center/CenterSettingsContainer/types";

type Translations = {
  headerName: string;
  privateTrue: string;
  privateFalse: string;
};

type ColumnValue = boolean;
type ColumnFormattedValue = string;

const getIsPrivateColumn = (params: { translations: Translations }) => {
  const { translations } = params;

  const { headerName, privateTrue, privateFalse } = translations;

  const result: GridColDef<Package, ColumnValue, ColumnFormattedValue> = {
    field: "isPrivate",
    headerName,
    flex: 1,
    sortable: true,
    disableColumnMenu: true,
    valueGetter: (getterParams) => {
      return getterParams.row.visibility === "private";
    },
    valueFormatter: (formatterParams: GridValueFormatterParams<boolean>) => {
      const { value } = formatterParams;

      return value ? privateTrue : privateFalse;
    },
  };

  return result;
};
export default getIsPrivateColumn;
