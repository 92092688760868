import React from "react";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import List from "../../Unknown/List";
import ListItem from "../../Unknown/ListItem";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";

interface AuditLogItemFieldsEditedBlockPops {
  changedFields: string[];
}

const AuditLogItemFieldsEditedBlock = ({
  changedFields,
}: AuditLogItemFieldsEditedBlockPops) => {
  const { list, listItem } = useStyles();
  const { fieldsEdited } = useTranslations();
  return (
    <Grid container flexDirection="column">
      <Typography variant="body2" lineHeight={1.43} letterSpacing={0.17}>
        {fieldsEdited}
      </Typography>
      <List sx={list}>
        {changedFields.map((changedField) => {
          return (
            <ListItem key={changedField} sx={listItem}>
              <Typography
                variant="body2"
                lineHeight={1.43}
                letterSpacing={0.17}
              >
                {changedField}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};

export default AuditLogItemFieldsEditedBlock;
