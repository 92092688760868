import React from "react";
import { capitalCase } from "change-case";
import { GridColDef } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { AppointmentColumnItemAddOns, AppointmentRowItem } from "../types";

export type ColumnTranslations = {
  columnAddOns: string;
};

const getColumnAddOns = (params: {
  columnItem: AppointmentColumnItemAddOns;
  translations: ColumnTranslations;
}) => {
  const { columnItem, translations } = params;

  const { field } = columnItem;

  const column: GridColDef<AppointmentRowItem, string> = {
    field,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnAddOns,
    valueGetter: (getterParams) => {
      const { addOns } = getterParams.row;

      const formattedAddOns = addOns.map((addOn) => {
        const { name, cost } = addOn;

        return `${capitalCase(name).replace(/\s+/g, "")}^${cost}`;
      });

      return formattedAddOns.join(";");
    },
    renderCell: ({ value }) => {
      return (
        <Typography variant="body2" style={{ wordBreak: "break-all" }}>
          {value}
        </Typography>
      );
    },
  };

  return column;
};

export default getColumnAddOns;
