import { GridColDef } from "../../../Unknown/DataGrid";
import calcSumByKey from "../../../../common/calcSumByKey";
import { Package } from "../../../Center/CenterSettingsContainer/types";

type Translations = {
  headerName: string;
};

type ColumnValue = number;
type ColumnFormattedValue = string;

const getPriceColumn = (params: {
  translations: Translations;
  formatNumber: (num: number) => string;
}) => {
  const { translations, formatNumber } = params;

  const { headerName } = translations;

  const result: GridColDef<Package, ColumnValue, ColumnFormattedValue> = {
    field: "price",
    headerName,
    flex: 1,
    sortable: true,
    disableColumnMenu: true,
    valueGetter: (getterParams) => {
      const { offerings, price } = getterParams.row;

      return price ?? calcSumByKey(offerings, "price");
    },
    valueFormatter: (formatterParams) => {
      const { value } = formatterParams;

      const formattedPrice = formatNumber(value);

      return formattedPrice;
    },
  };

  return result;
};
export default getPriceColumn;
