import { GridColDef } from "../../../Unknown/DataGrid";
import { Package } from "../../../Center/CenterSettingsContainer/types";

type Translations = {
  headerName: string;
};

const getNameColumn = (params: { translations: Translations }) => {
  const { translations } = params;

  const { headerName } = translations;

  const result: GridColDef<Package> = {
    field: "name",
    headerName,
    flex: 1,
    sortable: true,
    disableColumnMenu: true,
  };

  return result;
};
export default getNameColumn;
