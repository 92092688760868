import React, { FC, MutableRefObject, useMemo } from "react";
import FullCalendar, {
  DatesSetArg,
  EventClickArg,
  EventSourceInput,
} from "../../Unknown/Calendar";
import dayGridPlugin from "../../Unknown/Calendar/dayGrid";
import timeGridPlugin from "../../Unknown/Calendar/timeGrid";
import interactionPlugin, {
  DateClickArg,
} from "../../Unknown/Calendar/interaction";
import TimeSlotCalendarGridItem from "../TimeSlotCalendarGridItem";
import TimeSlotCalendarGridDayHeader from "../TimeSlotCalendarGridDayHeader";
import TimeSlotCalendarGridSlotLabel from "../TimeSlotCalendarGridSlotLabel";
import {
  convertFullCalendarViewToView,
  convertViewToFullCalendar,
  DateViewChangeParams,
  FullCalendarView,
  View,
  views,
} from "./helpers";
import useEventClassNamesFunc from "./useEventClassNamesFunc";
import useStyles from "./useStyles";
import "./styles.css";

export type TimeSlotCalendarGridProps = {
  events: EventSourceInput;
  calendarRef?: MutableRefObject<FullCalendar | null>;
  initialDate?: Date | string;
  initialView?: View;
  onDateViewChange?: (params: DateViewChangeParams) => void | Promise<void>;
  loading?: (isLoading: boolean) => void;
  eventClick?: (arg: EventClickArg) => void;
};

const slotDuration = { minutes: 15 };
const slotLabelInterval = { minutes: 60 };

const TimeSlotCalendarGrid: FC<TimeSlotCalendarGridProps> = ({
  events,
  calendarRef,
  initialDate,
  initialView,
  onDateViewChange,
  loading,
  eventClick,
}) => {
  const { dayCellRoot, moreLinkRoot } = useStyles();
  const getEventClassNamesFunc = useEventClassNamesFunc();

  const handleDateViewChange = async (arg: DatesSetArg) => {
    if (!onDateViewChange) return;

    const { start, end, view } = arg;

    await onDateViewChange({
      start,
      end,
      view: convertFullCalendarViewToView(view.type as FullCalendarView),
    });
  };

  const onDateClick = async (arg: DateClickArg) => {
    const { view, date } = arg;

    if (view.type !== views.month && view.type !== views.week) return;

    const api = calendarRef?.current?.getApi();
    if (!api) return;

    api.changeView(views.day, date);
  };

  const fullCalendarInitialView = useMemo(
    () => convertViewToFullCalendar(initialView),
    [initialView],
  );

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      headerToolbar={false}
      allDaySlot={false}
      eventShortHeight={60}
      slotEventOverlap={false}
      slotDuration={slotDuration}
      slotLabelInterval={slotLabelInterval}
      eventContent={(arg) => <TimeSlotCalendarGridItem {...arg} />}
      dayHeaderContent={(arg) => <TimeSlotCalendarGridDayHeader {...arg} />}
      slotLabelContent={(arg) => <TimeSlotCalendarGridSlotLabel {...arg} />}
      eventClassNames={getEventClassNamesFunc}
      events={events}
      initialView={fullCalendarInitialView}
      initialDate={initialDate}
      datesSet={handleDateViewChange}
      showNonCurrentDates={false}
      fixedWeekCount={false}
      loading={loading}
      moreLinkClick="timeGridDay"
      views={{ dayGridMonth: { dayMaxEventRows: 3, contentHeight: "auto" } }}
      dayCellClassNames={dayCellRoot}
      eventClick={eventClick}
      moreLinkClassNames={moreLinkRoot}
      height="auto"
      dateClick={onDateClick}
    />
  );
};

export default TimeSlotCalendarGrid;
