import { Theme, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, spacing }) => {
  const borderWidth = spacing(0.1);
  const borderStyle = "solid";
  const borderColor = alpha(palette.common.black, 0.23);

  return {
    activeOptionRoot: {
      backgroundColor: alpha(palette.common.black, 0.04),
      borderTopWidth: borderWidth,
      borderTopStyle: borderStyle,
      borderTopColor: borderColor,
      borderBottomWidth: borderWidth,
      borderBottomStyle: borderStyle,
      borderBottomColor: borderColor,
    },
  };
});

export default useStyles;
