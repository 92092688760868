import Big from "big.js";

const calcSumByKey = <
  K extends keyof T,
  T extends Record<K, number> & Record<string, unknown>,
>(
  array: T[],
  key: K,
) => {
  return array.reduce((sum, item) => sum.plus(item[key]), Big(0)).toNumber();
};

export default calcSumByKey;
