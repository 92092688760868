export const getLocaleCurrencySymbol = (currency: string, locale: string) => {
  const formatted = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  const currencyPart = formatted
    .formatToParts(1)
    .find((x) => x.type === "currency");

  return currencyPart?.value || "";
};
