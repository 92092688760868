import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, spacing }) => {
  return {
    childrenRoot: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: spacing(6),
      backgroundColor: palette.background.default,
    },
    layoutRoot: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      margin: 0,
      padding: 0,
    },
  };
});

export default useStyles;
