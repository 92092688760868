const useStyles = () => {
  return {
    list: {
      listStyle: "decimal",
      pl: 4,
      pt: 4,
      pb: 0,
    },
    listItem: {
      display: "list-item",
      p: 0,
    },
  };
};

export default useStyles;
