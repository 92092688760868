import React, { FC, useMemo } from "react";
import Box from "../../Unknown/Box";
import ButtonGroup from "../../Unknown/ButtonGroup";
import Button from "../../Unknown/Button";
import useTranslations from "./useTranslations";
import useStyles from "./useStyles";
import { View } from "../TimeSlotCalendarGrid/helpers";
import TimeSlotCalendarDateSwitcher from "../TimeSlotCalendarDateSwitcher";
import CircularProgress from "../../Unknown/CircularProgress";
import { AddOutlinedIcon } from "../../Unknown/Icons";

type TimeSlotCalendarHeaderProps = {
  onPrevClick: () => void | Promise<void>;
  onNextClick: () => void | Promise<void>;
  onViewChange: (view: View) => void | Promise<void>;
  onAddBlockClick: () => void;
  start?: Date;
  end?: Date;
  selectedView?: View;
  isDisabled?: boolean;
};

const TimeSlotCalendarHeader: FC<TimeSlotCalendarHeaderProps> = ({
  onPrevClick,
  onNextClick,
  onViewChange,
  onAddBlockClick,
  start,
  end,
  selectedView,
  isDisabled,
}) => {
  const { pressedRoot } = useStyles();

  const translations = useTranslations();

  const viewButtons = useMemo(() => {
    const result: Array<{ id: View; buttonText: string }> = [
      { id: "day", buttonText: translations.btnDay },
      { id: "week", buttonText: translations.btnWeek },
      { id: "month", buttonText: translations.btnMonth },
    ];

    return result;
  }, [translations.btnDay, translations.btnMonth, translations.btnWeek]);

  if (!start || !end || !selectedView) {
    return (
      <Box width={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="space-between" color="transparent">
      <Box flexGrow={1}>
        <TimeSlotCalendarDateSwitcher
          start={start}
          end={end}
          view={selectedView}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          isDisabled={isDisabled}
        />
      </Box>
      <Box display="flex">
        <ButtonGroup variant="contained" color="secondary">
          {viewButtons.map((btn) => (
            <Button
              key={btn.id}
              onClick={() => onViewChange(btn.id)}
              className={selectedView === btn.id ? pressedRoot : ""}
              disabled={isDisabled}
            >
              {btn.buttonText}
            </Button>
          ))}
        </ButtonGroup>
        <Box ml={3}>
          <Button
            variant="contained"
            onClick={onAddBlockClick}
            startIcon={<AddOutlinedIcon />}
          >
            {translations.btnBlockTime}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeSlotCalendarHeader;
