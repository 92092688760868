import React, { FC } from "react";
import { useParams } from "react-router-dom";
import {
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "../../Unknown/DataGrid";
import DateRangePicker, {
  DateRangePickerProps,
} from "../../Unknown/DateRangePicker";
import Box from "../../Unknown/Box";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";

interface AppointmentTableTopBarProps {
  dateRangePickerProps?: DateRangePickerProps<Date>;
}

const AppointmentTableTopBar: FC<AppointmentTableTopBarProps> = ({
  dateRangePickerProps,
}) => {
  const { role } = useParams();
  const {
    toolbarRoot,
    datePickerTextFieldRoot,
    datePickerTextFieldInput,
    datePickerFieldSeparatorRoot,
  } = useStyles();
  const { searchPlaceholder, fromDateLabel, toDateLabel } = useTranslations();

  switch (role) {
    case "regional_admin":
    case "super_admin": {
      return (
        <Box
          display="flex"
          justifyContent="space-between"
          className={toolbarRoot}
        >
          <Box p={2} display="flex" justifyContent="flex-start">
            <GridToolbarColumnsButton color="primary" variant="text" />
            <GridToolbarFilterButton />
            <GridToolbarExport />
          </Box>
          <Box
            className={toolbarRoot}
            p={2}
            display="flex"
            justifyContent="flex-end"
          >
            {dateRangePickerProps && (
              <DateRangePicker
                slotProps={{
                  textField: {
                    size: "small",
                    classes: {
                      root: datePickerTextFieldRoot,
                    },
                    InputProps: {
                      classes: {
                        input: datePickerTextFieldInput,
                      },
                    },
                  },
                  fieldSeparator: {
                    classes: {
                      root: datePickerFieldSeparatorRoot,
                    },
                  },
                }}
                localeText={{
                  start: fromDateLabel,
                  end: toDateLabel,
                }}
                {...dateRangePickerProps}
              />
            )}
          </Box>
        </Box>
      );
    }
    default: {
      return (
        <Box
          className={toolbarRoot}
          p={2}
          display="flex"
          justifyContent="flex-end"
        >
          <GridToolbarQuickFilter placeholder={searchPlaceholder} />
        </Box>
      );
    }
  }
};

export default AppointmentTableTopBar;
