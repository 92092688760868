import {
  DatabaseEnum,
  DatabaseRow,
} from "@Shape-Digital/kudzu-data/lib/types/common";
import getUniqueStrings from "../../../common/getUniqueStrings";
import { SupabaseCenterUser } from "./getSupabaseCenterUserData";
import getUserPermissions from "./getUserPermissions";

export type DatabaseUserData = {
  primaryRole: DatabaseRow<"center_user_roles">;
  roles: DatabaseRow<"center_user_roles">[];
  permissions: DatabaseEnum["center_user_role"][];
  centerIds: string[];
  config: {
    last_used_role: DatabaseEnum["center_user_role"];
  };
  firstName: string;
  lastName: string;
};

const convertSupabaseDataToContextUser = (
  supabaseCenterUser: SupabaseCenterUser,
): DatabaseUserData => {
  const { center_user_roles, center_user_configs } = supabaseCenterUser;

  const primaryRole =
    center_user_roles.find(({ is_primary }) => is_primary) ||
    center_user_roles[0];

  const permissions = getUserPermissions(center_user_roles);

  const centerIds = getUniqueStrings(
    supabaseCenterUser.center_user_roles.map(({ center_id }) => center_id),
  );

  return {
    primaryRole,
    roles: center_user_roles,
    permissions,
    centerIds,
    config: {
      last_used_role: center_user_configs[0].last_used_role,
    },
    firstName: supabaseCenterUser.first_name,
    lastName: supabaseCenterUser.last_name,
  };
};

export default convertSupabaseDataToContextUser;
