import { useField } from "formik";
import React, { FC } from "react";
import Autocomplete from "../../Unknown/Autocomplete";
import TextField, { TextFieldProps } from "../../Unknown/TextField";
import { OfferingOption } from "./types";
import Checkbox from "../../Unknown/Checkbox";

export type CenterPackageOfferingSelectFieldProps = {
  offerings: OfferingOption[];
  textFieldProps: TextFieldProps;
  onChange?: (value: OfferingOption[]) => void;
};

const CenterPackageOfferingSelectField: FC<
  CenterPackageOfferingSelectFieldProps
> = ({ offerings, textFieldProps, onChange }) => {
  const [field, meta, helpers] = useField<OfferingOption[]>("offerings");

  const { value } = field;
  const { error, touched } = meta;
  const { setValue, setTouched } = helpers;

  return (
    <Autocomplete
      value={value}
      multiple
      disableCloseOnSelect
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      onChange={(event, newValue) => {
        setValue(newValue);

        if (!onChange) return;

        onChange(newValue);
      }}
      onBlur={() => setTouched(true)}
      options={offerings}
      openOnFocus
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option.name}
        </li>
      )}
      renderTags={(selectedValues) => {
        return selectedValues
          .map((selectedValue) => selectedValue.name)
          .join(", ");
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...textFieldProps}
            error={touched && Boolean(error)}
            helperText={touched && error}
          />
        );
      }}
    />
  );
};

export default CenterPackageOfferingSelectField;
