import React, { FC } from "react";
import { SearchPatient } from "../../Unknown/AppContext/getSearchPatient";
import DataGrid from "../../Unknown/DataGrid";
import useColumns from "./useColumns";
import useRows from "./useRows";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";

export type PatientsTableProps = {
  patients: SearchPatient[];
};

const PatientsTable: FC<PatientsTableProps> = ({ patients }) => {
  const { root } = useStyles();
  const { columnTranslations } = useTranslations();

  const rows = useRows(patients);
  const columns = useColumns({ translations: columnTranslations });

  return (
    <DataGrid
      autoHeight
      getRowHeight={() => "auto"}
      rows={rows}
      columns={columns}
      hideFooter
      disableRowSelectionOnClick
      className={root}
      hideFooterRowCount
      initialState={{
        sorting: {
          sortModel: [{ field: "name", sort: "asc" }],
        },
      }}
      sortingOrder={["desc", "asc"]}
    />
  );
};

export default PatientsTable;
