import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import {
  AppointmentOfferingColumnItemNotes,
  AppointmentOfferingRowItem,
} from "../types";
import Tooltip from "../../../Unknown/Tooltip";

type ColumnTranslations = {
  columnNotes: string;
};

const maxLength = 50;

const getColumnDateTime = (params: {
  columnItem: AppointmentOfferingColumnItemNotes;
  translations: ColumnTranslations;
  classNames: {
    popper: string;
  };
}): GridColDef<AppointmentOfferingRowItem, string> => {
  const { columnItem, translations, classNames } = params;

  const { field } = columnItem;

  const { columnNotes } = translations;

  return {
    field,
    disableColumnMenu: true,
    headerName: columnNotes,
    flex: 1,
    getApplyQuickFilterFn: () => null,

    renderCell: ({ value }) => {
      if (!value) return null;

      const isEllipsisShown = value.length > maxLength;

      const formattedValue = isEllipsisShown
        ? `${value.slice(0, maxLength)}...`
        : value;

      return (
        <Tooltip
          title={value}
          placement="bottom-start"
          classes={{ tooltip: classNames.popper }}
        >
          <Typography variant="body2" whiteSpace="pre-line">
            {formattedValue}
          </Typography>
        </Tooltip>
      );
    },
  };
};

export default getColumnDateTime;
