import React, { FC } from "react";
import Typography from "../../Unknown/Typography";
import Box from "../../Unknown/Box";
import useTranslations, {
  CenterServicesAppointmentPreviewTranslations,
} from "./useTranslations";

export interface CenterService {
  name: string;
  side?: string;
  cost?: number;
}

type CenterServicesAppointmentPreviewProps = {
  centerServices: Array<CenterService>;
};

const sides: Record<
  string,
  keyof CenterServicesAppointmentPreviewTranslations
> = {
  left: "leftSide",
  right: "rightSide",
};

export const normalizeService = ({
  centerService,
  translations,
}: {
  centerService: CenterService;
  translations: CenterServicesAppointmentPreviewTranslations;
}) => {
  const { name, side } = centerService;

  const sideTranslationKey = side ? sides[side] : "";
  const sideTranslation = sideTranslationKey
    ? ` (${translations[sideTranslationKey]})`
    : "";

  return `${name} ${sideTranslation}`;
};

const CenterServicesAppointmentPreview: FC<
  CenterServicesAppointmentPreviewProps
> = ({ centerServices }) => {
  const translations = useTranslations();
  return (
    <Box display="flex" flexDirection="column">
      {centerServices.map((centerService) => {
        const text = normalizeService({ translations, centerService });

        return (
          <Typography key={text} variant="body2" color="inherit">
            {text}
          </Typography>
        );
      })}
    </Box>
  );
};

export default CenterServicesAppointmentPreview;
