import { defineMessages } from "react-intl";

const messages = defineMessages({
  leftSide: {
    id: "CenterService.CenterServicesAppointmentPreview.leftSide",
    defaultMessage: "Left",
  },
  rightSide: {
    id: "CenterService.CenterServicesAppointmentPreview.rightSide",
    defaultMessage: "Right",
  },
});

export default messages;
