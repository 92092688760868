import {
  DatabaseEnum,
  DatabaseRow,
} from "@Shape-Digital/kudzu-data/lib/types/common";
import getUniqueStrings from "../../../common/getUniqueStrings";

const adminRoles: DatabaseEnum["center_user_role"][] = [
  "super_admin",
  "regional_admin",
];

const checkIsAdmin = (role: DatabaseEnum["center_user_role"]) => {
  return adminRoles.includes(role);
};

const otherPermissions: DatabaseEnum["center_user_role"][] = [
  "receptionist",
  "technologist",
  "radiologist",
];
const getUserPermissions = (
  roles: DatabaseRow<"center_user_roles">[],
): DatabaseEnum["center_user_role"][] => {
  const userPermissions = roles.map(({ role }) => role);

  if (userPermissions.some(checkIsAdmin)) {
    return getUniqueStrings([...userPermissions, ...otherPermissions]);
  }

  return getUniqueStrings(userPermissions);
};

export default getUserPermissions;
