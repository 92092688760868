import React, { FC } from "react";
import { useFormikContext } from "formik";
import { Grid } from "@mui/material";
import FormikTextField from "../../Unknown/FormikTextField";
import FormikDesktopDatePicker from "../../Unknown/FormikDesktopDatePicker";
import FormikTimePicker from "../../Unknown/FormikTimePicker";

export type TimeSlotAddFormTranslations = {
  eventName: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
};

export type TimeSlotAddFormValues = {
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;
};

export type TimeSlotAddFormProps = {
  translations: TimeSlotAddFormTranslations;
};

const inputsSpacing = 3;

const defaultTextFieldProps = {
  fullWidth: true,
  required: true,
  autoComplete: "off",
};

const TimeSlotAddForm: FC<TimeSlotAddFormProps> = ({ translations }) => {
  const { isSubmitting } = useFormikContext<TimeSlotAddFormValues>();

  const minDate = new Date();

  return (
    <Grid container spacing={inputsSpacing}>
      <Grid item container spacing={inputsSpacing}>
        <Grid item xs>
          <FormikTextField
            name="name"
            label={translations.eventName}
            fullWidth
            disabled={isSubmitting}
            required
          />
        </Grid>
      </Grid>
      <Grid item container spacing={inputsSpacing}>
        <Grid item xs>
          <FormikDesktopDatePicker
            name="startDate"
            minDate={minDate}
            textFieldProps={{
              label: translations.startDate,
              disabled: isSubmitting,
              ...defaultTextFieldProps,
            }}
          />
        </Grid>
        <Grid item xs>
          <FormikDesktopDatePicker
            name="endDate"
            minDate={minDate}
            textFieldProps={{
              label: translations.endDate,
              disabled: isSubmitting,
              ...defaultTextFieldProps,
            }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={inputsSpacing}>
        <Grid item xs>
          <FormikTimePicker
            name="startTime"
            textFieldProps={{
              label: translations.startTime,
              disabled: isSubmitting,
              ...defaultTextFieldProps,
            }}
          />
        </Grid>
        <Grid item xs>
          <FormikTimePicker
            name="endTime"
            textFieldProps={{
              label: translations.endTime,
              disabled: isSubmitting,
              ...defaultTextFieldProps,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TimeSlotAddForm;
