import React, { useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CenterAddOnsSettingsTab, {
  CenterAddOnsSettingsTabProps,
} from "../../AddOn/CenterAddOnsSettingsTab";
import CenterOfferingsSettingsTab, {
  CenterOfferingsSettingsTabProps,
} from "../../Offering/CenterOfferingsSettingsTab";
import CenterPackagesSettingsTab, {
  CenterPackagesSettingsTabProps,
} from "../../Package/CenterPackageSettingsTab";
import CenterInformationSettingsTab, {
  CenterInformationSettingsTabProps,
} from "../CenterInformationSettingsTab";
import useTranslations from "./useTranslations";
import CenterRegisterTab, {
  CenterRegisterTabProps,
} from "../CenterRegisterTab";
import getOptionalArrayItem from "../../../common/getOptionalArrayItem";
import { AlertParams } from "../../Unknown/UIContext";

const tabIndexes: Record<string, number> = {
  information: 0,
  offerings: 1,
  packages: 2,
  addons: 3,
  register: 4,
};

const tabNumbers: Record<number, string> = {
  0: "information",
  1: "offerings",
  2: "packages",
  3: "addons",
  4: "register",
};

const DEFAULT_TAB_PATH = tabNumbers[0];

export type TabsParams = {
  centerInformationSettingsTabProps?: CenterInformationSettingsTabProps;
  centerOfferingsSettingsTabProps?: CenterOfferingsSettingsTabProps;
  centerPackagesSettingsTabProps?: CenterPackagesSettingsTabProps;
  centerAddOnsSettingsTabProps?: CenterAddOnsSettingsTabProps;
  centerRegisterTabProps?: CenterRegisterTabProps;
  setNotification: React.Dispatch<React.SetStateAction<AlertParams | null>>;
};

const useTabs = (params: TabsParams) => {
  const {
    centerInformationSettingsTabProps,
    centerOfferingsSettingsTabProps,
    centerPackagesSettingsTabProps,
    centerAddOnsSettingsTabProps,
    centerRegisterTabProps,
    setNotification,
  } = params;

  const { tabAddOns, tabCenterInfo, tabOfferings, tabPackages, tabRegister } =
    useTranslations();

  const navigate = useNavigate();
  const { role, settingsTab } = useParams();

  useEffect(() => {
    if (!settingsTab) {
      navigate(`/${role}/admin/${tabNumbers[0]}`);
    }
  }, [navigate, role, settingsTab]);

  const [tabIndex, setTabIndex] = useState(
    tabIndexes[settingsTab || DEFAULT_TAB_PATH],
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setNotification(null);
    navigate(`/${role}/admin/${tabNumbers[newValue]}`);
  };

  const tabs = useMemo(
    () => [
      {
        id: tabNumbers[0],
        label: tabCenterInfo,
        component: () =>
          centerInformationSettingsTabProps ? (
            <CenterInformationSettingsTab
              {...centerInformationSettingsTabProps}
            />
          ) : null,
      },
      {
        id: tabNumbers[1],
        label: tabOfferings,
        component: () =>
          centerOfferingsSettingsTabProps ? (
            <CenterOfferingsSettingsTab {...centerOfferingsSettingsTabProps} />
          ) : null,
      },
      {
        id: tabNumbers[2],
        label: tabPackages,
        component: () =>
          centerPackagesSettingsTabProps ? (
            <CenterPackagesSettingsTab {...centerPackagesSettingsTabProps} />
          ) : null,
      },
      {
        id: tabNumbers[3],
        label: tabAddOns,
        component: () =>
          centerAddOnsSettingsTabProps ? (
            <CenterAddOnsSettingsTab {...centerAddOnsSettingsTabProps} />
          ) : null,
      },
      ...getOptionalArrayItem(!!centerRegisterTabProps, {
        id: tabNumbers[4],
        label: tabRegister,
        component: () =>
          centerRegisterTabProps ? (
            <CenterRegisterTab
              {...centerRegisterTabProps}
              onRegisterSuccess={() => {
                centerRegisterTabProps.onRegisterSuccess();
                setTabIndex(0);
                setNotification(null);
                navigate(`/${role}/admin/${tabNumbers[0]}`);
              }}
            />
          ) : null,
      }),
    ],
    [
      centerAddOnsSettingsTabProps,
      centerInformationSettingsTabProps,
      centerOfferingsSettingsTabProps,
      centerPackagesSettingsTabProps,
      centerRegisterTabProps,
      navigate,
      role,
      setNotification,
      tabAddOns,
      tabCenterInfo,
      tabOfferings,
      tabPackages,
      tabRegister,
    ],
  );

  return { tabs, tabIndex, handleTabChange };
};

export default useTabs;
