import { defineMessages } from "react-intl";

const messages = defineMessages({
  errorPatientNotFound: {
    id: "Patient.PatientInformationDrawerContainer.errorPatientNotFound",
    defaultMessage: "Patient not found",
  },
  btnPatientEdit: {
    id: "Patient.PatientInformationDrawerContainer.btnPatientEdit",
    defaultMessage: "Edit",
  },
  errorCustomerNotFound: {
    id: "Patient.PatientInformationDrawerContainer.errorCustomerNotFound",
    defaultMessage: "Customer not found",
  },
  btnPatientCancel: {
    id: "Patient.PatientInformationDrawerContainer.btnPatientCancel",
    defaultMessage: "Cancel",
  },
  btnPatientSave: {
    id: "Patient.PatientInformationDrawerContainer.btnPatientSave",
    defaultMessage: "Save",
  },
  labelFirstName: {
    id: "Patient.PatientInformationDrawerContainer.labelFirstName",
    defaultMessage: "First name",
  },
  labelLastName: {
    id: "Patient.PatientInformationDrawerContainer.labelLastName",
    defaultMessage: "Last name",
  },
  labelBirthDate: {
    id: "Patient.PatientInformationDrawerContainer.labelBirthDate",
    defaultMessage: "Date of birth",
  },
  labelPhoneNumber: {
    id: "Patient.PatientInformationDrawerContainer.labelPhoneNumber",
    defaultMessage: "Phone",
  },
  labelEmail: {
    id: "Patient.PatientInformationDrawerContainer.labelEmail",
    defaultMessage: "Email",
  },
  labelRace: {
    id: "Patient.PatientInformationDrawerContainer.labelRace",
    defaultMessage: "Race",
  },
  labelSex: {
    id: "Patient.PatientInformationDrawerContainer.labelSex",
    defaultMessage: "Sex",
  },
  labelConfirmationCode: {
    id: "Patient.PatientInformationDrawerContainer.labelConfirmationCode",
    defaultMessage: "Confirmation code",
  },
  labelPrimaryAddress: {
    id: "Patient.PatientInformationDrawerContainer.labelPrimaryAddress",
    defaultMessage: "Address line 1",
  },
  labelSecondaryAddress: {
    id: "Patient.PatientInformationDrawerContainer.labelSecondaryAddress",
    defaultMessage: "Address line 2",
  },
  labelCity: {
    id: "Patient.PatientInformationDrawerContainer.labelCity",
    defaultMessage: "City",
  },
  labelPostalCode: {
    id: "Patient.PatientInformationDrawerContainer.labelPostalCode",
    defaultMessage: "ZIP",
  },
  labelStateIsoCode: {
    id: "Patient.PatientInformationDrawerContainer.labelStateIsoCode",
    defaultMessage: "State",
  },
  labelCountryIsoCode: {
    id: "Patient.PatientInformationDrawerContainer.labelCountryIsoCode",
    defaultMessage: "Country",
  },
  errorEmailInvalid: {
    id: "Patient.PatientInformationDrawerContainer.errorEmailInvalid",
    defaultMessage: "Email is invalid",
  },
  errorConfirmationCodeIsInvalid: {
    id: "Patient.PatientInformationDrawerContainer.errorConfirmationCodeIsInvalid",
    defaultMessage: "Confirmation code is invalid",
  },
  errorDateInvalid: {
    id: "Patient.PatientInformationDrawerContainer.errorDateInvalid",
    defaultMessage: "Date is invalid",
  },
  errorDateMin: {
    id: "Patient.PatientInformationDrawerContainer.errorDateMin",
    defaultMessage: "Minimum date is {value}",
  },
  errorDateMax: {
    id: "Patient.PatientInformationDrawerContainer.errorDateMax",
    defaultMessage: "Maximum date is {value}",
  },
  errorEmailInUse: {
    id: "Patient.PatientInformationDrawerContainer.errorEmailInUse",
    defaultMessage:
      "This email address cannot be used as it is already in use by another customer or patient",
  },
});

export default messages;
