import { GridColDef } from "../../../Unknown/DataGrid";
import { getStateByCodeAndCountry } from "../../../../common/regionHelpers";
import {
  AppointmentColumnItemPatientAddress,
  AppointmentRowItem,
} from "../types";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnPatientAddressTranslations = {
  columnPatientAddress: string;
};

const getColumnPatientAddress = (params: {
  columnItem: AppointmentColumnItemPatientAddress;
  translations: ColumnPatientAddressTranslations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnPatientAddress,
    valueGetter: (getterParams) => {
      const { patient } = getterParams.row;

      if (!patient) return "";

      const { state, country, postalCode } = patient;

      let stateName;

      if (country && state) {
        stateName = getStateByCodeAndCountry({
          countryCode: country,
          stateCode: state,
        })?.name;
      }

      const cellContent = [stateName, postalCode].filter(Boolean).join(" ");

      return cellContent;
    },
  };
};

export default getColumnPatientAddress;
