import { UpdateCenterOperatingHoursParams } from "@Shape-Digital/kudzu-data/lib/types/actions";
import differenceInMinutes from "date-fns/differenceInMinutes";
import startOfDay from "date-fns/startOfDay";
import subMilliseconds from "date-fns/subMilliseconds";
import objectKeys from "../../../common/objectKeys";
import { CenterOperatingHoursFormValues } from "../CenterOperatingHoursForm";

const getDayMinutesUTC = (date: Date, offsetMillis: number) => {
  const startOfDate = startOfDay(date);

  const UTCDate = subMilliseconds(date, offsetMillis);

  return differenceInMinutes(UTCDate, startOfDate);
};

type Keys = keyof UpdateCenterOperatingHoursParams["operatingHours"];
type DayValue = UpdateCenterOperatingHoursParams["operatingHours"][Keys];

const normalizeFormikValues = (
  values: CenterOperatingHoursFormValues,
  centerTimezoneOffsetMillis: number,
): UpdateCenterOperatingHoursParams["operatingHours"] => {
  const { operatingHours } = values;

  const result = objectKeys(operatingHours).reduce<
    UpdateCenterOperatingHoursParams["operatingHours"]
  >((acc, dayKey): UpdateCenterOperatingHoursParams["operatingHours"] => {
    const { isOpen, fromMinutesAt, toMinutesAt } = operatingHours[dayKey];

    if (!isOpen || !fromMinutesAt || !toMinutesAt) return acc;

    const fromMinutesAtUTC = getDayMinutesUTC(
      fromMinutesAt,
      centerTimezoneOffsetMillis,
    );

    const toMinutesAtUTC = getDayMinutesUTC(
      toMinutesAt,
      centerTimezoneOffsetMillis,
    );

    const dayValue: DayValue = { fromMinutesAtUTC, toMinutesAtUTC };

    return { ...acc, [dayKey]: dayValue };
  }, {});

  return result;
};

export default normalizeFormikValues;
