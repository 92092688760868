import { useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import parseISO from "date-fns/parseISO";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import { AppointmentRowItem } from "../AppointmentTableGrid/types";
import {
  isTimeZonePast,
  convertDatabaseDateToTimezone,
} from "../../../common/dateHelpers";
import {
  AppointmentPackageOffering,
  DashboardAppointment,
} from "../AppointmentTableContainer/getAppointments";
import useSystemConfig, { SystemConfig } from "../../../hooks/useSystemConfig";
import { DEFAULT_LOCALE } from "../../../common/constants";
import getFormattedPrice from "../../../common/getFormattedPrice";
import calcTotalPrice from "../../../common/calcTotalPrice";

const normalizeAppointment = ({
  appointment,
  systemConfig,
  intl,
  handleOpenCheckInDialog,
  handleOpenLoginDialog,
}: {
  appointment: DashboardAppointment;
  systemConfig: SystemConfig;
  intl: IntlShape;
  handleOpenCheckInDialog: (appointmentId: string) => void;
  handleOpenLoginDialog: (appointment: DashboardAppointment) => void;
}): AppointmentRowItem => {
  const {
    appointment_offerings,
    appointment_packages,
    appointment_add_ons,
    time_slot,
    center,
    status,
    appointment_patient_details,
    appointment_customer_details,
    appointment_payments,
  } = appointment;
  const { center_configs } = center;

  const centerConfig = center_configs?.[0];
  const appointmentPatientDetails = appointment_patient_details?.[0];
  const appointmentCustomerDetails = appointment_customer_details?.[0];

  const patient = appointmentPatientDetails?.patient;

  const startedAt = parseISO(time_slot.started_at);

  const currencyCode =
    centerConfig?.default_currency_code || systemConfig.default_currency_code;

  const isPast = isTimeZonePast({
    date: startedAt,
    timezone: center.timezone,
  });

  const { dateUTC: startedAtUTC, dateTimezone: startedAtCenterTimezone } =
    convertDatabaseDateToTimezone(time_slot.started_at, center.timezone);

  const { dateUTC: createdAtUTC, dateTimezone: createdAtCenterTimezone } =
    convertDatabaseDateToTimezone(appointment.created_at, center.timezone);

  const offerings = appointment_offerings.map((offering) => {
    return {
      name: offering.center_offering.name,
      side: offering.side || undefined,
      cost: offering.total_cost_amount,
    };
  });

  const packages = appointment_packages.map((packageItem) => {
    const packageOfferings = packageItem.appointment_offerings.map(
      (offering) => {
        return {
          name: offering.center_offering.name,
        };
      },
    );

    return {
      name: packageItem.center_package.name,
      cost: packageItem.total_cost_amount,
      offerings: packageOfferings,
    };
  });
  const totalPrice = calcTotalPrice({
    totalCostAmount: appointment.total_cost_amount,
    discountAmount: appointment.discount_amount,
  });

  const addOns = appointment_add_ons.map((addOn) => {
    return {
      name: addOn.center_add_on.name,
      cost: addOn.total_cost_amount,
    };
  });

  const locale = center.region || DEFAULT_LOCALE;

  const payments = appointment_payments.map((payment) => {
    const { dateTimezone: confirmedAtCenterTimezone } =
      convertDatabaseDateToTimezone(appointment.created_at, center.timezone);

    const confirmedAtDate =
      confirmedAtCenterTimezone.toLocaleDateString(locale);

    const confirmedAtTime = confirmedAtCenterTimezone
      .toLocaleTimeString(locale, {
        hour: "numeric",
        minute: "numeric",
      })
      .toLocaleLowerCase()
      .replace(/\s/, "");

    const timeZoneAbbr = formatInTimeZone(
      startedAtCenterTimezone,
      center.timezone || "UTC",
      "zzz",
    );

    const formattedAmount =
      typeof payment.amount === "number"
        ? getFormattedPrice({
            value: payment.amount,
            intl,
            currencyCode,
          })
        : "";

    return {
      method:
        payment.center_payment_provider?.payment_provider?.type ||
        payment.center_payment_method.payment_method?.type ||
        "",
      transactionId: payment.transaction_id,
      confirmedAt: `${confirmedAtDate} — ${confirmedAtTime} (${timeZoneAbbr})`,
      amount: formattedAmount,
    };
  });

  const packagesOfferings = appointment_packages.reduce(
    (acc, appointmentPackage) => {
      return [...acc, ...appointmentPackage.appointment_offerings];
    },
    [] as AppointmentPackageOffering[],
  );

  const hasUnexpectedFindings = [
    ...packagesOfferings,
    ...appointment.appointment_offerings,
  ].some(({ has_unexpected_findings }) => has_unexpected_findings);

  const isUnexpectedFindingsReceiptConfirmed =
    hasUnexpectedFindings &&
    [...packagesOfferings, ...appointment.appointment_offerings].every(
      ({ has_unexpected_findings, is_receipt_confirmed }) =>
        !has_unexpected_findings ||
        (has_unexpected_findings && is_receipt_confirmed),
    );

  const handleLogInClick = () => {
    handleOpenLoginDialog(appointment);
  };

  const handleCheckInClick = () => {
    handleOpenCheckInDialog(appointment.id);
  };

  const patientAddressLine1 =
    patient?.patient_addresses?.find((address) => address?.type === "primary")
      ?.address || null;

  const patientAddressLine2 =
    patient?.patient_addresses?.find((address) => address?.type === "secondary")
      ?.address || null;

  return {
    id: appointment.id,
    status,
    createdAtUTC,
    createdAtCenterTimezone,
    startedAtUTC,
    startedAtCenterTimezone,
    isPast,
    totalPrice,
    hasPriority: !!appointment.has_priority,
    isStat: !!appointment.is_stat,
    isAdditionalViews: !!appointment.is_additional_views,
    hasUnexpectedFindings,
    isUnexpectedFindingsReceiptConfirmed,
    appointmentCustomer: {
      firstName: appointmentCustomerDetails?.first_name || "",
      lastName: appointmentCustomerDetails?.last_name || "",
      customerType: appointmentCustomerDetails?.type || "",
    },
    appointmentPatient: {
      firstName: appointmentPatientDetails?.first_name || "",
      lastName: appointmentPatientDetails?.last_name || "",
      dateOfBirth: appointmentPatientDetails?.date_birth
        ? parseISO(appointmentPatientDetails.date_birth)
        : null,
      phoneNumber: appointmentPatientDetails?.mobile_phone_number || "",
      email: appointmentPatientDetails?.email || "",
    },
    patient: {
      mrn: patient?.mrn || "",
      state: patient?.state || undefined,
      city: patient?.city || undefined,
      country: patient?.country || undefined,
      postalCode: patient?.postal_code || undefined,
      addressLine1: patientAddressLine1,
      addressLine2: patientAddressLine2,
      race: patient?.race || "",
      sex: patient?.sex || "",
    },
    center: {
      name: center.name,
      locale: center.region,
      timezone: center.timezone,
      currencyCode,
      state: center.state,
      country: center.country,
      postalCode: center.postal_code,
    },
    offerings,
    packages,
    addOns,
    payments,
    handleLogInClick,
    handleCheckInClick,
  };
};

const useRows = ({
  appointments,
  handleOpenCheckInDialog,
  handleOpenLoginDialog,
}: {
  appointments: DashboardAppointment[];
  handleOpenCheckInDialog: (appointmentId: string) => void;
  handleOpenLoginDialog: (appointment: DashboardAppointment) => void;
}) => {
  const { dataState } = useSystemConfig();
  const intl = useIntl();
  const { status, data: systemConfig } = dataState;

  const data = useMemo<AppointmentRowItem[]>(() => {
    if (status !== "success") return [];

    const rows = appointments.map((appointment) =>
      normalizeAppointment({
        appointment,
        systemConfig,
        intl,
        handleOpenLoginDialog,
        handleOpenCheckInDialog,
      }),
    );

    return rows;
  }, [
    appointments,
    intl,
    status,
    systemConfig,
    handleOpenLoginDialog,
    handleOpenCheckInDialog,
  ]);

  return data;
};

export default useRows;
