import { useCallback, useState } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterOfferingFormValues } from "../CenterOfferingForm/types";
import { CenterOfferingFormikProps } from "../CenterOfferingFormik";
import useTranslations from "./useTranslations";

type AddFormHookParams = {
  isOpened: boolean;
  onClose: () => void;
  centerId: string;
  minPrice: number;
  currencyCode: string;
  refetchCenterData?: () => void | Promise<void>;
};

type AddFormHookResult = {
  addFormikProps: CenterOfferingFormikProps;
};

const initialValues: CenterOfferingFormValues = {
  name: "",
  modalityName: "",
  description: "",
  price: "",
  depositAmount: "",
  durationMinutes: "",
  visibility: "",
  scanSide: "",
};

const useAddForm = (params: AddFormHookParams): AddFormHookResult => {
  const {
    isOpened,
    onClose,
    centerId,
    minPrice,
    currencyCode,
    refetchCenterData,
  } = params;

  const addCenterOffering = useFirebaseAppFunction("addCenterOffering");

  const { addModalTitle, defaultError } = useTranslations();

  const { setAlert } = useUIContext();

  const [centerOfferingData, setCenterOfferingData] =
    useState<CenterOfferingFormValues>(initialValues);

  const onModalClose = useCallback(() => {
    setCenterOfferingData(initialValues);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(
    async (values: CenterOfferingFormValues) => {
      try {
        if (!centerOfferingData) throw new Error(defaultError);

        const {
          name,
          modalityName,
          description,
          scanSide,
          visibility,
          durationMinutes,
          price,
          depositAmount,
        } = values;

        if (
          scanSide === "" ||
          visibility === "" ||
          durationMinutes === "" ||
          price === "" ||
          depositAmount === ""
        ) {
          throw new Error(defaultError);
        }

        await addCenterOffering({
          centerId,
          name,
          modalityName,
          description,
          scanSide,
          visibility,
          durationMinutes,
          price,
          depositAmount,
        });

        if (refetchCenterData) await refetchCenterData();

        onModalClose();
      } catch (error) {
        setAlert({
          message: defaultError,
          isShown: true,
          severity: "error",
        });
      }
    },
    [
      addCenterOffering,
      centerId,
      centerOfferingData,
      defaultError,
      onModalClose,
      refetchCenterData,
      setAlert,
    ],
  );

  return {
    addFormikProps: {
      title: addModalTitle,
      isOpened,
      onModalClose,
      initialValues,
      handleSubmit,
      currencyCode,
      minPrice,
    },
  };
};

export default useAddForm;
