import { defineMessages } from "react-intl";

const messages = defineMessages({
  submit: {
    id: "Stripe.StripePaymentElement.submit",
    defaultMessage: "Submit",
  },
});

export default messages;
