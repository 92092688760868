import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useClickAway } from "react-use";
import { useAppContext } from "../AppContext";
import Button from "../Button";
import NavBarSearchInput, {
  NavBarSearchInputProps,
} from "../NavBarSearchInput";
import useElementPosition from "../../../hooks/useElementPosition";
import useTranslations from "./useTranslations";

export type NavBarSearchButtonProps = {
  title: string;
  boxPosition: {
    offsetLeft: number;
    offsetWidth: number;
    offsetHeight: number;
  };
  IconComponent: ReactNode | JSX.Element;
  className?: string;
};

const NavBarSearchButton: FC<NavBarSearchButtonProps> = ({
  title,
  boxPosition,
  IconComponent,
  className,
}) => {
  const { tooltipText } = useTranslations();
  const { patientsSearch } = useAppContext();
  const { searchValue, setSearchValue } = patientsSearch;
  const location = useLocation();

  const [buttonRef, buttonPosition] = useElementPosition<HTMLButtonElement>();
  const inputRef = useRef<HTMLElement | null>(null);
  const inputBoxRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(Boolean(searchValue.length));

  const onNavBarSearchInputOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen) return () => null;

    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  const onNavBarSearchInputClose = useCallback(() => {
    if (!searchValue) {
      setIsOpen(false);
      inputRef.current?.blur();
    }
  }, [searchValue]);

  useEffect(() => {
    if (!location.pathname.includes("/search")) {
      onNavBarSearchInputClose();
    }
  }, [location, onNavBarSearchInputClose]);

  useClickAway(inputBoxRef, onNavBarSearchInputClose);

  const position: NavBarSearchInputProps["position"] = {
    startOffsetLeft: buttonPosition.offsetLeft + buttonPosition.offsetWidth / 2,
    openedOffsetLeft: boxPosition.offsetLeft,
    openedWidth: boxPosition.offsetWidth,
    openedOffsetHeight: boxPosition.offsetHeight,
  };

  return (
    <>
      <Button
        startIcon={IconComponent}
        key={title}
        className={className}
        onClick={onNavBarSearchInputOpen}
        ref={buttonRef}
      >
        {title}
      </Button>
      <NavBarSearchInput
        position={position}
        inputRef={inputRef}
        inputBoxRef={inputBoxRef}
        isOpen={isOpen}
        value={searchValue}
        setValue={setSearchValue}
        tooltipText={tooltipText}
      />
    </>
  );
};

export default NavBarSearchButton;
