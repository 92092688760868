import { useCallback, useEffect, useState } from "react";
import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import useSupabase from "../../../hooks/useSupabase";
import { useUIContext } from "../UIContext";
import useTranslations from "./useTranslations";
import getSupabaseCenterUserData from "./getSupabaseCenterUserData";
import convertSupabaseDataToContextUser, {
  DatabaseUserData,
} from "./convertSupabaseDataToContextUser";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import checkCenterUserConfig from "./checkCenterUserConfig";
import { AuthUserData } from "../../Auth/AuthContext";

const useDatabaseUserData = (authUserData: AuthUserData) => {
  const supabase = useSupabase();
  const translations = useTranslations();
  const updateLastUsedRole = useFirebaseAppFunction("updateLastUsedRole");

  const { setAlert } = useUIContext();

  const [databaseUserData, setDatabaseUserData] =
    useState<DatabaseUserData | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadUserRoles = async () => {
      try {
        setIsLoading(true);

        const supabaseCenterUser = await getSupabaseCenterUserData({
          supabase,
          userId: authUserData.sub,
        });

        const createCenterUserConfigFunc = async () => {
          const primaryRole = supabaseCenterUser.center_user_roles.find(
            ({ is_primary }) => is_primary,
          );

          const { data } = await updateLastUsedRole({
            role:
              primaryRole?.role ||
              supabaseCenterUser.center_user_roles[0]?.role,
          });

          return data.updatedCenterUserConfig;
        };

        const updatedSupabaseCenterUser = await checkCenterUserConfig({
          supabaseCenterUser,
          createCenterUserConfigFunc,
        });

        const data = convertSupabaseDataToContextUser(
          updatedSupabaseCenterUser,
        );

        setDatabaseUserData(data);
      } catch (error) {
        setAlert({
          isShown: true,
          message: translations.defaultError,
          severity: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadUserRoles();
  }, [
    supabase,
    authUserData.sub,
    setAlert,
    translations.defaultError,
    updateLastUsedRole,
  ]);

  const updateRole = useCallback(
    async (role: DatabaseEnum["center_user_role"]) => {
      try {
        setIsLoading(true);

        const updatedConfig = await updateLastUsedRole({ role });

        setDatabaseUserData((prev) => {
          if (!prev) return prev;

          return {
            ...prev,
            config: {
              ...prev,
              last_used_role:
                updatedConfig.data.updatedCenterUserConfig.last_used_role,
            },
          };
        });
      } catch (error) {
        setAlert({
          isShown: true,
          message: translations.defaultError,
          severity: "error",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [setAlert, translations.defaultError, updateLastUsedRole],
  );

  return { databaseUserData, isLoading, updateLastUsedRole: updateRole };
};

export default useDatabaseUserData;
