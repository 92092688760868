import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemTotal, AppointmentRowItem } from "../types";

export type ColumnAppointmentTotalTranslations = {
  columnAppointmentTotal: string;
};

const getColumnAppointmentTotal = (params: {
  columnItem: AppointmentColumnItemTotal;
  translations: ColumnAppointmentTotalTranslations;
}): GridColDef<AppointmentRowItem, number> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;

  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    headerName: translations.columnAppointmentTotal,
    valueGetter: ({ row }) => {
      return row.totalPrice || 0;
    },
  };
};

export default getColumnAppointmentTotal;
