import { FormikConfig } from "formik";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { FormValues } from "../AppointmentPaymentAddForm/types";
import useValidationSchema from "./useValidationSchema";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useTranslations from "./useTranslations";

type FormikProps = FormikConfig<FormValues>;

type InitialValues = FormikConfig<FormValues>["initialValues"];
type SubmitHandler = FormikConfig<FormValues>["onSubmit"];

const useFormikProps = (params: {
  remainingBalance?: number;
  onPaymentIntentCreated: (clientSecret: string) => void;
  setErrorMessage: (value: string | null) => void;
  handleAddPayment: () => void;
  handleClose: () => void;
}): FormikProps => {
  const {
    remainingBalance,
    setErrorMessage,
    handleAddPayment,
    handleClose,
    onPaymentIntentCreated,
  } = params;

  const { appointmentId } = useParams();

  const { translations } = useTranslations();

  const payAppointmentRemainingBalance = useFirebaseAppFunction(
    "payAppointmentRemainingBalance",
  );

  const initialValues = useMemo<InitialValues>(() => {
    return {
      paymentMethodId: "",
      centerPaymentMethodId: "",
      amount: remainingBalance || 0,
    };
  }, [remainingBalance]);

  const validationSchema = useValidationSchema({ remainingBalance });

  const onSubmit: SubmitHandler = async (values, { resetForm }) => {
    try {
      const { paymentMethodId, centerPaymentMethodId, amount } = values;

      if (!appointmentId) return;

      const {
        data: { status, clientSecret },
      } = await payAppointmentRemainingBalance({
        paymentMethodId,
        centerPaymentMethodId,
        amount,
        appointmentId,
      });

      switch (status) {
        case "succeeded":
          handleAddPayment();
          handleClose();
          resetForm();
          break;
        case "payment_intent_created":
          if (!clientSecret) {
            setErrorMessage(translations.paymentFailed);
            break;
          }
          onPaymentIntentCreated(clientSecret);
          break;
        case "failed":
          setErrorMessage(translations.paymentFailed);
          break;
        case "timeout_exceeded":
          setErrorMessage(translations.timeoutExceeded);
          break;
        default:
          setErrorMessage(translations.defaultError);
      }
    } catch (error) {
      const message =
        error instanceof Error ? error.message : translations.defaultError;
      setErrorMessage(message);
    }
  };

  return {
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useFormikProps;
