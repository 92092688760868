import Big from "big.js";
import { OfferingOption } from "../CenterPackageOfferingSelectField/types";
import { Offering } from "./types";

const getOfferingsPrice = (params: {
  offerings: Offering[];
  selectedOptions: OfferingOption[];
}) => {
  const { offerings, selectedOptions } = params;

  const priceBig = offerings.reduce((acc, offering) => {
    const { id, price } = offering;

    const isOfferingSelected = selectedOptions.some(
      (option) => option.id === id,
    );

    return isOfferingSelected ? acc.plus(price) : acc;
  }, Big(0));

  return priceBig.toNumber();
};

export default getOfferingsPrice;
