import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { acceptTypes, maxFilesCount, maxFileSizeBytes } from "./constants";

const useFiles = () => {
  const [files, setFiles] = useState<File[]>([]);

  const onDropAccepted = useCallback((newFiles: File[]) => {
    setFiles((prev) => {
      const filesCountAccepted = Math.max(0, maxFilesCount - prev.length);
      const filteredFiles = newFiles.slice(0, filesCountAccepted);

      return [...prev, ...filteredFiles];
    });
  }, []);

  const clearFiles = useCallback(() => {
    setFiles([]);
  }, []);

  const { getInputProps, getRootProps } = useDropzone({
    accept: acceptTypes,
    maxFiles: maxFilesCount,
    maxSize: maxFileSizeBytes,
    onDropAccepted,
  });

  return { files, getInputProps, getRootProps, clearFiles };
};

export default useFiles;
