import supabaseClient from "../../../common/supabaseClient";
import { centerOfferingsRequest } from "./constants";
import { CenterOffering } from "./types";

const getCenterOfferingsData = async (params: {
  centerId: string;
  price: number;
  durationMinutes: number;
}) => {
  const { centerId, price, durationMinutes } = params;

  const orFilter = [
    `duration_minutes.eq.${durationMinutes}`,
    `duration_minutes.is.null`,
  ].join(",");

  const { data, error } = await supabaseClient
    .from<CenterOffering>("center_offerings")
    .select(centerOfferingsRequest)
    .eq("center_id", centerId)
    .eq("price", price)
    .eq("visibility", "public")
    .is("center_package_id", null)
    .or(orFilter);

  if (error) throw new Error(error.message);

  return data;
};

export default getCenterOfferingsData;
