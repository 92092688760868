import React, { FC } from "react";
import DataGrid from "../../Unknown/DataGrid";
import { PatientFormPreviewGridForm } from "./types";
import useColumns from "./useColumns";
import useStyles from "./useStyles";

export interface PatientFormsPreviewGridProps {
  forms: PatientFormPreviewGridForm[];
}

const PAGE_SIZE = 10;

const PatientFormsPreviewGrid: FC<PatientFormsPreviewGridProps> = ({
  forms,
}) => {
  const { root } = useStyles();
  const columns = useColumns();

  const isFooterShown = forms.length > PAGE_SIZE;

  return (
    <DataGrid
      autoHeight
      getRowHeight={() => "auto"}
      rows={forms}
      columns={columns}
      className={root}
      sx={{
        "& .MuiDataGrid-footerContainer": {
          display: isFooterShown ? undefined : "none",
        },
      }}
    />
  );
};

export default PatientFormsPreviewGrid;
