import { useState } from "react";
import { AppointmentPaymentAddDialogContainerProps } from "../AppointmentPaymentAddDialogContainer";
import { PaymentOptionsData } from "../AppointmentPaymentAddForm";
import { Appointment } from "./types";

const useAppointmentPaymentAddDialogContainer = (params: {
  currencyCode: string;
  centerLocale: string;
  appointment: Appointment | null;
  paymentOptionsData: PaymentOptionsData;
  handleAddPayment: () => void | Promise<void>;
}): {
  handleOpen: () => void;
  props: AppointmentPaymentAddDialogContainerProps;
} => {
  const { handleAddPayment, appointment, currencyCode, paymentOptionsData } =
    params;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return {
    handleOpen,
    props: {
      isOpen,
      appointment,
      currencyCode,
      paymentOptionsData,
      handleClose,
      handleAddPayment,
    },
  };
};

export default useAppointmentPaymentAddDialogContainer;
