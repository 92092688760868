import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Center.CenterDiscountFormik.title",
    defaultMessage: "Edit Discount For Multiple Purchases",
  },
  minimumDiscountError: {
    id: "Center.CenterDiscountFormik.minimumDiscountError",
    defaultMessage: "Must be positive or 0",
  },
  maximumDiscountError: {
    id: "Center.CenterDiscountFormik.maximumDiscountError",
    defaultMessage:
      "Setting discount to this amount will cause one or more offerings to be free",
  },
});

export default messages;
