import { lighten, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    panel: {
      "& .MuiDataGrid-columnsPanelRow .MuiFormControlLabel-root.Mui-disabled": {
        display: "none",
      },
    },
    root: {
      border: "none",
      color: "black",
      "& .MuiDataGrid-cell": {
        padding: 16,
        "&:focus": {
          outline: "none",
        },
        "&:focus-within": {
          outline: "none",
        },
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "visible",
      },
      "& .MuiDataGrid-columnHeader": {
        padding: 16,
        "&:focus": {
          outline: "none",
        },
        "&:focus-within": {
          outline: "none",
        },
        "&:last-child": {
          "& .MuiDataGrid-columnSeparator--sideRight": {
            display: "none",
          },
        },
      },
      "& .MuiDataGrid-columnHeader--sorted .MuiBadge-badge": {
        display: "none",
      },
      "& .row--general": {
        backgroundColor: palette.common.white,
        "&:hover": {
          backgroundColor: palette.common.white,
        },
      },
      "& .row--past": {
        backgroundColor: lighten(palette.common.black, 0.92),
        "&:hover": {
          backgroundColor: lighten(palette.common.black, 0.92),
        },
      },
    },
    hideRightSeparatorRoot: {
      "& .MuiDataGrid-columnSeparator--sideRight": {
        display: "none",
      },
    },
    hideHeaderTextRoot: {
      "& .MuiDataGrid-columnHeaderTitle": {
        display: "none",
      },
    },
  };
});

export default useStyles;
