import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentOfferingColumnItemOffering,
  AppointmentOfferingRowItem,
} from "../types";
import Typography from "../../../Unknown/Typography";

type ColumnValue = string;

export type ColumnTranslations = {
  columnOffering: string;
  sideLeft: string;
  sideRight: string;
};

const getSideTranslationKey = (
  side?: string,
): keyof ColumnTranslations | null => {
  switch (side) {
    case "left":
      return "sideLeft";
    case "right":
      return "sideRight";
    default:
      return null;
  }
};

const getColumnOffering = (params: {
  columnItem: AppointmentOfferingColumnItemOffering;
  translations: ColumnTranslations;
}): GridColDef<AppointmentOfferingRowItem, ColumnValue> => {
  const { columnItem, translations } = params;

  return {
    field: columnItem.field,
    disableColumnMenu: true,
    flex: 1,
    headerName: translations.columnOffering,
    getApplyQuickFilterFn: () => null,
    renderCell: (renderParams) => {
      if (!renderParams.row) return null;

      const { name, side } = renderParams.row;

      const sideTranslationKey = getSideTranslationKey(side);

      const text = sideTranslationKey
        ? `${name} (${translations[sideTranslationKey]})`
        : name;

      return <Typography variant="body2">{text}</Typography>;
    },
  };
};

export default getColumnOffering;
