import { useState } from "react";
import { InitialAppointmentFile } from "./types";

const useAppointmentFileAddDialog = (params: {
  appointmentId: string;
  handleAddFiles: (newFiles: InitialAppointmentFile[]) => void | Promise<void>;
}) => {
  const { appointmentId, handleAddFiles } = params;
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const props = {
    isOpen,
    handleClose,
    appointmentId,
    handleAddFiles,
  };

  return {
    handleOpen,
    props,
  };
};

export default useAppointmentFileAddDialog;
