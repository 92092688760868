import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { DashboardAppointmentOffering } from "../AppointmentOfferingTableContainer/getAppointmentOfferings";

const getReportDeliveryDeadlineHours = ({
  appointmentOffering,
  systemConfig,
}: {
  appointmentOffering: DashboardAppointmentOffering;
  systemConfig: DatabaseRow<"system_configs">;
}): number => {
  const {
    appointment: { center, is_stat, has_priority },
  } = appointmentOffering;

  const centerConfig = center.center_configs[0];

  if (is_stat) {
    return (
      centerConfig.stat_report_deadline_hours ||
      systemConfig.stat_report_deadline_hours ||
      0
    );
  }

  if (has_priority) {
    return (
      centerConfig.priority_report_deadline_hours ||
      systemConfig.priority_report_deadline_hours ||
      0
    );
  }

  return (
    centerConfig.report_delivery_deadline_hours ||
    systemConfig.report_delivery_deadline_hours ||
    0
  );
};

export default getReportDeliveryDeadlineHours;
