import React, { FC, useMemo, useState } from "react";
import CenterSettingsContainer from "../CenterSettingsContainer";
import { useAppContext } from "../../Unknown/AppContext";
import Box from "../../Unknown/Box";
import { AlertParams } from "../../Unknown/UIContext";
import Alert from "../../Unknown/Alert";
import AlertTitle from "../../Unknown/AlertTitle";

const CenterSettingsScreen: FC = () => {
  const [notification, setNotification] = useState<AlertParams | null>(null);
  const { databaseUserData } = useAppContext();

  const centerId = useMemo(() => {
    return databaseUserData.primaryRole.center_id;
  }, [databaseUserData.primaryRole.center_id]);

  const handleClose = () => {
    setNotification(null);
  };

  return (
    <>
      {notification && (
        <Alert
          sx={{ mb: 7 }}
          severity={notification.severity}
          onClose={handleClose}
        >
          <AlertTitle>{notification.message}</AlertTitle>
          {notification.description}
        </Alert>
      )}
      <Box bgcolor="common.white">
        <CenterSettingsContainer
          setNotification={setNotification}
          centerId={centerId}
        />
      </Box>
    </>
  );
};

export default CenterSettingsScreen;
