import { format } from "date-fns";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemPatientDOB, AppointmentRowItem } from "../types";

export type ColumnPatientTranslations = {
  columnPatientDOB: string;
};

const getColumnPatientDOB = (params: {
  columnItem: AppointmentColumnItemPatientDOB;
  translations: ColumnPatientTranslations;
}): GridColDef<AppointmentRowItem, Date | null> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;

  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    type: "date",
    headerAlign: "left",
    align: "left",
    headerName: translations.columnPatientDOB,
    valueGetter: ({ row }) => {
      const { appointmentPatient } = row;

      if (!appointmentPatient) return null;

      const { dateOfBirth } = appointmentPatient;

      return dateOfBirth;
    },
    renderCell: ({ row }) => {
      const { appointmentPatient } = row;

      if (!appointmentPatient) return null;

      const { dateOfBirth } = appointmentPatient;

      if (!dateOfBirth) return null;

      const formattedDate = format(dateOfBirth, "MM/dd/yyyy");

      return formattedDate;
    },
  };
};

export default getColumnPatientDOB;
