import { intervalToDuration } from "date-fns";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemPatientAge, AppointmentRowItem } from "../types";

export type ColumnPatientTranslations = {
  columnPatientAge: string;
};

const now = new Date();

const getColumnPatientAge = (params: {
  columnItem: AppointmentColumnItemPatientAge;
  translations: ColumnPatientTranslations;
}): GridColDef<AppointmentRowItem, number | null> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;

  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: 200,
    type: "number",
    headerAlign: "left",
    align: "left",
    headerName: translations.columnPatientAge,
    valueGetter: ({ row }) => {
      const { appointmentPatient } = row;

      if (!appointmentPatient) return null;

      const { dateOfBirth } = appointmentPatient;

      if (!dateOfBirth) return null;

      const { years } = intervalToDuration({
        start: dateOfBirth,
        end: now,
      });

      return years || null;
    },
  };
};

export default getColumnPatientAge;
