import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, spacing }) => {
  return {
    root: {
      color: "black",
      border: "none",
      "& .MuiDataGrid-cell": {
        padding: spacing(3),
        "&:focus": {
          outline: "none",
        },
        "&:focus-within": {
          outline: "none",
        },
      },
      "& .MuiDataGrid-columnSeparator": {
        visibility: "visible",
      },
      "& .MuiDataGrid-columnHeader": {
        padding: spacing(3),
        "&:focus": {
          outline: "none",
        },
        "&:focus-within": {
          outline: "none",
        },
        "&:last-child": {
          "& .MuiDataGrid-columnSeparator--sideRight": {
            display: "none",
          },
        },
      },
      "& .MuiDataGrid-row": {
        backgroundColor: palette.common.white,
        "&:hover": {
          backgroundColor: palette.common.white,
        },
      },
    },
  };
});

export default useStyles;
