import { useCallback, useState } from "react";
import { useAppContext } from "../../Unknown/AppContext";
import { useUIContext } from "../../Unknown/UIContext";
import { AppointmentNote } from "../AppointmentNoteListCard/types";
import { Center, Offering } from "./types";
import useTranslations from "./useTranslations";

const useNotes = (params: {
  initialNotes: AppointmentNote[];
  offerings: Offering[];
  center: Center | null;
}) => {
  const { databaseUserData } = useAppContext();
  const { initialNotes, offerings, center } = params;

  const { translations } = useTranslations();
  const { defaultError } = translations;

  const { setAlert } = useUIContext();

  const [notes, setNotes] = useState(initialNotes);

  const handleAddNote = useCallback(
    (addParams: { id: string; description: string; offeringId?: string }) => {
      try {
        if (!center) return;

        const { id, description, offeringId } = addParams;

        const appointmentOffering = offeringId
          ? offerings.find(
              (currentOffering) => currentOffering.id === offeringId,
            )
          : undefined;

        const note: AppointmentNote = {
          id,
          createdAtCenterTimezone: new Date(),
          locale: center.locale,
          description,
          centerUser: {
            firstName: databaseUserData.firstName,
            lastName: databaseUserData.lastName,
          },
          appointmentOffering,
        };

        setNotes((prev) => [note, ...prev]);
      } catch (error) {
        setAlert({ isShown: true, severity: "error", message: defaultError });
      }
    },
    [
      center,
      databaseUserData.firstName,
      databaseUserData.lastName,
      defaultError,
      offerings,
      setAlert,
    ],
  );

  return { notes, handleAddNote };
};

export default useNotes;
