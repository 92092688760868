import { Theme, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    labelRoot: {
      fontSize: 12,
      letterSpacing: 1,
      textTransform: "uppercase",
      color: alpha(palette.common.black, 0.6),
    },
    linkRoot: {
      textDecoration: "none",
      color: palette.primary.main,
      cursor: "pointer",
    },
  };
});

export default useStyles;
