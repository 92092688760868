import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentReport } from "../types";

type ColumnValue = string;

type ColumnsTranslations = {
  headerName: string;
};

type ColumnsParams = {
  translations: ColumnsTranslations;
};

const getColumnName = ({
  translations,
}: ColumnsParams): GridColDef<AppointmentReport, ColumnValue> => {
  const { headerName } = translations;

  return {
    field: "name",
    disableColumnMenu: true,
    flex: 1,
    headerName,
    sortable: false,
  };
};

export default getColumnName;
