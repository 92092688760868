import * as Yup from "yup";
import { EMAIL_REGEXP_STR, MAX_STRING_LEN } from "../../../common/constants";
import { CustomerEditFormTranslations } from "../CustomerEditForm/types";
import { confirmationCodeLength } from "./constants";
import { ValidationSchemaErrorTranslations } from "./types";

const getValidationSchema = (params: {
  translations: {
    labels: CustomerEditFormTranslations;
    errors: ValidationSchemaErrorTranslations;
  };
  initialEmailValue?: string;
  isOtherPerson?: boolean;
}) => {
  const { translations, initialEmailValue, isOtherPerson } = params;
  const { labels, errors } = translations;

  const { errorEmailInvalid } = errors;

  const optionalString = Yup.string().trim().max(MAX_STRING_LEN);
  const requiredString = optionalString.required();

  const informationShape: Record<string, Yup.AnySchema> = {
    firstName: requiredString.label(labels.labelFirstName),
    lastName: requiredString.label(labels.labelLastName),
    confirmationCode: Yup.string()
      .label(labels.labelConfirmationCode)
      .trim()
      .min(confirmationCodeLength)
      .max(confirmationCodeLength)
      .when("email", (email, field) =>
        !isOtherPerson && initialEmailValue && email !== initialEmailValue
          ? field.required()
          : field,
      ),
    email: requiredString
      .matches(new RegExp(`^${EMAIL_REGEXP_STR}$`), errorEmailInvalid)
      .label(labels.labelEmail),
  };

  if (!isOtherPerson) {
    informationShape.phoneNumber = Yup.string()
      .trim()
      .required()
      .label(labels.labelPhoneNumber);
  }

  const schema = Yup.object().shape({
    information: Yup.object().shape(informationShape),
  });

  return schema;
};

export default getValidationSchema;
