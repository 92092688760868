import { defineMessages } from "react-intl";

export default defineMessages({
  labelDescription: {
    id: "AppointmentNote.AppointmentNoteAddDialogContainer.labelDescription",
    defaultMessage: "Note",
  },
  labelOffering: {
    id: "AppointmentNote.AppointmentNoteAddDialogContainer.labelOffering",
    defaultMessage: "Study note applies to",
  },
});
