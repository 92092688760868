import { formNames } from "./constants";

const formikSeparator = ".";

const getFormNames = (namePrefix?: string) => {
  const namePrefixWithFormikSeparator = namePrefix
    ? `${namePrefix}${formikSeparator}`
    : "";

  return formNames.reduce(
    (acc, formName) => ({
      ...acc,
      [formName]: `${namePrefixWithFormikSeparator}${formName}`,
    }),
    {} as Record<(typeof formNames)[number], string>,
  );
};

export default getFormNames;
