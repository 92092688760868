import React, { FC } from "react";
import AvailabilityCalendarMonth, {
  AvailabilityCalendarMonthProps,
} from "../../Unknown/AvailabilityCalendarMonth";
import AvailabilityCalendarTimeSlots, {
  AvailabilityCalendarTimeSlotsProps,
} from "../../Unknown/AvailabilityCalendarTimeSlots";
import Grid from "../../Unknown/Grid";
import Box from "../../Unknown/Box";
import StatusDialog from "../../Unknown/StatusDialog";
import useActionButtons from "./useActionButtons";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";
import TextField from "../../Unknown/TextField";

export type AppointmentRescheduleDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void | Promise<void>;
  onTimeOverride: (time: string | null) => void;
  isLoading?: boolean;
  overriddenTime: string | null;
  errorMessage?: string | null;
  calendarProps: AvailabilityCalendarMonthProps;
  timePickerProps: AvailabilityCalendarTimeSlotsProps;
};

const AppointmentRescheduleDialog: FC<AppointmentRescheduleDialogProps> = ({
  isOpen,
  handleClose,
  handleSubmit,
  isLoading,
  errorMessage,
  calendarProps,
  timePickerProps,
  overriddenTime,
  onTimeOverride,
}) => {
  const { calendarRoot } = useStyles();

  const { translations } = useTranslations();

  const isSubmitDisabled =
    !calendarProps.value ||
    (!timePickerProps.selectedTimeSlot && !overriddenTime);

  const actionButtons = useActionButtons({
    handleClose,
    handleSubmit,
    isLoading,
    errorMessage,
    isSubmitDisabled,
  });

  const isDaySelected = !!calendarProps.value;

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      isLoading={isLoading}
      errorMessage={errorMessage}
      actionButtons={actionButtons}
      onClose={handleClose}
      fullWidth
      PaperProps={{ sx: { maxWidth: 800 } }}
    >
      <Grid container direction="row" wrap="nowrap" spacing={6}>
        <Grid item>
          <Box className={calendarRoot}>
            <AvailabilityCalendarMonth {...calendarProps} />
          </Box>
          <Box mt={4}>
            <TextField
              label={translations.overrideTimeLabel}
              value={overriddenTime || ""}
              onChange={({ target }) => onTimeOverride(target.value)}
              disabled={!isDaySelected}
              fullWidth
              type="time"
              InputLabelProps={{ shrink: true }}
            />
          </Box>
        </Grid>
        <Grid item maxWidth={300}>
          <AvailabilityCalendarTimeSlots {...timePickerProps} />
        </Grid>
      </Grid>
    </StatusDialog>
  );
};

export default AppointmentRescheduleDialog;
