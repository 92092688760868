import React from "react";
import { GridColDef, GridComparatorFn } from "../../../Unknown/DataGrid";
import PatientPreviewEmail from "../../PatientPreviewEmail";
import { SearchPatientItem } from "../useRows";

type ColumnValue = {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  url: string;
  formattedMobilePhoneNumber: string;
};

type ColumnsDetailsTranslations = {
  titleDetails: string;
};

type ColumnsDetailsParams = {
  translations: ColumnsDetailsTranslations;
};

const sortComparator: GridComparatorFn<ColumnValue> = (
  currentValue,
  compareValue,
) => {
  return currentValue.fullName.localeCompare(compareValue.fullName);
};

const getColumnDetails = ({
  translations,
}: ColumnsDetailsParams): GridColDef<SearchPatientItem, ColumnValue> => {
  return {
    field: "name",
    disableColumnMenu: true,
    flex: 1,
    headerName: translations.titleDetails,
    sortComparator,
    valueGetter: (getterParams) => {
      const { firstName, lastName, email, formattedMobilePhoneNumber, url } =
        getterParams.row.details;

      const fullName = `${lastName} ${firstName}`;

      return {
        firstName,
        lastName,
        fullName,
        email,
        url,
        formattedMobilePhoneNumber,
      };
    },
    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      return <PatientPreviewEmail {...renderParams.value} />;
    },
  };
};

export default getColumnDetails;
