import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentOfferingColumnItemBtnOptions,
  AppointmentOfferingRowItem,
} from "../types";
import Button from "../../../Unknown/Button";
import Box from "../../../Unknown/Box";

export type ColumnBtnOptionsTranslations = {
  optionsBtnEdit: string;
  optionsBtnRead: string;
};

const getColumnBtnOptions = (params: {
  columnItem: AppointmentOfferingColumnItemBtnOptions;
  translations: ColumnBtnOptionsTranslations;
  headerClassName?: string;
}): GridColDef<AppointmentOfferingRowItem> => {
  const { columnItem, translations, headerClassName } = params;
  const { optionsBtnEdit, optionsBtnRead } = translations;

  return {
    field: columnItem.field,
    headerName: "",
    disableColumnMenu: true,
    sortable: false,
    headerClassName,
    getApplyQuickFilterFn: () => null,
    renderCell: ({ row }) => {
      const { status, appointmentStatus, onReadClick, onEditClick } = row;

      switch (status) {
        case "read": {
          if (appointmentStatus === "reports_sent") {
            return null;
          }

          return (
            <Box display="flex" justifyContent="center" width="100%">
              <Button sx={{ fontSize: 13 }} onClick={onEditClick}>
                {optionsBtnEdit}
              </Button>
            </Box>
          );
        }
        case "reading": {
          return null;
        }
        default: {
          return (
            <Box display="flex" justifyContent="center" width="100%">
              <Button sx={{ fontSize: 13 }} onClick={onReadClick}>
                {optionsBtnRead}
              </Button>
            </Box>
          );
        }
      }
    },
  };
};

export default getColumnBtnOptions;
