import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GridColDef } from "../../Unknown/DataGrid";
import getColumnAlert from "./columns/getColumnAlert";
import getColumnStatus from "./columns/getColumnStatus";
import getColumnPatient from "./columns/getColumnPatient";
import useTranslations from "./useTranslations";
import {
  AppointmentOfferingColumnItem,
  AppointmentOfferingRowItem,
} from "./types";
import getColumnBtnOptions from "./columns/getColumnBtnOptions";
import getColumnTime from "./columns/getColumnTime";
import getColumnBtnView from "./columns/getColumnBtnView";
import useStyles from "./useStyles";
import getColumnOffering from "./columns/getColumnOffering";
import getColumnLocation from "./columns/getColumnLocation";
import getColumnNotes from "./columns/getColumnNotes";

export type DashboardColumnsParams = {
  columnItems: AppointmentOfferingColumnItem[];
};

const useDashboardColumns = (params: DashboardColumnsParams) => {
  const { btnHeaderClassName, timeRoot, popperClassName } = useStyles();
  const navigate = useNavigate();
  const { role } = useParams();
  const { columnTranslations } = useTranslations();

  const columns = useMemo((): GridColDef<AppointmentOfferingRowItem>[] => {
    const { columnItems } = params;

    const innerColumns = columnItems.map((columnItem) => {
      switch (columnItem.field) {
        case "alert": {
          return getColumnAlert({
            columnItem,
            translations: {
              columnAlert: columnTranslations.columnAlert,
              tooltipStat: columnTranslations.tooltipStat,
              tooltipAdditionalViews: columnTranslations.tooltipAdditionalViews,
              tooltipUnexpectedFindings:
                columnTranslations.tooltipUnexpectedFindings,
              tooltipReceiptConfirmed:
                columnTranslations.tooltipReceiptConfirmed,
              tooltipHasPriority: columnTranslations.tooltipHasPriority,
            },
          });
        }
        case "status": {
          return getColumnStatus({
            columnItem,
            translations: {
              columnStatus: columnTranslations.columnStatus,
              statusPending: columnTranslations.statusPending,
              statusUnread: columnTranslations.statusUnread,
              statusReading: columnTranslations.statusReading,
              statusRead: columnTranslations.statusRead,
            },
          });
        }
        case "patient": {
          return getColumnPatient({
            columnItem,
            translations: {
              columnPatient: columnTranslations.columnPatient,
            },
            onNameClick: (id: string) =>
              navigate(`/${role}/dashboard/patient/${id}`),
          });
        }
        case "time": {
          return getColumnTime({
            columnItem,
            translations: {
              columnDateTime: columnTranslations.columnDateTime,
            },
            classNames: {
              timeRoot,
            },
          });
        }
        case "offering": {
          return getColumnOffering({
            columnItem,
            translations: {
              columnOffering: columnTranslations.columnOffering,
              sideRight: columnTranslations.sideRight,
              sideLeft: columnTranslations.sideLeft,
            },
          });
        }
        case "notes": {
          return getColumnNotes({
            columnItem,
            translations: {
              columnNotes: columnTranslations.columnNotes,
            },
            classNames: {
              popper: popperClassName,
            },
          });
        }
        case "location": {
          return getColumnLocation({
            columnItem,
            translations: {
              columnLocation: columnTranslations.columnLocation,
            },
          });
        }
        case "btnOptions": {
          return getColumnBtnOptions({
            columnItem,
            translations: {
              optionsBtnEdit: columnTranslations.optionsBtnEdit,
              optionsBtnRead: columnTranslations.optionsBtnRead,
            },
            headerClassName: btnHeaderClassName,
          });
        }
        case "btnView": {
          return getColumnBtnView({
            columnItem,
            translations: {
              btnView: columnTranslations.btnView,
            },
            handleClick: (id) => navigate(`/${role}/appointment/${id}`),
            headerClassName: btnHeaderClassName,
          });
        }
        default: {
          return null;
        }
      }
    });

    return innerColumns.filter(
      Boolean,
    ) as GridColDef<AppointmentOfferingRowItem>[];
  }, [
    params,
    columnTranslations.columnAlert,
    columnTranslations.tooltipStat,
    columnTranslations.tooltipAdditionalViews,
    columnTranslations.tooltipUnexpectedFindings,
    columnTranslations.tooltipReceiptConfirmed,
    columnTranslations.tooltipHasPriority,
    columnTranslations.columnStatus,
    columnTranslations.statusPending,
    columnTranslations.statusUnread,
    columnTranslations.statusReading,
    columnTranslations.statusRead,
    columnTranslations.columnPatient,
    columnTranslations.columnDateTime,
    columnTranslations.columnOffering,
    columnTranslations.sideRight,
    columnTranslations.sideLeft,
    columnTranslations.columnNotes,
    columnTranslations.columnLocation,
    columnTranslations.optionsBtnEdit,
    columnTranslations.optionsBtnRead,
    columnTranslations.btnView,
    navigate,
    role,
    timeRoot,
    popperClassName,
    btnHeaderClassName,
  ]);
  return columns;
};

export default useDashboardColumns;
