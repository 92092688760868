import { defineMessages } from "react-intl";

const messages = defineMessages({
  scanSideSingle: {
    id: "Offering.CenterOfferingForm.scanSideSingle",
    defaultMessage: "Single-sided",
  },
  scanSideDual: {
    id: "Offering.CenterOfferingForm.scanSideDual",
    defaultMessage: "Dual-sided",
  },
  visibilityPrivate: {
    id: "Offering.CenterOfferingForm.visibilityPrivate",
    defaultMessage: "Private",
  },
  visibilityPublic: {
    id: "Offering.CenterOfferingForm.visibilityPublic",
    defaultMessage: "Public",
  },
  visibilityOnlyPackage: {
    id: "Offering.CenterOfferingForm.visibilityOnlyPackage",
    defaultMessage: "Only for packages",
  },
  durationMinutesUnit: {
    id: "Offering.CenterOfferingForm.durationMinutesUnit",
    defaultMessage: "min",
  },
});

export default messages;
