import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PatientInformationDrawerContainerProps } from "../../Patient/PatientInformationDrawerContainer";

const usePatientDrawerContainer = (params: {
  refetchAppointment: () => void | Promise<void>;
}): { isOpen: boolean; props: PatientInformationDrawerContainerProps } => {
  const { refetchAppointment } = params;
  const { role, patientMrn } = useParams();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(`/${role}/`);
  }, [navigate, role]);

  const handleCustomerClick = useCallback(
    (customerId: string) => {
      navigate(`/${role}/dashboard/customer/${customerId}`);
    },
    [navigate, role],
  );

  const handleAppointmentClick = useCallback(
    (appointmentId: string) => {
      navigate(`/${role}/appointment/${appointmentId}`);
    },
    [navigate, role],
  );

  return {
    isOpen: !!patientMrn,
    props: {
      patientMrn,
      isOpen: !!patientMrn,
      handleClose,
      handleAppointmentClick,
      handleCustomerClick,
      handlePostSubmit: refetchAppointment,
    },
  };
};

export default usePatientDrawerContainer;
