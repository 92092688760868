import { useCallback, useEffect, useMemo, useState } from "react";
import { FormikTouched } from "formik";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import {
  Package,
  PackageDataOffering,
  PackageOffering,
  PackagesData,
} from "../../Center/CenterSettingsContainer/types";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterPackageFormValues } from "../CenterPackageForm/types";
import { CenterPackageFormikProps } from "../CenterPackageFormik";
import useTranslations from "./useTranslations";

type EditFormHookParams = {
  isOpened: boolean;
  editedPackageId: string | null;
  onClose: () => void;
  packagesData: PackagesData;
  refetchCenterData?: () => void | Promise<void>;
};

const useEditForm = (
  params: EditFormHookParams,
): CenterPackageFormikProps | null => {
  const {
    isOpened,
    editedPackageId,
    onClose,
    packagesData,
    refetchCenterData,
  } = params;

  const { packages, minPrice, currencyCode, offerings } = packagesData;

  const updateCenterPackage = useFirebaseAppFunction("updateCenterPackage");

  const { editModalTitle, defaultError } = useTranslations();

  const { setAlert } = useUIContext();

  const [centerPackageData, setCenterPackageData] = useState<Package | null>(
    null,
  );

  useEffect(() => {
    if (!isOpened) return;

    if (!editedPackageId) {
      setCenterPackageData(null);
      onClose();
      return;
    }

    const selectedPackage = packages.find(
      (packageItem) => packageItem.id === editedPackageId,
    );

    if (!selectedPackage) {
      setCenterPackageData(null);
      onClose();
      return;
    }

    setCenterPackageData(selectedPackage);
  }, [editedPackageId, isOpened, packages, onClose]);

  const initialValues = useMemo<CenterPackageFormValues | null>(() => {
    if (!centerPackageData) return null;

    return {
      name: centerPackageData.name,
      modalityName: centerPackageData.modalityName,
      description: centerPackageData.description,
      price: centerPackageData.price,
      depositAmount: centerPackageData.depositAmount,
      visibility: centerPackageData.visibility,
      offerings: centerPackageData.offerings.map((offering) => ({
        id: offering.id,
        name: offering.name,
      })),
    };
  }, [centerPackageData]);

  const initialTouched: FormikTouched<CenterPackageFormValues> = {
    price: true,
  };

  const handleSubmit = useCallback(
    async (values: CenterPackageFormValues) => {
      try {
        if (!centerPackageData) throw new Error(defaultError);

        const {
          name,
          modalityName,
          description,
          offerings: selectedOfferings,
          visibility,
          price,
          depositAmount,
        } = values;

        if (visibility === "" || price === "" || depositAmount === "") {
          throw new Error(defaultError);
        }

        const offeringIds = selectedOfferings.map(({ id }) => id);

        await updateCenterPackage({
          id: centerPackageData.id,
          name,
          modalityName,
          description,
          offeringIds,
          visibility,
          price,
          depositAmount,
        });

        if (refetchCenterData) await refetchCenterData();

        onClose();
      } catch (error) {
        setAlert({
          message: defaultError,
          isShown: true,
          severity: "error",
        });
      }
    },
    [
      centerPackageData,
      defaultError,
      onClose,
      refetchCenterData,
      setAlert,
      updateCenterPackage,
    ],
  );

  let allOfferings: (PackageOffering | PackageDataOffering)[] = [...offerings];

  const centerPackageOfferings =
    packages.find(({ id }) => id === editedPackageId)?.offerings || [];

  centerPackageOfferings.forEach((packageOffering) => {
    const isAlreadyExist = offerings.some(
      (offering) => offering.id === packageOffering.id,
    );

    if (!isAlreadyExist) {
      allOfferings = [packageOffering, ...allOfferings];
    }
  });

  if (!initialValues) return null;

  return {
    title: editModalTitle,
    isOpened,
    onModalClose: onClose,
    initialValues,
    initialTouched,
    handleSubmit,
    currencyCode,
    minPrice,
    offerings: allOfferings,
  };
};

export default useEditForm;
