import React, { FC } from "react";
import Button, { ButtonProps } from "../../Unknown/Button";
import Grid from "../../Unknown/Grid";

export type ActionButton = ButtonProps & { text: string; key: string };

export type AppointmentActionBarProps = {
  leftButtons?: ActionButton[];
  rightButtons?: ActionButton[];
};

const AppointmentActionBar: FC<AppointmentActionBarProps> = ({
  leftButtons,
  rightButtons,
}) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      direction="row"
      flexWrap="nowrap"
    >
      <Grid item container direction="row" spacing={3}>
        {leftButtons?.map(({ text, key, ...props }) => (
          <Grid item key={key}>
            <Button {...props}>{text}</Button>
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing={3}
        justifyContent="flex-end"
      >
        {rightButtons?.map(({ text, key, ...props }) => (
          <Grid item key={key}>
            <Button {...props}>{text}</Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default AppointmentActionBar;
