import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentColumnItemPatientState,
  AppointmentRowItem,
} from "../types";

export type ColumnPatientStateTranslations = {
  columnPatientState: string;
};

const getColumnPatientState = (params: {
  columnItem: AppointmentColumnItemPatientState;
  translations: ColumnPatientStateTranslations;
  onNameClick?: (id: string) => void;
}): GridColDef<AppointmentRowItem, string | null> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnPatientState,
    valueGetter: ({ row }) => {
      return row.patient?.state || null;
    },
  };
};

export default getColumnPatientState;
