import React, { FC } from "react";
import { SlotLabelContentArg } from "../../Unknown/Calendar";
import Typography from "../../Unknown/Typography";

type TimeSlotCalendarGridSlotLabelProps = SlotLabelContentArg;

const TimeSlotCalendarGridSlotLabel: FC<TimeSlotCalendarGridSlotLabelProps> = ({
  text,
}) => {
  return <Typography variant="body2">{text}</Typography>;
};

export default TimeSlotCalendarGridSlotLabel;
