import React, { FC } from "react";
import { useParams } from "react-router-dom";
import Box from "../Box";
import DashboardHeader from "../DashboardHeader";
import AppointmentTableContainer from "../../Appointment/AppointmentTableContainer";
import AppointmentOfferingTableContainer from "../../AppointmentOffering/AppointmentOfferingTableContainer";
import useDate from "./useDate";

const TimeSlotCalendarScreen: FC = () => {
  const { role } = useParams();

  const { startDate, endDate, zonedDate } = useDate();

  switch (role) {
    case "radiologist": {
      return (
        <>
          <Box pb={2.5}>
            <DashboardHeader date={startDate} role={role} />
          </Box>
          <AppointmentOfferingTableContainer
            startDate={startDate}
            endDate={endDate}
            role={role}
          />
        </>
      );
    }
    default: {
      return (
        <>
          <Box pb={2.5}>
            <DashboardHeader date={zonedDate} role={role} />
          </Box>
          <AppointmentTableContainer date={startDate} role={role} />
        </>
      );
    }
  }
};

export default TimeSlotCalendarScreen;
