import { GridColDef } from "../../../Unknown/DataGrid";
import { Package } from "../../../Center/CenterSettingsContainer/types";

type Translations = {
  headerName: string;
};

type ColumnValue = string;
type ColumnFormattedValue = string;

const getOfferingsColumn = (params: { translations: Translations }) => {
  const { translations } = params;

  const { headerName } = translations;

  const result: GridColDef<Package, ColumnValue, ColumnFormattedValue> = {
    field: "offerings",
    headerName,
    flex: 1,
    sortable: true,
    disableColumnMenu: true,
    valueGetter: (getterParams) => {
      const { offerings } = getterParams.row;
      return offerings
        .map(({ name }) => name)
        .sort((a, b) => a.localeCompare(b))
        .join(", ");
    },
  };

  return result;
};
export default getOfferingsColumn;
