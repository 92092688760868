import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      columnFormType: formatMessage(messages.columnFormType),
      columnCompletedAt: formatMessage(messages.columnCompletedAt),
      columnExpiresAt: formatMessage(messages.columnExpiresAt),
      btnTitle: formatMessage(messages.btnTitle),
    };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
