import supabaseClient from "../../../common/supabaseClient";
import { Customer } from "./types";

const supabaseCustomerRequest = `
id,
first_name,
last_name,
email
`;

const getAppointmentCustomerData = async (id: string) => {
  const { data, error } = await supabaseClient
    .from<Customer>("appointment_customer_details")
    .select(supabaseCustomerRequest)
    .eq("id", id)
    .limit(1)
    .maybeSingle();

  if (error) throw new Error(error.message);

  return data;
};

export default getAppointmentCustomerData;
