import { useMemo } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { FormValues, Customer, CustomerFormikConfig } from "./types";
import getFormikInitialValues from "./getFormikInitialValues";
import getValidationSchema from "./getValidationSchema";
import useErrorMessages from "./useErrorMessages";
import useTranslations from "./useTranslations";
import { getValidPhoneNumber } from "../../../common/phoneNumberHelpers";

const convertToStringOrEmpty = (value?: string | number) => {
  return value !== undefined ? `${value}` : "";
};

const useCustomerFormik = (params: {
  customer: Customer | null;
  customerId?: string;
  isOtherPerson?: boolean;
  onSubmitSuccess?: (values: FormValues) => void | Promise<void>;
}): {
  formikConfig: CustomerFormikConfig;
} => {
  const updateCenterCustomer = useFirebaseAppFunction("updateCenterCustomer");

  const { customer, customerId, isOtherPerson, onSubmitSuccess } = params;

  const { setAlert } = useUIContext();

  const { getErrorMessageOnUpdateCustomer } = useErrorMessages();

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const { formLabelTranslations, validationSchemaTranslations } =
    useTranslations();

  const initialValues = useMemo<CustomerFormikConfig["initialValues"]>(() => {
    return getFormikInitialValues({
      customer,
      isOtherPerson,
    });
  }, [customer, isOtherPerson]);

  const onSubmit: CustomerFormikConfig["onSubmit"] = async (values) => {
    if (!customerId) return;

    const { information } = values;

    const phoneNumber = convertToStringOrEmpty(information.phoneNumber);
    const confirmationCode = convertToStringOrEmpty(
      information.confirmationCode,
    );

    try {
      const { data } = await runAsyncFunction(updateCenterCustomer, {
        ...information,
        isOtherPerson: isOtherPerson || false,
        customerId,
        phoneNumber: getValidPhoneNumber(phoneNumber),
        confirmationCode,
      });

      if (data.status === "error") {
        const msg = getErrorMessageOnUpdateCustomer(data.error.code);

        throw new Error(msg);
      }

      if (onSubmitSuccess) await onSubmitSuccess(values);
    } catch (error) {
      setAlert({
        isShown: true,
        severity: "error",
        message: (error as Error).message,
      });
    }
  };

  const validationSchema = useMemo<
    CustomerFormikConfig["validationSchema"]
  >(() => {
    return getValidationSchema({
      translations: {
        labels: formLabelTranslations,
        errors: validationSchemaTranslations,
      },
      initialEmailValue: initialValues.information.email,
      isOtherPerson,
    });
  }, [
    formLabelTranslations,
    initialValues.information.email,
    isOtherPerson,
    validationSchemaTranslations,
  ]);

  return {
    formikConfig: {
      enableReinitialize: true,
      initialValues,
      onSubmit,
      validationSchema,
    },
  };
};

export default useCustomerFormik;
