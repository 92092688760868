import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Offering.AppointmentOfferingModifyDialog.title",
    defaultMessage: "Edit Offering",
  },
  btnSubmit: {
    id: "Offering.AppointmentOfferingModifyDialog.btnSubmit",
    defaultMessage: "Save",
  },
  btnCancel: {
    id: "Offering.AppointmentOfferingModifyDialog.btnCancel",
    defaultMessage: "Cancel",
  },
});

export default messages;
