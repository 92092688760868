import { useCallback, useEffect } from "react";
import useDataState from "../../../hooks/useDataState";
import { SystemConfig } from "../../../hooks/useSystemConfig";
import getSettingsCenterData from "./getSettingsCenterData";
import normalizeAddOns from "./normalizeAddOns";
import normalizeAddress from "./normalizeAddress";
import normalizeContactInformation from "./normalizeContactInformation";
import normalizeDiscount from "./normalizeDiscount";
import normalizeOfferings from "./normalizeOfferings";
import normalizeOperatingHours from "./normalizeOperatingHours";
import normalizePackages from "./normalizePackages";
import {
  AddOnsData,
  Address,
  ContactInformation,
  Discount,
  OfferingsData,
  PackagesData,
  OperatingHours,
} from "./types";

type NormalizedSettingsCenter = {
  address: Address;
  contactInformation: ContactInformation;
  operatingHours: OperatingHours;
  discount: Discount;
  packagesData: PackagesData;
  offeringsData: OfferingsData;
  addOnsData: AddOnsData;
  isRegisteredInMwl: boolean;
};

const useCenterSettingsData = (
  centerId: string,
  systemConfig: SystemConfig,
) => {
  const [dataState, updateDataState] = useDataState<NormalizedSettingsCenter>();

  const fetchCenterData = useCallback(async () => {
    try {
      updateDataState({ status: "loading" });
      const databaseCenterData = await getSettingsCenterData(centerId);

      const address = normalizeAddress(databaseCenterData);

      const contactInformation =
        normalizeContactInformation(databaseCenterData);

      const operatingHours = normalizeOperatingHours(databaseCenterData);

      const discount = normalizeDiscount(databaseCenterData, systemConfig);

      const offeringsData = normalizeOfferings(
        databaseCenterData,
        systemConfig,
      );
      const packagesData = normalizePackages(databaseCenterData, systemConfig);

      const addOnsData = normalizeAddOns(databaseCenterData, systemConfig);

      const isRegisteredInMwl = !!databaseCenterData.mwl_secret;

      const normalizedData = {
        address,
        contactInformation,
        operatingHours,
        discount,
        packagesData,
        offeringsData,
        addOnsData,
        isRegisteredInMwl,
      };

      updateDataState({ status: "success", data: normalizedData });
    } catch (error) {
      updateDataState({ status: "error", error: "error" });
    }
  }, [centerId, systemConfig, updateDataState]);

  useEffect(() => {
    fetchCenterData();
  }, [fetchCenterData]);

  return [dataState, fetchCenterData] as const;
};

export default useCenterSettingsData;
