import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelPhone: {
    id: "Center.CenterContactInformationForm.labelPhone",
    defaultMessage: "Phone",
  },
  labelFax: {
    id: "Center.CenterContactInformationForm.labelFax",
    defaultMessage: "Fax",
  },
  labelEmail: {
    id: "Center.CenterContactInformationForm.labelEmail",
    defaultMessage: "Email",
  },
});

export default messages;
