import React, { FC, ReactNode, useMemo } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import getUserAllowedActionPages from "./getUserAllowedPages";
import { useAppContext } from "../AppContext";

type PrivateRoleRouteProps = {
  children: ReactNode;
};

const PrivateRoleRoute: FC<PrivateRoleRouteProps> = ({ children }) => {
  const { role: pageParamsRole } = useParams();
  const location = useLocation();

  const { databaseUserData } = useAppContext();

  const isUserAllowed = useMemo(() => {
    const isUserAllowedToRole = databaseUserData.permissions.some(
      (permission) => permission === pageParamsRole,
    );

    const allowedActionPages = getUserAllowedActionPages(
      databaseUserData.permissions,
    );

    const currentPaths = location.pathname.split("/").filter(Boolean);

    const currentActionPage = currentPaths[1] || "dashboard";

    const isUserAllowedToActionPage =
      allowedActionPages.includes(currentActionPage);

    return isUserAllowedToRole && isUserAllowedToActionPage;
  }, [databaseUserData.permissions, location.pathname, pageParamsRole]);

  if (!isUserAllowed) {
    return <Navigate replace to="/" />;
  }

  return <>{children}</>;
};

export default PrivateRoleRoute;
