import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { Appointment } from "../AppointmentCardsContainer/types";

interface CheckIsCanBeConfirmedParams {
  appointment: Appointment | null;
  role?: DatabaseEnum["center_user_role"];
}

const checkIsCanBeConfirmed = ({
  appointment,
  role,
}: CheckIsCanBeConfirmedParams): boolean => {
  if (!appointment || appointment.has_priority) return false;

  switch (role) {
    case "technologist":
    case "super_admin":
    case "regional_admin":
      return !!["incomplete"].includes(appointment.status);
    default:
      return false;
  }
};

export default checkIsCanBeConfirmed;
