import getFormNames from "../../../common/getFormNames";
import { SideMenuOption } from "./types";

export const inputSpacing = 4;

export const sides: SideMenuOption[] = [
  { id: "left", translationKey: "sideLeft" },
  { id: "right", translationKey: "sideRight" },
];

const formNamesList = [
  "offering",
  "side",
  "modalityName",
  "reason",
  "isDualSide",
] as const;

export const formNames = getFormNames({ formNamesList });
