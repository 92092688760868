import { useMemo } from "react";
import { AppointmentNoteListCardContainerProps } from "../../AppointmentNote/AppointmentNoteListCardContainer";
import getInitialNotes from "./getInitialNotes";
import { Appointment } from "./types";

type NoteOffering = AppointmentNoteListCardContainerProps["offerings"][number];

const useAppointmentNotesContainer = (
  appointment: Appointment | null,
): AppointmentNoteListCardContainerProps => {
  const props = useMemo<AppointmentNoteListCardContainerProps>(() => {
    const offerings = (
      appointment?.appointment_offerings || []
    ).map<NoteOffering>((appointmentOffering) => {
      return {
        id: appointmentOffering.id,
        modalityName:
          appointmentOffering.modality_name ||
          appointmentOffering.center_offering.name,
      };
    });

    const packageOfferings = (appointment?.appointment_packages || [])
      .map((appointmentPackage) => {
        return appointmentPackage.appointment_offerings.map<NoteOffering>(
          (appointmentOffering) => {
            return {
              id: appointmentOffering.id,
              modalityName:
                appointmentOffering.modality_name ||
                appointmentOffering.center_offering.name,
            };
          },
        );
      })
      .flat();

    const center = appointment
      ? {
          locale: appointment.center.region,
        }
      : null;

    const appointmentProp = appointment
      ? {
          id: appointment.id,
        }
      : null;

    return {
      initialNotes: getInitialNotes(appointment),
      offerings: [...offerings, ...packageOfferings],
      center,
      appointment: appointmentProp,
    };
  }, [appointment]);

  return props;
};

export default useAppointmentNotesContainer;
