import { useNavigate, useParams } from "react-router-dom";
import React, { FC } from "react";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import { useAppContext } from "../../Unknown/AppContext";

type Appointment = {
  id: string;
  startedAt: Date;
  centerName: string;
};

export type AppointmentsPreviewProps = {
  appointments: Appointment[];
};

const AppointmentsPreview: FC<AppointmentsPreviewProps> = ({
  appointments,
}) => {
  const { role } = useParams();
  const navigate = useNavigate();
  const {
    patientsSearch: { setSearchValue },
  } = useAppContext();

  return (
    <Grid container direction="column">
      {appointments.map((appointment) => {
        const { id, startedAt, centerName } = appointment;
        const date = startedAt.toLocaleDateString();
        const text = `${date} — ${centerName}`;
        const appointmentDetailsUrl = `/${role}/appointment/${appointment.id}`;

        const onAppointmentClick = () => {
          setSearchValue("");
          navigate(appointmentDetailsUrl);
        };

        return (
          <Grid item key={id}>
            <Typography
              onClick={onAppointmentClick}
              variant="body2"
              color="primary.main"
              sx={{ cursor: "pointer" }}
            >
              {text}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AppointmentsPreview;
