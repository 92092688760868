import React, { FC } from "react";
import { useField } from "formik";
import DesktopDatePicker from "../DesktopDatePicker";
import TextField, { TextFieldProps } from "../TextField";

export type FormikDesktopDatePickerProps = {
  name: string;
  minDate?: Date;
  textFieldProps: Omit<
    TextFieldProps,
    "value" | "multiline" | "onChange" | "onBlur" | "error" | "helperText"
  >;
};
const FormikDesktopDatePicker: FC<FormikDesktopDatePickerProps> = ({
  name,
  minDate,
  textFieldProps,
}) => {
  const [, meta, helpers] = useField(name);
  const { touched, error, value } = meta;
  const { setValue, setTouched } = helpers;

  return (
    <DesktopDatePicker
      label={textFieldProps.label}
      onChange={(newValue) => setValue(newValue, true)}
      value={value}
      disabled={textFieldProps.disabled}
      minDate={minDate}
      InputAdornmentProps={{
        onBlur: () => setTouched(true, true),
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...textFieldProps}
            error={touched && Boolean(error)}
            helperText={touched && error}
            name={name}
          />
        );
      }}
    />
  );
};

export default FormikDesktopDatePicker;
