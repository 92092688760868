import formatInTimeZone from "date-fns-tz/formatInTimeZone";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemBookedTime, AppointmentRowItem } from "../types";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnBookedTimeTranslations = {
  columnBookedTime: string;
};

const getColumnBookedTime = (params: {
  columnItem: AppointmentColumnItemBookedTime;
  translations: ColumnBookedTimeTranslations;
}): GridColDef<AppointmentRowItem, Date, string> => {
  const { columnItem, translations } = params;
  const { field, localeFormat } = columnItem;

  return {
    field,
    disableColumnMenu: true,
    flex: 0.5,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnBookedTime,
    type: "dateTime",
    valueGetter: ({ row }) => {
      const { createdAtCenterTimezone } = row;

      return createdAtCenterTimezone;
    },
    valueFormatter: ({ value }) => {
      const formattedTime = value
        .toTimeString()
        .toLocaleLowerCase()
        .replace(/\s/, "");

      return formattedTime;
    },
    renderCell: ({ row, value }) => {
      if (!value) return null;

      const {
        createdAtCenterTimezone,
        center: { timezone, locale },
      } = row;

      const centerLocale = localeFormat === "center" ? locale : undefined;

      const formattedTime = createdAtCenterTimezone
        .toLocaleTimeString(centerLocale, {
          hour: "numeric",
          minute: "numeric",
        })
        .toLocaleLowerCase()
        .replace(/\s/, "");

      const timeZoneAbbr = formatInTimeZone(
        createdAtCenterTimezone,
        timezone || "UTC",
        "zzz",
      ).toLocaleUpperCase();

      return `${formattedTime} (${timeZoneAbbr})`;
    },
  };
};

export default getColumnBookedTime;
