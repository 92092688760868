import { useCallback, useState } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterAddOnFormValues } from "../CenterAddOnForm/types";
import { CenterAddOnFormikProps } from "../CenterAddOnFormik";
import { TYPE_STAT } from "../CenterAddOnFormik/constants";
import useTranslations from "./useTranslations";

type AddFormHookParams = {
  isOpened: boolean;
  onClose: () => void;
  centerId: string;
  currencyCode: string;
  refetchCenterData?: () => void | Promise<void>;
};

type AddFormHookResult = {
  addFormikProps: CenterAddOnFormikProps;
};

const initialAddOnFormik: CenterAddOnFormikProps["centerAddOnData"] = {
  name: "",
  price: 0,
  type: "",
};

const useAddForm = (params: AddFormHookParams): AddFormHookResult => {
  const { isOpened, onClose, centerId, currencyCode, refetchCenterData } =
    params;

  const addCenterAddOn = useFirebaseAppFunction("addCenterAddOn");

  const { titleAddAddOn, defaultError } = useTranslations();

  const { setAlert } = useUIContext();

  const [centerAddOnData, setCenterAddOnData] =
    useState<CenterAddOnFormikProps["centerAddOnData"]>(initialAddOnFormik);

  const onModalClose = useCallback(() => {
    setCenterAddOnData(initialAddOnFormik);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(
    async (values: CenterAddOnFormValues) => {
      try {
        if (!centerAddOnData) throw new Error(defaultError);

        const { name, price, isStat } = values;

        await addCenterAddOn({
          centerId,
          name,
          price,
          type: isStat ? TYPE_STAT : "",
        });

        if (refetchCenterData) await refetchCenterData();

        onModalClose();
      } catch (error) {
        setAlert({
          message: defaultError,
          isShown: true,
          severity: "error",
        });
      }
    },
    [
      addCenterAddOn,
      centerId,
      centerAddOnData,
      defaultError,
      onModalClose,
      refetchCenterData,
      setAlert,
    ],
  );

  return {
    addFormikProps: {
      title: titleAddAddOn,
      isOpened,
      onModalClose,
      centerAddOnData,
      handleSubmit,
      currencyCode,
    },
  };
};

export default useAddForm;
