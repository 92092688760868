import { GridColDef } from "../../Unknown/DataGrid";
import getColumnAppointments from "./columns/getColumnAppointments";
import getColumnDateOfBirth from "./columns/getColumnDateBirth";
import getColumnDetails from "./columns/getColumnDetails";
import { SearchPatientItem } from "./useRows";

export type ColumnTranslations = {
  titleDetails: string;
  titleDateBirth: string;
  titleAppointments: string;
};

type ColumnParams = {
  translations: ColumnTranslations;
};

const useColumns = ({
  translations,
}: ColumnParams): GridColDef<SearchPatientItem>[] => {
  const { titleDetails, titleDateBirth, titleAppointments } = translations;

  return [
    getColumnDetails({ translations: { titleDetails } }),
    getColumnDateOfBirth({ translations: { titleDateBirth } }),
    getColumnAppointments({ translations: { titleAppointments } }),
  ];
};

export default useColumns;
