import isAfter from "date-fns/isAfter";
import parseISO from "date-fns/parseISO";
import isSameDay from "date-fns/isSameDay";
import subMinutes from "date-fns/subMinutes";
import { DashboardAppointment } from "./getAppointments";
import {
  getNowUTC,
  getTimezoneOffsetMillis,
  UTCDateTimeToTimezone,
} from "../../../common/dateHelpers";

export type MinimumStartFilterMinutes = "startOfDay" | number;

const filterDashboardAppointments = (params: {
  appointments: DashboardAppointment[];
  minimumStartFilterMinutes?: MinimumStartFilterMinutes;
}): DashboardAppointment[] => {
  const { appointments, minimumStartFilterMinutes } = params;

  if (minimumStartFilterMinutes === "startOfDay") {
    const nowUTC = getNowUTC();

    return appointments.filter((appointment) => {
      const start = appointment.time_slot.started_at;
      const { timezone } = appointment.center;
      const centerTimezoneOffsetMillis = getTimezoneOffsetMillis(timezone);

      const nowCenterTimezone = UTCDateTimeToTimezone(
        nowUTC,
        centerTimezoneOffsetMillis,
      );

      const startCenterTimezone = UTCDateTimeToTimezone(
        parseISO(start),
        centerTimezoneOffsetMillis,
      );

      const isTodayDate = isSameDay(nowCenterTimezone, startCenterTimezone);

      return isTodayDate;
    });
  }

  const nowUTC = getNowUTC();

  return appointments.filter((appointment) => {
    if (!minimumStartFilterMinutes) return true;

    const start = appointment.time_slot.started_at;
    const { timezone } = appointment.center;

    const centerTimezoneOffsetMillis = getTimezoneOffsetMillis(timezone);

    const nowCenterTimezone = UTCDateTimeToTimezone(
      nowUTC,
      centerTimezoneOffsetMillis,
    );

    const startCenterTimezone = UTCDateTimeToTimezone(
      parseISO(start),
      centerTimezoneOffsetMillis,
    );

    const isTodayDate = isSameDay(nowCenterTimezone, startCenterTimezone);

    const isMinTime = isAfter(
      startCenterTimezone,
      subMinutes(nowCenterTimezone, minimumStartFilterMinutes),
    );

    return isTodayDate && isMinTime;
  });
};

export default filterDashboardAppointments;
