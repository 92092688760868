import { SystemConfig } from "../../../hooks/useSystemConfig";
import { SettingsCenter } from "./getSettingsCenterData";
import { OfferingsData, OfferingDurationSource } from "./types";

const getOfferingDuration = (params: {
  systemDuration: number;
  centerDuration?: number | null;
  offeringDuration?: number | null;
}): { value: number; source: OfferingDurationSource } => {
  const { systemDuration, centerDuration, offeringDuration } = params;
  if (typeof offeringDuration === "number") {
    return { value: offeringDuration, source: "offering" };
  }

  if (typeof centerDuration === "number") {
    return { value: centerDuration, source: "center" };
  }

  return { value: systemDuration, source: "system" };
};

const normalizeOfferings = (
  center: SettingsCenter,
  systemConfig: SystemConfig,
): OfferingsData => {
  const centerConfig = center.center_configs[0];

  const currencyCode =
    centerConfig?.default_currency_code || systemConfig.default_currency_code;

  const discountAmount =
    centerConfig?.discount_amount ?? systemConfig.discount_amount;

  const filteredCenterOfferings = center.center_offerings.filter((offering) => {
    return offering.center_package_id === null;
  });

  const offerings = filteredCenterOfferings.map((offering) => {
    const {
      id,
      name,
      price,
      duration_minutes,
      visibility,
      modality_name,
      scan_side,
      deposit_amount,
      description,
    } = offering;

    const { value: durationMinutes, source: durationSource } =
      getOfferingDuration({
        systemDuration: systemConfig.default_offering_duration_minutes,
        centerDuration: centerConfig?.default_offering_duration_minutes,
        offeringDuration: duration_minutes,
      });

    return {
      id,
      name,
      price,
      durationMinutes,
      durationSource,
      visibility,
      modalityName: modality_name,
      scanSide: scan_side,
      depositAmount: deposit_amount,
      description,
    };
  });

  return { offerings, currencyCode, minPrice: discountAmount };
};

export default normalizeOfferings;
