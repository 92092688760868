import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelPhoneNumber: {
    id: "Customer.CustomerCard.labelPhoneNumber",
    defaultMessage: "Phone",
  },
  labelEmail: {
    id: "Customer.CustomerCard.labelEmail",
    defaultMessage: "Email",
  },
  labelPatients: {
    id: "Customer.CustomerCard.labelPatients",
    defaultMessage: "Ward(s)",
  },
});

export default messages;
