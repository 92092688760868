const getFormNames = <T extends string>(params: {
  formNamesList: readonly T[];
  prefix?: string;
  separator?: string;
}) => {
  const { formNamesList, prefix, separator = "." } = params;

  const prefixWithSeparator = prefix ? `${prefix}${separator}` : "";

  return formNamesList.reduce(
    (acc, formName) => ({
      ...acc,
      [formName]: `${prefixWithSeparator}${formName}`,
    }),
    {} as Record<T, string>,
  );
};

export default getFormNames;
