import { useMemo } from "react";
import { AppointmentTechnologistNoteCardContainerProps } from "../../AppointmentTechnologistNote/AppointmentTechnologistNoteCardContainer";
import { Appointment } from "./types";

const useAppointmentTechnologistNotesContainer = (
  appointment: Appointment | null,
  refetch: () => void,
): AppointmentTechnologistNoteCardContainerProps => {
  const props = useMemo<AppointmentTechnologistNoteCardContainerProps>(() => {
    const [note] = appointment?.appointment_technologist_notes || [];

    return {
      text: note?.text || "",
      refetch,
    };
  }, [appointment, refetch]);

  return props;
};

export default useAppointmentTechnologistNotesContainer;
