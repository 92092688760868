import React, { FC } from "react";
import { useFormikContext } from "formik";
import FormikTextField from "../../Unknown/FormikTextField";
import {
  AppointmentOfferingAdditionalViewFormValues,
  LabelTranslationsKey,
} from "./types";
import { formNames } from "./constants";

export type AppointmentOfferingAdditionalViewFormProps = {
  labelTranslations: Record<LabelTranslationsKey, string>;
};

const AppointmentOfferingAdditionalViewForm: FC<
  AppointmentOfferingAdditionalViewFormProps
> = ({ labelTranslations }) => {
  const { isSubmitting } =
    useFormikContext<AppointmentOfferingAdditionalViewFormValues>();

  return (
    <FormikTextField
      required
      fullWidth
      disabled={isSubmitting}
      name={formNames.durationMinutes}
      label={labelTranslations.labelDurationMinutes}
      type="number"
    />
  );
};

export default AppointmentOfferingAdditionalViewForm;
