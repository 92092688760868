import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentColumnItemFullPackages,
  AppointmentRowItem,
  AppointmentRowItemPackage,
} from "../types";
import Box from "../../../Unknown/Box";
import Typography from "../../../Unknown/Typography";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnTranslations = {
  columnFullPackages: string;
};

type AmountFormatter = (cost: number, currencyCode: string) => string;

const normalizePackage = ({
  formatAmount,
  currencyCode,
  packageItem,
}: {
  formatAmount: AmountFormatter;
  currencyCode: string;
  packageItem: AppointmentRowItemPackage;
}) => {
  const { name, cost, offerings } = packageItem;

  const formattedCost = formatAmount(cost, currencyCode);

  const formattedOfferings = offerings
    .map((offering) => offering.name)
    .join(", ");

  return `${name} - ${formattedCost}: ${formattedOfferings}`;
};

const getColumnFullPackages = (params: {
  columnItem: AppointmentColumnItemFullPackages;
  translations: ColumnTranslations;
  formatAmount: AmountFormatter;
}) => {
  const { columnItem, translations, formatAmount } = params;

  const { field } = columnItem;

  const column: GridColDef<AppointmentRowItem, string> = {
    field,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnFullPackages,
    valueGetter: ({ row }) => {
      const { packages, center } = row;
      const { currencyCode } = center;

      const formattedPackages = packages.map((packageItem) => {
        return normalizePackage({ packageItem, currencyCode, formatAmount });
      });

      return formattedPackages.join(";");
    },
    renderCell: ({ row }) => {
      const { packages, center } = row;
      const { currencyCode } = center;

      return (
        <Box display="flex" flexDirection="column">
          {packages.map((packageItem) => {
            const text = normalizePackage({
              packageItem,
              currencyCode,
              formatAmount,
            });

            return (
              <Typography key={text} variant="body2" color="inherit">
                {text}
              </Typography>
            );
          })}
        </Box>
      );
    },
  };

  return column;
};

export default getColumnFullPackages;
