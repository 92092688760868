import React from "react";
import { GridColDef, GridComparatorFn } from "../../../Unknown/DataGrid";
import {
  AppointmentOfferingColumnItemStatus,
  AppointmentOfferingRowItem,
} from "../types";
import Chip, { ChipProps } from "../../../Unknown/Chip";

type ColumnValue = {
  status: AppointmentOfferingRowItem["status"];
  statusTranslation: string;
  color: ChipProps["color"];
};

const sortComparator: GridComparatorFn<ColumnValue> = (
  currentValue,
  compareValue,
) => {
  const statusComparator = currentValue.statusTranslation.localeCompare(
    compareValue.statusTranslation,
  );

  return statusComparator;
};

export type ColumnStatusTranslations = {
  columnStatus: string;
  statusUnread: string;
  statusPending: string;
  statusReading: string;
  statusRead: string;
};

const statuses: Record<
  AppointmentOfferingRowItem["status"],
  keyof ColumnStatusTranslations
> = {
  unread: "statusUnread",
  pending: "statusPending",
  reading: "statusReading",
  read: "statusRead",
};

const colors: Record<AppointmentOfferingRowItem["status"], ChipProps["color"]> =
  {
    unread: undefined,
    pending: undefined,
    reading: "primary",
    read: "success",
  };

const getColumnStatus = (params: {
  columnItem: AppointmentOfferingColumnItemStatus;
  translations: ColumnStatusTranslations;
}) => {
  const { columnItem, translations } = params;

  const column: GridColDef<AppointmentOfferingRowItem, ColumnValue> = {
    field: columnItem.field,
    disableColumnMenu: true,
    headerName: translations.columnStatus,
    sortComparator,
    getApplyQuickFilterFn: () => null,
    valueGetter: (getterParams) => {
      const { status } = getterParams.row;
      const statusTranslationKey = status in statuses ? statuses[status] : null;
      const statusTranslation = statusTranslationKey
        ? translations[statusTranslationKey]
        : "";
      const color = colors[status];

      return { status, statusTranslation, color };
    },

    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      const { statusTranslation, color } = renderParams.value;

      return (
        <Chip variant="outlined" label={statusTranslation} color={color} />
      );
    },
  };

  return column;
};

export default getColumnStatus;
