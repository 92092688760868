import React, { FC } from "react";
import { getIn, useFormikContext } from "formik";
import FormikTextField from "../../Unknown/FormikTextField";
import Grid from "../../Unknown/Grid";
import MenuItem from "../../Unknown/MenuItem";
import { PatientEditFormTranslations } from "./types";
import useRegions from "./useRegions";
import { defaultGridItemProps, inputSpacing } from "./constants";
import getFormNames from "./getFormNames";
import Button from "../../Unknown/Button";
import useTranslations from "./useTranslations";
import CircularProgress from "../../Unknown/CircularProgress";
import FormikPhoneInput from "../../Unknown/FormikPhoneInput";
import formatEmail from "../../../common/formatEmail";
import { useRaceOptions } from "./useRaceOptions";
import { useSexOptions } from "./useSexOptions";

export type PatientEditFormProps = {
  translations: PatientEditFormTranslations;
  namePrefix?: string;
  isEmailShown?: boolean;
  isPhoneShown?: boolean;
  isConfirmationCodeShown?: boolean;
  isSendingCode?: boolean;
  isSendCodeDisabled?: boolean;
  handleSendCodeClick?: () => void | Promise<void>;
};

const PatientEditForm: FC<PatientEditFormProps> = ({
  translations,
  namePrefix,
  isEmailShown,
  isPhoneShown,
  isConfirmationCodeShown,
  isSendingCode,
  isSendCodeDisabled,
  handleSendCodeClick,
}) => {
  const { btnSendCode } = useTranslations();
  const formNames = getFormNames(namePrefix);
  const raceOptions = useRaceOptions();
  const sexOptions = useSexOptions();

  const { isSubmitting, values, setFieldValue } = useFormikContext();

  const countryIsoCodeValue = getIn(values, formNames.countryIsoCode);

  const { countries, states, onCountryChange } = useRegions({
    countryIsoCodeValue,
    stateIsoCodeName: formNames.stateIsoCode,
  });

  const defaultInputProps = { disabled: isSubmitting, fullWidth: true };

  return (
    <Grid container spacing={inputSpacing} direction="row">
      <Grid item container spacing={inputSpacing}>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            required
            name={formNames.firstName}
            label={translations.labelFirstName}
          />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            required
            name={formNames.lastName}
            label={translations.labelLastName}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={inputSpacing}>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            required
            type="date"
            name={formNames.birthDate}
            label={translations.labelBirthDate}
          />
        </Grid>

        <Grid {...defaultGridItemProps}>
          <FormikTextField
            select
            fullWidth
            disabled={isSubmitting}
            name={formNames.sex}
            label={translations.labelSex}
          >
            {sexOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
      </Grid>
      <Grid item container spacing={inputSpacing}>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            select
            fullWidth
            disabled={isSubmitting}
            name={formNames.race}
            label={translations.labelRace}
          >
            {raceOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        {isPhoneShown && (
          <Grid {...defaultGridItemProps}>
            <FormikPhoneInput
              name={formNames.phoneNumber}
              label={translations.labelPhoneNumber}
              fullWidth
              required
              disabled={isSubmitting}
              autoComplete="off"
            />
          </Grid>
        )}
      </Grid>
      {isEmailShown && (
        <Grid item container spacing={inputSpacing}>
          <Grid {...defaultGridItemProps}>
            <FormikTextField
              {...defaultInputProps}
              required
              name={formNames.email}
              label={translations.labelEmail}
              onChange={({ target }) =>
                setFieldValue(formNames.email, formatEmail(target.value))
              }
            />
          </Grid>
        </Grid>
      )}
      {isConfirmationCodeShown && (
        <Grid
          item
          container
          spacing={inputSpacing}
          direction="row"
          wrap="nowrap"
        >
          <Grid {...defaultGridItemProps}>
            <FormikTextField
              {...defaultInputProps}
              required
              name={formNames.confirmationCode}
              label={translations.labelConfirmationCode}
            />
          </Grid>
          <Grid {...defaultGridItemProps} mt={1.4}>
            <Button
              onClick={handleSendCodeClick}
              variant="contained"
              size="large"
              fullWidth
              startIcon={isSendingCode ? <CircularProgress size={20} /> : null}
              disabled={isSendCodeDisabled || isSendingCode}
            >
              {btnSendCode}
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item container spacing={inputSpacing}>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            name={formNames.primaryAddress}
            label={translations.labelPrimaryAddress}
          />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            name={formNames.secondaryAddress}
            label={translations.labelSecondaryAddress}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={inputSpacing}>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            name={formNames.city}
            label={translations.labelCity}
          />
        </Grid>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            name={formNames.postalCode}
            label={translations.labelPostalCode}
          />
        </Grid>
      </Grid>

      <Grid item spacing={inputSpacing} container>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            name={formNames.stateIsoCode}
            label={translations.labelStateIsoCode}
            select
          >
            {states.map((state) => (
              <MenuItem key={state.isoCode} value={state.isoCode}>
                {state.name}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid {...defaultGridItemProps}>
          <FormikTextField
            {...defaultInputProps}
            name={formNames.countryIsoCode}
            label={translations.labelCountryIsoCode}
            onChange={onCountryChange}
            select
          >
            {countries.map((country) => (
              <MenuItem key={country.isoCode} value={country.isoCode}>
                {country.name}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientEditForm;
