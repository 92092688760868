import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      isStat: formatMessage(messages.isStat),
      requiresParentGuardian: formatMessage(messages.requiresParentGuardian),
      additionalViews: formatMessage(messages.additionalViews),
      hasPriority: formatMessage(messages.hasPriority),
      hasUnexpectedFindings: formatMessage(messages.hasUnexpectedFindings),
      receiptConfirmed: formatMessage(messages.receiptConfirmed),
      value: formatMessage(messages.value),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
