import React, { FC } from "react";
import { AddOn } from "../../Center/CenterSettingsContainer/types";
import DataGrid from "../../Unknown/DataGrid";
import useGridColumns from "./useGridColumns";
import useStyles from "./useStyles";

export type CenterAddOnsViewProps = {
  addOns: AddOn[];
  currencyCode: string;
  onArchiveClick: (addOnId: string) => void;
  onEditClick: (addOnId: string) => void;
};

const CenterAddOnsView: FC<CenterAddOnsViewProps> = ({
  addOns,
  currencyCode,
  onArchiveClick,
  onEditClick,
}) => {
  const { root } = useStyles();
  const { columns } = useGridColumns({
    currencyCode,
    onArchiveClick,
    onEditClick,
  });

  return (
    <DataGrid
      autoHeight
      getRowHeight={() => "auto"}
      className={root}
      columns={columns}
      rows={addOns}
      hideFooter
      hideFooterRowCount
      disableRowSelectionOnClick
      initialState={{
        sorting: { sortModel: [{ field: "name", sort: "asc" }] },
      }}
      sortingOrder={["desc", "asc"]}
    />
  );
};

export default CenterAddOnsView;
