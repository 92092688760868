import { useLocation } from "react-router-dom";

const useActiveProfileOptionId = (): string | undefined => {
  const location = useLocation();
  const [permissionId] = location.pathname.split("/").filter(Boolean);

  return permissionId;
};

export default useActiveProfileOptionId;
