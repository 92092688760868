import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import supabaseClient from "../../../common/supabaseClient";
import {
  allowedOfferingVisibility,
  allowedPackageVisibility,
} from "./constants";

type SettingsCenterAddress = DatabaseRow<"center_addresses">;
type SettingsCenterOpeningHours = DatabaseRow<"center_operating_hours">;
type SettingsCenterConfigs = DatabaseRow<"center_configs">;
type SettingsCenterAddOn = DatabaseRow<"center_add_ons">;
type SettingsCenterOffering = DatabaseRow<"center_offerings"> & {
  visibility: Extract<
    DatabaseRow<"center_offerings">["visibility"],
    (typeof allowedOfferingVisibility)[number]
  >;
  offering: DatabaseRow<"offerings">;
};

type SettingsCenterPackage = DatabaseRow<"center_packages"> & {
  visibility: Extract<
    DatabaseRow<"center_packages">["visibility"],
    (typeof allowedPackageVisibility)[number]
  >;
  center_offerings: DatabaseRow<"center_offerings">[];
};

export type SettingsCenter = DatabaseRow<"centers"> & {
  center_addresses: SettingsCenterAddress[];
  center_operating_hours: SettingsCenterOpeningHours[];
  center_configs: SettingsCenterConfigs[];
  center_offerings: SettingsCenterOffering[];
  center_packages: SettingsCenterPackage[];
  center_add_ons: SettingsCenterAddOn[];
};

const supabaseSettingsCenterRequest = `
*,
center_addresses ( * ),
center_operating_hours ( * ),
center_configs ( * ),
center_packages (
  *,
  center_offerings (
    *,
    offering: offering_id ( * )
  )
),
center_offerings (
  *,
  offering: offering_id ( * )
),
center_add_ons ( * )
`;

const getSettingsCenterData = async (centerId: string) => {
  const { error, data } = await supabaseClient
    .from<SettingsCenter>("centers")
    .select(supabaseSettingsCenterRequest)
    .eq("id", centerId)
    .limit(1, { foreignTable: "center_configs" })
    .in(
      "center_offerings.visibility" as keyof SettingsCenter,
      allowedOfferingVisibility,
    )
    .is("center_offerings.center_package_id" as keyof SettingsCenter, null)
    .in(
      "center_packages.visibility" as keyof SettingsCenter,
      allowedPackageVisibility,
    )
    .eq("center_add_ons.visibility" as keyof SettingsCenter, "public")
    .not("center_offerings.offering_id" as keyof SettingsCenter, "is", null)
    .not(
      "center_packages.center_offerings.offering_id" as keyof SettingsCenter,
      "is",
      null,
    )
    .limit(1)
    .single();

  if (error) throw new Error(error.message);

  return data;
};

export default getSettingsCenterData;
