import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Appointment.AppointmentCheckInDialog.title",
    defaultMessage: "Check In Customer",
  },
  description: {
    id: "Appointment.AppointmentCheckInDialog.description",
    defaultMessage:
      "In order to check in the customer the following actions must have been performed:",
  },
  firstCheckText: {
    id: "Appointment.AppointmentCheckInDialog.firstCheckText",
    defaultMessage: "Customer ID has been validated",
  },
  secondCheckText: {
    id: "Appointment.AppointmentCheckInDialog.secondCheckText",
    defaultMessage:
      "Customer has performed the intake process and filled in the required forms",
  },
  btnCancel: {
    id: "Appointment.AppointmentCheckInDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnSubmit: {
    id: "Appointment.AppointmentCheckInDialog.btnSubmit",
    defaultMessage: "Check in Customer",
  },
});

export default messages;
