import { addMilliseconds, parseISO } from "date-fns";
import { getTimezoneOffsetMillis } from "../../../common/dateHelpers";
import { AppointmentNoteListCardContainerProps } from "../../AppointmentNote/AppointmentNoteListCardContainer";
import { Appointment } from "./types";

type InitialNote =
  AppointmentNoteListCardContainerProps["initialNotes"][number];

const getInitialNotes = (appointment: Appointment | null) => {
  if (!appointment) return [];

  const { center, appointment_notes } = appointment;

  const centerTimezoneOffsetMillis = getTimezoneOffsetMillis(center.timezone);

  const locale = center.region;

  const initialNotes = appointment_notes.map<InitialNote>(
    (appointment_note) => {
      const { id, created_at, description, center_user, appointment_offering } =
        appointment_note;

      const { first_name, last_name } = center_user;

      const createdAtUTC = parseISO(created_at);

      const createdAtCenterTimezone = addMilliseconds(
        createdAtUTC,
        centerTimezoneOffsetMillis,
      );

      const appointmentOffering = appointment_offering
        ? {
            id: appointment_offering.id,
            modalityName:
              appointment_offering.modality_name ||
              appointment_offering.center_offering.name,
          }
        : undefined;

      const result: InitialNote = {
        id,
        createdAtCenterTimezone,
        locale,
        description,
        centerUser: {
          firstName: first_name,
          lastName: last_name,
        },
        appointmentOffering,
      };

      return result;
    },
  );

  return initialNotes;
};

export default getInitialNotes;
