import { Formik } from "formik";
import React, { FC } from "react";
import CenterDiscountForm, {
  CenterDiscountFormValues,
} from "../CenterDiscountForm";
import { Discount } from "../CenterSettingsContainer/types";
import CenterSettingsFormikDialog from "../CenterSettingsFormikDialog";
import useCenterDiscountForm from "./useCenterDiscountForm";
import useTranslations from "./useTranslations";

export type CenterDiscountFormikProps = {
  isOpened: boolean;
  onModalClose: () => void;
  centerId: string;
  centerDiscountData: Discount;
  handlePostSubmit: (values: CenterDiscountFormValues) => void | Promise<void>;
};

const CenterDiscountFormik: FC<CenterDiscountFormikProps> = ({
  isOpened,
  onModalClose,
  centerId,
  centerDiscountData,
  handlePostSubmit,
}) => {
  const { title } = useTranslations();
  const { formikConfig } = useCenterDiscountForm({
    centerId,
    centerDiscountData,
    handlePostSubmit,
  });

  return (
    <Formik {...formikConfig}>
      <CenterSettingsFormikDialog
        title={title}
        isOpened={isOpened}
        onClose={onModalClose}
      >
        <CenterDiscountForm />
      </CenterSettingsFormikDialog>
    </Formik>
  );
};

export default CenterDiscountFormik;
