import { Formik } from "formik";
import React, { FC } from "react";
import { CenterOfferingFormValues } from "../CenterOfferingForm/types";
import CenterSettingsFormikDialog from "../../Center/CenterSettingsFormikDialog";
import useForm from "./useForm";
import CenterOfferingForm from "../CenterOfferingForm";
import useLabelTranslations from "./useLabelTranslations";

export type CenterOfferingFormikProps = {
  title: string;
  isOpened: boolean;
  onModalClose: () => void;
  initialValues: CenterOfferingFormValues;
  handleSubmit: (values: CenterOfferingFormValues) => void | Promise<void>;
  currencyCode: string;
  minPrice: number;
};

const CenterOfferingFormik: FC<CenterOfferingFormikProps> = ({
  title,
  isOpened,
  onModalClose,
  initialValues,
  handleSubmit,
  minPrice,
  currencyCode,
}) => {
  const { formikConfig } = useForm({
    initialValues,
    minPrice,
    handleSubmit,
  });

  const labelTranslations = useLabelTranslations();

  return (
    <Formik {...formikConfig}>
      <CenterSettingsFormikDialog
        title={title}
        isOpened={isOpened}
        onClose={onModalClose}
      >
        <CenterOfferingForm
          currencyCode={currencyCode}
          labelTranslations={labelTranslations}
        />
      </CenterSettingsFormikDialog>
    </Formik>
  );
};

export default CenterOfferingFormik;
