import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "AppointmentFile.AppointmentFileGridContainer.title",
    defaultMessage: "Files",
  },
  btnAddFiles: {
    id: "AppointmentFile.AppointmentFileGridContainer.btnAddFiles",
    defaultMessage: "Upload file(s)",
  },
});
