import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      headerName: formatMessage(messages.headerName),
      headerStudies: formatMessage(messages.headerStudies),
      sideLeft: formatMessage(messages.sideLeft),
      sideRight: formatMessage(messages.sideRight),
      btnAdditionalViewsRequired: formatMessage(
        messages.btnAdditionalViewsRequired,
      ),
      btnEdit: formatMessage(messages.btnEdit),
      btnRead: formatMessage(messages.btnRead),
      btnContinueReading: formatMessage(messages.btnContinueReading),
    };
  }, [formatMessage]);

  return translations;
};
export default useTranslations;
