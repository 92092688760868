import { Formik } from "formik";
import React, { FC } from "react";
import { CenterAddOnFormValues } from "../CenterAddOnForm/types";
import CenterSettingsFormikDialog from "../../Center/CenterSettingsFormikDialog";
import useForm from "./useForm";
import CenterAddOnForm from "../CenterAddOnForm";
import useLabelTranslations from "./useLabelTranslations";
import { AddOnParams } from "./types";

export type CenterAddOnFormikProps = {
  title: string;
  isOpened: boolean;
  onModalClose: () => void;
  centerAddOnData: AddOnParams;
  handleSubmit: (values: CenterAddOnFormValues) => void | Promise<void>;
  currencyCode: string;
};

const CenterAddOnFormik: FC<CenterAddOnFormikProps> = ({
  title,
  isOpened,
  onModalClose,
  centerAddOnData,
  handleSubmit,
  currencyCode,
}) => {
  const { formikConfig } = useForm({
    centerAddOnData,
    handleSubmit,
  });

  const labelTranslations = useLabelTranslations();

  return (
    <Formik {...formikConfig}>
      <CenterSettingsFormikDialog
        title={title}
        isOpened={isOpened}
        onClose={onModalClose}
        maxWidth="sm"
      >
        <CenterAddOnForm
          currencyCode={currencyCode}
          labelTranslations={labelTranslations}
        />
      </CenterSettingsFormikDialog>
    </Formik>
  );
};

export default CenterAddOnFormik;
