import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import subYears from "date-fns/subYears";
import { FormValues } from "./types";

export const baseFormInitialValues: FormValues = {
  information: {
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    confirmationCode: "",
    primaryAddress: "",
    secondaryAddress: "",
    city: "",
    postalCode: "",
    stateIsoCode: "",
    countryIsoCode: "",
    race: "",
    sex: "",
  },
};

export const confirmationCodeLength = 6;

export const minBirthDate = startOfDay(subYears(Date.now(), 100));
export const maxBirthDate = endOfDay(Date.now());
