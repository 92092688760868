import { Theme, darken } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    pressedRoot: {
      backgroundColor: darken(palette.secondary.main, 0.15),
    },
  };
});

export default useStyles;
