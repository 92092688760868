import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import React, { FC } from "react";
import { useFormikContext, getIn } from "formik";
import Grid, { GridProps } from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import FormikSwitch from "../../Unknown/FormikSwitch";
import FormikDesktopTimePicker from "../../Unknown/FormikDesktopTimePicker";

export type CenterOperatingHoursDayInputProps = {
  nameKey: string;
  translations: {
    dayName: string;
    labelOpen: string;
    toText: string;
    labelTime: string;
  };
};

export type CenterOperatingHoursDayInputValues = {
  isOpen: boolean;
  fromMinutesAt: Date | null;
  toMinutesAt: Date | null;
};

const inputSpacing = 4;

const gridItemProps: GridProps = {
  item: true,
  flexGrow: 1,
  flexBasis: 1,
};

const getFieldNames = (key: string) => ({
  isOpen: `${key}.isOpen`,
  fromMinutesAt: `${key}.fromMinutesAt`,
  toMinutesAt: `${key}.toMinutesAt`,
});

const CenterOperatingHoursDayInput: FC<CenterOperatingHoursDayInputProps> = ({
  nameKey,
  translations,
}) => {
  const names = getFieldNames(nameKey);

  const { isSubmitting, values } = useFormikContext();

  const { dayName, labelOpen, toText, labelTime } = translations;

  const isOpen = getIn(values, names.isOpen);

  return (
    <Grid item container spacing={inputSpacing} alignItems="center">
      <Grid item width="25%">
        <Typography>{dayName}</Typography>
      </Grid>
      <Grid item width="25%">
        <FormikSwitch
          label={labelOpen}
          name={names.isOpen}
          disabled={isSubmitting}
        />
      </Grid>
      {!isOpen ? (
        <Grid item width="50%" />
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid {...gridItemProps}>
            <FormikDesktopTimePicker
              label={labelTime}
              name={names.fromMinutesAt}
              isRequired
              isDisabled={isSubmitting}
            />
          </Grid>
          <Grid item>
            <Typography>{toText}</Typography>
          </Grid>
          <Grid {...gridItemProps}>
            <FormikDesktopTimePicker
              label={labelTime}
              name={names.toMinutesAt}
              isRequired
              isDisabled={isSubmitting}
            />
          </Grid>
        </LocalizationProvider>
      )}
    </Grid>
  );
};

export default CenterOperatingHoursDayInput;
