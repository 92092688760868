import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, spacing }) => {
  return {
    textFieldRoot: {
      backgroundColor: palette.common.white,
      overflow: "hidden",
      zIndex: 0,
      "& .MuiInputBase-root": {
        position: "relative",
        overflow: "hidden",
      },
      "& .MuiInputBase-root::after": {
        content: '""',
        position: "absolute",
        top: 0,
        right: 0,
        width: 0,
        height: 0,
        display: "block",
        borderLeftWidth: 35,
        borderLeftStyle: "solid",
        borderLeftColor: "transparent",
        borderBottomWidth: 35,
        borderBottomStyle: "solid",
        borderBottomColor: "transparent",
        borderTopWidth: 35,
        borderTopStyle: "solid",
        borderTopColor: "#EEE",
        zIndex: -1,
      },
    },
    clearButtonRoot: {
      marginRight: spacing(3),
    },
    questionRoot: {
      position: "absolute",
      color: palette.common.black,
      fontSize: 14,
      cursor: "default",
      width: 24,
      height: 24,
      top: 1,
      right: 1,
      paddingLeft: 4,
      textAlign: "center",
      borderRadius: "0 0 0 100%",
    },
    tooltipTooltip: {
      backgroundColor: palette.grey[600],
      color: palette.common.white,
    },
    tooltipArrow: {
      color: palette.grey[600],
    },
  };
});

export default useStyles;
