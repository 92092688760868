import { defineMessages } from "react-intl";

const messages = defineMessages({
  errorCustomerNotFound: {
    id: "Unknown.DashboardScreen.errorCustomerNotFound",
    defaultMessage: "Customer not found",
  },
  btnEdit: {
    id: "Unknown.DashboardScreen.btnEdit",
    defaultMessage: "Edit",
  },
  btnCancel: {
    id: "Customer.CustomerInformationDrawerContainer.btnCustomerCancel",
    defaultMessage: "Cancel",
  },
  btnCustomerSave: {
    id: "Customer.CustomerInformationDrawerContainer.btnCustomerSave",
    defaultMessage: "Save",
  },
  labelFirstName: {
    id: "Customer.CustomerInformationDrawerContainer.labelFirstName",
    defaultMessage: "First name",
  },
  labelLastName: {
    id: "Customer.CustomerInformationDrawerContainer.labelLastName",
    defaultMessage: "Last name",
  },
  labelPhoneNumber: {
    id: "Customer.CustomerInformationDrawerContainer.labelPhoneNumber",
    defaultMessage: "Phone",
  },
  labelEmail: {
    id: "Customer.CustomerInformationDrawerContainer.labelEmail",
    defaultMessage: "Email",
  },
  labelConfirmationCode: {
    id: "Customer.CustomerInformationDrawerContainer.labelConfirmationCode",
    defaultMessage: "Confirmation code",
  },
  errorEmailInvalid: {
    id: "Customer.CustomerInformationDrawerContainer.errorEmailInvalid",
    defaultMessage: "Email is invalid",
  },
  errorConfirmationCodeIsInvalid: {
    id: "Customer.CustomerInformationDrawerContainer.errorConfirmationCodeIsInvalid",
    defaultMessage: "Confirmation code is invalid",
  },
  errorEmailInUse: {
    id: "Customer.CustomerInformationDrawerContainer.errorEmailInUse",
    defaultMessage:
      "This email address cannot be used as it is already in use by another customer or patient",
  },
});

export default messages;
