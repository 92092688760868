import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "AppointmentAdditionalView.AppointmentAdditionalViewDialog.title",
    defaultMessage: 'Mark Study As "Requires Additional Views"',
  },
  btnSubmit: {
    id: "AppointmentAdditionalView.AppointmentAdditionalViewDialog.btnSubmit",
    defaultMessage: "Confirm",
  },
  btnCancel: {
    id: "AppointmentAdditionalView.AppointmentAdditionalViewDialog.btnCancel",
    defaultMessage: "Cancel",
  },
});

export default messages;
