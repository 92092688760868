import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentColumnItemPatientEmail,
  AppointmentRowItem,
} from "../types";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import PatientEmail from "../../../Patient/PatientEmail";

export type ColumnPatientEmailTranslations = {
  columnPatientEmail: string;
};

const getColumnPatientEmail = (params: {
  columnItem: AppointmentColumnItemPatientEmail;
  translations: ColumnPatientEmailTranslations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnPatientEmail,
    valueGetter: (getterParams) => {
      const { appointmentPatient } = getterParams.row;

      if (!appointmentPatient) return "";

      return appointmentPatient?.email;
    },
    renderCell: ({ row }) => {
      const { appointmentPatient } = row;

      if (!appointmentPatient) return null;

      if (!appointmentPatient?.email) return null;

      return <PatientEmail email={appointmentPatient.email} />;
    },
  };
};

export default getColumnPatientEmail;
