import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import { PatientFormPreviewGridForm } from "../types";

type ColumnTranslations = {
  columnCompletedAt: string;
};

type ColumnValue = {
  completedAt: string;
};

const getColumnFormType = (params: {
  translations: ColumnTranslations;
  headerClassName?: string;
}): GridColDef<PatientFormPreviewGridForm, ColumnValue> => {
  const { translations, headerClassName } = params;

  return {
    field: "completedAt",
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    headerName: translations.columnCompletedAt,
    headerClassName,
    valueGetter: (getterParams) => {
      const { completedAt } = getterParams.row;
      return { completedAt };
    },
    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      return (
        <Typography variant="body2">
          {renderParams.value.completedAt}
        </Typography>
      );
    },
  };
};

export default getColumnFormType;
