import AddOutlined from "@mui/icons-material/AddOutlined";
import React, { FC } from "react";
import { AddOnsData } from "../../Center/CenterSettingsContainer/types";
import CenterAddOnArchiveDialog from "../CenterAddOnArchiveDialog";
import Box from "../../Unknown/Box";
import Button from "../../Unknown/Button";
import CenterAddOnFormik from "../CenterAddOnFormik";
import CenterAddOnsView from "../CenterAddOnsView";
import useAddForm from "./useAddForm";
import useArchiveModal from "./useArchiveModal";
import useEditForm from "./useEditForm";
import useOpenedModal from "./useOpenedModal";
import useTranslations from "./useTranslations";

export type CenterAddOnsSettingsTabProps = {
  centerId: string;
  addOnsData: AddOnsData;
  refetchCenterData?: () => void | Promise<void>;
};

const CenterAddOnsSettingsTab: FC<CenterAddOnsSettingsTabProps> = ({
  centerId,
  addOnsData,
  refetchCenterData,
}) => {
  const { btnAddAddOn } = useTranslations();

  const { addOns, currencyCode } = addOnsData;

  const {
    id: addOnId,
    openedModal,
    onAddClick,
    onEditClick,
    onArchiveClick,
    onModalClose,
  } = useOpenedModal();

  const { addFormikProps } = useAddForm({
    isOpened: openedModal === "add",
    onClose: onModalClose,
    centerId,
    currencyCode,
    refetchCenterData,
  });

  const { editFormikProps } = useEditForm({
    isOpened: openedModal === "edit",
    editedAddOnId: addOnId,
    onClose: onModalClose,
    addOnsData,
    refetchCenterData,
  });

  const { archiveDialogProps } = useArchiveModal({
    isOpened: openedModal === "archive",
    addOnId,
    onClose: onModalClose,
    addOns,
    refetchCenterData,
  });

  return (
    <Box pt={7}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          startIcon={<AddOutlined />}
          onClick={onAddClick}
        >
          {btnAddAddOn}
        </Button>
      </Box>

      <Box display="flex" height="100%">
        <Box flexGrow={1} pb={3} overflow="auto">
          <CenterAddOnsView
            addOns={addOns}
            currencyCode={currencyCode}
            onArchiveClick={onArchiveClick}
            onEditClick={onEditClick}
          />
        </Box>
      </Box>

      {editFormikProps && <CenterAddOnFormik {...editFormikProps} />}
      <CenterAddOnFormik {...addFormikProps} />
      <CenterAddOnArchiveDialog {...archiveDialogProps} />
    </Box>
  );
};

export default CenterAddOnsSettingsTab;
