import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { GridColDef } from "../../Unknown/DataGrid";
import useCenterServiceTranslations from "../../CenterService/CenterServicesAppointmentPreview/useTranslations";
import useTranslations from "./useTranslations";
import getColumnAlert from "./columns/getColumnAlert";
import getColumnStatus from "./columns/getColumnStatus";
import getColumnPatientName from "./columns/getColumnPatientName";
import getColumnLocation from "./columns/getColumnLocation";
import getColumnOffering from "./columns/getColumnOffering";
import { AppointmentColumnItem, AppointmentRowItem } from "./types";
import getColumnBtnOptions from "./columns/getColumnBtnOptions";
import getColumnTime from "./columns/getColumnTime";
import getColumnBtnView from "./columns/getColumnBtnView";
import useStyles from "./useStyles";
import getColumnFullOfferings from "./columns/getColumnFullOfferings";
import getColumnFullPackages from "./columns/getColumnFullPackages";
import getColumnFullAddOns from "./columns/getColumnFullAddOns";
import getColumnCustomer from "./columns/getColumnCustomer";
import getColumnPayments from "./columns/getColumnPayments";
import getColumnBookingScenario from "./columns/getColumnBookingScenario";
import getColumnBookedBy from "./columns/getColumnBookedBy";
import getColumnCreatedAt from "./columns/getColumnCreatedAt";
import getColumnPatientAddress from "./columns/getColumnPatientAddress";
import getColumnPatientEmail from "./columns/getColumnPatientEmail";
import getColumnDate from "./columns/getColumnDate";
import getColumnLocationState from "./columns/getColumnLocationState";
import getColumnLocationZip from "./columns/getColumnLocationZip";
import getColumnPatientZip from "./columns/getColumnPatientZip";
import getColumnPatientState from "./columns/getColumnPatientState";
import getColumnBookedDate from "./columns/getColumnBookedDate";
import getColumnBookedTime from "./columns/getColumnBookedTime";
import getColumnIsPast from "./columns/getColumnIsPast";
import getColumnFlags from "./columns/getColumnFlags";
import getColumnPatientAge from "./columns/getColumnPatientAge";
import getColumnPatientDOB from "./columns/getColumnPatientDOB";
import getColumnPatientPhone from "./columns/getColumnPatientPhone";
import getColumnPatientAddressLine1 from "./columns/getColumnPatientAddressLine1";
import getColumnPatientAddressLine2 from "./columns/getColumnPatientAddressLine2";
import getColumnPatientCity from "./columns/getColumnPatientCity";
import getColumnPatientId from "./columns/getColumnPatientId";
import getColumnPatientRace from "./columns/getColumnPatientRace";
import getColumnPatientSex from "./columns/getColumnPatientSex";
import { useRaceTranslations } from "../../Patient/PatientEditForm/useRaceOptions";
import { useSexTranslations } from "../../Patient/PatientEditForm/useSexOptions";
import getColumnAppointmentTotal from "./columns/getColumnAppointmentTotal";
import getColumnCenterServices from "./columns/getColumnCenterServices";
import getColumnAddOns from "./columns/getColumnAddOns";
import getColumnPatient from "./columns/getColumnPatient";

export type DashboardColumnsParams = {
  columnItems: AppointmentColumnItem[];
};

const useDashboardColumns = (params: DashboardColumnsParams) => {
  const { columnTranslations } = useTranslations();
  const centerServiceTranslations = useCenterServiceTranslations();
  const { hideRightSeparatorRoot, hideHeaderTextRoot } = useStyles();
  const navigate = useNavigate();
  const { role } = useParams();
  const raceTranslations = useRaceTranslations();
  const sexTranslations = useSexTranslations();

  const { formatNumber } = useIntl();

  const columns = useMemo((): GridColDef<AppointmentRowItem>[] => {
    const { columnItems } = params;

    const formatAmount = (price: number, currencyCode: string) => {
      return formatNumber(price, {
        style: "currency",
        currency: currencyCode,
      }).replace(".00", "");
    };

    const innerColumns = columnItems.map((columnItem) => {
      switch (columnItem.field) {
        case "alert": {
          return getColumnAlert({
            columnItem,
            translations: {
              columnAlert: columnTranslations.columnAlert,
              tooltipStat: columnTranslations.tooltipStat,
              tooltipAdditionalViews: columnTranslations.tooltipAdditionalViews,
              tooltipUnexpectedFindings:
                columnTranslations.tooltipUnexpectedFindings,
              tooltipReceiptConfirmed:
                columnTranslations.tooltipReceiptConfirmed,
              tooltipHasPriority: columnTranslations.tooltipHasPriority,
              tooltipRequiresGuardian:
                columnTranslations.tooltipRequiresGuardian,
              containsAny: columnTranslations.containsAny,
              containsAll: columnTranslations.containsAll,
            },
            headerClassName: hideHeaderTextRoot,
          });
        }
        case "flags": {
          return getColumnFlags({
            columnItem,
            translations: {
              columnAlert: columnTranslations.columnFlags,
              flagStat: columnTranslations.flagStat,
              flagAdditionalViews: columnTranslations.flagAdditionalViews,
              flagHasPriority: columnTranslations.flagHasPriority,
              flagUnexpectedFindings: columnTranslations.flagUnexpectedFindings,
              flagRequiresGuardian: columnTranslations.flagRequiresGuardian,
              flagReceiptConfirmed: columnTranslations.flagReceiptConfirmed,
              containsAny: columnTranslations.containsAny,
              containsAll: columnTranslations.containsAll,
            },
          });
        }
        case "status": {
          return getColumnStatus({
            columnItem,
            translations: {
              columnStatus: columnTranslations.columnStatus,
              statusCheckout: columnTranslations.statusCheckout,
              statusIncomplete: columnTranslations.statusIncomplete,
              statusAbandoned: columnTranslations.statusAbandoned,
              statusConfirmed: columnTranslations.statusConfirmed,
              statusCanceled: columnTranslations.statusCanceled,
              statusIntakeComplete: columnTranslations.statusIntakeComplete,
              statusCheckedIn: columnTranslations.statusCheckedIn,
              statusNoShow: columnTranslations.statusNoShow,
              statusReportsError: columnTranslations.statusReportsError,
              statusReportsSent: columnTranslations.statusReportsSent,
              statusStudiesRead: columnTranslations.statusStudiesRead,
              statusStudiesUnread: columnTranslations.statusStudiesUnread,
            },
          });
        }
        case "patient": {
          return getColumnPatient({
            columnItem,
            translations: {
              columnPatient: columnTranslations.columnPatient,
              years: columnTranslations.years,
            },
            onNameClick: (id: string) =>
              navigate(`/${role}/dashboard/patient/${id}`),
          });
        }
        case "patientName": {
          return getColumnPatientName({
            columnItem,
            translations: {
              columnPatientName: columnTranslations.columnPatientName,
            },
            onNameClick: (id: string) =>
              navigate(`/${role}/dashboard/patient/${id}`),
          });
        }
        case "patientAge": {
          return getColumnPatientAge({
            columnItem,
            translations: {
              columnPatientAge: columnTranslations.columnPatientAge,
            },
          });
        }
        case "appointmentTotal": {
          return getColumnAppointmentTotal({
            columnItem,
            translations: {
              columnAppointmentTotal: columnTranslations.columnAppointmentTotal,
            },
          });
        }
        case "patientDOB": {
          return getColumnPatientDOB({
            columnItem,
            translations: {
              columnPatientDOB: columnTranslations.columnPatientDOB,
            },
          });
        }
        case "patientId": {
          return getColumnPatientId({
            columnItem,
            translations: {
              columnPatientId: columnTranslations.columnPatientId,
            },
          });
        }
        case "patientRace": {
          return getColumnPatientRace({
            columnItem,
            translations: {
              columnPatientRace: columnTranslations.columnPatientRace,
              raceOptions: raceTranslations,
            },
          });
        }
        case "patientSex": {
          return getColumnPatientSex({
            columnItem,
            translations: {
              columnPatientSex: columnTranslations.columnPatientSex,
              sexOptions: sexTranslations,
            },
          });
        }
        case "patientPhone": {
          return getColumnPatientPhone({
            columnItem,
            translations: {
              columnPatientPhone: columnTranslations.columnPatientPhone,
            },
          });
        }
        case "date": {
          return getColumnDate({
            columnItem,
            translations: {
              columnDate: columnTranslations.columnDate,
            },
          });
        }
        case "time": {
          return getColumnTime({
            columnItem,
            translations: {
              columnTime: columnTranslations.columnTime,
            },
          });
        }
        case "bookedDate": {
          return getColumnBookedDate({
            columnItem,
            translations: {
              columnBookedDate: columnTranslations.columnBookedDate,
            },
          });
        }
        case "bookedTime": {
          return getColumnBookedTime({
            columnItem,
            translations: {
              columnBookedTime: columnTranslations.columnBookedTime,
            },
          });
        }
        case "location": {
          return getColumnLocation({
            columnItem,
            translations: {
              columnLocation: columnTranslations.columnLocation,
            },
          });
        }
        case "locationState": {
          return getColumnLocationState({
            columnItem,
            translations: {
              columnLocationState: columnTranslations.columnLocationState,
            },
          });
        }
        case "locationZip": {
          return getColumnLocationZip({
            columnItem,
            translations: {
              columnLocationZip: columnTranslations.columnLocationZip,
            },
          });
        }
        case "offering": {
          return getColumnOffering({
            columnItem,
            translations: {
              columnOffering: columnTranslations.columnOffering,
              columnOfferingStudies: columnTranslations.columnOfferingStudies,
            },
            centerServiceTranslations,
          });
        }
        case "centerServices": {
          return getColumnCenterServices({
            columnItem,
            translations: {
              columnOffering: columnTranslations.columnOffering,
            },
            centerServiceTranslations,
          });
        }
        case "btnOptions": {
          return getColumnBtnOptions({
            columnItem,
            translations: {
              columnBtnOptions: columnTranslations.columnBtnOptions,
              optionsBtnCheckIn: columnTranslations.optionsBtnCheckIn,
              optionsBtnLogIn: columnTranslations.optionsBtnLogIn,
            },
            headerClassName: `${hideRightSeparatorRoot} ${hideHeaderTextRoot}`,
          });
        }
        case "btnView": {
          return getColumnBtnView({
            columnItem,
            translations: {
              columnBtnView: columnTranslations.columnBtnView,
              btnView: columnTranslations.btnView,
            },
            handleClick: (id) => navigate(`/${role}/appointment/${id}`),
            headerClassName: `${hideRightSeparatorRoot} ${hideHeaderTextRoot}`,
          });
        }
        case "fullOfferings": {
          return getColumnFullOfferings({
            columnItem,
            translations: {
              columnFullOfferings: columnTranslations.columnFullOfferings,
              sideRight: columnTranslations.sideRight,
              sideLeft: columnTranslations.sideLeft,
            },
            formatAmount,
          });
        }
        case "fullPackages": {
          return getColumnFullPackages({
            columnItem,
            translations: {
              columnFullPackages: columnTranslations.columnFullPackages,
            },
            formatAmount,
          });
        }
        case "addOns": {
          return getColumnAddOns({
            columnItem,
            translations: {
              columnAddOns: columnTranslations.columnFullAddOns,
            },
          });
        }
        case "fullAddOns": {
          return getColumnFullAddOns({
            columnItem,
            translations: {
              columnFullAddOns: columnTranslations.columnFullAddOns,
            },
            formatAmount,
          });
        }
        case "customer": {
          return getColumnCustomer({
            columnItem,
            translations: {
              columnCustomer: columnTranslations.columnCustomer,
            },
          });
        }
        case "payments": {
          return getColumnPayments({
            columnItem,
            translations: {
              columnPayments: columnTranslations.columnPayments,
              providerStripe: columnTranslations.providerStripe,
              providerCash: columnTranslations.providerCash,
              providerUnknown: columnTranslations.providerUnknown,
            },
          });
        }
        case "bookingScenario": {
          return getColumnBookingScenario({
            columnItem,
            translations: {
              columnBookingScenario: columnTranslations.columnBookingScenario,
              scenarioPatient: columnTranslations.scenarioPatient,
              scenarioLegalGuardian: columnTranslations.scenarioLegalGuardian,
              scenarioOtherPerson: columnTranslations.scenarioOtherPerson,
            },
          });
        }
        case "bookedBy": {
          return getColumnBookedBy({
            columnItem,
            translations: {
              columnBookedBy: columnTranslations.columnBookedBy,
            },
          });
        }
        case "createdAt": {
          return getColumnCreatedAt({
            columnItem,
            translations: {
              columnCreatedAt: columnTranslations.columnCreatedAt,
            },
          });
        }
        case "patientAddress": {
          return getColumnPatientAddress({
            columnItem,
            translations: {
              columnPatientAddress: columnTranslations.columnPatientAddress,
            },
          });
        }
        case "patientState": {
          return getColumnPatientState({
            columnItem,
            translations: {
              columnPatientState: columnTranslations.columnPatientState,
            },
          });
        }
        case "patientZip": {
          return getColumnPatientZip({
            columnItem,
            translations: {
              columnPatientZip: columnTranslations.columnPatientZip,
            },
          });
        }
        case "patientEmail": {
          return getColumnPatientEmail({
            columnItem,
            translations: {
              columnPatientEmail: columnTranslations.columnPatientEmail,
            },
          });
        }
        case "patientAddressLine1": {
          return getColumnPatientAddressLine1({
            columnItem,
            translations: {
              columnPatientAddressLine1:
                columnTranslations.columnPatientAddressLine1,
            },
          });
        }
        case "patientCity": {
          return getColumnPatientCity({
            columnItem,
            translations: {
              columnPatientCity: columnTranslations.columnPatientCity,
            },
          });
        }
        case "patientAddressLine2": {
          return getColumnPatientAddressLine2({
            columnItem,
            translations: {
              columnPatientAddressLine2:
                columnTranslations.columnPatientAddressLine2,
            },
          });
        }
        case "isPast": {
          return getColumnIsPast({
            columnItem,
          });
        }
        default: {
          throw new Error("Column is not defined");
        }
      }
    });

    return innerColumns;
  }, [
    params,
    formatNumber,
    columnTranslations,
    hideHeaderTextRoot,
    navigate,
    role,
    raceTranslations,
    sexTranslations,
    centerServiceTranslations,
    hideRightSeparatorRoot,
  ]);
  return columns;
};

export default useDashboardColumns;
