import React, { GridColDef } from "../../../Unknown/DataGrid";
import Box from "../../../Unknown/Box";
import Typography from "../../../Unknown/Typography";
import { AppointmentFile } from "../types";

type ColumnValue = Date;

type ColumnTranslations = {
  headerUploadedAt: string;
};

type ColumnParams = {
  translations: ColumnTranslations;
  headerClassName: string;
  uploadedAtTimeClassName: string;
};

const getColumnDateBirth = ({
  translations,
  headerClassName,
  uploadedAtTimeClassName,
}: ColumnParams): GridColDef<AppointmentFile, ColumnValue> => {
  const { headerUploadedAt } = translations;
  return {
    field: "uploadedAt",
    disableColumnMenu: true,
    flex: 1,
    headerName: headerUploadedAt,
    sortable: false,
    headerClassName,
    renderCell: (renderParams) => {
      const { value } = renderParams;
      if (!value) return null;

      const date = value.toLocaleDateString();
      const time = value.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      return (
        <Box display="flex" flexDirection="column">
          <Typography variant="body2">{date}</Typography>
          <Typography className={uploadedAtTimeClassName}>{time}</Typography>
        </Box>
      );
    },
  };
};

export default getColumnDateBirth;
