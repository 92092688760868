import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Typography from "../../Unknown/Typography";
import Grid from "../../Unknown/Grid";
import useStyles from "./useStyles";
import messages from "./messages";

interface AppointmentAdditionalViewProps {
  link: string;
  title: string;
  linkText: string;
}

const AppointmentAdditionalView: React.FC<AppointmentAdditionalViewProps> = ({
  title,
  link,
  linkText,
}) => {
  const { labelRoot, linkRoot } = useStyles();

  return (
    <Grid item>
      <Typography className={labelRoot}>
        <FormattedMessage {...messages.additionalViews} />
      </Typography>
      <Typography variant="body2">
        <Typography component="span" variant="body2">
          {title}
        </Typography>
        <Typography
          ml={2}
          variant="body2"
          className={linkRoot}
          component={Link}
          to={link}
        >
          {linkText}
        </Typography>
      </Typography>
    </Grid>
  );
};

export default AppointmentAdditionalView;
