import { useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../AppContext";

const usePatientInformationDrawerContainer = () => {
  const { role, patientMrn } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    patientsSearch: { setSearchValue },
  } = useAppContext();

  const handleClose = useCallback(() => {
    navigate(`/${role}/search${search}`);
  }, [navigate, role, search]);

  const handleCustomerClick = useCallback(
    (customerId: string) => {
      navigate(`/${role}/search/customer/${customerId}${search}`);
    },
    [navigate, role, search],
  );

  const handleAppointmentClick = (id: string) => {
    setSearchValue("");
    navigate(`/${role}/appointment/${id}`);
  };

  return {
    isOpen: !!patientMrn,
    props: {
      patientMrn,
      isOpen: !!patientMrn,
      handleClose,
      handleCustomerClick,
      handleAppointmentClick,
    },
  };
};

export default usePatientInformationDrawerContainer;
