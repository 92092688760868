import React, { FC } from "react";
import { Formik } from "formik";
import AppointmentTechnologistNoteEditDialog from "../AppointmentTechnologistNoteEditDialog";
import useFormikProps from "./useFormikProps";

export type AppointmentTechnologistNoteEditDialogContainerProps = {
  isOpen: boolean;
  text: string;
  appointmentId?: string | null;
  errorMessage: string | null;
  setErrorMessage: (message: string | null) => void;
  handleClose: () => void;
  handleEditNote: ({ text }: { text: string }) => void | Promise<void>;
};

const AppointmentTechnologistNoteEditDialogContainer: FC<
  AppointmentTechnologistNoteEditDialogContainerProps
> = ({
  isOpen,
  appointmentId,
  text,
  errorMessage,
  setErrorMessage,
  handleClose,
  handleEditNote,
}) => {
  const onClose = () => {
    handleClose();
  };

  const formikProps = useFormikProps({
    setErrorMessage,
    handleEditNote,
    handleClose: onClose,
    appointmentId,
    initialValue: text,
  });

  return (
    <Formik {...formikProps} enableReinitialize>
      <AppointmentTechnologistNoteEditDialog
        isOpen={isOpen}
        handleClose={handleClose}
        errorMessage={errorMessage}
      />
    </Formik>
  );
};

export default AppointmentTechnologistNoteEditDialogContainer;
