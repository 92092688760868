import { InformationGridItemData } from "../../Unknown/InformationGridItem/types";

const getInformationGridItems = (params: {
  phoneNumber?: {
    url: string;
    styled: string;
  };
  email?: string;
  patients: {
    firstName: string;
    lastName: string;
    onClick: () => void;
  }[];
  translations: {
    labelPhoneNumber: string;
    labelEmail: string;
    labelPatients: string;
  };
}) => {
  const { phoneNumber, email, patients, translations } = params;

  const result: ({ id: string } & InformationGridItemData)[] = [];

  if (phoneNumber) {
    result.push({
      id: "phoneNumber",
      type: "link",
      label: translations.labelPhoneNumber,
      text: phoneNumber.styled,
      href: `tel:${phoneNumber.url}`,
    });
  }

  if (email) {
    result.push({
      id: "email",
      type: "link",
      label: translations.labelEmail,
      text: email,
      href: `mailto:${email}`,
    });
  }

  if (patients?.length) {
    result.push({
      id: "patients",
      type: "clickList",
      label: translations.labelPatients,
      clickList: patients?.map((patient) => ({
        text: `${patient.lastName}, ${patient.firstName}`,
        onClick: patient.onClick,
      })),
    });
  }

  return result;
};

export default getInformationGridItems;
