import { defineMessages } from "react-intl";

const messages = defineMessages({
  headerAddress: {
    id: "Center.CenterInformationSettings.headerAddress",
    defaultMessage: "Address",
  },
  headerContactInfo: {
    id: "Center.CenterInformationSettings.headerContactInfo",
    defaultMessage: "Contact Information",
  },
  headerOperatingHours: {
    id: "Center.CenterInformationSettings.headerOperatingHours",
    defaultMessage: "Operating Hours",
  },
  headerDiscount: {
    id: "Center.CenterInformationSettings.headerDiscount",
    defaultMessage: "Discount For Multiple Purchases",
  },
  btnEdit: {
    id: "Center.CenterInformationSettings.btnEdit",
    defaultMessage: "Edit",
  },
  labelPhone: {
    id: "Center.CenterInformationSettings.labelPhone",
    defaultMessage: "Phone",
  },
  labelFax: {
    id: "Center.CenterInformationSettings.labelFax",
    defaultMessage: "Fax",
  },
  labelEmail: {
    id: "Center.CenterInformationSettings.labelEmail",
    defaultMessage: "Email",
  },
  dayClosed: {
    id: "Center.CenterInformationSettings.dayClosed",
    defaultMessage: "Closed",
  },
});

export default messages;
