import React from "react";
import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { GridColDef } from "../../../Unknown/DataGrid";
import { CenterService } from "../types";
import SimpleOptionsMenu from "../../../Unknown/SimpleOptionsMenu";
import Box from "../../../Unknown/Box";
import getOptionalArrayItem from "../../../../common/getOptionalArrayItem";

type ColumnValue = null;

type ColumnTranslations = {
  btnEdit: string;
  btnAdditionalViewsRequired: string;
  btnRead: string;
  btnContinueReading: string;
};

const getColumnOptions = (params: {
  translations: ColumnTranslations;
  headerClassName: string;
  role?: string;
  onEditClick: (id: string) => void;
  onAddAdditionalViewClick: (id: string) => void;
  onEditStudyClick: ({ url }: { url: string }) => void;
  onReadStudyClick: ({ id, url }: { id: string; url: string }) => void;
}): GridColDef<CenterService, ColumnValue> => {
  const {
    translations,
    headerClassName,
    role,
    onEditClick,
    onReadStudyClick,
    onEditStudyClick,
    onAddAdditionalViewClick,
  } = params;
  const { btnEdit, btnRead, btnContinueReading, btnAdditionalViewsRequired } =
    translations;

  const readLabelByStatus: Record<
    Extract<
      DatabaseEnum["appointment_offering_status"],
      "read" | "reading" | "unread"
    >,
    string
  > = {
    read: btnEdit,
    unread: btnRead,
    reading: btnContinueReading,
  };

  return {
    field: "options",
    disableColumnMenu: true,
    headerName: "",
    sortable: false,
    headerClassName,
    flex: 0.2,
    maxWidth: 80,
    renderCell: (renderParams) => {
      const { id, type, isAdditionalViews, studies, appointmentStatus } =
        renderParams.row;
      if (type === "offering") {
        const [study] = studies;
        const isReadButtonShown =
          role === "radiologist" &&
          appointmentStatus !== "reports_sent" &&
          !!study.pacsUrl &&
          ["unread", "reading", "read"].includes(study.status);

        const onReadStudy = () => {
          if (study.status === "unread") {
            onReadStudyClick({ id: study.id, url: study.pacsUrl || "" });
            return;
          }

          onEditStudyClick({ url: study.pacsUrl || "" });
        };

        return (
          <Box display="flex" justifyContent="center" width="100%">
            <SimpleOptionsMenu
              options={[
                ...getOptionalArrayItem(role !== "radiologist", {
                  id: "edit",
                  text: btnEdit,
                  onClick: () => onEditClick(id),
                }),
                ...getOptionalArrayItem(!isAdditionalViews, {
                  id: "additional-views-required",
                  text: btnAdditionalViewsRequired,
                  onClick: () => onAddAdditionalViewClick(id),
                }),
                ...getOptionalArrayItem(isReadButtonShown, {
                  id: "read",
                  text: readLabelByStatus[
                    study.status as keyof typeof readLabelByStatus
                  ],
                  onClick: onReadStudy,
                }),
              ]}
            />
          </Box>
        );
      }

      if (type === "package") {
        return (
          <Box
            height="100%"
            width="100%"
            display="flex"
            gap={3}
            flexDirection="column"
            flexWrap="nowrap"
          >
            {studies.map((study) => {
              const isReadButtonShown =
                role === "radiologist" &&
                !!study.pacsUrl &&
                appointmentStatus !== "reports_sent" &&
                ["unread", "reading", "read"].includes(study.status);

              const onReadStudy = () => {
                if (study.status === "unread") {
                  onReadStudyClick({ id: study.id, url: study.pacsUrl || "" });
                  return;
                }

                onEditStudyClick({ url: study.pacsUrl || "" });
              };

              return (
                <Box
                  key={study.id}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flex={1}
                >
                  <SimpleOptionsMenu
                    options={[
                      ...getOptionalArrayItem(!study.isAdditionalViews, {
                        id: "additional-views-required",
                        text: btnAdditionalViewsRequired,
                        onClick: () => onAddAdditionalViewClick(study.id),
                      }),
                      ...getOptionalArrayItem(isReadButtonShown, {
                        id: "read",
                        text: readLabelByStatus[
                          study.status as keyof typeof readLabelByStatus
                        ],
                        onClick: onReadStudy,
                      }),
                    ]}
                  />
                </Box>
              );
            })}
          </Box>
        );
      }

      return null;
    },
  };
};

export default getColumnOptions;
