import { useMemo } from "react";
import { StatusDialogProps } from "../../Unknown/StatusDialog";
import useTranslations from "./useTranslations";

const useActionButtons = (params: {
  handleClose: () => void;
  handleSubmit: () => void | Promise<void>;
  isLoading?: boolean;
  errorMessage?: string | null;
  isSubmitDisabled?: boolean;
}) => {
  const {
    handleClose,
    handleSubmit,
    isLoading,
    errorMessage,
    isSubmitDisabled,
  } = params;

  const { btnCancel, btnSubmit } = useTranslations();

  const actionButtons = useMemo<StatusDialogProps["actionButtons"]>(() => {
    return [
      {
        text: btnCancel,
        key: "cancel",
        onClick: handleClose,
        disabled: isLoading,
      },
      {
        text: btnSubmit,
        key: "submit",
        onClick: handleSubmit,
        disabled: isLoading || !!errorMessage || isSubmitDisabled,
      },
    ];
  }, [
    btnCancel,
    btnSubmit,
    errorMessage,
    handleClose,
    handleSubmit,
    isLoading,
    isSubmitDisabled,
  ]);

  return actionButtons;
};

export default useActionButtons;
