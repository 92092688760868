import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Appointment.AppointmentMarkPriorityDialog.title",
    defaultMessage: "Set Priority On Appointment",
  },
  description: {
    id: "Appointment.AppointmentMarkPriorityDialog.description",
    defaultMessage:
      "Are you sure you want to set priority on this appointment?",
  },
  btnCancel: {
    id: "Appointment.AppointmentMarkPriorityDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnSubmit: {
    id: "Appointment.AppointmentMarkPriorityDialog.btnSubmit",
    defaultMessage: "Set priority",
  },
});

export default messages;
