import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      defaultError: formatMessage(commonMessages.defaultError),
      title: formatMessage(messages.title),
      btnSubmit: formatMessage(messages.btnSubmit),
      btnCancel: formatMessage(messages.btnCancel),
    };
  }, [formatMessage]);

  return translations;
};

export default useTranslations;
