import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelName: {
    id: "Package.CenterPackageFormik.labelName",
    defaultMessage: "Name",
  },
  labelModalityName: {
    id: "Package.CenterPackageFormik.labelModalityName",
    defaultMessage: "Study Protocol",
  },
  labelOfferings: {
    id: "Package.CenterPackageFormik.labelOfferings",
    defaultMessage: "Offerings",
  },
  labelPrice: {
    id: "Package.CenterPackageFormik.labelPrice",
    defaultMessage: "Price",
  },
  labelDepositAmount: {
    id: "Package.CenterPackageFormik.labelDepositAmount",
    defaultMessage: "Deposit amount",
  },
  labelVisibility: {
    id: "Package.CenterPackageFormik.labelVisibility",
    defaultMessage: "Type",
  },
  labelDescription: {
    id: "Package.CenterPackageFormik.labelDescription",
    defaultMessage: "Description",
  },
  maxDepositAmountError: {
    id: "Package.CenterPackageFormik.maxDepositAmountError",
    defaultMessage: "Deposit amount must be less than price",
  },
});

export default messages;
