import { defineMessages } from "react-intl";

const messages = defineMessages({
  statusIncomplete: {
    id: "TimeSlot.TimeSlotCalendarGridItem.statusIncomplete",
    defaultMessage: "Incomplete",
  },
});

export default messages;
