import { useIntl } from "react-intl";
import useTranslations from "./useTranslations";
import { Discount } from "../CenterSettingsContainer/types";

const useDiscountBlockProps = (params: {
  discount: Discount;
  onClick?: () => void;
}) => {
  const { discount, onClick } = params;

  const { formatNumber } = useIntl();

  const { headerDiscount, btnEdit } = useTranslations();

  const discountString = formatNumber(discount.amount, {
    style: "currency",
    currency: discount.currencyCode,
  }).replace(".00", "");

  return {
    title: headerDiscount,
    buttonText: btnEdit,
    onClick,
    data: discountString,
  };
};

export default useDiscountBlockProps;
