import { Formik } from "formik";
import React, { FC, useState } from "react";
import useForm from "./useForm";
import useLabelTranslations from "./useLabelTranslations";
import { InitialOffering } from "./types";
import {
  AppointmentOfferingModifyFormValues,
  OfferingOption,
} from "../AppointmentOfferingModifyForm/types";
import AppointmentOfferingModifyDialog from "../AppointmentOfferingModifyDialog";

export type AppointmentOfferingModifyContainerProps = {
  appointmentId: string;
  offeringId: string;
  isOpened: boolean;
  onModalClose: () => void;
  initialOffering: InitialOffering;
  handleSubmit: (
    values: AppointmentOfferingModifyFormValues,
  ) => void | Promise<void>;
  offerings: OfferingOption[];
  isLoading?: boolean;
};

const AppointmentOfferingModifyContainer: FC<
  AppointmentOfferingModifyContainerProps
> = ({
  appointmentId,
  offeringId,
  isOpened,
  onModalClose,
  initialOffering,
  handleSubmit,
  offerings,
  isLoading,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const formikConfig = useForm({
    appointmentId,
    offeringId,
    initialOffering,
    handleSubmit,
    handleError: setErrorMessage,
  });

  const labelTranslations = useLabelTranslations();

  return (
    <Formik {...formikConfig}>
      <AppointmentOfferingModifyDialog
        isOpened={isOpened}
        offerings={offerings}
        labelTranslations={labelTranslations}
        handleClose={onModalClose}
        errorMessage={errorMessage}
        isLoading={isLoading}
      />
    </Formik>
  );
};

export default AppointmentOfferingModifyContainer;
