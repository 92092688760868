import { useMemo } from "react";
import { MedicalInformationCardProps } from "../../MedicalInformation/MedicalInformationCard";
import { Appointment } from "./types";

const useMedicalInformationCard = (
  appointment: Appointment | null,
): MedicalInformationCardProps | null => {
  const props = useMemo(() => {
    if (!appointment) return null;

    const patient = appointment.appointment_patient_details[0]?.patient;

    if (!patient) return null;

    const { exam_reason, surgical_history, cancer_history } =
      appointment.appointment_patient_details_form[0] || {};

    const { name, email, zip_code, phone_number } =
      appointment.appointment_patient_details_form[0]?.referring_physician ||
      {};

    const { height_ft, height_in, weight_lbs } =
      appointment.appointment_patient_details_form[0] || {};

    const result: MedicalInformationCardProps = {
      examReason: exam_reason,
      surgicalHistory: surgical_history || undefined,
      cancerHistory: cancer_history || undefined,
      referringPhysicianName: name || undefined,
      referringPhysicianPostalCode: zip_code || undefined,
      referringPhysicianEmail: email || undefined,
      referringPhysicianPhoneNumber: phone_number || undefined,
      heightFt: height_ft || undefined,
      heightIn: height_in || undefined,
      weightLbs: weight_lbs || undefined,
    };

    return result;
  }, [appointment]);

  if (!appointment || !props) return null;

  return props;
};

export default useMedicalInformationCard;
