import { useMemo } from "react";
import * as Yup from "yup";
import { MAX_STRING_LEN } from "../../../common/constants";
import { MAX_DESCRIPTION_LEN, MAX_PRICE } from "./constants";
import useLabelTranslations from "./useLabelTranslations";

const requiredString = Yup.string().trim().max(MAX_STRING_LEN).required();

const useFormSchema = (params: { minPrice: number }) => {
  const { minPrice } = params;

  const labelTranslations = useLabelTranslations();

  const schema = useMemo(() => {
    const {
      labelDepositAmount,
      labelDescription,
      labelModalityName,
      labelOfferings,
      labelName,
      labelPrice,
      labelVisibility,
    } = labelTranslations;

    return Yup.object().shape({
      name: requiredString.label(labelName),
      modalityName: requiredString.label(labelModalityName),
      offerings: Yup.array()
        .required()
        .min(1)
        .of(Yup.mixed())
        .label(labelOfferings),
      description: Yup.string()
        .label(labelDescription)
        .trim()
        .required()
        .max(MAX_DESCRIPTION_LEN),
      visibility: requiredString.label(labelVisibility),
      price: Yup.number()
        .label(labelPrice)
        .positive()
        .moreThan(minPrice)
        .lessThan(MAX_PRICE)
        .required(),
      depositAmount: Yup.number()
        .label(labelDepositAmount)
        .positive()
        .lessThan(Yup.ref("price"))
        .required(),
    });
  }, [labelTranslations, minPrice]);

  return schema;
};

export default useFormSchema;
