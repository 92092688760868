import { defineMessages } from "react-intl";

const messages = defineMessages({
  btnEdit: {
    id: "Customer.CustomerInformationDrawer.btnCustomerEdit",
    defaultMessage: "Edit",
  },
  btnCancel: {
    id: "Customer.CustomerInformationDrawer.btnCustomerCancel",
    defaultMessage: "Cancel",
  },
  btnSave: {
    id: "Customer.CustomerInformationDrawer.btnCustomerSave",
    defaultMessage: "Save",
  },
});

export default messages;
