import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentColumnItemLocationState,
  AppointmentRowItem,
} from "../types";

export type ColumnLocationStateTranslations = {
  columnLocationState: string;
};

const getColumnLocationState = (params: {
  columnItem: AppointmentColumnItemLocationState;
  translations: ColumnLocationStateTranslations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;

  return {
    field: columnItem.field,
    disableColumnMenu: true,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnLocationState,
    valueGetter: (getterParams) => {
      const { center } = getterParams.row;
      const { state } = center;

      return state;
    },
  };
};

export default getColumnLocationState;
