import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemPatientZip, AppointmentRowItem } from "../types";

export type ColumnPatientZipTranslations = {
  columnPatientZip: string;
};

const getColumnPatientZip = (params: {
  columnItem: AppointmentColumnItemPatientZip;
  translations: ColumnPatientZipTranslations;
  onNameClick?: (id: string) => void;
}): GridColDef<AppointmentRowItem, string | null> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 0.8,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnPatientZip,
    valueGetter: ({ row }) => {
      return row.patient?.postalCode || null;
    },
  };
};

export default getColumnPatientZip;
