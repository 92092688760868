import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentColumnItemFullOfferings,
  AppointmentRowItem,
  AppointmentRowItemOffering,
} from "../types";
import Box from "../../../Unknown/Box";
import Typography from "../../../Unknown/Typography";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnTranslations = {
  columnFullOfferings: string;
  sideRight: string;
  sideLeft: string;
};

type AmountFormatter = (amount: number, currencyCode: string) => string;

const sides: Record<string, keyof ColumnTranslations> = {
  right: "sideRight",
  left: "sideLeft",
};

const normalizeOffering = ({
  formatAmount,
  translations,
  currencyCode,
  offering,
}: {
  formatAmount: AmountFormatter;
  translations: ColumnTranslations;
  currencyCode: string;
  offering: AppointmentRowItemOffering;
}) => {
  const { name, side, cost } = offering;

  const sideTranslationKey = side ? sides[side] : "";
  const sideTranslation = sideTranslationKey
    ? ` (${translations[sideTranslationKey]})`
    : "";

  const formattedCost = formatAmount(cost, currencyCode);

  const formattedName = sideTranslation ? `${name} ${sideTranslation}` : name;

  return `${formattedName} - ${formattedCost}`;
};

const getColumnFullOfferings = (params: {
  columnItem: AppointmentColumnItemFullOfferings;
  translations: ColumnTranslations;
  formatAmount: AmountFormatter;
}) => {
  const { columnItem, translations, formatAmount } = params;

  const { field } = columnItem;

  const column: GridColDef<AppointmentRowItem, string> = {
    field,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnFullOfferings,
    valueGetter: (getterParams) => {
      const { offerings, center } = getterParams.row;
      const { currencyCode } = center;

      const formattedOfferings = offerings
        .map((offering) => {
          return normalizeOffering({
            currencyCode,
            offering,
            translations,
            formatAmount,
          });
        })
        .join(";");

      return formattedOfferings;
    },
    renderCell: ({ row }) => {
      const { offerings, center } = row;
      const { currencyCode } = center;

      if (!offerings?.length) return null;

      return (
        <Box display="flex" flexDirection="column">
          {offerings.map((offering) => {
            const text = normalizeOffering({
              currencyCode,
              offering,
              translations,
              formatAmount,
            });

            return (
              <Typography key={text} variant="body2" color="inherit">
                {text}
              </Typography>
            );
          })}
        </Box>
      );
    },
  };

  return column;
};

export default getColumnFullOfferings;
