import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

const availableModals = ["edit", "add", "archive"] as const;

type OpenedModal = (typeof availableModals)[number] | null;

const useOpenedModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [openedModal, setOpenedModal] = useState<OpenedModal | null>(() => {
    const modal = searchParams.get("modal");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (availableModals.includes(modal as any)) return modal as OpenedModal;

    return null;
  });

  const [id, setId] = useState<string | null>(() => searchParams.get("id"));

  const onAddClick = useCallback(() => {
    setOpenedModal("add");
    setId(null);

    setSearchParams({ modal: "add" });
  }, [setSearchParams]);

  const onEditClick = useCallback(
    (itemId: string) => {
      setOpenedModal("edit");
      setId(itemId);

      setSearchParams({ modal: "edit", id: itemId });
    },
    [setSearchParams],
  );

  const onArchiveClick = useCallback(
    (itemId: string) => {
      setOpenedModal("archive");
      setId(itemId);

      setSearchParams({ modal: "archive", id: itemId });
    },
    [setSearchParams],
  );

  const onModalClose = useCallback(() => {
    setOpenedModal(null);
    setId(null);

    setSearchParams({});
  }, [setSearchParams]);

  return {
    id,
    openedModal,
    onAddClick,
    onEditClick,
    onArchiveClick,
    onModalClose,
  };
};

export default useOpenedModal;
