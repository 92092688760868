import React, { FC } from "react";
import AppointmentPreviewCard from "../AppointmentPreviewCard";
import { Appointment, AppointmentOffering } from "./types";
import useAppointmentPreviewCard from "./useAppointmentPreviewCard";
import AppointmentOfferingModifyContainer from "../../AppointmentOffering/AppointmentOfferingModifyContainer";
import useAppointmentOfferingModifyContainer from "./useAppointmentOfferingModifyContainer";
import useAppointmentAdditionalViewContainerProps from "./useAppointmentAdditionalViewContainer";
import AppointmentOfferingAdditionalViewContainer from "../../AppointmentOffering/AppointmentOfferingAdditionalViewContainer";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";

export type AppointmentPreviewCardContainerProps = {
  appointment: Appointment;
  appointmentOfferings: AppointmentOffering[];
  refetch: () => Promise<void>;
};

const AppointmentPreviewCardContainer: FC<
  AppointmentPreviewCardContainerProps
> = ({ appointment, refetch, appointmentOfferings }) => {
  const {
    props: appointmentOfferingModifyContainerProps,
    isOpened: isAppointmentOfferingModifyContainerOpened,
    onOpenClick: onModifyOfferingClick,
  } = useAppointmentOfferingModifyContainer({
    handleSubmit: refetch,
    appointmentId: appointment.id,
    appointmentOfferings,
    centerId: appointment.center.id,
  });

  const {
    props: appointmentAdditionalViewContainerProps,
    isOpened: isAppointmentAdditionalViewContainerOpened,
    onOpenClick: onAddAdditionalViewClick,
  } = useAppointmentAdditionalViewContainerProps({
    appointmentId: appointment.id,
    refetch,
  });

  const markAppointmentOfferingAsReading = useFirebaseAppFunction(
    "markAppointmentOfferingAsReading",
  );

  const onReadStudyClick = async ({ id, url }: { id: string; url: string }) => {
    window.open(url);

    await markAppointmentOfferingAsReading({
      appointmentOfferingId: id,
    });
  };

  const onEditStudyClick = async ({ url }: { url: string }) => {
    window.open(url);
  };

  const appointmentPreviewCardProps = useAppointmentPreviewCard({
    appointment,
    onModifyOfferingClick,
    onAddAdditionalViewClick,
    onReadStudyClick,
    onEditStudyClick,
  });

  return (
    <>
      <AppointmentPreviewCard {...appointmentPreviewCardProps} />
      {appointmentOfferingModifyContainerProps &&
        isAppointmentOfferingModifyContainerOpened && (
          <AppointmentOfferingModifyContainer
            {...appointmentOfferingModifyContainerProps}
          />
        )}
      {appointmentAdditionalViewContainerProps &&
        isAppointmentAdditionalViewContainerOpened && (
          <AppointmentOfferingAdditionalViewContainer
            {...appointmentAdditionalViewContainerProps}
          />
        )}
    </>
  );
};

export default AppointmentPreviewCardContainer;
