import React, { FC, useMemo } from "react";
import StatusDialog, { StatusDialogProps } from "../../Unknown/StatusDialog";
import useTranslations from "./useTranslations";
import AuditLogList from "../AuditLogList";
import { AppointmentAuditLog } from "../AppointmentAuditLog/getAppointmentAuditLogs";

export type AppointmentAuditLogDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  isLoading?: boolean;
  errorMessage?: string | null;
  auditLogs: AppointmentAuditLog[];
};

const AppointmentAuditLogDialog: FC<AppointmentAuditLogDialogProps> = ({
  isOpen,
  handleClose,
  isLoading,
  errorMessage,
  auditLogs,
}) => {
  const { translations } = useTranslations();

  const actionButtons = useMemo<StatusDialogProps["actionButtons"]>(() => {
    return [
      {
        text: translations.btnClose,
        key: "close",
        onClick: handleClose,
        disabled: isLoading,
      },
    ];
  }, [handleClose, isLoading, translations.btnClose]);

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      isLoading={isLoading}
      errorMessage={errorMessage}
      actionButtons={actionButtons}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          maxWidth: 800,
        },
      }}
    >
      <AuditLogList auditLogs={auditLogs} />
    </StatusDialog>
  );
};

export default AppointmentAuditLogDialog;
