import React, { FC } from "react";
import format from "date-fns/format";
import { DayHeaderContentArg } from "../../Unknown/Calendar";
import Typography from "../../Unknown/Typography";
import { views } from "../TimeSlotCalendarGrid/helpers";

type TimeSlotCalendarGridDayHeaderProps = DayHeaderContentArg;

const formatDate = (params: { date: Date; viewType: string }) => {
  const { date, viewType } = params;
  switch (viewType) {
    case views.month:
      return format(date, "EEE");

    case views.week:
    case views.day:
      return format(date, "EEE, MMM dd");

    default:
      return "";
  }
};

const TimeSlotCalendarGridDayHeader: FC<TimeSlotCalendarGridDayHeaderProps> = ({
  date,
  view,
}) => {
  const text = formatDate({ date, viewType: view.type });
  return <Typography>{text}</Typography>;
};

export default TimeSlotCalendarGridDayHeader;
