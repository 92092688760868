import { defineMessages } from "react-intl";

const messages = defineMessages({
  headerName: {
    id: "AppointmentFile.AppointmentFileGrid.headerName",
    defaultMessage: "File",
  },
  headerUploadedAt: {
    id: "AppointmentFile.AppointmentFileGrid.headerUploadedAt",
    defaultMessage: "Uploaded",
  },
  btnDownload: {
    id: "AppointmentFile.AppointmentFileGrid.btnDownload",
    defaultMessage: "Download",
  },
});

export default messages;
