import parseISO from "date-fns/parseISO";
import formatAddress from "../../../common/formatAddress";
import { formatInternational } from "../../../common/phoneNumberHelpers";
import getPatientAddressByType from "./getPatientAddressByType";
import { Patient, PatientInformationDrawerData } from "./types";

const getPatientInformationCardProps = (params: {
  patient: Patient;
  handleCustomerClick: (id: string) => void;
}) => {
  const { patient, handleCustomerClick } = params;
  const {
    mrn,
    date_birth,
    mobile_phone_number,
    email,
    city,
    postal_code,
    state,
    country,
    patient_addresses,
    legal_guardian,
    race,
    sex,
  } = patient;

  const birthDate = parseISO(date_birth);

  const primaryAddress = getPatientAddressByType(patient_addresses, "primary");
  const secondaryAddress = getPatientAddressByType(
    patient_addresses,
    "secondary",
  );

  const address = formatAddress({
    primaryAddress,
    secondaryAddress,
    city,
    postalCode: postal_code,
    state,
    country,
  });

  const patientInformation: PatientInformationDrawerData["patientInformationCardProps"] =
    {
      mrn,
      birthDate: birthDate.toLocaleDateString(),
      address,
      race: race || "",
      sex: sex || "",
    };

  if (mobile_phone_number) {
    const styled = formatInternational({
      phoneNumber: mobile_phone_number,
    });
    patientInformation.phoneNumber = {
      url: mobile_phone_number,
      styled,
    };
  }

  if (email) {
    patientInformation.email = email;
  }

  if (legal_guardian) {
    patientInformation.legalGuardian = {
      firstName: legal_guardian.first_name,
      lastName: legal_guardian.last_name,
      onClick: () => handleCustomerClick(legal_guardian.id),
    };
  }

  return patientInformation;
};

export default getPatientInformationCardProps;
