import {
  DatabaseEnum,
  DatabaseRow,
} from "@Shape-Digital/kudzu-data/lib/types/common";
import { SupabaseCenterUser } from "./getSupabaseCenterUserData";

const adminRoles: DatabaseEnum["center_user_role"][] = [
  "regional_admin",
  "super_admin",
];

const checkCenterUserConfig = async (params: {
  supabaseCenterUser: SupabaseCenterUser;
  createCenterUserConfigFunc: () =>
    | Promise<DatabaseRow<"center_user_configs">>
    | DatabaseRow<"center_user_configs">;
}): Promise<SupabaseCenterUser> => {
  const { supabaseCenterUser, createCenterUserConfigFunc } = params;

  const lastUsedRole =
    supabaseCenterUser.center_user_configs?.[0]?.last_used_role;

  if (lastUsedRole) {
    const isLastUsedRoleAvailable = supabaseCenterUser.center_user_roles.some(
      (role) => adminRoles.includes(role.role) || role.role === lastUsedRole,
    );

    if (isLastUsedRoleAvailable) {
      return supabaseCenterUser;
    }
  }

  const newConfig = await createCenterUserConfigFunc();

  return { ...supabaseCenterUser, center_user_configs: [newConfig] };
};

export default checkCenterUserConfig;
