import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = (params: { name: string }) => {
  const { name } = params;
  const { formatMessage } = useIntl();

  const translationsResult = useMemo(() => {
    const translations = {
      title: formatMessage(messages.title),
      description: formatMessage(messages.description, { name }),
      btnCancel: formatMessage(messages.btnCancel),
      btnDelete: formatMessage(messages.btnDelete),
    };

    return translations;
  }, [formatMessage, name]);

  return translationsResult;
};
export default useTranslations;
