import React, { FC, useCallback } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { TextFieldProps } from "@mui/material";
import TextField from "../TextField";
import StaticDatePicker, { StaticDatePickerProps } from "../StaticDatePicker";
import CircularProgress from "../CircularProgress";
import AvailabilityCalendarPickersDay from "../AvailabilityCalendarPickersDay";

type PartialStaticDatePickerProps = PartialBy<
  StaticDatePickerProps<Date, Date>,
  "renderInput"
>;

type RenderDayFunction = NonNullable<PartialStaticDatePickerProps["renderDay"]>;

export type AvailabilityCalendarMonthProps = PartialStaticDatePickerProps & {
  availableDays: number[];
};

const AvailabilityCalendarMonth: FC<AvailabilityCalendarMonthProps> = ({
  availableDays,
  ...staticDatePickerProps
}) => {
  const { value } = staticDatePickerProps;

  const renderInput = (params: TextFieldProps) => <TextField {...params} />;
  const renderLoading = () => <CircularProgress />;
  const renderDay = useCallback<RenderDayFunction>(
    (_day, _selectedDates, pickersDayProps) => (
      <AvailabilityCalendarPickersDay
        key={pickersDayProps.key}
        availableDays={availableDays}
        selectedDate={value}
        {...pickersDayProps}
      />
    ),
    [availableDays, value],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        renderInput={renderInput}
        renderLoading={renderLoading}
        renderDay={renderDay}
        {...staticDatePickerProps}
      />
    </LocalizationProvider>
  );
};

export default AvailabilityCalendarMonth;
