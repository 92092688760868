import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import React, { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import useMessagesTranslations from "../../../hooks/useMessagesTranslations";
import { useAuthContext } from "../../Auth/AuthContext";
import { useAppContext } from "../AppContext";
import getRoleActions from "../AppContext/getRoleActions";
import Box from "../Box";
import { LogoutIcon, ViewCozyOutlinedIcon } from "../Icons";
import Navbar, { NavbarProps } from "../Navbar";
import messages from "./messages";
import useActiveActionId from "./useActiveActionId";
import useActiveProfileOptionId from "./useActiveProfileOptionId";
import useStyles from "./useStyles";
import { useUIContext } from "../UIContext";
import useTranslations from "./useTranslations";

type AuthenticatedLayoutProps = {
  children: ReactNode;
};

type NavBarProfileOption = NavbarProps["profileProps"]["options"][number];

const AuthenticatedLayout: FC<AuthenticatedLayoutProps> = ({ children }) => {
  const [isAddAppointmentLoading, setIsAddAppointmentLoading] = useState(false);
  const { databaseUserData, updateLastUsedRole } = useAppContext();
  const { signOut, getAuthUserToken } = useAuthContext();
  const translations = useMessagesTranslations(messages);
  const actionsTranslations = useTranslations();
  const { role } = useParams();
  const activeActionId = useActiveActionId();
  const navigate = useNavigate();
  const { layoutRoot, childrenRoot } = useStyles();
  const activeProfileOptionId = useActiveProfileOptionId();

  const { formatMessage } = useIntl();
  const location = useLocation();
  const { setAlert } = useUIContext();

  const onAddAppointmentClick = useCallback(async () => {
    setIsAddAppointmentLoading(true);
    try {
      const token = await getAuthUserToken();
      if (!token) {
        return;
      }

      window.open(
        `${process.env.REACT_APP_BOOKING_APP_BASE_URL}?token=${token}`,
      );
    } catch (error) {
      setAlert({
        message: formatMessage(messages.addAppointmentError),
        severity: "error",
        isShown: true,
      });
    } finally {
      setIsAddAppointmentLoading(false);
    }
  }, [formatMessage, getAuthUserToken, setAlert]);

  const actions = useMemo(() => {
    return getRoleActions({
      onAddAppointmentClick,
      translations: actionsTranslations,
      isAddAppointmentLoading,
      role: role as DatabaseEnum["center_user_role"],
    });
  }, [
    role,
    actionsTranslations,
    isAddAppointmentLoading,
    onAddAppointmentClick,
  ]);

  const options = useMemo(() => {
    const linkPath = location.pathname.split("/").splice(2).join("/") || "";

    const titles: Record<
      DatabaseEnum["center_user_role"],
      keyof typeof translations
    > = {
      receptionist: "viewReceptionist",
      technologist: "viewTechnologist",
      radiologist: "viewRadiologist",
      regional_admin: "viewRegionalAdmin",
      super_admin: "viewSuperAdmin",
    };

    const rolesSwitchers =
      databaseUserData.permissions.map<NavBarProfileOption>((permission) => {
        const translationKey = titles[permission];

        const onClick = async () => {
          await updateLastUsedRole(permission);
          navigate(`/${permission}/${linkPath}`);
        };

        return {
          id: permission,
          IconComponent: <ViewCozyOutlinedIcon />,
          title: translations[translationKey],
          meta: { itemType: "click", onClick },
        };
      });

    const logoutButton: NavBarProfileOption = {
      id: "logout",
      IconComponent: <LogoutIcon />,
      title: translations.btnLogOut,
      meta: { itemType: "click", onClick: signOut },
    };

    return [...rolesSwitchers, logoutButton];
  }, [
    databaseUserData.permissions,
    location.pathname,
    navigate,
    signOut,
    translations,
    updateLastUsedRole,
  ]);

  return (
    <Box className={layoutRoot}>
      <Navbar
        actionsProps={{ actions, activeItemId: activeActionId }}
        profileProps={{ options, activeOptionId: activeProfileOptionId }}
      />
      <Box className={childrenRoot}>{children}</Box>
    </Box>
  );
};

export default AuthenticatedLayout;
