import React, { FC } from "react";
import { useFormikContext } from "formik";
import StatusDialog from "../../Unknown/StatusDialog";
import AppointmentNoteAddForm, {
  AppointmentNoteAddFormProps,
} from "../AppointmentNoteAddForm";
import useTranslations from "./useTranslations";
import { FormValues } from "../AppointmentNoteAddForm/types";
import useActionButtons from "./useActionButtons";

export type AppointmentNoteAddDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  offerings: AppointmentNoteAddFormProps["offerings"];
  formTranslations: AppointmentNoteAddFormProps["translations"];
  errorMessage?: string | null;
};

const AppointmentNoteAddDialog: FC<AppointmentNoteAddDialogProps> = ({
  isOpen,
  offerings,
  formTranslations,
  errorMessage,
  handleClose,
}) => {
  const { translations } = useTranslations();

  const { isSubmitting, resetForm, handleSubmit } =
    useFormikContext<FormValues>();

  const onClose = () => {
    handleClose();
    resetForm();
  };

  const actionButtons = useActionButtons({
    handleClose: onClose,
    handleSubmit,
    isLoading: isSubmitting,
    errorMessage,
  });

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      actionButtons={actionButtons}
      errorMessage={errorMessage}
      isLoading={isSubmitting}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          maxWidth: 800,
        },
      }}
    >
      <AppointmentNoteAddForm
        offerings={offerings}
        translations={formTranslations}
      />
    </StatusDialog>
  );
};

export default AppointmentNoteAddDialog;
