import { useState } from "react";
import { AppointmentOfferingAdditionalViewContainerProps } from "../../AppointmentOffering/AppointmentOfferingAdditionalViewContainer";

const useAppointmentAdditionalViewContainer = (params: {
  appointmentId: string;
  refetch: () => Promise<void>;
}): {
  props: AppointmentOfferingAdditionalViewContainerProps | null;
  isOpened: boolean;
  onOpenClick: (id: string) => void;
} => {
  const { appointmentId, refetch } = params;

  const [selectedOfferingId, setSelectedOfferingId] = useState<string | null>(
    null,
  );

  const onCloseClick = () => setSelectedOfferingId(null);

  const onSubmit = async () => {
    await refetch();
    setSelectedOfferingId(null);
  };

  const isOpened = !!selectedOfferingId;

  return {
    isOpened,
    onOpenClick: setSelectedOfferingId,
    props: selectedOfferingId
      ? {
          appointmentId,
          offeringId: selectedOfferingId,
          isOpened,
          onModalClose: onCloseClick,
          handleSubmit: onSubmit,
        }
      : null,
  };
};

export default useAppointmentAdditionalViewContainer;
