import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "AppointmentPayment.AppointmentPaymentListCard.title",
    defaultMessage: "Payments",
  },
  btnAdd: {
    id: "AppointmentPayment.AppointmentPaymentListCard.btnAdd",
    defaultMessage: "Add payment",
  },
  totalPrice: {
    id: "AppointmentPayment.AppointmentPaymentListCard.totalPrice",
    defaultMessage: "Total price",
  },
  outstandingBalance: {
    id: "AppointmentPayment.AppointmentPaymentListCard.outstandingBalance",
    defaultMessage: "Outstanding balance",
  },
});
