import * as Yup from "yup";
import { CenterDiscountFormValues } from "../CenterDiscountForm";

export type CenterDiscountValidationTranslations = {
  requiredField: string;
  minimumDiscountError: string;
  maximumDiscountError: string;
};

const getCenterDiscountFormSchema = (params: {
  translations: CenterDiscountValidationTranslations;
  maxDiscount: number;
}) => {
  const { translations, maxDiscount } = params;

  const { requiredField, minimumDiscountError, maximumDiscountError } =
    translations;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Yup.object().shape<Record<keyof CenterDiscountFormValues, any>>({
    amount: Yup.number()
      .min(0, minimumDiscountError)
      .lessThan(maxDiscount, maximumDiscountError)
      .required(requiredField),
  });
};

export default getCenterDiscountFormSchema;
