import React, { FC, useState } from "react";
import Box from "../../Unknown/Box";
import CircularProgress from "../../Unknown/CircularProgress";
import Button from "../../Unknown/Button";
import useTranslations from "./useTranslations";
import Typography from "../../Unknown/Typography";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";

export type CenterRegisterTabProps = {
  centerId: string;
  onRegisterError: () => void | Promise<void>;
  onRegisterSuccess: () => void | Promise<void>;
};

const CenterRegisterTab: FC<CenterRegisterTabProps> = ({
  centerId,
  onRegisterError,
  onRegisterSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const registerCenterInMwl = useFirebaseAppFunction("registerCenterInMwl");

  const { description, registerButtonTitle } = useTranslations();

  const onRegisterClick = async () => {
    setIsLoading(true);
    try {
      await registerCenterInMwl({ centerId });
      await onRegisterSuccess();
    } catch {
      onRegisterError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box pt={7} pb={5}>
      <Typography mb={4}>{description}</Typography>
      <Button
        variant="contained"
        disabled={isLoading}
        startIcon={isLoading && <CircularProgress size={18} color="inherit" />}
        onClick={onRegisterClick}
      >
        {registerButtonTitle}
      </Button>
    </Box>
  );
};

export default CenterRegisterTab;
