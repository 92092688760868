import { alpha, lighten, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, shadows, spacing }) => {
  return {
    root: {
      border: "none",
      color: "black",
      "& .MuiDataGrid-cell": {
        padding: 16,
        "&:focus": {
          outline: "none",
        },
        "&:focus-within": {
          outline: "none",
        },
      },
      "& .MuiDataGrid-columnHeader": {
        "&:focus": {
          outline: "none",
          padding: 16,
        },
        "& .MuiDataGrid-columnSeparator": {
          visibility: "visible",
        },
        "&:focus-within": {
          outline: "none",
        },
        "&:last-child": {
          "& .MuiDataGrid-columnSeparator--sideRight": {
            display: "none",
          },
        },
      },
      "& .row--general": {
        backgroundColor: palette.common.white,
        "&:hover": {
          backgroundColor: palette.common.white,
        },
      },
      "& .row--past": {
        backgroundColor: lighten(palette.common.black, 0.92),
        "&:hover": {
          backgroundColor: lighten(palette.common.black, 0.92),
        },
      },
    },
    btnHeaderClassName: {
      "& .MuiDataGrid-columnSeparator--sideRight": {
        display: "none",
      },
    },
    timeRoot: {
      color: alpha(palette.common.black, 0.6),
      fontSize: 13,
    },
    popperClassName: {
      backgroundColor: palette.common.white,
      color: palette.common.black,
      boxShadow: shadows[5],
      fontSize: 14,
      fontWeight: 400,
      padding: spacing(3),
      marginLeft: spacing(-3),
      whiteSpace: "pre-line",
    },
  };
});

export default useStyles;
