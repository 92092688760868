import React from "react";
import { GridColDef, GridComparatorFn } from "../../../Unknown/DataGrid";
import CenterServicesAppointmentPreview from "../../../CenterService/CenterServicesAppointmentPreview";
import { Appointment } from "../types";

type ColumnValue = {
  centerServices: { name: string; side?: string }[];
};

type ColumnTranslations = {
  columnCenterServices: string;
};

const sortComparator: GridComparatorFn<ColumnValue> = (
  currentValue,
  compareValue,
) => {
  const currentCenterServices = currentValue.centerServices
    .map(({ name }) => name)
    .join("");
  const compareCenterServices = compareValue.centerServices
    .map(({ name }) => name)
    .join("");

  return currentCenterServices.localeCompare(compareCenterServices);
};

const getColumnCenterServices = (params: {
  translations: ColumnTranslations;
}): GridColDef<Appointment, ColumnValue> => {
  const { translations } = params;

  return {
    field: "centerServices",
    disableColumnMenu: true,
    sortable: true,
    flex: 1,
    sortComparator,
    headerName: translations.columnCenterServices,
    valueGetter: (getterParams) => {
      const { centerServices } = getterParams.row;
      const sorted = centerServices.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      return { centerServices: sorted };
    },
    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      return (
        <CenterServicesAppointmentPreview
          centerServices={renderParams.value.centerServices}
        />
      );
    },
  };
};

export default getColumnCenterServices;
