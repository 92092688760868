import { defineMessages } from "react-intl";

const messages = defineMessages({
  headingName: {
    id: "Offering.CenterOfferingsView.headingName",
    defaultMessage: "Name",
  },
  headingPrice: {
    id: "Offering.CenterOfferingsView.headingPrice",
    defaultMessage: "Price",
  },
  headingDuration: {
    id: "Offering.CenterOfferingsView.headingDuration",
    defaultMessage: "Duration",
  },
  headingIsPrivate: {
    id: "Offering.CenterOfferingsView.headingIsPrivate",
    defaultMessage: "Private",
  },
  durationMinutes: {
    id: "Offering.CenterOfferingsView.durationMinutes",
    defaultMessage: "min",
  },
  privateTrue: {
    id: "Offering.CenterOfferingsView.privateTrue",
    defaultMessage: "Yes",
  },
  privateFalse: {
    id: "Offering.CenterOfferingsView.privateFalse",
    defaultMessage: "No",
  },
  btnArchive: {
    id: "Offering.CenterOfferingsView.btnArchive",
    defaultMessage: "Archive",
  },
  btnEdit: {
    id: "Offering.CenterOfferingsView.btnEdit",
    defaultMessage: "Edit",
  },
});

export default messages;
