import { useCallback, useEffect, useState } from "react";
import { AppointmentAuditLogDialogProps } from "../../AuditLog/AppointmentAuditLogDialog";
import getAppointmentAuditLogs, {
  AppointmentAuditLog,
} from "../../AuditLog/AppointmentAuditLog/getAppointmentAuditLogs";

const useAppointmentMarkNoShowDialog = (
  appointmentId: string,
): {
  handleOpen: () => void;
  props: AppointmentAuditLogDialogProps;
} => {
  const [isOpen, setIsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [auditLogs, setAuditLogs] = useState<AppointmentAuditLog[]>([]);

  const handleOpen = () => setIsOpen(true);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setErrorMessage(null);
  }, []);

  useEffect(() => {
    const getAuditLogs = async () => {
      setIsLoading(true);
      try {
        const data = await getAppointmentAuditLogs(appointmentId);
        setAuditLogs(data);
      } catch (error) {
        setErrorMessage((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    };
    getAuditLogs();
  }, [appointmentId]);

  return {
    handleOpen,
    props: {
      isOpen,
      handleClose,
      isLoading,
      errorMessage,
      auditLogs,
    },
  };
};

export default useAppointmentMarkNoShowDialog;
