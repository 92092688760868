import React, { FC, useCallback, useMemo } from "react";
import Box from "../../Unknown/Box";
import Tabs from "../../Unknown/Tabs";
import Tab from "../../Unknown/Tab";
import { useAppContext } from "../../Unknown/AppContext";
import useCenterSettingsData from "./useCenterSettingsData";
import { AlertParams } from "../../Unknown/UIContext";
import useTranslations from "./useTranslations";
import useTabs, { TabsParams } from "./useTabs";

export type CenterSettingsContainerProps = {
  centerId: string;
  setNotification: React.Dispatch<React.SetStateAction<AlertParams | null>>;
};

const CenterSettingsContainer: FC<CenterSettingsContainerProps> = ({
  centerId,
  setNotification,
}) => {
  const { systemConfig } = useAppContext();

  const [centerData, refetchCenterData] = useCenterSettingsData(
    centerId,
    systemConfig,
  );

  const translations = useTranslations();

  const { data, status } = centerData;

  const onRegisterSuccess = useCallback(async () => {
    await refetchCenterData();
    setNotification({
      severity: "success",
      message: translations.registerCenterSuccessMessage,
      isShown: true,
    });
  }, [
    refetchCenterData,
    setNotification,
    translations.registerCenterSuccessMessage,
  ]);

  const onRegisterError = useCallback(() => {
    setNotification({
      severity: "error",
      message: translations.registerCenterErrorMessage,
      isShown: true,
    });
  }, [setNotification, translations.registerCenterErrorMessage]);

  const tabsParams = useMemo<TabsParams>(() => {
    if (status !== "success") {
      return { setNotification };
    }

    const {
      address,
      contactInformation,
      operatingHours,
      discount,
      offeringsData,
      packagesData,
      addOnsData,
    } = data;

    return {
      setNotification,
      centerInformationSettingsTabProps: {
        centerId,
        address,
        contactInformation,
        operatingHours,
        discount,
        refetchCenterData,
      },
      centerOfferingsSettingsTabProps: {
        centerId,
        offeringsData,
        refetchCenterData,
      },
      centerPackagesSettingsTabProps: {
        packagesData,
        centerId,
        refetchCenterData,
      },
      centerAddOnsSettingsTabProps: {
        centerId,
        addOnsData,
        refetchCenterData,
      },
      ...(data.isRegisteredInMwl
        ? {}
        : {
            centerRegisterTabProps: {
              centerId,
              onRegisterError,
              onRegisterSuccess,
            },
          }),
    };
  }, [
    status,
    data,
    centerId,
    setNotification,
    refetchCenterData,
    onRegisterError,
    onRegisterSuccess,
  ]);

  const { tabs, tabIndex, handleTabChange } = useTabs(tabsParams);

  return (
    <Box px={5}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              id={`tab-${tab.id}`}
              aria-controls={`tabpanel-${tab.id}`}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => {
        return (
          <Box
            key={tab.id}
            role="tabpanel"
            hidden={tabIndex !== index}
            id={`tabpanel-${tab.id}`}
            aria-labelledby={`tab-${tab.id}`}
          >
            {tabIndex === index && tab.component()}
          </Box>
        );
      })}
    </Box>
  );
};

export default CenterSettingsContainer;
