import { DEFAULT_COUNTRY_CODE } from "../../../common/constants";
import { baseFormInitialValues } from "./constants";
import getPatientAddressByType from "./getPatientAddressByType";
import { Patient, PatientFormikConfig } from "./types";

const getFormikInitialValues = (params: {
  patient: Patient | null;
  isEmailShown?: boolean;
  isPhoneShown?: boolean;
}) => {
  const { patient, isEmailShown, isPhoneShown } = params;

  if (!patient) return baseFormInitialValues;

  const {
    first_name,
    last_name,
    date_birth,
    mobile_phone_number,
    email,
    city,
    postal_code,
    state,
    country,
    patient_addresses,
    race,
    sex,
  } = patient;

  const primaryAddress = getPatientAddressByType(patient_addresses, "primary");
  const secondaryAddress = getPatientAddressByType(
    patient_addresses,
    "secondary",
  );

  const information: PatientFormikConfig["initialValues"]["information"] = {
    firstName: first_name,
    lastName: last_name,
    birthDate: date_birth,
    primaryAddress: primaryAddress || "",
    secondaryAddress: secondaryAddress || "",
    city: city || "",
    postalCode: postal_code || "",
    stateIsoCode: state || "",
    countryIsoCode: country || DEFAULT_COUNTRY_CODE,
    confirmationCode: "",
    race: race || "",
    sex: sex || "",
  };

  if (isEmailShown) {
    information.email = email;
  }

  if (isPhoneShown) {
    information.phoneNumber = mobile_phone_number || "";
  }

  return { information };
};

export default getFormikInitialValues;
