import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    const columnTranslations = {
      columnAlert: formatMessage(messages.columnAlert),
      columnStatus: formatMessage(messages.columnStatus),
      columnOfferingsStatus: formatMessage(messages.columnOfferingsStatus),
      columnPatient: formatMessage(messages.columnPatient),
      columnDateTime: formatMessage(messages.columnDateTime),
      columnLocation: formatMessage(messages.columnLocation),
      columnOffering: formatMessage(messages.columnOffering),
      columnNotes: formatMessage(messages.columnNotes),
      columnOfferingStudies: formatMessage(messages.columnOfferingStudies),

      tooltipStat: formatMessage(messages.tooltipStat),
      tooltipAdditionalViews: formatMessage(messages.tooltipAdditionalViews),
      tooltipUnexpectedFindings: formatMessage(
        messages.tooltipUnexpectedFindings,
      ),
      tooltipReceiptConfirmed: formatMessage(messages.tooltipReceiptConfirmed),
      tooltipHasPriority: formatMessage(messages.tooltipHasPriority),

      statusPending: formatMessage(messages.statusPending),
      statusUnread: formatMessage(messages.statusUnread),
      statusReading: formatMessage(messages.statusReading),
      statusRead: formatMessage(messages.statusRead),

      optionsBtnRead: formatMessage(messages.optionsBtnRead),
      optionsBtnEdit: formatMessage(messages.optionsBtnEdit),

      btnView: formatMessage(messages.btnView),
      sideRight: formatMessage(messages.sideRight),
      sideLeft: formatMessage(messages.sideLeft),
    };

    return { columnTranslations };
  }, [formatMessage]);
  return translations;
};
export default useTranslations;
