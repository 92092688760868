import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppointmentCardsContainer from "../AppointmentCardsContainer";

const AppointmentScreen: FC = () => {
  const { role, appointmentId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!appointmentId) {
      navigate(`/${role}/`);
    }
  }, [appointmentId, navigate, role]);

  if (!appointmentId) {
    return null;
  }

  return <AppointmentCardsContainer appointmentId={appointmentId} />;
};

export default AppointmentScreen;
