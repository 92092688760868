import React, { FC } from "react";
import { useFormikContext } from "formik";
import FormikTextField, {
  FormikTextFieldProps,
} from "../../Unknown/FormikTextField";
import Grid from "../../Unknown/Grid";
import useTranslations from "./useTranslations";
import FormikPhoneInput from "../../Unknown/FormikPhoneInput";
import formatEmail from "../../../common/formatEmail";

const defaultTextFieldProps: Partial<FormikTextFieldProps> = {
  fullWidth: true,
  required: true,
  autoComplete: "off",
};

export type CenterContactInformationFormValues = {
  phone: string;
  fax: string;
  email: string;
};

const inputSpacing = 4;

const CenterContactInformationForm: FC = () => {
  const translations = useTranslations();

  const { isSubmitting, setFieldValue } =
    useFormikContext<CenterContactInformationFormValues>();

  return (
    <Grid container spacing={inputSpacing} direction="row">
      <Grid item container spacing={inputSpacing}>
        <Grid item flexGrow={1} flexBasis={1} width="30%">
          <FormikPhoneInput
            fullWidth
            required
            autoComplete="off"
            name="phone"
            disabled={isSubmitting}
            label={translations.labelPhone}
          />
        </Grid>
        <Grid item flexGrow={1} flexBasis={1} width="30%">
          <FormikPhoneInput
            fullWidth
            autoComplete="off"
            name="fax"
            label={translations.labelFax}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item flexGrow={1} flexBasis={1} width="30%">
          <FormikTextField
            {...defaultTextFieldProps}
            name="email"
            label={translations.labelEmail}
            disabled={isSubmitting}
            onChange={({ target }) =>
              setFieldValue("email", formatEmail(target.value))
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CenterContactInformationForm;
