import React, { FC } from "react";
import { Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { EventContentArg } from "../../Unknown/Calendar";
import Grid from "../../Unknown/Grid";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";
import { CalendarEventExtendedProp } from "../TimeSlotCalendarScreen/convertTimeSlotsToEvents";

type TimeSlotCalendarGridItemProps = EventContentArg;

const formatTimeText = (text: string) => {
  return text.toLocaleLowerCase().replace(/\s/, "");
};

const TimeSlotCalendarGridItem: FC<TimeSlotCalendarGridItemProps> = ({
  event,
  view,
}) => {
  const { title, extendedProps } = event;
  const { description, appointmentStatus } =
    extendedProps as CalendarEventExtendedProp;

  const { statusIncomplete } = useTranslations();
  const { titleRoot, textRoot, monthRoot } = useStyles();

  const { formatTime } = useIntl();

  const startTime = formatTime(event.start || undefined);
  const endTime = formatTime(event.end || undefined);

  const timeText = formatTimeText(`${startTime} - ${endTime}`);

  const additionalText =
    appointmentStatus === "incomplete" ? `(${statusIncomplete})` : "";

  switch (view.type) {
    case "dayGridMonth": {
      return (
        <Typography className={monthRoot}>
          {title} {timeText} {additionalText}
        </Typography>
      );
    }
    case "timeGridWeek":
    case "timeGridDay":
      return (
        <Grid container spacing={1}>
          <Grid item>
            <Typography className={titleRoot}>{title}</Typography>
          </Grid>
          <Grid item>
            <Typography className={textRoot}>{timeText}</Typography>
          </Grid>
          {!!description && (
            <Grid item>
              <Typography className={textRoot}>{description}</Typography>
            </Grid>
          )}
          {!!additionalText && (
            <Grid item>
              <Typography className={textRoot}>{additionalText}</Typography>
            </Grid>
          )}
        </Grid>
      );
    default:
      return null;
  }
};

export default TimeSlotCalendarGridItem;
