import {
  DatabaseEnum,
  DatabaseRow,
} from "@Shape-Digital/kudzu-data/lib/types/common";
import { SupabaseClient } from "@supabase/supabase-js";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import { formatClientTimezoneToUTC_ISO } from "../../../common/dateHelpers";
import { DateRange } from "../../Unknown/DateRangePicker";
import getDateRangeFilterValues from "./getDateRangeFilterValues";

type AppointmentPackageCenterOffering = Pick<
  DatabaseRow<"center_offerings">,
  "name"
>;
export type AppointmentPackageOffering = Pick<
  DatabaseRow<"appointment_offerings">,
  | "status"
  | "total_cost_amount"
  | "has_unexpected_findings"
  | "is_receipt_confirmed"
> & {
  center_offering: AppointmentPackageCenterOffering;
};

type CenterAddOn = Pick<DatabaseRow<"center_add_ons">, "name">;
type AppointmentAddOn = Pick<
  DatabaseRow<"appointment_add_ons">,
  "total_cost_amount"
> & {
  center_add_on: CenterAddOn;
};

type CenterPackage = Pick<DatabaseRow<"center_packages">, "name">;
type AppointmentPackage = Pick<
  DatabaseRow<"appointment_packages">,
  "total_cost_amount"
> & {
  center_package: CenterPackage;
  appointment_offerings: AppointmentPackageOffering[];
};

type CenterOffering = Pick<DatabaseRow<"center_offerings">, "name">;
export type AppointmentOffering = Pick<
  DatabaseRow<"appointment_offerings">,
  | "side"
  | "status"
  | "total_cost_amount"
  | "has_unexpected_findings"
  | "is_receipt_confirmed"
  | "modality_name"
> & {
  center_offering: CenterOffering;
};

type CenterConfig = Pick<
  DatabaseRow<"center_configs">,
  "default_currency_code"
>;

type AppointmentCenter = Pick<
  DatabaseRow<"centers">,
  "name" | "region" | "timezone" | "country" | "state" | "postal_code"
> & {
  center_configs: CenterConfig[];
};

type AppointmentTimeSlot = Pick<DatabaseRow<"center_time_slots">, "started_at">;

type PatientAddress = Pick<
  DatabaseRow<"patient_addresses">,
  "address" | "type"
>;

type Patient = Pick<
  DatabaseRow<"patients">,
  "mrn" | "state" | "country" | "postal_code" | "city" | "race" | "sex"
> & {
  patient_addresses: PatientAddress[];
};

type AppointmentPatientDetails = Pick<
  DatabaseRow<"appointment_patient_details">,
  "first_name" | "last_name" | "date_birth" | "mobile_phone_number" | "email"
> & {
  patient: Patient;
};

type AppointmentCustomerDetails = Pick<
  DatabaseRow<"appointment_customer_details">,
  "first_name" | "last_name" | "type" | "customer_id"
>;

type AppointmentReport = Pick<
  DatabaseRow<"appointment_reports">,
  "id" | "status"
>;

type PaymentProvider = Pick<DatabaseRow<"payment_providers">, "type">;

export type AppointmentPayment = DatabaseRow<"appointment_payments"> & {
  center_payment_provider: {
    payment_provider: PaymentProvider;
  };
  center_payment_method: {
    payment_method: DatabaseRow<"payment_methods">;
  };
};

export type DashboardAppointment = DatabaseRow<"appointments"> & {
  appointment_patient_details: AppointmentPatientDetails[];
  appointment_customer_details: AppointmentCustomerDetails[];
  time_slot: AppointmentTimeSlot;
  center: AppointmentCenter;
  appointment_offerings: AppointmentOffering[];
  appointment_packages: AppointmentPackage[];
  appointment_add_ons: AppointmentAddOn[];
  appointment_payments: AppointmentPayment[];
  appointment_reports: AppointmentReport[];
};

const supabaseAppointmentsRequest = `
*,
appointment_patient_details (
  first_name,
  last_name,
  date_birth,
  mobile_phone_number,
  email,
  patient:patient_id (
    mrn,
    state,
    city,
    race,
    sex,
    country,
    postal_code,
    patient_addresses (
      type,
      address
    )
  )
),
time_slot:time_slot_id!inner (
  started_at
),
appointment_reports (
  id,
  status
),
center:center_id (
  name,
  region,
  timezone,
  country,
  state,
  postal_code,
  center_configs (
    default_currency_code
  )
),
appointment_offerings (
  side,
  total_cost_amount,
  status,
  has_unexpected_findings,
  is_receipt_confirmed,
  center_offering: center_offering_id (
    name
  )
),
appointment_packages (
  total_cost_amount,
  center_package: center_package_id (
    name
  ),
  appointment_offerings (
    status,
    has_unexpected_findings,
    is_receipt_confirmed,
    center_offering: center_offering_id (
      name
    )
  )
),
appointment_add_ons (
  total_cost_amount,
  center_add_on: center_add_on_id (
    name
  )
),
appointment_customer_details (
  type,
  customer_id,
  first_name,
  last_name
),
appointment_payments (
  id,
  transaction_id,
  amount,
  confirmed_at,
  center_payment_provider: center_payment_provider_id (
    payment_provider: payment_provider_id (
      type
    )
  ),
  center_payment_method: center_payment_method_id (
    payment_method: payment_method_id (
      type
    )
  )
)
`;

const getDashboardAppointments = async (params: {
  supabase: SupabaseClient;
  centerIds: string[];
  date: Date;
  statuses: DatabaseEnum["appointment_status"][];
  dateRange?: DateRange<Date>;
  timezone?: string | null;
}) => {
  const { supabase, centerIds, date, statuses, dateRange, timezone } = params;

  const startOfDate = formatClientTimezoneToUTC_ISO(startOfDay(date), timezone);

  const endOfDate = formatClientTimezoneToUTC_ISO(endOfDay(date), timezone);

  const todaysDateFilter = `and(started_at.gte.${startOfDate},started_at.lte.${endOfDate})`;

  const dateRangeFilterValues = getDateRangeFilterValues({
    dateRange,
    timezone,
  });

  const dateFilter = dateRangeFilterValues || todaysDateFilter;

  let query = supabase
    .from<DashboardAppointment>("appointments")
    .select(supabaseAppointmentsRequest)
    .order("id")
    .in("status", statuses)
    .is(
      "appointment_offerings.appointment_package_id" as keyof DashboardAppointment,
      null,
    )
    .not(
      "appointment_payments.confirmed_at" as keyof DashboardAppointment,
      "is",
      null,
    )
    .or(dateFilter, { foreignTable: "time_slot" });

  if (centerIds.length) {
    query = query.in("center_id", centerIds);
  }

  if (statuses.length) {
    query = query.in("status", statuses);
  }

  const { data, error } = await query;

  if (error) {
    throw new Error(error.message);
  }

  return data;
};

export default getDashboardAppointments;
