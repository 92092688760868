import Box from "../../../Unknown/Box";
import React, { GridColDef } from "../../../Unknown/DataGrid";
import IconButton from "../../../Unknown/IconButton";
import {
  AssignmentLateOutlinedIcon,
  AssignmentTurnedInOutlinedIcon,
} from "../../../Unknown/Icons";
import Tooltip from "../../../Unknown/Tooltip";
import { AppointmentReport } from "../types";

type ColumnTranslations = {
  tooltipUnexpectedFindings: string;
  tooltipReceiptConfirmed: string;
};

type ColumnParams = {
  translations: ColumnTranslations;
  headerClassName: string;
};

const getColumnAlert = ({
  translations,
  headerClassName,
}: ColumnParams): GridColDef<AppointmentReport, string> => {
  const { tooltipUnexpectedFindings, tooltipReceiptConfirmed } = translations;
  return {
    field: "alert",
    disableColumnMenu: true,
    width: 50,
    headerName: "",
    sortable: false,
    headerClassName,
    renderCell: ({ row }) => {
      const tooltipPopperProps = {
        sx: {
          maxWidth: 100,
        },
      };

      return (
        <Box ml={-1}>
          {row.hasUnexpectedFindings && !row.isReceiptConfirmed && (
            <Tooltip
              title={tooltipUnexpectedFindings}
              arrow
              slotProps={{
                popper: tooltipPopperProps,
              }}
            >
              <IconButton size="small">
                <AssignmentLateOutlinedIcon color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {row.isReceiptConfirmed && (
            <Tooltip
              title={tooltipReceiptConfirmed}
              arrow
              slotProps={{
                popper: tooltipPopperProps,
              }}
            >
              <IconButton size="small">
                <AssignmentTurnedInOutlinedIcon
                  fontSize="small"
                  color="success"
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
    },
  };
};

export default getColumnAlert;
