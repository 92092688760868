import { useCallback, useState } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import getCenterOfferingsData from "./getCenterOfferingsData";
import { CenterOffering } from "./types";
import useTranslations from "./useTranslations";

const useCenterOfferingsData = () => {
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const { defaultError } = useTranslations();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [data, setData] = useState<CenterOffering[] | null>(null);

  const fetch = useCallback(
    async (params?: {
      centerId: string;
      price: number;
      durationMinutes: number;
    }) => {
      if (!params) return;

      setIsLoading(true);

      try {
        const result = await runAsyncFunction(getCenterOfferingsData, params);

        setData(result);
      } catch (error) {
        setErrorMessage(defaultError);
        setData(null);
      } finally {
        setIsLoading(false);
      }
    },
    [defaultError, runAsyncFunction],
  );

  return {
    isLoading,
    errorMessage,
    data,
    refetch: fetch,
  };
};

export default useCenterOfferingsData;
