import { useCallback, useMemo, useState } from "react";
import { AppointmentReportResendDialogProps } from ".";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { AppointmentReport } from "../AppointmentReportGrid/types";

const useAppointmentReportResendDialog = (params: {
  appointmentId: string;
  patientEmail: string;
  patientFirstName: string;
  patientLastName: string;
  reports: AppointmentReport[];
  refetch: () => Promise<void>;
}): {
  props: AppointmentReportResendDialogProps | null;
  isOpened: boolean;
  onOpenClick: (id: string) => void;
} => {
  const {
    reports,
    appointmentId,
    patientEmail,
    patientFirstName,
    patientLastName,
    refetch,
  } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [reportId, setReportId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { runAsyncFunction } = useDefaultErrorWrapper();
  const resendAppointmentReport = useFirebaseAppFunction(
    "resendAppointmentReport",
  );

  const onOpenClick = (id: string) => setReportId(id);
  const handleClose = () => setReportId(null);

  const report = useMemo(() => {
    if (!reportId) return null;
    return reports.find(({ id }) => id === reportId);
  }, [reportId, reports]);

  const handleSubmit = useCallback(async () => {
    if (!reportId) return;

    setIsLoading(true);
    try {
      await runAsyncFunction(resendAppointmentReport, {
        appointmentId,
        reportId,
      });

      await refetch();
      handleClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [
    appointmentId,
    reportId,
    refetch,
    runAsyncFunction,
    resendAppointmentReport,
  ]);

  const isOpened = !!reportId;

  return {
    isOpened,
    onOpenClick,
    props: report
      ? {
          isOpened,
          filename: report.name || "",
          isLoading,
          patientEmail,
          patientFirstName,
          patientLastName,
          errorMessage,
          handleClose,
          handleSubmit,
        }
      : null,
  };
};

export default useAppointmentReportResendDialog;
