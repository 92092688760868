import React, { FC } from "react";
import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import useStyles from "./useStyles";

type PatientNamePreviewProps = {
  fullName: string;
  onNameClick?: () => void;
};

const PatientNamePreview: FC<PatientNamePreviewProps> = ({
  fullName,
  onNameClick,
}) => {
  const { nameText } = useStyles();

  return (
    <Box overflow="hidden" textOverflow="ellipsis">
      <Typography
        variant="body2"
        className={nameText}
        title={fullName}
        onClick={onNameClick}
      >
        {fullName}
      </Typography>
    </Box>
  );
};

export default PatientNamePreview;
