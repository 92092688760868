import { defineMessages } from "react-intl";

export default defineMessages({
  method: {
    id: "AppointmentPayment.AppointmentPaymentAddDialogContainer.method",
    defaultMessage: "Method",
  },
  amount: {
    id: "AppointmentPayment.AppointmentPaymentAddDialogContainer.amount",
    defaultMessage: "Amount",
  },
  totalPrice: {
    id: "AppointmentPayment.AppointmentPaymentAddDialogContainer.totalPrice",
    defaultMessage: "Total price",
  },
  remainingBalance: {
    id: "AppointmentPayment.AppointmentPaymentAddDialogContainer.remainingBalance",
    defaultMessage: "Remaining balance",
  },
  methodIsRequired: {
    id: "AppointmentPayment.AppointmentPaymentAddDialogContainer.methodIsRequired",
    defaultMessage: "Payment method is required",
  },
  paymentFailed: {
    id: "AppointmentPayment.AppointmentPaymentAddDialogContainer.paymentFailed",
    defaultMessage: "Payment failed",
  },
  invalidAmount: {
    id: "AppointmentPayment.AppointmentPaymentAddDialogContainer.invalidAmount",
    defaultMessage:
      "Amount must be greater than 0 and less than the remaining balance",
  },
  timeoutExceeded: {
    id: "AppointmentPayment.AppointmentPaymentAddDialogContainer.timeoutExceeded",
    defaultMessage: "Timeout exceeded, please try again",
  },
});
