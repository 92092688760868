import { GetCustomerPaymentMethodsResponse } from "@Shape-Digital/kudzu-data/lib/types/actions";
import { useEffect, useState } from "react";

import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { PaymentOption } from "../../AppointmentPayment/AppointmentPaymentAddForm";
import { Appointment } from "./types";
import useTranslations from "./useTranslations";

const usePaymentOptions = (appointment: Appointment | null) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [savedPaymentMethods, setSavedPaymentMethods] =
    useState<GetCustomerPaymentMethodsResponse>([]);

  const getCustomerPaymentMethods = useFirebaseAppFunction(
    "getCustomerPaymentMethods",
  );

  const { paymentsTranslations: translations } = useTranslations();

  let paymentOptions: PaymentOption[] = [];

  const centerPaymentMethods =
    appointment?.center?.center_payment_methods || [];

  const cardMethod = centerPaymentMethods.find(
    (method) => method.payment_method.type === "card",
  );

  const cardPaymentProvider =
    cardMethod?.center_payment_provider.payment_provider.type || "";

  const customerId = appointment?.appointment_customer_details[0]?.customer_id;

  useEffect(() => {
    if (customerId && cardPaymentProvider) {
      const getSavedMethods = async () => {
        try {
          setIsLoading(true);
          const { data: paymentMethods } = await getCustomerPaymentMethods({
            customerId,
            type: "card",
            paymentProvider: cardPaymentProvider,
          });

          setSavedPaymentMethods(paymentMethods);
        } catch (error) {
          if (error instanceof Error) {
            setErrorMessage(error.message);
          }
        } finally {
          setIsLoading(false);
        }
      };
      getSavedMethods();
    }
  }, [cardPaymentProvider, customerId, getCustomerPaymentMethods]);

  if (!appointment) return { isLoading, errorMessage, paymentOptions: [] };

  const centerPaymentProviderReaders =
    appointment.center?.center_payment_provider_readers || [];

  const cashMethod = centerPaymentMethods.find(
    (method) => method.payment_method.type === "cash",
  );

  if (cashMethod) {
    paymentOptions = [
      {
        id: "cash",
        centerPaymentMethodId: cashMethod.id,
        label: translations.cash,
      },
    ];
  }

  const terminalMethod = centerPaymentMethods.find(
    (method) => method.payment_method.type === "card_present",
  );

  if (terminalMethod) {
    const readerOptions = centerPaymentProviderReaders.map((reader) => {
      return {
        id: reader.payment_provider_reader_id,
        centerPaymentMethodId: terminalMethod.id,
        label: reader.payment_provider_reader_label,
      };
    });

    paymentOptions = [...paymentOptions, ...readerOptions];
  }

  if (cardMethod) {
    paymentOptions = [
      ...paymentOptions,
      {
        id: "direct-payment",
        label: translations.card,
        centerPaymentMethodId: cardMethod.id,
      },
    ];
  }

  if (cardMethod && savedPaymentMethods.length) {
    const cardMethodsByLast4Digit: Record<string, PaymentOption> = {};

    savedPaymentMethods.forEach(({ id, title }) => {
      const isCardExist = cardMethodsByLast4Digit[title];

      if (!isCardExist) {
        const cardLabel = `**** **** **** ${title}`;
        cardMethodsByLast4Digit[title] = {
          id,
          label: cardLabel,
          centerPaymentMethodId: cardMethod.id,
        };
      }
    });

    paymentOptions = [
      ...paymentOptions,
      ...Object.values(cardMethodsByLast4Digit),
    ];
  }

  return { isLoading, errorMessage, paymentOptions };
};

export default usePaymentOptions;
