import React, { FC } from "react";
import Box from "../../Unknown/Box";
import Link from "../../Unknown/Link";
import Typography from "../../Unknown/Typography";
import { formatInternational } from "../../../common/phoneNumberHelpers";
import useStyles from "./useStyles";

type PatientPhoneProps = {
  phoneNumber: string;
};

const PatientPhone: FC<PatientPhoneProps> = ({ phoneNumber }) => {
  const { linkTypographyRoot } = useStyles();
  const formattedPhoneNumber = formatInternational({
    phoneNumber,
  });

  return (
    <Box overflow="hidden" textOverflow="ellipsis">
      <Typography
        component={Link}
        href={`tel:${phoneNumber}`}
        variant="body2"
        className={linkTypographyRoot}
        display="block"
      >
        {formattedPhoneNumber}
      </Typography>
    </Box>
  );
};

export default PatientPhone;
