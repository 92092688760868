import { defineMessages } from "react-intl";

const messages = defineMessages({
  titleAppointments: {
    id: "Customer.CustomerMainCards.titleAppointments",
    defaultMessage: "Appointments",
  },
  titleInformation: {
    id: "Customer.CustomerMainCards.titleInformation",
    defaultMessage: "Information",
  },
  titleAuditLogs: {
    id: "Customer.CustomerMainCards.titleAuditLogs",
    defaultMessage: "Audit Log",
  },
  viewAuditLogsBtn: {
    id: "Customer.CustomerMainCards.viewAuditLogsBtn",
    defaultMessage: "View audit log",
  },
  hideAuditLogsBtn: {
    id: "Customer.CustomerMainCards.hideAuditLogsBtn",
    defaultMessage: "Hide audit log",
  },
});

export default messages;
