import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "AppointmentFile.AppointmentFileAddDialog.title",
    defaultMessage: "Upload File(s)",
  },
  btnSubmit: {
    id: "AppointmentFile.AppointmentFileAddDialog.btnSubmit",
    defaultMessage: "Upload",
  },
  btnCancel: {
    id: "AppointmentFile.AppointmentFileAddDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  filesHelperText: {
    id: "AppointmentFile.AppointmentFileAddDialogContainer.filesHelperText",
    defaultMessage: "Drag and drop files here or click to select files",
  },
  labelFiles: {
    id: "AppointmentFile.AppointmentFileAddDialogContainer.labelFiles",
    defaultMessage: "Files",
  },
  errorMaxSize: {
    id: "AppointmentFile.AppointmentFileAddDialogContainer.errorMaxSize",
    defaultMessage: "Max file size is {value} mb",
  },
  errorMaxCount: {
    id: "AppointmentFile.AppointmentFileAddDialogContainer.errorMaxCount",
    defaultMessage: "Max file count is {value}",
  },
  errorFormatInvalid: {
    id: "AppointmentFile.AppointmentFileAddDialogContainer.errorFormatInvalid",
    defaultMessage: "File format is invalid. Please use: {value}",
  },
});

export default messages;
