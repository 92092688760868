import React, { FC } from "react";
import { useFormikContext } from "formik";
import FormikTextField, {
  FormikTextFieldProps,
} from "../../Unknown/FormikTextField";
import Grid from "../../Unknown/Grid";
import MenuItem from "../../Unknown/MenuItem";
import useTranslations from "./useTranslations";
import { CenterOfferingFormValues, LabelTranslationsKey } from "./types";
import { inputSpacing, scanSides, visibilityTypes } from "./constants";
import InputAdornment from "../../Unknown/InputAdornment";
import Typography from "../../Unknown/Typography";

type CenterOfferingFormProps = {
  currencyCode: string;
  labelTranslations: Record<LabelTranslationsKey, string>;
};

const CenterOfferingForm: FC<CenterOfferingFormProps> = ({
  currencyCode,
  labelTranslations,
}) => {
  const translations = useTranslations({ currencyCode });

  const { isSubmitting } = useFormikContext<CenterOfferingFormValues>();

  const defaultTextFieldProps: Partial<FormikTextFieldProps> = {
    fullWidth: true,
    required: true,
    autoComplete: "off",
    disabled: isSubmitting,
  };

  return (
    <Grid container spacing={inputSpacing} direction="row">
      <Grid item container spacing={inputSpacing}>
        <Grid item xs={4}>
          <FormikTextField
            {...defaultTextFieldProps}
            name="name"
            label={labelTranslations.labelName}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField
            {...defaultTextFieldProps}
            name="modalityName"
            label={labelTranslations.labelModalityName}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField
            {...defaultTextFieldProps}
            name="scanSide"
            label={labelTranslations.labelScanSide}
            select
          >
            {scanSides.map((scanSide) => (
              <MenuItem key={scanSide.id} value={scanSide.id}>
                {translations[scanSide.translationKey]}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
      </Grid>
      <Grid item container spacing={inputSpacing}>
        <Grid item xs={4}>
          <FormikTextField
            {...defaultTextFieldProps}
            type="number"
            name="durationMinutes"
            label={labelTranslations.labelDurationMinutes}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography>{translations.durationMinutesUnit}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField
            {...defaultTextFieldProps}
            type="number"
            name="price"
            label={labelTranslations.labelPrice}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{translations.currencySymbol}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField
            {...defaultTextFieldProps}
            type="number"
            name="depositAmount"
            label={labelTranslations.labelDepositAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{translations.currencySymbol}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={inputSpacing}>
        <Grid item xs={4}>
          <FormikTextField
            {...defaultTextFieldProps}
            name="visibility"
            label={labelTranslations.labelVisibility}
            select
          >
            {visibilityTypes.map((visibilityType) => (
              <MenuItem key={visibilityType.id} value={visibilityType.id}>
                {translations[visibilityType.translationKey]}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item xs={8}>
          <FormikTextField
            {...defaultTextFieldProps}
            name="description"
            label={labelTranslations.labelDescription}
            multiline
            maxRows={4}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CenterOfferingForm;
