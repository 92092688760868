import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import startOfDay from "date-fns/startOfDay";
import { useMemo } from "react";

import * as yup from "yup";
import subMilliseconds from "date-fns/subMilliseconds";
import {
  addTimeToDate,
  getNowUTC,
  getTimezoneOffsetMillis,
} from "../../../common/dateHelpers";
import useValidationTranslations from "./useValidationTranslations";

const MIN_NAME_LENGTH = 3;
const MAX_NAME_LENGTH = 50;

const useValidationSchema = (params: { timeZone?: string }) => {
  const { timeZone } = params;

  const translations = useValidationTranslations({
    minNameLength: MIN_NAME_LENGTH,
    maxNameLength: MAX_NAME_LENGTH,
  });

  const validationSchema = useMemo(() => {
    const {
      errorMinName,
      errorMaxName,
      errorRequired,
      errorStartMinDate,
      errorStartMinTime,
      errorEndMinDate,
      errorEndMinTime,
      dateInvalid,
      timeInvalid,
    } = translations;

    return yup.object().shape({
      name: yup
        .string()
        .trim()
        .min(MIN_NAME_LENGTH, errorMinName)
        .max(MAX_NAME_LENGTH, errorMaxName)
        .required(errorRequired),
      startDate: yup
        .date()
        .typeError(dateInvalid)
        .min(startOfDay(new Date()), errorStartMinDate)
        .required(errorRequired),
      endDate: yup
        .date()
        .typeError(dateInvalid)
        .test("maxEndDate", errorRequired, function maxEndDate(value) {
          if (!value) return false;

          const { startDate } = this.parent;

          if (!startDate) return true;

          const isEndDateAfterStart = !isBefore(value, startDate);

          if (!isEndDateAfterStart) {
            return this.createError({ message: errorEndMinDate });
          }

          return true;
        })
        .required(errorRequired),
      startTime: yup
        .date()
        .typeError(timeInvalid)
        .test("minStartTime", errorRequired, function minStartTime(value) {
          if (!value) return false;

          const { startDate } = this.parent;

          if (!startDate) return true;

          const startDateTime = addTimeToDate(startDate, value);

          const nowUTC = getNowUTC();

          const centerTimeZoneOffsetMillis = getTimezoneOffsetMillis(timeZone);

          const startTimeUTC = subMilliseconds(
            startDateTime,
            centerTimeZoneOffsetMillis,
          );

          if (isBefore(startTimeUTC, nowUTC)) {
            return this.createError({ message: errorStartMinTime });
          }

          return true;
        })
        .required(errorRequired),
      endTime: yup
        .date()
        .typeError(timeInvalid)
        .test("maxEndTime", errorRequired, function maxEndTime(value) {
          if (!value) return false;

          const { startDate, endDate, startTime } = this.parent;

          if (!startDate || !endDate || !startTime) return true;

          if (!isSameDay(startDate, endDate)) return true;

          const startDateTime = addTimeToDate(startDate, startTime);
          const endDateTime = addTimeToDate(endDate, value);

          if (!isAfter(endDateTime, startDateTime)) {
            return this.createError({ message: errorEndMinTime });
          }

          return true;
        })
        .required(errorRequired),
    });
  }, [timeZone, translations]);

  return validationSchema;
};

export default useValidationSchema;
