import { defineMessages } from "react-intl";

const messages = defineMessages({
  headingName: {
    id: "AddOn.CenterAddOnsView.headingName",
    defaultMessage: "Name",
  },
  headingType: {
    id: "AddOn.CenterAddOnsView.headingType",
    defaultMessage: "Type",
  },
  headingPrice: {
    id: "AddOn.CenterAddOnsView.headingPrice",
    defaultMessage: "Price",
  },
  typeStat: {
    id: "AddOn.CenterAddOnsView.typeStat",
    defaultMessage: "STAT",
  },
  btnArchive: {
    id: "AddOn.CenterAddOnsView.btnArchive",
    defaultMessage: "Archive",
  },
  btnEdit: {
    id: "AddOn.CenterAddOnsView.btnEdit",
    defaultMessage: "Edit",
  },
});

export default messages;
