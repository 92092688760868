import { useCallback, useMemo, useState } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { AddOnsData } from "../../Center/CenterSettingsContainer/types";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterAddOnArchiveDialogProps } from "../CenterAddOnArchiveDialog";
import useTranslations from "./useTranslations";

type AddOn = Pick<AddOnsData["addOns"][number], "id" | "name">;

type ArchiveFormHookParams = {
  isOpened: boolean;
  addOnId: string | null;
  onClose: () => void;
  addOns: AddOn[];
  refetchCenterData?: () => void | Promise<void>;
};

type ArchiveFormHookResult = {
  archiveDialogProps: CenterAddOnArchiveDialogProps;
};

const useArchiveModal = (
  params: ArchiveFormHookParams,
): ArchiveFormHookResult => {
  const { isOpened, addOnId, onClose, addOns, refetchCenterData } = params;

  const { defaultError } = useTranslations();

  const { setAlert } = useUIContext();

  const [isLoading, setIsLoading] = useState(false);

  const archiveCenterAddOn = useFirebaseAppFunction("archiveCenterAddOn");

  const name = useMemo(() => {
    const addon = addOns.find((currentAddOn) => currentAddOn.id === addOnId);

    return addon?.name || "";
  }, [addOnId, addOns]);

  const onSubmit = useCallback(async () => {
    try {
      if (!addOnId) return;

      setIsLoading(true);

      await archiveCenterAddOn({ id: addOnId });

      if (refetchCenterData) await refetchCenterData();

      onClose();
    } catch (error) {
      setAlert({ isShown: true, severity: "error", message: defaultError });
    } finally {
      setIsLoading(false);
    }
  }, [
    archiveCenterAddOn,
    defaultError,
    addOnId,
    onClose,
    refetchCenterData,
    setAlert,
  ]);

  return {
    archiveDialogProps: {
      isOpen: isOpened,
      name,
      onSubmit,
      onClose,
      isLoading,
    },
  };
};

export default useArchiveModal;
