import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import { CenterDiscountValidationTranslations } from "./getCenterDiscountFormSchema";
import messages from "./messages";

const useFormTranslations = (params: { maxDiscount: number }) => {
  const { maxDiscount } = params;

  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
    };

    const validationTranslations: CenterDiscountValidationTranslations = {
      requiredField: formatMessage(commonMessages.requiredField),
      minimumDiscountError: formatMessage(messages.minimumDiscountError),
      maximumDiscountError: formatMessage(messages.maximumDiscountError, {
        value: maxDiscount,
      }),
    };

    return { translations, validationTranslations };
  }, [formatMessage, maxDiscount]);

  return result;
};
export default useFormTranslations;
