import React, { GridColDef } from "../../../Unknown/DataGrid";
import Grid from "../../../Unknown/Grid";
import Typography from "../../../Unknown/Typography";
import { CenterService } from "../types";

type ColumnValue = CenterService["studies"];

type ColumnTranslations = {
  headerStudies: string;
};

type ColumnParams = {
  translations: ColumnTranslations;
  headerClassName: string;
};
const getColumnStudies = ({
  translations,
  headerClassName,
}: ColumnParams): GridColDef<CenterService, ColumnValue> => {
  const { headerStudies } = translations;
  return {
    field: "studies",
    disableColumnMenu: true,
    flex: 0.8,
    headerName: headerStudies,
    sortable: false,
    headerClassName,
    renderCell: (renderParams) => {
      const { value } = renderParams;
      if (!value) return null;

      return (
        <Grid container spacing={3} direction="column" wrap="nowrap">
          {value.map((study) => {
            return (
              <Grid item key={study.id}>
                <Typography variant="body2">{study.modalityName}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {study.acc}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      );
    },
  };
};

export default getColumnStudies;
