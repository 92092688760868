import { useMemo } from "react";
import { PatientInformationDrawerProps } from "../PatientInformationDrawer";
import getAppointmentsPreviewGridPropsProps from "./getAppointmentsPreviewGridPropsProps";
import getPatientFormsPreviewGridProps from "./getPatientFormsPreviewGridProps";
import getPatientInformationCardProps from "./getPatientInformationCardProps";
import { Patient } from "./types";
import usePatientEditFormContainer from "./usePatientEditFormContainer";

const useNormalizedPatientDrawerData = (params: {
  patient: Patient | null;
  isEmailShown: boolean;
  isPhoneShown: boolean;
  handleCustomerClick: (customerId: string) => void;
  handleAppointmentClick: (appointmentId: string) => void;
}) => {
  const {
    patient,
    isEmailShown,
    isPhoneShown,
    handleCustomerClick,
    handleAppointmentClick,
  } = params;

  const patientEditFormContainerProps = usePatientEditFormContainer({
    patientId: patient?.id,
    isEmailShown,
    isPhoneShown,
  });

  const data = useMemo(() => {
    if (!patient || !patientEditFormContainerProps) return null;

    const result: PatientInformationDrawerProps["data"] = {
      name: `${patient.last_name}, ${patient.first_name}`,
      patientInformationCardProps: getPatientInformationCardProps({
        patient,
        handleCustomerClick,
      }),
      appointmentsPreviewGridPropsProps: getAppointmentsPreviewGridPropsProps({
        patient,
        handleAppointmentClick,
      }),
      patientEditFormContainerProps,
      patientFormsPreviewGridProps: getPatientFormsPreviewGridProps(patient),
      auditLogsData: {
        auditLogs: patient.patient_audit_logs,
        timezone:
          patient.appointment_patient_details[0].appointment.center.timezone,
      },
    };

    return result;
  }, [
    patient,
    patientEditFormContainerProps,
    handleCustomerClick,
    handleAppointmentClick,
  ]);

  return data;
};

export default useNormalizedPatientDrawerData;
