import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Unknown.DateTypographyHeader.title",
    defaultMessage: "Today's appointments ({dateString})",
  },
});

export default messages;
