import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, spacing }) => {
  return {
    btnRoot: {
      backgroundColor: palette.common.white,
      color: palette.grey[700],
      "&:hover": {
        color: palette.common.white,
      },
      padding: spacing(2),
      minWidth: 0,
      fontSize: 14,
    },
    dateRoot: {
      marginLeft: spacing(2),
      marginRight: spacing(2),
      paddingLeft: spacing(3.2),
      paddingTop: spacing(1.4),
      paddingRight: spacing(3.2),
      paddingBottom: spacing(1.4),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  };
});

export default useStyles;
