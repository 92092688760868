import { useState } from "react";
import { useParams } from "react-router-dom";
import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import getOptionalArrayItem from "../../../common/getOptionalArrayItem";
import { AppointmentActionBarProps } from ".";
import checkIsCanBeMarkedAsPriority from "./checkIsCanBeMarkedAsPriority";
import checkIsCanBeMarkedAsNoShow from "./checkIsCanBeMarkedAsNoShow";
import useTranslations from "../AppointmentCardsContainer/useTranslations";
import { Appointment } from "../AppointmentCardsContainer/types";
import checkIsCanBeCanceled from "./checkIsCanBeCanceled";
import checkIsCanBeRescheduled from "./checkIsCanBeRescheduled";
import checkIsCanLogin from "./checkIsCanLogin";
import checkIsCanCheckIn from "./checkIsCanCheckIn";
import checkIsAdditionalViewsCanBeBooked from "./checkIsAdditionalViewsCanBeBooked";
import checkIsScreeningFormButtonShown from "./checkIsScreeningFormButtonShown";
import checkIsCanBeConfirmed from "./checkIsCanBeConfirmed";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";

const useAppointmentActionsBar = (params: {
  appointment: Appointment | null;
  handleCancelClick: () => void;
  handleRescheduleClick: () => void;
  handlePriorityClick: () => void;
  handleNoShowClick: () => void;
  handleViewAuditLogClick: () => void;
  handleLoginClick: () => void;
  handleCheckInClick: () => void;
  handleBookAdditionalViewClick: () => void;
  handleScreeningFormClick: () => void;
  refetch: () => void;
}): AppointmentActionBarProps => {
  const {
    appointment,
    handleCancelClick,
    handleRescheduleClick,
    handlePriorityClick,
    handleNoShowClick,
    handleViewAuditLogClick,
    handleLoginClick,
    handleCheckInClick,
    handleBookAdditionalViewClick,
    handleScreeningFormClick,
    refetch,
  } = params;

  const [isConfirming, setIsConfirming] = useState(false);

  const { role } = useParams<{ role?: DatabaseEnum["center_user_role"] }>();
  const confirmAppointment = useFirebaseAppFunction("confirmAppointment");
  const { actionBarTranslations } = useTranslations();
  const { setAlert } = useUIContext();

  const handleConfirmClick = async () => {
    if (!appointment) return;
    try {
      setIsConfirming(true);
      await confirmAppointment({ appointmentId: appointment.id });
      await refetch();
    } catch (error) {
      if (error instanceof Error) {
        setAlert({ severity: "error", message: error.message, isShown: true });
      }
    } finally {
      setIsConfirming(false);
    }
  };

  const isCanBeMarkedAsPriority = checkIsCanBeMarkedAsPriority({
    role,
    appointment,
  });

  const isCanBeMarkedAsNoShow = checkIsCanBeMarkedAsNoShow({
    role,
    appointment,
  });

  const isCanBeCanceled = checkIsCanBeCanceled({
    role,
    appointment,
  });

  const isCanBeRescheduled = checkIsCanBeRescheduled({
    role,
    appointment,
  });

  const isCanLogin = checkIsCanLogin({
    role,
    appointment,
  });

  const isCanCheckIn = checkIsCanCheckIn({
    role,
    appointment,
  });

  const isAdditionalViewsCanBeBooked = checkIsAdditionalViewsCanBeBooked({
    role,
    appointment,
  });

  const isScreeningFormButtonShown = checkIsScreeningFormButtonShown({
    role,
    appointment,
  });

  const isCanBeConfirmed = checkIsCanBeConfirmed({
    role,
    appointment,
  });

  const priorityButtonProps = getOptionalArrayItem(isCanBeMarkedAsPriority, {
    text: actionBarTranslations.btnPriority,
    key: "priority",
    variant: "contained",
    color: "primary",
    onClick: handlePriorityClick,
  } as const);

  const noShowButtonProps = getOptionalArrayItem(isCanBeMarkedAsNoShow, {
    text: actionBarTranslations.btnNoShow,
    key: "no-show",
    variant: "contained",
    color: "error",
    onClick: handleNoShowClick,
  } as const);

  const viewAuditLogButtonProps = getOptionalArrayItem(true, {
    text: actionBarTranslations.btnViewAuditLogs,
    key: "view-audit-log",
    variant: "outlined",
    color: "primary",
    onClick: handleViewAuditLogClick,
  } as const);

  const cancelButtonProps = getOptionalArrayItem(isCanBeCanceled, {
    text: actionBarTranslations.btnCancel,
    key: "cancel",
    variant: "contained",
    color: "error",
    onClick: handleCancelClick,
  } as const);

  const rescheduleButtonProps = getOptionalArrayItem(isCanBeRescheduled, {
    text: actionBarTranslations.btnReschedule,
    key: "reschedule",
    variant: "contained",
    onClick: handleRescheduleClick,
  } as const);

  const loginButtonProps = getOptionalArrayItem(isCanLogin, {
    text: actionBarTranslations.btnLogin,
    key: "login",
    color: "primary",
    variant: "contained",
    onClick: handleLoginClick,
  } as const);

  const checkInButtonProps = getOptionalArrayItem(isCanCheckIn, {
    text: actionBarTranslations.btnCheckIn,
    key: "check-in",
    color: "primary",
    variant: "contained",
    onClick: handleCheckInClick,
  } as const);

  const bookAdditionalViewsButtonProps = getOptionalArrayItem(
    isAdditionalViewsCanBeBooked,
    {
      text: actionBarTranslations.btnBookAdditionalViews,
      key: "book-additional-view",
      color: "primary",
      variant: "contained",
      onClick: handleBookAdditionalViewClick,
    } as const,
  );

  const confirmButtonProps = getOptionalArrayItem(isCanBeConfirmed, {
    text: actionBarTranslations.btnConfirmAppointment,
    key: "confirm-appointment",
    color: "primary",
    variant: "contained",
    disabled: isConfirming,
    onClick: handleConfirmClick,
  } as const);

  const screeningFormButtonProps = getOptionalArrayItem(
    isScreeningFormButtonShown,
    {
      text: actionBarTranslations.btnScreeningForm,
      key: "screening-form",
      color: "primary",
      variant: "contained",
      onClick: handleScreeningFormClick,
    } as const,
  );

  return {
    leftButtons: [...viewAuditLogButtonProps],
    rightButtons: [
      ...noShowButtonProps,
      ...cancelButtonProps,
      ...rescheduleButtonProps,
      ...priorityButtonProps,
      ...loginButtonProps,
      ...checkInButtonProps,
      ...bookAdditionalViewsButtonProps,
      ...screeningFormButtonProps,
      ...confirmButtonProps,
    ],
  };
};

export default useAppointmentActionsBar;
