import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import { formatClientTimezoneToUTC_ISO } from "../../../common/dateHelpers";
import { DateRange } from "../../Unknown/DateRangePicker";

export const DAYS_OFFSET = 1;

const getDateRangeFilterValues = ({
  dateRange,
  timezone,
}: {
  dateRange?: DateRange<Date>;
  timezone?: string | null;
}): string | null => {
  if (!dateRange) return null;

  const fromDate = dateRange[0]
    ? `started_at.gte.${formatClientTimezoneToUTC_ISO(
        startOfDay(dateRange[0]),
        timezone,
      )}`
    : null;

  const toDate = dateRange[1]
    ? `started_at.lte.${formatClientTimezoneToUTC_ISO(
        endOfDay(dateRange[1]),
        timezone,
      )}`
    : null;

  if (fromDate && toDate) {
    return `and(${fromDate},${toDate})`;
  }

  return fromDate || toDate;
};

export default getDateRangeFilterValues;
