import React, { FC } from "react";
import { PAGE_SIZE } from "../../../common/constants";
import DataGrid from "../../Unknown/DataGrid";
import { Appointment } from "./types";
import useColumns from "./useColumns";
import useStyles from "./useStyles";

export type AppointmentsPreviewGridProps = {
  appointments: Appointment[];
  onViewClick: (id: string) => void;
};

const AppointmentsPreviewGrid: FC<AppointmentsPreviewGridProps> = ({
  appointments,
  onViewClick,
}) => {
  const { root } = useStyles();
  const columns = useColumns({ onViewClick });

  return (
    <DataGrid
      autoHeight
      getRowHeight={() => "auto"}
      rows={appointments}
      columns={columns}
      disableRowSelectionOnClick
      className={root}
      hideFooterRowCount
      hideFooter
      initialState={{
        sorting: {
          sortModel: [{ field: "dateTime", sort: "desc" }],
        },
      }}
      sortingOrder={["asc", "desc"]}
      pageSizeOptions={[PAGE_SIZE]}
    />
  );
};

export default AppointmentsPreviewGrid;
