import { IntlShape } from "react-intl";

const getFormattedPrice = ({
  intl,
  currencyCode,
  value,
  disableFloat,
}: {
  intl: IntlShape;
  currencyCode: string;
  value: number;
  disableFloat?: boolean;
}): string => {
  const formattedPrice = intl.formatNumber(value, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: disableFloat ? 0 : 2,
    maximumFractionDigits: disableFloat ? 0 : 2,
  });

  return formattedPrice;
};

export default getFormattedPrice;
