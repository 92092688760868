import { defineMessages, useIntl } from "react-intl";

export const messages = defineMessages({
  white: {
    id: "Patient.EditForm.white",
    defaultMessage: "White",
  },
  africanAmerican: {
    id: "Patient.EditForm.africanAmerican",
    defaultMessage: "African American",
  },
  asianPacificIslander: {
    id: "common.messages.asianPacificIslander",
    defaultMessage: "Asian/Pacific Islander",
  },
  americanIndian: {
    id: "Patient.EditForm.americanIndian",
    defaultMessage: "American Indian",
  },
  hispanic: {
    id: "Patient.EditForm.hispanic",
    defaultMessage: "Hispanic",
  },
  other: {
    id: "Patient.EditForm.other",
    defaultMessage: "Other",
  },
  preferNotToAnswer: {
    id: "Patient.EditForm.preferNotToAnswer",
    defaultMessage: "Prefer not to answer",
  },
});

export const useRaceTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = {
    white: formatMessage(messages.white),
    african_american: formatMessage(messages.africanAmerican),
    asian_pacific_islander: formatMessage(messages.asianPacificIslander),
    american_indian: formatMessage(messages.americanIndian),
    hispanic: formatMessage(messages.hispanic),
    other: formatMessage(messages.other),
    prefer_not_to_answer: formatMessage(messages.preferNotToAnswer),
  };

  return translations;
};

export const useRaceOptions = () => {
  const { formatMessage } = useIntl();

  const options = [
    {
      value: "white",
      label: formatMessage(messages.white),
    },
    {
      value: "african_american",
      label: formatMessage(messages.africanAmerican),
    },
    {
      value: "asian_pacific_islander",
      label: formatMessage(messages.asianPacificIslander),
    },
    {
      value: "american_indian",
      label: formatMessage(messages.americanIndian),
    },
    {
      value: "hispanic",
      label: formatMessage(messages.hispanic),
    },
    {
      value: "other",
      label: formatMessage(messages.other),
    },
    {
      value: "prefer_not_to_answer",
      label: formatMessage(messages.preferNotToAnswer),
    },
  ];

  return options;
};
