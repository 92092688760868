import format from "date-fns/format";
import subMilliseconds from "date-fns/subMilliseconds";
import { View } from "../TimeSlotCalendarGrid/helpers";

const getMidDateMillis = (start: Date, end: Date) =>
  (start.getTime() + end.getTime()) / 2;

const formatSwitcherDate = (params: { start: Date; end: Date; view: View }) => {
  const { start, end, view } = params;
  switch (view) {
    case "day":
      return format(start, "EEE, MMM dd, yyyy");
    case "week":
      return `${format(start, "MMM dd")} - ${format(
        subMilliseconds(end, 1),
        "MMM dd, yyyy",
      )}`;
    case "month": {
      const middleDateMillis = getMidDateMillis(start, end);
      return format(middleDateMillis, "MMMM yyyy");
    }
    default:
      return "";
  }
};

export default formatSwitcherDate;
