import { useCallback, useMemo, useState } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { PackagesData } from "../../Center/CenterSettingsContainer/types";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterPackageArchiveDialogProps } from "../CenterPackageArchiveDialog";
import useTranslations from "./useTranslations";

type Package = Pick<PackagesData["packages"][number], "id" | "name">;

type ArchiveFormHookParams = {
  isOpened: boolean;
  packageId: string | null;
  onClose: () => void;
  packages: Package[];
  refetchCenterData?: () => void | Promise<void>;
};

type ArchiveFormHookResult = {
  archiveDialogProps: CenterPackageArchiveDialogProps;
};

const useArchiveModal = (
  params: ArchiveFormHookParams,
): ArchiveFormHookResult => {
  const { isOpened, packageId, onClose, packages, refetchCenterData } = params;

  const { defaultError } = useTranslations();

  const { setAlert } = useUIContext();

  const [isLoading, setIsLoading] = useState(false);

  const archiveCenterPackage = useFirebaseAppFunction("archiveCenterPackage");

  const name = useMemo(() => {
    const packageItem = packages.find(
      (currentPackage) => currentPackage.id === packageId,
    );

    return packageItem?.name || "";
  }, [packageId, packages]);

  const onSubmit = useCallback(async () => {
    try {
      if (!packageId) return;

      setIsLoading(true);

      await archiveCenterPackage({ id: packageId });

      if (refetchCenterData) await refetchCenterData();

      onClose();
    } catch (error) {
      setAlert({ isShown: true, severity: "error", message: defaultError });
    } finally {
      setIsLoading(false);
    }
  }, [
    archiveCenterPackage,
    defaultError,
    packageId,
    onClose,
    refetchCenterData,
    setAlert,
  ]);

  return {
    archiveDialogProps: {
      isOpen: isOpened,
      name,
      onSubmit,
      onClose,
      isLoading,
    },
  };
};

export default useArchiveModal;
