import { Formik } from "formik";
import React, { FC } from "react";
import CenterAddressForm, {
  CenterAddressFormValues,
} from "../CenterAddressForm";
import { Address } from "../CenterSettingsContainer/types";
import CenterSettingsFormikDialog from "../CenterSettingsFormikDialog";
import useCenterAddressForm from "./useCenterAddressForm";
import useTranslations from "./useTranslations";

export type CenterAddressFormikProps = {
  isOpened: boolean;
  onModalClose: () => void;
  centerId: string;
  centerAddressData: Address;
  handlePostSubmit: (values: CenterAddressFormValues) => void | Promise<void>;
};

const CenterAddressFormik: FC<CenterAddressFormikProps> = ({
  isOpened,
  onModalClose,
  centerId,
  centerAddressData,
  handlePostSubmit,
}) => {
  const { title } = useTranslations();
  const { formikConfig } = useCenterAddressForm({
    centerId,
    centerAddressData,
    handlePostSubmit,
  });

  return (
    <Formik {...formikConfig}>
      <CenterSettingsFormikDialog
        title={title}
        isOpened={isOpened}
        onClose={onModalClose}
      >
        <CenterAddressForm />
      </CenterSettingsFormikDialog>
    </Formik>
  );
};

export default CenterAddressFormik;
