import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import { PatientFormPreviewGridForm } from "../types";

type ColumnTranslations = {
  columnFormType: string;
};

type ColumnValue = {
  formType: string;
};

const getColumnFormType = (params: {
  translations: ColumnTranslations;
  headerClassName?: string;
}): GridColDef<PatientFormPreviewGridForm, ColumnValue> => {
  const { translations, headerClassName } = params;

  return {
    field: "formType",
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    headerName: translations.columnFormType,
    headerClassName,
    valueGetter: (getterParams) => {
      const { formType } = getterParams.row;
      return { formType };
    },
    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      return (
        <Typography variant="body2">{renderParams.value.formType}</Typography>
      );
    },
  };
};

export default getColumnFormType;
