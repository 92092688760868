import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(() => {
  return {
    titleRoot: {
      fontWeight: 700,
      fontSize: 10,
    },
    textRoot: {
      fontSize: 10,
    },
    monthRoot: {
      fontSize: 10,
    },
  };
});

export default useStyles;
