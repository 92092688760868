import { useState } from "react";
import { AppointmentNoteAddDialogContainerProps } from "../AppointmentNoteAddDialogContainer";
import { Appointment, Offering } from "./types";

const useAppointmentNodeAddDialogContainer = (params: {
  offerings: Offering[];
  handleAddNote: (params: {
    id: string;
    description: string;
    offeringId?: string;
  }) => void | Promise<void>;
  appointment: Appointment | null;
}): {
  handleOpen: () => void;
  props: AppointmentNoteAddDialogContainerProps;
} => {
  const { offerings, handleAddNote, appointment } = params;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return {
    handleOpen,
    props: {
      isOpen,
      handleClose,
      offerings,
      handleAddNote,
      appointmentId: appointment?.id || null,
    },
  };
};

export default useAppointmentNodeAddDialogContainer;
