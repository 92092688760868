import { useCallback, useRef } from "react";
import FullCalendar from "../../Unknown/Calendar";

const calendarGridViews = {
  month: "dayGridMonth",
  week: "timeGridWeek",
  day: "timeGridDay",
};

const useCalendarApi = () => {
  const calendarRef = useRef<FullCalendar | null>(null);

  const onViewChange = useCallback((view: keyof typeof calendarGridViews) => {
    const api = calendarRef.current?.getApi();
    if (!api) return;

    api.changeView(calendarGridViews[view], api.view.activeStart);
  }, []);

  const onPrevClick = useCallback(() => {
    const api = calendarRef.current?.getApi();
    if (!api) return;

    api.prev();
  }, []);

  const onNextClick = useCallback(() => {
    const api = calendarRef.current?.getApi();
    if (!api) return;

    api.next();
  }, []);

  const refetchCalendarEvents = useCallback(() => {
    const api = calendarRef.current?.getApi();
    if (!api) return;

    api.refetchEvents();
  }, []);

  return {
    calendarRef,
    onViewChange,
    onNextClick,
    onPrevClick,
    onAddEvent: refetchCalendarEvents,
    onEditEvent: refetchCalendarEvents,
    onDeleteEvent: refetchCalendarEvents,
    refetchCalendarEvents,
  };
};

export default useCalendarApi;
