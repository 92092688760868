import { ScanSideMenuOption, VisibilityTypeMenuOption } from "./types";

export const inputSpacing = 4;

export const scanSides: ScanSideMenuOption[] = [
  { id: "single", translationKey: "scanSideSingle" },
  { id: "dual", translationKey: "scanSideDual" },
];

export const visibilityTypes: VisibilityTypeMenuOption[] = [
  { id: "public", translationKey: "visibilityPublic" },
  { id: "only_package", translationKey: "visibilityOnlyPackage" },
  { id: "private", translationKey: "visibilityPrivate" },
];
