import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import Typography from "../../Unknown/Typography";
import getDeadlineMinutes from "./getDeadlineMinutes";
import useFormattedValue from "./useFormattedValue";

const timerUpdateInterval = 1000;

interface ReportDeliveryDeadlineCountdownProps {
  readyToReadAtCenterTimezone: Date;
  reportDeliveryDeadlineHours: number;
  timezone: string;
}

const ReportDeliveryDeadlineCountdown: React.FC<
  ReportDeliveryDeadlineCountdownProps
> = ({
  readyToReadAtCenterTimezone,
  reportDeliveryDeadlineHours,
  timezone,
}) => {
  const [minutesLeft, setMinutesLeft] = useState<number | null>(null);

  useEffectOnce(() => {
    const updateCountdown = () => {
      const deadlineMinutes = getDeadlineMinutes({
        timezone,
        readyToReadAtCenterTimezone,
        reportDeliveryDeadlineHours,
      });

      setMinutesLeft(Math.max(deadlineMinutes, 0));
    };

    updateCountdown();

    const interval = setInterval(updateCountdown, timerUpdateInterval);

    return () => {
      clearInterval(interval);
    };
  });

  const formattedValue = useFormattedValue(minutesLeft);

  return (
    <Typography
      variant="subtitle2"
      fontSize={11}
      lineHeight={1.2}
      textAlign="center"
      color="text.secondary"
    >
      {formattedValue}
    </Typography>
  );
};

export default ReportDeliveryDeadlineCountdown;
