import React, { useMemo } from "react";
import {
  AssignmentLateOutlinedIcon,
  AssignmentTurnedInOutlinedIcon,
  QueueOutlinedIcon,
  TimerOutlinedIcon,
  ArrowCircleUpIcon,
} from "../../Unknown/Icons";
import getDefaultActions from "./getDefaultActions";
import getStatusTranslation from "./getStatusTranslation";
import { Appointment, DefaultAction } from "./types";
import useTranslations from "./useTranslations";

const useActions = (params: { appointment: Appointment }) => {
  const { appointment } = params;
  const translations = useTranslations({
    scheduledAt: appointment.createdAtCenterTimezone,
  });

  const {
    tooltipStat,
    additionalViews,
    tooltipPriority,
    tooltipUnexpectedFindings,
    tooltipReceiptConfirmed,
  } = translations;

  const actions = useMemo(() => {
    const statusTranslation = getStatusTranslation(
      appointment.status,
      translations,
    );

    const appointmentCardFlagActions: DefaultAction[] = [];

    const appointmentCardDefaultActions: DefaultAction[] = [
      {
        id: "status",
        type: "chip",
        chipProps: {
          color: "primary",
          label: statusTranslation,
        },
      },
    ];

    if (
      appointment.hasUnexpectedFindings &&
      !appointment.isUnexpectedFindingsReceiptConfirmed
    ) {
      appointmentCardFlagActions.push({
        id: "has-unexpected-findings",
        type: "icon",
        icon: <AssignmentLateOutlinedIcon fontSize="small" />,
        color: "error",
        tooltip: tooltipUnexpectedFindings,
      });
    }

    if (appointment.isUnexpectedFindingsReceiptConfirmed) {
      appointmentCardFlagActions.push({
        id: "is-unexpected-findings-receipt-confirmed",
        type: "icon",
        icon: <AssignmentTurnedInOutlinedIcon fontSize="small" />,
        color: "success",
        tooltip: tooltipReceiptConfirmed,
      });
    }

    if (appointment.isAdditionalViews) {
      appointmentCardFlagActions.push({
        id: "is-additional-views",
        type: "icon",
        icon: <QueueOutlinedIcon fontSize="small" />,
        color: "primary",
        tooltip: additionalViews,
      });
    }

    if (appointment.isStat) {
      appointmentCardFlagActions.push({
        id: "is-stat",
        type: "icon",
        icon: <TimerOutlinedIcon fontSize="small" />,
        color: "primary",
        tooltip: tooltipStat,
      });
    }

    if (appointment.hasPriority) {
      appointmentCardFlagActions.push({
        id: "has-priority",
        type: "icon",
        icon: <ArrowCircleUpIcon fontSize="small" />,
        color: "primary",
        tooltip: tooltipPriority,
      });
    }

    const appointmentCardActions = [
      ...appointmentCardFlagActions,
      ...appointmentCardDefaultActions,
    ];

    return getDefaultActions(appointmentCardActions);
  }, [
    appointment.status,
    appointment.hasUnexpectedFindings,
    appointment.isUnexpectedFindingsReceiptConfirmed,
    appointment.isAdditionalViews,
    appointment.isStat,
    appointment.hasPriority,
    translations,
    tooltipUnexpectedFindings,
    tooltipReceiptConfirmed,
    additionalViews,
    tooltipStat,
    tooltipPriority,
  ]);

  return actions;
};

export default useActions;
