import { useCallback, useState } from "react";

const useCalendarView = (defaultCalendarMonth: Date) => {
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [overriddenTime, setOverriddenTime] = useState<string | null>(null);
  const [selectedMonth, setSelectedMonth] = useState(defaultCalendarMonth);

  const onDateChange = useCallback((date: Date | null) => {
    setSelectedDate(date);
    setSelectedTime(null);
  }, []);

  const onMonthChange = useCallback(
    (date: Date) => {
      setSelectedMonth(date);
      onDateChange(null);
    },
    [onDateChange],
  );

  const resetForm = useCallback(() => {
    onMonthChange(defaultCalendarMonth);
  }, [defaultCalendarMonth, onMonthChange]);

  return {
    selectedTime,
    onTimeChange: setSelectedTime,
    selectedDate,
    onDateChange,
    selectedMonth,
    overriddenTime,
    onTimeOverride: setOverriddenTime,
    onMonthChange,
    resetForm,
  };
};

export default useCalendarView;
