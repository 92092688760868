import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "../../Unknown/DataGrid";
import useTranslations from "./useTranslations";
import Box from "../../Unknown/Box";
import Button from "../../Unknown/Button";

const useGridColumns = (params: {
  currencyCode: string;
  onArchiveClick: (columnId: string) => void;
  onEditClick: (columnId: string) => void;
}) => {
  const { currencyCode, onArchiveClick, onEditClick } = params;
  const translations = useTranslations();

  const { formatNumber } = useIntl();

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: "name",
        headerName: translations.headingName,
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
      },
      {
        field: "type",
        headerName: translations.headingType,
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
        valueFormatter: (formatterParams: GridValueFormatterParams<string>) => {
          const { value } = formatterParams;

          const { typeStat } = translations;

          return value === "stat" ? typeStat : "";
        },
      },
      {
        field: "price",
        headerName: translations.headingPrice,
        flex: 1,
        sortable: true,
        disableColumnMenu: true,
        valueFormatter: (formatterParams: GridValueFormatterParams<number>) => {
          const { value } = formatterParams;

          if (!value && value !== 0) return "";

          const formattedPrice = formatNumber(value, {
            style: "currency",
            currency: currencyCode,
          }).replace(".00", "");

          return formattedPrice;
        },
      },
      {
        field: "btnArchive",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (renderParams: GridRenderCellParams) => {
          return (
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="text"
                size="small"
                color="error"
                onClick={() => onArchiveClick(renderParams.row.id)}
              >
                {translations.btnArchive}
              </Button>
            </Box>
          );
        },
      },
      {
        field: "btnEdit",
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        renderCell: (renderParams: GridRenderCellParams) => {
          return (
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="text"
                size="small"
                onClick={() => onEditClick(renderParams.row.id)}
              >
                {translations.btnEdit}
              </Button>
            </Box>
          );
        },
      },
    ];
  }, [currencyCode, formatNumber, onArchiveClick, onEditClick, translations]);

  return { columns };
};

export default useGridColumns;
