import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { initialAdminDateRange } from "./constants";
import { useAppContext } from "../AppContext";
import {
  UTCDateTimeToTimezone,
  getNowUTC,
  getTimezoneOffsetMillis,
} from "../../../common/dateHelpers";

const getRoleInitialDates = (role?: string) => {
  switch (role) {
    case "regional_admin":
    case "super_admin": {
      return {
        start: startOfDay(subDays(new Date(), initialAdminDateRange)),
        end: endOfDay(new Date()),
      };
    }

    default: {
      return {
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
      };
    }
  }
};

const useDate = () => {
  const { role } = useParams();

  const [startDate, setStartDate] = useState(getRoleInitialDates(role).start);
  const [endDate, setEndDate] = useState(getRoleInitialDates(role).end);

  const { centers } = useAppContext();

  const utcNow = getNowUTC();

  const zonedDate =
    centers.length === 1
      ? startOfDay(
          UTCDateTimeToTimezone(
            utcNow,
            getTimezoneOffsetMillis(centers[0].timezone),
          ),
        )
      : startDate;

  return { startDate, setStartDate, endDate, setEndDate, zonedDate };
};

export default useDate;
