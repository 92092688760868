import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useLabelTranslations = () => {
  const { formatMessage } = useIntl();

  const labelTranslations = useMemo(() => {
    return {
      labelName: formatMessage(messages.labelName),
      labelModalityName: formatMessage(messages.labelModalityName),
      labelOfferings: formatMessage(messages.labelOfferings),
      labelPrice: formatMessage(messages.labelPrice),
      labelDepositAmount: formatMessage(messages.labelDepositAmount),
      labelVisibility: formatMessage(messages.labelVisibility),
      labelDescription: formatMessage(messages.labelDescription),
    };
  }, [formatMessage]);

  return labelTranslations;
};

export default useLabelTranslations;
