import React, { FC } from "react";
import { formatInternational } from "../../../common/phoneNumberHelpers";
import Card from "../../Unknown/Card";
import CardContent from "../../Unknown/CardContent";
import CardHeader from "../../Unknown/CardHeader";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";
import { getFormattedHeight } from "../../../common/getFormattedHeight";

export type MedicalInformationCardProps = {
  examReason?: string;
  surgicalHistory?: string;
  cancerHistory?: string;
  heightFt?: number;
  heightIn?: number;
  weightLbs?: number;
  referringPhysicianName?: string;
  referringPhysicianPostalCode?: string;
  referringPhysicianEmail?: string;
  referringPhysicianPhoneNumber?: string;
};

const MedicalInformationCard: FC<MedicalInformationCardProps> = ({
  examReason,
  surgicalHistory,
  cancerHistory,
  heightFt,
  heightIn,
  weightLbs,
  referringPhysicianName,
  referringPhysicianPostalCode,
  referringPhysicianEmail,
  referringPhysicianPhoneNumber,
}) => {
  const { labelRoot } = useStyles();

  const {
    title,
    labelHeightAndWeight,
    labelExamReason,
    labelSurgicalHistory,
    labelCancerHistory,
    labelReferringPhysician,
    labelReferringPhysicianName,
    labelReferringPhysicianPostalCode,
    labelReferringPhysicianEmail,
    labelReferringPhysicianPhoneNumber,
  } = useTranslations();

  const formattedPhoneNumber = formatInternational({
    phoneNumber: referringPhysicianPhoneNumber,
  });

  const isReferringPhysicianShown =
    referringPhysicianName ||
    referringPhysicianPostalCode ||
    referringPhysicianEmail ||
    referringPhysicianPhoneNumber;

  const referringPhysicianTexts = [
    { key: labelReferringPhysicianName, value: referringPhysicianName },
    {
      key: labelReferringPhysicianPostalCode,
      value: referringPhysicianPostalCode,
    },
    { key: labelReferringPhysicianEmail, value: referringPhysicianEmail },
    {
      key: labelReferringPhysicianPhoneNumber,
      value: formattedPhoneNumber,
    },
  ].map(({ key, value }) => `${key}: ${value || ""}`);

  const formattedWeight =
    typeof weightLbs === "number" ? `${weightLbs} lbs` : null;
  const formattedHeight =
    heightFt || heightIn ? getFormattedHeight(heightFt, heightIn) : null;

  const patientAntro = [formattedHeight, formattedWeight]
    .filter(Boolean)
    .join(" — ");

  const textList = [
    { label: labelHeightAndWeight, text: patientAntro },
    { label: labelExamReason, text: examReason },
    { label: labelSurgicalHistory, text: surgicalHistory },
    { label: labelCancerHistory, text: cancerHistory },
  ];

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent sx={{ pt: 0 }}>
        <Grid container spacing={4} direction="column" wrap="nowrap">
          {textList.map(({ label, text }) => (
            <Grid item xs key={label}>
              <Typography className={labelRoot}>{label}</Typography>
              <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                {text || ""}
              </Typography>
            </Grid>
          ))}

          <Grid item xs>
            <Typography className={labelRoot}>
              {labelReferringPhysician}
            </Typography>
            {isReferringPhysicianShown &&
              referringPhysicianTexts.map((text) => (
                <Typography
                  key={text}
                  variant="body2"
                  sx={{ wordBreak: "break-word" }}
                >
                  {text}
                </Typography>
              ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MedicalInformationCard;
