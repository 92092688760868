import React, { FC } from "react";
import Button from "../../Unknown/Button";
import CircularProgress from "../../Unknown/CircularProgress";
import Dialog from "../../Unknown/Dialog";
import Typography from "../../Unknown/Typography";
import DialogActions from "../../Unknown/DialogActions";
import DialogContent from "../../Unknown/DialogContent";
import DialogTitle from "../../Unknown/DialogTitle";
import useTranslations from "./useTranslations";

export type TimeSlotDeletionConfirmationDialogProps = {
  name: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void | Promise<void>;
  isLoading?: boolean;
};

const TimeSlotDeletionConfirmationDialog: FC<
  TimeSlotDeletionConfirmationDialogProps
> = ({ name, isOpen, onClose, onSubmit, isLoading }) => {
  const translations = useTranslations({ name });

  return (
    <Dialog open={isOpen} onClose={onClose} disableRestoreFocus maxWidth="sm">
      <DialogTitle>{translations.title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{translations.description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading} size="small">
          {translations.btnCancel}
        </Button>
        <Button
          onClick={onSubmit}
          disabled={isLoading}
          color="error"
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
          size="small"
        >
          {translations.btnDelete}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeSlotDeletionConfirmationDialog;
