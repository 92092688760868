import React, { FC } from "react";
import { useFormikContext } from "formik";
import FormikTextField from "../../Unknown/FormikTextField";
import { FormValues, Offering, Translations } from "./types";
import { inputsSpacing, names } from "./constants";
import Grid from "../../Unknown/Grid";
// import MenuItem from "../../Unknown/MenuItem";

export type AppointmentNoteAddFormProps = {
  translations: Translations;
  offerings: Offering[];
};

const AppointmentNoteAddForm: FC<AppointmentNoteAddFormProps> = ({
  translations,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  offerings,
}) => {
  const { isSubmitting } = useFormikContext<FormValues>();

  return (
    <Grid container spacing={inputsSpacing}>
      <Grid item xs>
        <FormikTextField
          name={names.description}
          label={translations.labelDescription}
          fullWidth
          multiline
          disabled={isSubmitting}
          required
        />
      </Grid>
      {/* TODO: Uncomment next lines if the notes need to be linked to studies */}
      {/* discussion: https://voypost.slack.com/archives/C03A4TA9A5R/p1680851177950369?thread_ts=1680699380.367469&cid=C03A4TA9A5R */}
      {/* <Grid item xs>
        <FormikTextField
          name={names.offeringId}
          label={translations.labelOffering}
          select
          fullWidth
          disabled={isSubmitting}
        >
          {offerings.map((offering) => (
            <MenuItem key={offering.id} value={offering.id}>
              {offering.modalityName}
            </MenuItem>
          ))}
        </FormikTextField>
      </Grid> */}
    </Grid>
  );
};

export default AppointmentNoteAddForm;
