import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      fieldsEdited: formatMessage(messages.fieldsEdited),
    }),
    [formatMessage],
  );
};
export default useTranslations;
