import { FormikConfig } from "formik";
import { useCallback, useMemo } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterDiscountFormValues } from "../CenterDiscountForm";
import { Discount } from "../CenterSettingsContainer/types";
import getCenterDiscountFormSchema from "./getCenterDiscountFormSchema";
import useFormTranslations from "./useFormTranslations";

export type CenterDiscountData = {
  primaryDiscountLine: string;
  secondaryDiscountLine?: string;
  city: string;
  postalCode: string;
  stateIsoCode: string;
  stateName: string;
  countryIsoCode: string;
  countryName: string;
};

export type CenterDiscountFormHookParams = {
  centerId: string;
  centerDiscountData: Discount;
  handlePostSubmit?: (values: CenterDiscountFormValues) => Promise<void> | void;
};

type CenterDiscountFormikConfig = FormikConfig<CenterDiscountFormValues>;

type CenterDiscountFormHookResult = {
  formikConfig: CenterDiscountFormikConfig;
};

const useCenterDiscountForm = (
  params: CenterDiscountFormHookParams,
): CenterDiscountFormHookResult => {
  const { centerId, centerDiscountData, handlePostSubmit } = params;

  const { setAlert } = useUIContext();

  const updateCenterDiscount = useFirebaseAppFunction("updateCenterDiscount");

  const { amount, maxDiscount } = centerDiscountData;

  const { translations, validationTranslations } = useFormTranslations({
    maxDiscount,
  });

  const initialValues = useMemo<
    CenterDiscountFormikConfig["initialValues"]
  >(() => {
    return { amount };
  }, [amount]);

  const validationSchema = useMemo(() => {
    return getCenterDiscountFormSchema({
      translations: validationTranslations,
      maxDiscount,
    });
  }, [maxDiscount, validationTranslations]);

  const onSubmit = useCallback<CenterDiscountFormikConfig["onSubmit"]>(
    async (values) => {
      try {
        await updateCenterDiscount({ centerId, ...values });
        if (handlePostSubmit) {
          await handlePostSubmit(values);
        }
      } catch (e) {
        setAlert({
          message: translations.defaultError,
          isShown: false,
          severity: "error",
        });
      }
    },
    [
      centerId,
      handlePostSubmit,
      setAlert,
      translations.defaultError,
      updateCenterDiscount,
    ],
  );

  return {
    formikConfig: {
      initialValues,
      validationSchema,
      onSubmit,
    },
  };
};

export default useCenterDiscountForm;
