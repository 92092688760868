import { useMemo } from "react";
import { GridColDef } from "../../Unknown/DataGrid";
import { useAppContext } from "../../Unknown/AppContext";
import getColumnBtnView from "./columns/getColumnBtnView";
import getColumnCenter from "./columns/getColumnCenter";
import getColumnCenterServices from "./columns/getColumnCenterServices";
import getColumnDateTime from "./columns/getColumnDateTime";
import { Appointment } from "./types";
import useStyles from "./useStyles";
import useTranslations from "./useTranslations";

const useColumns = (params: { onViewClick: (id: string) => void }) => {
  const { onViewClick } = params;
  const translations = useTranslations();
  const { timeRoot, hiddenSeparators } = useStyles();

  const { databaseUserData } = useAppContext();
  const { centerIds } = databaseUserData;

  const localeFormat = centerIds.length === 1 ? "center" : "client";

  return useMemo<GridColDef<Appointment>[]>(() => {
    const columnDateTime = getColumnDateTime({
      translations,
      classNames: { timeRoot },
      localeFormat,
    });

    const columnCenter = getColumnCenter({ translations });

    const columnCenterServices = getColumnCenterServices({ translations });

    const columnBtnView = getColumnBtnView({
      translations,
      onClick: onViewClick,
      headerClassName: hiddenSeparators,
    });

    return [columnDateTime, columnCenter, columnCenterServices, columnBtnView];
  }, [hiddenSeparators, localeFormat, onViewClick, timeRoot, translations]);
};

export default useColumns;
