import React, { FC } from "react";
import Card from "../../Unknown/Card";
import CardContent from "../../Unknown/CardContent";
import CardHeader from "../../Unknown/CardHeader";
import AppointmentReportGrid from "../AppointmentReportGrid";
import { AppointmentReport } from "../AppointmentReportGrid/types";
import AppointmentReportResendDialog from "../AppointmentReportResendDialog";
import useAppointmentReportResendDialog from "../AppointmentReportResendDialog/useAppointmentReportResendDialog";
import AppointmentReportUnexpectedFindingsDialog from "../AppointmentReportUnexpectedFindingsDialog";
import useAppointmentReportUnexpectedFindingsDialog from "../AppointmentReportUnexpectedFindingsDialog/useAppointmentReportUnexpectedFindingsDialog";
import useAppointmentReportConfirmReceiptDialog from "../AppointmentReportConfirmReceiptDialog/useAppointmentReportConfirmReceiptDialog";
import AppointmentReportConfirmReceiptDialog from "../AppointmentReportConfirmReceiptDialog";
import useTranslations from "./useTranslations";

export type AppointmentReportGridContainerProps = {
  appointmentId: string;
  patientFirstName: string;
  patientLastName: string;
  patientEmail: string;
  reports: AppointmentReport[];
  refetch: () => Promise<void>;
};

const AppointmentReportGridContainer: FC<
  AppointmentReportGridContainerProps
> = ({
  appointmentId,
  patientEmail,
  patientFirstName,
  patientLastName,
  reports,
  refetch,
}) => {
  const { translations } = useTranslations();
  const { title } = translations;

  const {
    isOpened: isResendDialogOpened,
    onOpenClick: onResendClick,
    props: resendDialogProps,
  } = useAppointmentReportResendDialog({
    reports,
    appointmentId,
    patientEmail,
    patientFirstName,
    patientLastName,
    refetch,
  });

  const {
    isOpened: isUnexpectedFindingsDialogOpened,
    onOpenClick: onUnexpectedFindingsClick,
    props: unexpectedFindingsDialogProps,
  } = useAppointmentReportUnexpectedFindingsDialog({
    reports,
    appointmentId,
    refetch,
  });

  const {
    isOpened: isConfitmReceiptDialogOpened,
    onOpenClick: onConfirmReceiptClick,
    props: confirmReceiptDialogProps,
  } = useAppointmentReportConfirmReceiptDialog({
    reports,
    appointmentId,
    refetch,
  });

  return (
    <>
      <Card>
        <CardHeader title={title} />
        <CardContent sx={{ pt: 0 }}>
          <AppointmentReportGrid
            reports={reports}
            onResendClick={onResendClick}
            onConfirmReceiptClick={onConfirmReceiptClick}
            onUnexpectedFindingsClick={onUnexpectedFindingsClick}
          />
        </CardContent>
      </Card>
      {resendDialogProps && isResendDialogOpened && (
        <AppointmentReportResendDialog {...resendDialogProps} />
      )}
      {unexpectedFindingsDialogProps && isUnexpectedFindingsDialogOpened && (
        <AppointmentReportUnexpectedFindingsDialog
          {...unexpectedFindingsDialogProps}
        />
      )}
      {confirmReceiptDialogProps && isConfitmReceiptDialogOpened && (
        <AppointmentReportConfirmReceiptDialog {...confirmReceiptDialogProps} />
      )}
    </>
  );
};

export default AppointmentReportGridContainer;
