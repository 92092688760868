import { Theme, lighten } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    toolbarRoot: {
      backgroundColor: lighten(palette.primary.main, 0.92),
    },
  };
});

export default useStyles;
