import React, { FC, useState } from "react";
import { useFormikContext } from "formik";
import FormikTextField from "../../Unknown/FormikTextField";
import Grid from "../../Unknown/Grid";
import MenuItem from "../../Unknown/MenuItem";
import useTranslations from "./useTranslations";
import {
  CenterPackageFormValues,
  LabelTranslationsKey,
  Offering,
} from "./types";
import { inputSpacing, visibilityTypes } from "./constants";
import InputAdornment from "../../Unknown/InputAdornment";
import Typography from "../../Unknown/Typography";
import CenterPackageOfferingSelectField from "../CenterPackageOfferingSelectField";
import useStyles from "./useStyles";
import { OfferingOption } from "../CenterPackageOfferingSelectField/types";
import getOfferingsPrice from "./getOfferingsPrice";

type CenterPackageFormProps = {
  currencyCode: string;
  labelTranslations: Record<LabelTranslationsKey, string>;
  offerings: Offering[];
  isPriceChangeable?: boolean;
};

const CenterPackageForm: FC<CenterPackageFormProps> = ({
  currencyCode,
  labelTranslations,
  offerings,
  isPriceChangeable,
}) => {
  const { priceHelperRoot } = useStyles();

  const { isSubmitting, values, touched, setFieldValue } =
    useFormikContext<CenterPackageFormValues>();

  const [offeringsPrice, setOfferingsPrice] = useState(
    getOfferingsPrice({ offerings, selectedOptions: values.offerings }),
  );

  const translations = useTranslations({ currencyCode, offeringsPrice });

  const onOfferingsChange = (newValue: OfferingOption[]) => {
    const newOfferingsPrice = getOfferingsPrice({
      offerings,
      selectedOptions: newValue,
    });

    setOfferingsPrice(newOfferingsPrice);

    const shouldChangePrice = isPriceChangeable && !touched.price;

    if (shouldChangePrice) {
      setFieldValue("price", newOfferingsPrice);
    }
  };

  return (
    <Grid container spacing={inputSpacing} direction="row">
      <Grid item container spacing={inputSpacing}>
        <Grid item xs={4}>
          <FormikTextField
            fullWidth
            required
            disabled={isSubmitting}
            name="name"
            label={labelTranslations.labelName}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField
            fullWidth
            required
            disabled={isSubmitting}
            name="modalityName"
            label={labelTranslations.labelModalityName}
          />
        </Grid>
        <Grid item xs={4}>
          <CenterPackageOfferingSelectField
            offerings={offerings.map(({ id, name }) => ({ id, name }))}
            onChange={onOfferingsChange}
            textFieldProps={{
              label: labelTranslations.labelOfferings,
              name: "offerings",
              required: true,
              disabled: isSubmitting,
              fullWidth: true,
            }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={inputSpacing}>
        <Grid item xs={4}>
          <Typography variant="body1">{translations.textPrice}</Typography>
          <Typography className={priceHelperRoot}>
            {translations.helperPrice}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormikTextField
            fullWidth
            required
            disabled={isSubmitting}
            type="number"
            name="price"
            label={labelTranslations.labelPrice}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{translations.currencySymbol}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField
            fullWidth
            required
            disabled={isSubmitting}
            type="number"
            name="depositAmount"
            label={labelTranslations.labelDepositAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography>{translations.currencySymbol}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={inputSpacing}>
        <Grid item xs={4}>
          <FormikTextField
            fullWidth
            required
            disabled={isSubmitting}
            name="visibility"
            label={labelTranslations.labelVisibility}
            select
          >
            {visibilityTypes.map((visibilityType) => (
              <MenuItem key={visibilityType.id} value={visibilityType.id}>
                {translations[visibilityType.translationKey]}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item xs={8}>
          <FormikTextField
            fullWidth
            required
            disabled={isSubmitting}
            name="description"
            label={labelTranslations.labelDescription}
            multiline
            maxRows={4}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CenterPackageForm;
