import { useMemo } from "react";
import { InformationGridItem, Patient } from "./types";
import useTranslations from "./useTranslations";

const usePatientInformationGridItems = (patient: Patient) => {
  const { translations } = useTranslations();

  const informationGridItems = useMemo(() => {
    const {
      labelName,
      labelMrn,
      labelPhone,
      labelEmail,
      labelBirthDate,
      labelLegalGuardian,
    } = translations;

    const {
      firstName,
      lastName,
      mrn,
      phoneNumber,
      email,
      birthDate,
      legalGuardian,
      onPatientClick,
    } = patient;

    const result: InformationGridItem[] = [
      {
        id: "name",
        type: "click",
        label: labelName,
        text: `${lastName}, ${firstName}`,
        onClick: onPatientClick,
      },
      { id: "mrn", type: "text", label: labelMrn, text: mrn },
    ];

    if (phoneNumber) {
      result.push({
        id: "phoneNumber",
        type: "link",
        label: labelPhone,
        text: phoneNumber.styled,
        href: `tel:${phoneNumber.url}`,
      });
    }

    if (email) {
      result.push({
        id: "email",
        type: "link",
        label: labelEmail,
        text: email,
        href: `mailto:${email}`,
      });
    }

    if (birthDate) {
      result.push({
        id: "birthDate",
        type: "text",
        label: labelBirthDate,
        text: birthDate,
      });
    }

    if (legalGuardian) {
      result.push({
        id: "legalGuardian",
        type: "click",
        label: labelLegalGuardian,
        text: `${legalGuardian.lastName}, ${legalGuardian.firstName}`,
        onClick: legalGuardian.onClick,
      });
    }

    return result;
  }, [patient, translations]);

  return informationGridItems;
};

export default usePatientInformationGridItems;
