import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "MedicalInformation.MedicalInformationCard.title",
    defaultMessage: "Medical Information",
  },
  labelExamReason: {
    id: "MedicalInformation.MedicalInformationCard.labelExamReason",
    defaultMessage: "Reason for exam",
  },
  labelSurgicalHistory: {
    id: "MedicalInformation.MedicalInformationCard.labelSurgicalHistory",
    defaultMessage: "Surgical history",
  },
  labelCancerHistory: {
    id: "MedicalInformation.MedicalInformationCard.labelCancerHistory",
    defaultMessage: "Cancer history",
  },
  labelReferringPhysician: {
    id: "MedicalInformation.MedicalInformationCard.labelReferringPhysician",
    defaultMessage: "Referring physician",
  },
  labelReferringPhysicianName: {
    id: "MedicalInformation.MedicalInformationCard.labelReferringPhysicianName",
    defaultMessage: "Name",
  },
  labelReferringPhysicianPostalCode: {
    id: "MedicalInformation.MedicalInformationCard.labelReferringPhysicianPostalCode",
    defaultMessage: "ZIP",
  },
  labelReferringPhysicianEmail: {
    id: "MedicalInformation.MedicalInformationCard.labelReferringPhysicianEmail",
    defaultMessage: "Email address",
  },
  labelReferringPhysicianPhoneNumber: {
    id: "MedicalInformation.MedicalInformationCard.labelReferringPhysicianPhoneNumber",
    defaultMessage: "Phone number",
  },
  labelHeightAndWeight: {
    id: "MedicalInformation.MedicalInformationCard.labelHeightAndWeight",
    defaultMessage: "Height and Weight",
  },
});
