import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      headerName: formatMessage(messages.headerName),
      headerUploadedAt: formatMessage(messages.headerUploadedAt),
      btnDownload: formatMessage(messages.btnDownload),
    };

    return { translations };
  }, [formatMessage]);

  return result;
};
export default useTranslations;
