import React, { useMemo } from "react";
import { SupervisorAccountOutlinedIcon } from "../../Unknown/Icons";
import { PatientCardProps } from "../PatientCard";
import getPatientCardDefaultActions from "../PatientCard/getPatientCardDefaultActions";
import { PatientCardDefaultAction } from "../PatientCard/types";
import { FormsStatus } from "./types";
import useTranslations from "./useTranslations";

const useActions = (params: {
  formsStatus?: FormsStatus;
  isGuardianRequired?: boolean;
}): PatientCardProps["actions"] => {
  const { formsStatus, isGuardianRequired } = params;

  const { translations } = useTranslations();

  const actions = useMemo(() => {
    const {
      tooltipRequiresGuardian,
      formsStatusValid,
      formsStatusNotPopulated,
      formsStatusExpired,
    } = translations;

    const patientCardDefaultActions: PatientCardDefaultAction[] = [];

    if (isGuardianRequired) {
      patientCardDefaultActions.push({
        id: "guardian",
        type: "icon",
        icon: <SupervisorAccountOutlinedIcon fontSize="small" />,
        color: "primary",
        tooltip: tooltipRequiresGuardian,
      });
    }

    if (formsStatus) {
      switch (formsStatus) {
        case "valid": {
          patientCardDefaultActions.push({
            id: "forms",
            type: "chip",
            chipProps: { color: "success", label: formsStatusValid },
          });
          break;
        }
        case "not_populated": {
          patientCardDefaultActions.push({
            id: "forms",
            type: "chip",
            chipProps: { color: "error", label: formsStatusNotPopulated },
          });
          break;
        }
        case "expired": {
          patientCardDefaultActions.push({
            id: "forms",
            type: "chip",
            chipProps: { color: "error", label: formsStatusExpired },
          });
          break;
        }
        default: {
          break;
        }
      }
    }

    return getPatientCardDefaultActions(patientCardDefaultActions);
  }, [formsStatus, isGuardianRequired, translations]);

  return actions;
};

export default useActions;
