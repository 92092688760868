import AddOutlined from "@mui/icons-material/AddOutlined";
import React, { FC } from "react";
import { OfferingsData } from "../../Center/CenterSettingsContainer/types";
import Box from "../../Unknown/Box";
import Button from "../../Unknown/Button";
import CenterOfferingArchiveDialog from "../CenterOfferingArchiveDialog";
import CenterOfferingFormik from "../CenterOfferingFormik";
import CenterOfferingsView from "../CenterOfferingsView";
import useAddForm from "./useAddForm";
import useArchiveModal from "./useArchiveModal";
import useEditForm from "./useEditForm";
import useOpenedModal from "./useOpenedModal";
import useTranslations from "./useTranslations";

export type CenterOfferingsSettingsTabProps = {
  centerId: string;
  offeringsData: OfferingsData;
  refetchCenterData?: () => void | Promise<void>;
};

const CenterOfferingsSettingsTab: FC<CenterOfferingsSettingsTabProps> = ({
  centerId,
  offeringsData,
  refetchCenterData,
}) => {
  const { btnAddOffering } = useTranslations();

  const {
    id: offeringId,
    openedModal,
    onAddClick,
    onEditClick,
    onArchiveClick,
    onModalClose,
  } = useOpenedModal();

  const { offerings, currencyCode, minPrice } = offeringsData;

  const { editFormikProps } = useEditForm({
    isOpened: openedModal === "edit",
    editedOfferingId: offeringId,
    onClose: onModalClose,
    offeringsData,
    refetchCenterData,
  });

  const { addFormikProps } = useAddForm({
    isOpened: openedModal === "add",
    onClose: onModalClose,
    centerId,
    currencyCode,
    minPrice,
    refetchCenterData,
  });

  const { archiveDialogProps } = useArchiveModal({
    isOpened: openedModal === "archive",
    offeringId,
    onClose: onModalClose,
    offerings,
    refetchCenterData,
  });

  return (
    <Box pt={7}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          startIcon={<AddOutlined />}
          onClick={onAddClick}
        >
          {btnAddOffering}
        </Button>
      </Box>

      <Box display="flex" height="100%">
        <Box flexGrow={1} pb={3} overflow="auto">
          <CenterOfferingsView
            offerings={offerings}
            currencyCode={currencyCode}
            onArchiveClick={onArchiveClick}
            onEditClick={onEditClick}
          />
        </Box>
      </Box>

      {editFormikProps && <CenterOfferingFormik {...editFormikProps} />}
      <CenterOfferingFormik {...addFormikProps} />
      <CenterOfferingArchiveDialog {...archiveDialogProps} />
    </Box>
  );
};

export default CenterOfferingsSettingsTab;
