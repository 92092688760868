import parseISO from "date-fns/parseISO";
import { Patient, PatientInformationDrawerData } from "./types";

type Result = PatientInformationDrawerData["patientFormsPreviewGridProps"];

type Form = Result["forms"][number];

const getPatientFormsPreviewGridProps = (patient: Patient): Result => {
  const forms = patient.patient_forms.map<Form>((patientForm) => {
    const {
      id,
      type,
      file_name,
      patient_signed_at,
      expiration_at,
      patient_id,
    } = patientForm;

    return {
      id,
      formType: type
        .trim()
        .split("")
        .map((char, idx) => (idx === 0 ? char.toUpperCase() : char))
        .join(""),
      completedAt: parseISO(patient_signed_at).toLocaleDateString(),
      expiresAt: parseISO(expiration_at).toLocaleDateString(),
      fileName: file_name,
      patientId: patient_id,
    };
  });

  return { forms };
};

export default getPatientFormsPreviewGridProps;
