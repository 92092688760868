import React, { FC } from "react";
import { Formik } from "formik";
import StatusDialog from "../../Unknown/StatusDialog";
import useTranslations from "./useTranslations";
import useFormikComponent from "./useFormikComponent";
import { Appointment } from "../../Appointment/AppointmentCardsContainer/types";
import AppointmentScreeningForm from "../AppointmentScreeningForm";

export type AppointmentScreeningFormContainerDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  onSuccess: () => void;
  appointment: Appointment | null;
  isLoading: boolean;
};

const AppointmentScreeningFormContainerDialog: FC<
  AppointmentScreeningFormContainerDialogProps
> = ({ isOpen, handleClose, onSuccess, appointment, isLoading }) => {
  const { translations } = useTranslations();
  const formikProps = useFormikComponent({
    appointment,
    onSuccess,
    handleClose,
  });

  if (!isLoading && !appointment) {
    handleClose();
    return null;
  }

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          maxWidth: 800,
        },
      }}
    >
      <Formik {...formikProps}>
        <AppointmentScreeningForm
          appointment={appointment || ({} as Appointment)}
          handleClose={handleClose}
        />
      </Formik>
    </StatusDialog>
  );
};

export default AppointmentScreeningFormContainerDialog;
