import { defineMessages } from "react-intl";

const messages = defineMessages({
  system: {
    id: "AuditLog.AuditLogList.system",
    defaultMessage: "System",
  },
  appointmentMarkedAsNoShow: {
    id: "AuditLog.AuditLogList.appointmentMarkedAsNoShow",
    defaultMessage: "Marked appointment as “no show”",
  },
  appointmentCanceled: {
    id: "AuditLog.AuditLogList.appointmentCanceled",
    defaultMessage: "Canceled appointment",
  },
  appointmentRescheduled: {
    id: "AuditLog.AuditLogList.appointmentRescheduled",
    defaultMessage: "Rescheduled appointment",
  },
  loggedInPatient: {
    id: "AuditLog.AuditLogList.loggedInPatient",
    defaultMessage: "Logged patient in manually",
  },
  checkedInPatient: {
    id: "AuditLog.AuditLogList.checkedInPatient",
    defaultMessage: "Checked patient in",
  },
  appointmentMarkedAsPriority: {
    id: "AuditLog.AuditLogList.appointmentMarkedAsPriority",
    defaultMessage: "Marked appointment as “priority”",
  },
  confirmedIncompleteAppointment: {
    id: "AuditLog.AuditLogList.confirmedIncompleteAppointment",
    defaultMessage: "Confirmed incomplete appointment",
  },
  addedFilesToAppointment: {
    id: "AuditLog.AuditLogList.addedFilesToAppointment",
    defaultMessage: "Added file to appointment",
  },
  addedPayment: {
    id: "AuditLog.AuditLogList.addedPayment",
    defaultMessage: "Added a payment",
  },
  reportsSentToPatient: {
    id: "AuditLog.AuditLogList.reportsSentToPatient",
    defaultMessage: "Report sent to patient",
  },
  reportMarkedAsUnexpectedFindings: {
    id: "AuditLog.AuditLogList.reportMarkedAsUnexpectedFindings",
    defaultMessage: "Marked report as “unexpected findings”",
  },
  reportMarkedAsUnexpectedFindingsConfirmed: {
    id: "AuditLog.AuditLogList.reportMarkedAsUnexpectedFindingsConfirmed",
    defaultMessage: "Marked report as “unexpected findings receipt confirmed”",
  },
  offeringChanged: {
    id: "AuditLog.AuditLogList.offeringChanged",
    defaultMessage: "Changed appointment offering",
  },
  studyMarkedAsAdditionalViewRequired: {
    id: "AuditLog.AuditLogList.studyMarkedAsAdditionalViewRequired",
    defaultMessage: "Study marked as requiring additional views",
  },
});

export default messages;
