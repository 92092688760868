import { defineMessages } from "react-intl";

const messages = defineMessages({
  fieldsEdited: {
    id: "AuditLog.AuditLogItemFieldsEditedBlock.fieldsEdited",
    defaultMessage: "Fields edited:",
  },
});

export default messages;
