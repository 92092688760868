import React, { FC, useState } from "react";
import Alert from "../../Unknown/Alert";
import Skeleton from "../../Unknown/Skeleton";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import Stack from "../../Unknown/Stack";
import Box from "../../Unknown/Box";
import CustomerInformationCard, {
  CustomerInformationCardProps,
} from "../CustomerInformationCard";
import useTranslations from "./useTranslations";
import Paper from "../../Unknown/Paper";
import CustomerEditFormContainer, {
  CustomerEditFormContainerProps,
} from "../CustomerEditFormContainer";
import { AuditLogListProps } from "../../AuditLog/AuditLogList/types";
import Button from "../../Unknown/Button";
import PatientAuditLogCard from "../../AuditLog/AuditLogCard";

export type CustomerMainCardsProps = {
  isEditMode: boolean;
  isLoading?: boolean;
  error?: string | null;
  data: {
    name: string;
    customerInformationCardProps: CustomerInformationCardProps;
    customerEditFormContainerProps: CustomerEditFormContainerProps;
    auditLogsData: AuditLogListProps | null;
  } | null;
};

const CustomerMainCards: FC<CustomerMainCardsProps> = ({
  isEditMode,
  isLoading,
  data,
  error,
}) => {
  const translations = useTranslations();
  const [isAuditLogsShown, setIsAuditLogShown] = useState(false);
  if (error) {
    return (
      <Box py={3} px={6}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (isLoading || !data) {
    return (
      <Stack spacing={4} py={3} px={6}>
        <Typography variant="h5">
          <Skeleton variant="rounded" />
        </Typography>
        <Skeleton height={250} variant="rounded" />
      </Stack>
    );
  }

  const {
    name,
    customerInformationCardProps,
    customerEditFormContainerProps,
    auditLogsData,
  } = data;

  return (
    <Grid
      container
      spacing={3}
      py={3}
      px={6}
      direction="column"
      flexWrap="nowrap"
    >
      <Grid
        item
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
        p={0}
      >
        <Typography variant="h4">{name}</Typography>
        {!!auditLogsData && (
          <Button onClick={() => setIsAuditLogShown((prevState) => !prevState)}>
            {isAuditLogsShown
              ? translations.hideAuditLogsBtn
              : translations.viewAuditLogsBtn}
          </Button>
        )}
      </Grid>
      {isAuditLogsShown && auditLogsData && (
        <PatientAuditLogCard
          title={translations.titleAuditLogs}
          auditLogsData={auditLogsData}
        />
      )}
      <Grid item xs>
        <Paper>
          <Box p={3}>
            <Typography variant="h5">
              {translations.titleInformation}
            </Typography>
            <Box mt={3}>
              {isEditMode ? (
                <CustomerEditFormContainer
                  {...customerEditFormContainerProps}
                />
              ) : (
                <CustomerInformationCard {...customerInformationCardProps} />
              )}
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CustomerMainCards;
