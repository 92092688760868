import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
      appointmentInPastError: formatMessage(messages.appointmentInPastError),
      statusNotSupportedError: formatMessage(messages.statusNotSupportedError),
      timeSlotNotAvailableError: formatMessage(
        messages.timeSlotNotAvailableError,
      ),
    };

    return {
      translations,
    };
  }, [formatMessage]);
  return result;
};
export default useTranslations;
