import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Appointment.AppointmentMarkNoShowDialog.title",
    defaultMessage: "Mark Appointment As “No Show”",
  },
  description: {
    id: "Appointment.AppointmentMarkNoShowDialog.description",
    defaultMessage:
      "Are you sure you want to mark this appointment as “no show”?",
  },
  warning: {
    id: "Appointment.AppointmentMarkNoShowDialog.warning",
    defaultMessage: "Doing so will cancel the appointment.",
  },
  btnCancel: {
    id: "Appointment.AppointmentMarkNoShowDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnSubmit: {
    id: "Appointment.AppointmentMarkNoShowDialog.btnSubmit",
    defaultMessage: "Mark as no show",
  },
});

export default messages;
