import { alpha, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    nameRoot: {
      fontSize: 14,
      color: palette.primary.main,
      textDecoration: "none",
    },
    detailsRoot: {
      fontSize: 13,
      color: alpha(palette.common.black, 0.6),
    },
  };
});

export default useStyles;
