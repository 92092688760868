import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  return {
    searchLabel: formatMessage(messages.searchLabel),
    adminLabel: formatMessage(messages.adminLabel),
    calendarLabel: formatMessage(messages.calendarLabel),
    dashboardLabel: formatMessage(messages.dashboardLabel),
    addAppointmentLabel: formatMessage(messages.addAppointmentLabel),
  };
};

export default useTranslations;
