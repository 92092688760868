import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useLabelTranslations = () => {
  const { formatMessage } = useIntl();

  const labelTranslations = useMemo(() => {
    return {
      labelOffering: formatMessage(messages.labelOffering),
      labelSide: formatMessage(messages.labelSide),
      labelModalityName: formatMessage(messages.labelModalityName),
      labelReason: formatMessage(messages.labelReason),
    };
  }, [formatMessage]);

  return labelTranslations;
};

export default useLabelTranslations;
