import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemBtnOptions, AppointmentRowItem } from "../types";
import Button from "../../../Unknown/Button";

export type ColumnBtnOptionsTranslations = {
  columnBtnOptions: string;
  optionsBtnCheckIn: string;
  optionsBtnLogIn: string;
};

const getColumnBtnOptions = (params: {
  columnItem: AppointmentColumnItemBtnOptions;
  translations: ColumnBtnOptionsTranslations;
  headerClassName?: string;
}): GridColDef<AppointmentRowItem> => {
  const { columnItem, translations, headerClassName } = params;
  const { optionsBtnLogIn, optionsBtnCheckIn } = translations;

  return {
    field: columnItem.field,
    headerName: "",
    disableColumnMenu: true,
    filterable: false,
    disableExport: true,
    sortable: false,
    align: "center",
    headerClassName,
    renderCell: (renderParams) => {
      const { status, handleLogInClick, handleCheckInClick } = renderParams.row;

      return (
        <>
          {status === "confirmed" && (
            <Button sx={{ fontSize: 13 }} onClick={handleLogInClick}>
              {optionsBtnLogIn}
            </Button>
          )}
          {status === "intake_complete" && (
            <Button sx={{ fontSize: 13 }} onClick={handleCheckInClick}>
              {optionsBtnCheckIn}
            </Button>
          )}
        </>
      );
    },
  };
};

export default getColumnBtnOptions;
