import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import { Appointment } from "../types";

type ColumnTranslations = {
  columnCenter: string;
};

type ColumnValue = {
  centerName: string;
};

const getColumnCenter = (params: {
  translations: ColumnTranslations;
}): GridColDef<Appointment, ColumnValue> => {
  const { translations } = params;

  return {
    field: "center",
    disableColumnMenu: true,
    flex: 1,
    headerName: translations.columnCenter,
    valueGetter: (getterParams) => {
      const { center } = getterParams.row;
      const { name } = center;
      return { centerName: name };
    },
    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      return (
        <Typography variant="body2">{renderParams.value.centerName}</Typography>
      );
    },
  };
};

export default getColumnCenter;
