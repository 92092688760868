import { FormikConfig } from "formik";
import { useCallback, useMemo } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { FormValues } from "../AppointmentNoteAddForm/types";
import useTranslations from "./useTranslations";
import useValidationSchema from "./useValidationSchema";

type FormikProps = FormikConfig<FormValues>;

type InitialValues = FormikConfig<FormValues>["initialValues"];
type SubmitHandler = FormikConfig<FormValues>["onSubmit"];

const useFormikProps = (params: {
  setErrorMessage: (value: string | null) => void;
  handleAddNote: (values: FormValues & { id: string }) => void | Promise<void>;
  handleClose: () => void;
  appointmentId: string | null;
}): FormikProps => {
  const { setErrorMessage, handleAddNote, handleClose, appointmentId } = params;

  const { translations } = useTranslations();
  const { defaultError } = translations;

  const createAppointmentNote = useFirebaseAppFunction("createAppointmentNote");

  const initialValues = useMemo<InitialValues>(() => {
    return {
      description: "",
      offeringId: "",
    };
  }, []);

  const validationSchema = useValidationSchema();

  const onSubmit = useCallback<SubmitHandler>(
    async (values, { resetForm }) => {
      try {
        if (!appointmentId) {
          throw new Error(defaultError);
        }

        const { offeringId, description } = values;

        const { data } = await createAppointmentNote({
          appointmentId,
          description,
          appointmentOfferingId: offeringId || undefined,
        });

        await handleAddNote({ ...values, id: data.appointmentNoteId });

        handleClose();
        resetForm();
      } catch (error) {
        setErrorMessage((error as Error).message);
      }
    },
    [
      appointmentId,
      createAppointmentNote,
      defaultError,
      handleAddNote,
      handleClose,
      setErrorMessage,
    ],
  );

  return {
    initialValues,
    validationSchema,
    onSubmit,
  };
};

export default useFormikProps;
