import { InformationGridItemData } from "../../Unknown/InformationGridItem/types";

const getInformationGridItems = (params: {
  mrn: string;
  birthDate?: string;
  phoneNumber?: {
    url: string;
    styled: string;
  };
  email?: string;
  address?: string;
  race?: string;
  sex?: string;
  legalGuardian?: {
    firstName: string;
    lastName: string;
    onClick: () => void;
  };
  translations: {
    labelId: string;
    labelBirthDate: string;
    labelPhoneNumber: string;
    labelEmail: string;
    labelAddress: string;
    labelLegalGuardian: string;
    labelRace: string;
    labelSex: string;
  };
  raceTranslations: Record<string, string>;
  sexTranslations: Record<string, string>;
}) => {
  const {
    mrn,
    birthDate,
    phoneNumber,
    email,
    address,
    legalGuardian,
    translations,
    race,
    sex,
    sexTranslations,
    raceTranslations,
  } = params;

  const result: ({ id: string } & InformationGridItemData)[] = [
    { id: "mrn", type: "text", label: translations.labelId, text: mrn },
  ];

  if (birthDate) {
    result.push({
      id: "birthDate",
      type: "text",
      label: translations.labelBirthDate,
      text: birthDate,
    });
  }

  if (sex) {
    result.push({
      id: "sex",
      type: "text",
      label: translations.labelSex,
      text: sexTranslations[sex as keyof typeof sexTranslations] || "",
    });
  }

  if (race) {
    result.push({
      id: "race",
      type: "text",
      label: translations.labelRace,
      text: raceTranslations[race as keyof typeof raceTranslations] || "",
    });
  }

  if (phoneNumber) {
    result.push({
      id: "phoneNumber",
      type: "link",
      label: translations.labelPhoneNumber,
      text: phoneNumber.styled,
      href: `tel:${phoneNumber.url}`,
    });
  }

  if (email) {
    result.push({
      id: "email",
      type: "link",
      label: translations.labelEmail,
      text: email,
      href: `mailto:${email}`,
    });
  }

  if (address) {
    result.push({
      id: "address",
      type: "text",
      label: translations.labelAddress,
      text: address,
    });
  }

  if (legalGuardian) {
    result.push({
      id: "legalGuardian",
      type: "click",
      label: translations.labelLegalGuardian,
      text: `${legalGuardian.lastName}, ${legalGuardian.firstName}`,
      onClick: legalGuardian.onClick,
    });
  }

  return result;
};

export default getInformationGridItems;
