import { defineMessages } from "react-intl";

const messages = defineMessages({
  description: {
    id: "Center.CenterRegisterTab.description",
    defaultMessage:
      "The center has not yet been registered with the modality worklist. Please click the button below to perform the registration.",
  },
  registerButtonTitle: {
    id: "Center.CenterRegisterTab.registerButtonTitle",
    defaultMessage: "Register Center",
  },
});

export default messages;
