import useTranslations from "./useTranslations";
import { ContactInformation } from "../CenterSettingsContainer/types";
import { CenterInformationSettingsBlockProps } from "../CenterInformationSettingsBlock";
import { formatInternational } from "../../../common/phoneNumberHelpers";

const formatTelNumber = (params: { telNumber?: string }) => {
  const { telNumber } = params;
  if (!telNumber) return "";

  return formatInternational({ phoneNumber: telNumber });
};
const useContactInformationBlockProps = (params: {
  contactInformation: ContactInformation;
  onClick?: () => void;
}): CenterInformationSettingsBlockProps => {
  const { contactInformation, onClick } = params;

  const translations = useTranslations();

  const centerPhoneNumber = formatTelNumber({
    telNumber: contactInformation.phone,
  });

  const centerFaxNumber = formatTelNumber({
    telNumber: contactInformation.fax,
  });

  const contactData = [
    { key: translations.labelPhone, value: centerPhoneNumber || "" },
    { key: translations.labelFax, value: centerFaxNumber || "" },
    { key: translations.labelEmail, value: contactInformation.email || "" },
  ];

  return {
    title: translations.headerContactInfo,
    buttonText: translations.btnEdit,
    onClick,
    data: contactData,
  };
};

export default useContactInformationBlockProps;
