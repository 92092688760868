import Big from "big.js";

type CenterOffering = { duration_minutes: number | null };

type AppointmentOffering = { center_offering: CenterOffering };

type AppointmentPackage = {
  appointment_offerings: AppointmentOffering[];
};

const calcCenterOfferingsDurationBig = (
  appointmentOfferings: AppointmentOffering[],
  defaultDuration: number,
): Big => {
  const offeringsDurationBig = appointmentOfferings.reduce((sum, offering) => {
    return sum.plus(
      offering.center_offering.duration_minutes ?? defaultDuration,
    );
  }, Big(0));

  return offeringsDurationBig;
};

const calcAppointmentDurationMinutes = (params: {
  offerings: AppointmentOffering[];
  packages: AppointmentPackage[];
  defaultDuration: number;
}): number => {
  const { offerings, packages, defaultDuration } = params;

  const offeringsDurationBig = calcCenterOfferingsDurationBig(
    offerings,
    defaultDuration,
  );

  const packagesDurationBig = packages.reduce((sum, packageItem) => {
    const { appointment_offerings } = packageItem;

    const packageDurationBig = calcCenterOfferingsDurationBig(
      appointment_offerings,
      defaultDuration,
    );

    return sum.plus(packageDurationBig);
  }, Big(0));

  return offeringsDurationBig.plus(packagesDurationBig).toNumber();
};

export default calcAppointmentDurationMinutes;
