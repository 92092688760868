import { useMemo } from "react";
import { GridColDef } from "../../Unknown/DataGrid";
import getColumnPaidBy from "./columns/getColumnPaidBy";
import getColumnReceivedAt from "./columns/getColumnReceivedAt";
import getColumnPayments from "./columns/getColumnPayments";
import { AppointmentPayment } from "./types";
import useTranslations from "./useTranslations";

const useColumns = (): GridColDef<AppointmentPayment>[] => {
  const translations = useTranslations();

  const columns = useMemo(() => {
    const {
      headerPayment,
      headerPaidBy,
      headerReceived,
      methodCard,
      methodCash,
    } = translations;

    return [
      getColumnPayments({
        translations: { headerPayment, methodCash, methodCard },
      }),
      getColumnReceivedAt({
        translations: { headerReceived },
      }),
      getColumnPaidBy({
        translations: { headerPaidBy },
      }),
    ];
  }, [translations]);

  return columns;
};

export default useColumns;
