import format from "date-fns/format";
import { convertDatabaseDateToTimezone } from "../../../common/dateHelpers";
import { AuditLog } from "./types";

const dateFormatType = "MM/dd/yyyy hh:mm a";

const getAuditLogChangedAt = (auditLog: AuditLog, timezone?: string) => {
  let changedAt = format(new Date(auditLog.created_at), dateFormatType);
  const isAppointmentAuditLog = "appointment_id" in auditLog;

  if (isAppointmentAuditLog) {
    const { dateTimezone } = convertDatabaseDateToTimezone(
      auditLog.created_at,
      auditLog.appointment.center.timezone,
    );
    changedAt = format(dateTimezone, dateFormatType);
  }

  if (!isAppointmentAuditLog && timezone) {
    const { dateTimezone } = convertDatabaseDateToTimezone(
      auditLog.created_at,
      timezone,
    );
    changedAt = format(dateTimezone, dateFormatType);
  }

  return changedAt;
};

export default getAuditLogChangedAt;
