import React, { FC } from "react";
import Grid from "../../Unknown/Grid";
import StatusDialog from "../../Unknown/StatusDialog";
import useActionButtons from "./useActionButtons";
import useTranslations from "./useTranslations";
import Typography from "../../Unknown/Typography";

export type CustomerLogInDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  isLoading?: boolean;
  errorMessage?: string | null;
  customerName: string;
  confirmationCode: string;
};

const CustomerLogInDialog: FC<CustomerLogInDialogProps> = ({
  isOpen,
  handleClose,
  isLoading,
  errorMessage,
  customerName,
  confirmationCode,
}) => {
  const { translations } = useTranslations();

  const actionButtons = useActionButtons({
    handleClose,
  });

  return (
    <StatusDialog
      open={isOpen}
      title={translations.title}
      isLoading={isLoading}
      errorMessage={errorMessage}
      actionButtons={actionButtons}
      onClose={handleClose}
      fullWidth
      PaperProps={{ sx: { maxWidth: 500 } }}
    >
      <Grid container direction="column" wrap="nowrap" gap={4}>
        <Typography>
          {translations.description}{" "}
          <Typography component="span" fontWeight={500}>
            {customerName}
          </Typography>{" "}
          {translations.is}
        </Typography>
        <Typography variant="h4" textAlign="center">
          {confirmationCode}
        </Typography>
      </Grid>
    </StatusDialog>
  );
};

export default CustomerLogInDialog;
