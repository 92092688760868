import { defineMessages } from "react-intl";

const messages = defineMessages({
  headingName: {
    id: "Package.CenterPackagesView.headingName",
    defaultMessage: "Name",
  },
  headingPrice: {
    id: "Package.CenterPackagesView.headingPrice",
    defaultMessage: "Price",
  },
  headingOfferings: {
    id: "Package.CenterPackagesView.headingOfferings",
    defaultMessage: "Offerings",
  },
  headingIsPrivate: {
    id: "Package.CenterPackagesView.headingIsPrivate",
    defaultMessage: "Private",
  },
  privateTrue: {
    id: "Package.CenterPackagesView.privateTrue",
    defaultMessage: "Yes",
  },
  privateFalse: {
    id: "Package.CenterPackagesView.privateFalse",
    defaultMessage: "No",
  },
  btnArchive: {
    id: "Package.CenterPackagesView.btnArchive",
    defaultMessage: "Archive",
  },
  btnEdit: {
    id: "Package.CenterPackagesView.btnEdit",
    defaultMessage: "Edit",
  },
});

export default messages;
