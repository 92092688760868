import supabaseClient from "../../../common/supabaseClient";
import { Patient } from "./types";

const supabasePatientRequest = `
*,
patient_addresses (
  type,
  address
),
legal_guardian:legal_guardian_id (
  id,
  first_name,
  last_name
),
appointment_patient_details (
  appointment:appointment_id (
    id,
    center:center_id (
      name,
      timezone,
      region
    ),
    time_slot:time_slot_id (
      started_at
    ),
    appointment_offerings (
      side,
      center_offering:center_offering_id (
        name
      )
    ),
    appointment_packages (
      center_package: center_package_id (
        name
      )
    )
  )
),
patient_forms (
  id,
  type,
  patient_signed_at,
  expiration_at,
  file_name,
  patient_id
),
patient_audit_logs (
  id,
  created_at,
  fields,
  role,
  patient_id,
  customer: customer_id (
    first_name,
    last_name
  ),
  center_user: center_user_id (
    first_name,
    last_name
  )
)
`;

const getPatientData = async (mrn: string) => {
  const { data, error } = await supabaseClient
    .from<Patient>("patients")
    .select(supabasePatientRequest)
    .eq("mrn", mrn)
    .is(
      "appointment_patient_details.appointment.appointment_offerings.appointment_package_id" as keyof Patient,
      null,
    )
    .limit(1)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .order("created_at" as any, {
      foreignTable: "patient_audit_logs",
      ascending: false,
    })
    .maybeSingle();

  if (error) throw new Error(error.message);

  return data;
};

export default getPatientData;
