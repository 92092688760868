import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import ListItemButton from "../ListItemButton";
import ListItemIcon from "../ListItemIcon";
import ListItemText from "../ListItemText";
import useStyles from "./useStyles";

export type NavbarProfileOptionLink = {
  itemType: "link";
  to: string;
};

export type NavbarProfileOptionClick = {
  itemType: "click";
  onClick: () => Promise<void> | void;
};

export type NavbarProfileOptionProps = {
  IconComponent: ReactNode;
  title: string;
  meta: NavbarProfileOptionLink | NavbarProfileOptionClick;
  handleCloseUserMenu?: () => void;
  isActive?: boolean;
};

const NavbarProfileOption: FC<NavbarProfileOptionProps> = ({
  IconComponent,
  title,
  meta,
  handleCloseUserMenu,
  isActive,
}) => {
  const navigate = useNavigate();
  const { activeOptionRoot } = useStyles();

  const { itemType } = meta;

  const className = isActive ? activeOptionRoot : "";

  switch (itemType) {
    case "link": {
      const onClick = () => {
        navigate(meta.to);
        if (handleCloseUserMenu) {
          handleCloseUserMenu();
        }
      };
      return (
        <ListItemButton dense onClick={onClick} className={className}>
          <ListItemIcon>{IconComponent}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      );
    }
    case "click": {
      const onClick = () => {
        meta.onClick();
        if (handleCloseUserMenu) {
          handleCloseUserMenu();
        }
      };
      return (
        <ListItemButton dense onClick={onClick} className={className}>
          <ListItemIcon sx={{ minWidth: 24 }}>{IconComponent}</ListItemIcon>
          <ListItemText sx={{ ml: 2 }} primary={title} />
        </ListItemButton>
      );
    }
    default: {
      return null;
    }
  }
};
export default NavbarProfileOption;
