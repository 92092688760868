import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemFlags, AppointmentRowItem } from "../types";
import AppointmentTableFilterFlags, {
  AppointmentFlag,
} from "../../AppointmentTableFilterFlags";
import getOptionalArrayItem from "../../../../common/getOptionalArrayItem";
import checkIsFilterEmpty from "../../AppointmentTableFilterFlags/checkIsFilterEmpty";
import Typography from "../../../Unknown/Typography";
import Box from "../../../Unknown/Box";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

interface ColumnValue {
  value: string;
  label: string;
}

export type ColumnFlagsTranslations = {
  columnAlert: string;
  flagStat: string;
  flagAdditionalViews: string;
  flagRequiresGuardian: string;
  flagHasPriority: string;
  flagUnexpectedFindings: string;
  flagReceiptConfirmed: string;
  containsAny: string;
  containsAll: string;
};

const getColumnFlags = (params: {
  columnItem: AppointmentColumnItemFlags;
  translations: ColumnFlagsTranslations;
  headerClassName?: string;
}): GridColDef<AppointmentRowItem, ColumnValue[], string> => {
  const { columnItem, translations, headerClassName } = params;

  return {
    field: columnItem.field,
    headerName: translations.columnAlert,
    headerClassName,
    disableColumnMenu: true,
    sortable: false,
    flex: 0.5,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    valueFormatter: ({ value }) => {
      return value.map(({ label }) => label).join(",");
    },
    valueGetter: ({ row }) => {
      return [
        ...getOptionalArrayItem(
          row.appointmentCustomer?.customerType === "legal_guardian",
          {
            value: AppointmentFlag.requiresParentGuardian,
            label: translations.flagRequiresGuardian,
          },
        ),
        ...getOptionalArrayItem(row.isStat, {
          value: AppointmentFlag.isStat,
          label: translations.flagStat,
        }),
        ...getOptionalArrayItem(row.isAdditionalViews, {
          value: AppointmentFlag.isAdditionalViews,
          label: translations.flagAdditionalViews,
        }),
        ...getOptionalArrayItem(row.hasPriority, {
          value: AppointmentFlag.hasPriority,
          label: translations.flagHasPriority,
        }),
        ...getOptionalArrayItem(
          row.hasUnexpectedFindings &&
            !row.isUnexpectedFindingsReceiptConfirmed,
          {
            value: AppointmentFlag.hasUnexpectedFindings,
            label: translations.flagUnexpectedFindings,
          },
        ),
        ...getOptionalArrayItem(row.isUnexpectedFindingsReceiptConfirmed, {
          value: AppointmentFlag.isReceiptConfirmed,
          label: translations.flagReceiptConfirmed,
        }),
      ];
    },
    filterOperators: [
      {
        label: translations.containsAny,
        value: "contains-any",
        getApplyFilterFn: (filterItem) => {
          if (checkIsFilterEmpty(filterItem)) return null;

          return ({ value }) => {
            return value?.some((flag) => filterItem.value.includes(flag.value));
          };
        },
        InputComponent: AppointmentTableFilterFlags,
      },
      {
        label: translations.containsAll,
        value: "contains-all",
        getApplyFilterFn: (filterItem) => {
          if (checkIsFilterEmpty(filterItem)) return null;

          return ({ value }) => {
            return filterItem.value.every((filterValue: string) =>
              value?.some((flag) => flag.value === filterValue),
            );
          };
        },
        InputComponent: AppointmentTableFilterFlags,
      },
    ],
    renderCell: ({ value }) => {
      return (
        <Box display="block">
          {value?.map(({ label, value: id }, index) => {
            const isLast = index === value.length - 1;
            const formattedLabel = isLast ? label : `${label},`;

            return (
              <Typography variant="body2" key={id}>
                {formattedLabel}
              </Typography>
            );
          })}
        </Box>
      );
    },
  };
};

export default getColumnFlags;
