import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "Appointment.AppointmentRescheduleDialog.title",
    defaultMessage: "Reschedule Appointment",
  },
  description: {
    id: "Appointment.AppointmentRescheduleDialog.description",
    defaultMessage: "Are you sure you want to reschedule this appointment?",
  },
  btnCancel: {
    id: "Appointment.AppointmentRescheduleDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnSubmit: {
    id: "Appointment.AppointmentRescheduleDialog.btnSubmit",
    defaultMessage: "Save appointment",
  },
  overrideTimeLabel: {
    id: "Appointment.AppointmentRescheduleDialog.overrideTimeLabel",
    defaultMessage: "Override Appointment Start Time",
  },
});

export default messages;
