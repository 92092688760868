import { defineMessages } from "react-intl";

const messages = defineMessages({
  searchPlaceholder: {
    id: "Appointment.AppointmentTableTopBar.searchPlaceholder",
    defaultMessage: "Find name",
  },
  fromDateLabel: {
    id: "Appointment.AppointmentTableTopBar.fromDateLabel",
    defaultMessage: "From date",
  },
  toDateLabel: {
    id: "Appointment.AppointmentTableTopBar.toDateLabel",
    defaultMessage: "To date",
  },
});

export default messages;
