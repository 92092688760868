import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import { PatientEditFormTranslations } from "../PatientEditForm/types";
import { maxBirthDate, minBirthDate } from "./constants";
import messages from "./messages";
import { ValidationSchemaErrorTranslations } from "./types";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      defaultError: formatMessage(commonMessages.defaultError),
      errorPatientNotFound: formatMessage(messages.errorPatientNotFound),
      errorCustomerNotFound: formatMessage(messages.errorCustomerNotFound),
      btnPatientEdit: formatMessage(messages.btnPatientEdit),
      btnPatientSave: formatMessage(messages.btnPatientSave),
      btnPatientCancel: formatMessage(messages.btnPatientCancel),
      errorEmailInUse: formatMessage(messages.errorEmailInUse),
      errorConfirmationCodeIsInvalid: formatMessage(
        messages.errorConfirmationCodeIsInvalid,
      ),
    };

    const formLabelTranslations: PatientEditFormTranslations = {
      labelFirstName: formatMessage(messages.labelFirstName),
      labelLastName: formatMessage(messages.labelLastName),
      labelBirthDate: formatMessage(messages.labelBirthDate),
      labelPhoneNumber: formatMessage(messages.labelPhoneNumber),
      labelEmail: formatMessage(messages.labelEmail),
      labelConfirmationCode: formatMessage(messages.labelConfirmationCode),
      labelPrimaryAddress: formatMessage(messages.labelPrimaryAddress),
      labelSecondaryAddress: formatMessage(messages.labelSecondaryAddress),
      labelCity: formatMessage(messages.labelCity),
      labelPostalCode: formatMessage(messages.labelPostalCode),
      labelStateIsoCode: formatMessage(messages.labelStateIsoCode),
      labelCountryIsoCode: formatMessage(messages.labelCountryIsoCode),
      labelRace: formatMessage(messages.labelRace),
      labelSex: formatMessage(messages.labelSex),
    };

    const validationSchemaTranslations: ValidationSchemaErrorTranslations = {
      errorEmailInvalid: formatMessage(messages.errorEmailInvalid),
      errorDateInvalid: formatMessage(messages.errorDateInvalid),
      errorDateMin: formatMessage(messages.errorDateMin, {
        value: minBirthDate.toLocaleDateString(),
      }),
      errorDateMax: formatMessage(messages.errorDateMax, {
        value: maxBirthDate.toLocaleDateString(),
      }),
    };

    return {
      translations,
      formLabelTranslations,
      validationSchemaTranslations,
    };
  }, [formatMessage]);

  return result;
};
export default useTranslations;
