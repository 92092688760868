import { EventInput } from "@fullcalendar/react";
import { useCallback, useState } from "react";
import zonedTimeToUtc from "date-fns-tz/zonedTimeToUtc";
import { addTimeToDate } from "../../../common/dateHelpers";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { TimeSlotAddDialogProps } from "../TimeSlotAddDialog";
import { TimeSlotAddFormValues } from "../TimeSlotAddDialogForm";
import { CalendarEventExtendedProp } from "./convertTimeSlotsToEvents";
import useTranslations from "./useTranslations";

type TimeSlotAddDialogResult = TimeSlotAddDialogProps & {
  onOpen: () => void;
};

const initialValues: TimeSlotAddFormValues = {
  name: "",
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
};

const useTimeSlotAddDialog = (params: {
  onTimeSlotAdd: (value: EventInput) => void;
  centerData?: { id: string; timezone?: string | null } | null;
}): TimeSlotAddDialogResult => {
  const { onTimeSlotAdd, centerData } = params;
  const { setAlert } = useUIContext();

  const { translations, addDialogTranslations } = useTranslations();
  const { defaultError, errorTimeSlotIsAlreadyTaken } = translations;

  const addCenterTimeSlot = useFirebaseAppFunction("addCenterTimeSlot");

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const onSubmit: TimeSlotAddDialogProps["onSubmit"] = useCallback(
    async (values) => {
      if (!centerData) return;
      try {
        const { name, startDate, startTime, endDate, endTime } = values;

        if (!startDate || !startTime || !endDate || !endTime) {
          throw new Error("Some required values are not defined");
        }

        const startDateTime = addTimeToDate(startDate, startTime);
        const endDateTime = addTimeToDate(endDate, endTime);

        const startDateTimeUTC = zonedTimeToUtc(
          startDateTime,
          centerData?.timezone || "UTC",
        ).toISOString();

        const endDateTimeUTC = zonedTimeToUtc(
          endDateTime,
          centerData?.timezone || "UTC",
        ).toISOString();

        const { data: addResponse } = await addCenterTimeSlot({
          centerId: centerData.id,
          name,
          startDateTimeUTC,
          endDateTimeUTC,
        });

        if (addResponse.status === "error") {
          switch (addResponse.error.code) {
            case "TIMESLOT_IS_TAKEN": {
              setAlert({
                isShown: true,
                severity: "error",
                message: errorTimeSlotIsAlreadyTaken,
              });
              return;
            }

            default: {
              throw new Error(defaultError);
            }
          }
        }

        const { timeSlot } = addResponse.data;

        const extendedProps: CalendarEventExtendedProp = {
          timeSlotId: timeSlot.id,
          timeSlotType: "planned_closures",
        };

        onTimeSlotAdd({
          id: timeSlot.id,
          title: name,
          start: startDateTime,
          end: endDateTime,
          extendedProps,
          textColor: "unset",
        });

        onClose();
      } catch (error) {
        setAlert({ isShown: true, severity: "error", message: defaultError });
      }
    },
    [
      addCenterTimeSlot,
      centerData,
      defaultError,
      errorTimeSlotIsAlreadyTaken,
      onTimeSlotAdd,
      setAlert,
    ],
  );

  return {
    isOpen,
    onClose,
    onSubmit,
    initialValues,
    title: addDialogTranslations.addTitle,
    description: addDialogTranslations.addDescription,
    onOpen,
    centerTimeZone: centerData?.timezone || undefined,
  };
};

export default useTimeSlotAddDialog;
