import { defineMessages } from "react-intl";

const messages = defineMessages({
  columnStatus: {
    id: "Appointment.AppointmentsTableGrid.columnStatus",
    defaultMessage: "Status",
  },
  columnPatient: {
    id: "Appointment.AppointmentsTableGrid.columnPatient",
    defaultMessage: "Patient",
  },
  columnPatientDOB: {
    id: "Appointment.AppointmentsTableGrid.columnPatientDOB",
    defaultMessage: "Patient DOB",
  },
  columnPatientId: {
    id: "Appointment.AppointmentsTableGrid.columnPatientId",
    defaultMessage: "Patient ID",
  },
  columnPatientCity: {
    id: "Appointment.AppointmentsTableGrid.columnPatientCity",
    defaultMessage: "Patient city",
  },
  columnAppointmentTotal: {
    id: "Appointment.AppointmentsTableGrid.columnAppointmentTotal",
    defaultMessage: "Appointment total",
  },
  columnPatientPhone: {
    id: "Appointment.AppointmentsTableGrid.columnPatientPhone",
    defaultMessage: "Patient phone",
  },
  columnPatientName: {
    id: "Appointment.AppointmentsTableGrid.columnPatientName",
    defaultMessage: "Patient name",
  },
  columnPatientRace: {
    id: "Appointment.AppointmentsTableGrid.columnPatientRace",
    defaultMessage: "Race",
  },
  columnPatientSex: {
    id: "Appointment.AppointmentsTableGrid.columnPatientSex",
    defaultMessage: "Sex",
  },
  columnPatientEmail: {
    id: "Appointment.AppointmentsTableGrid.columnPatientEmail",
    defaultMessage: "Patient email",
  },
  columnTime: {
    id: "Appointment.AppointmentsTableGrid.columnTime",
    defaultMessage: "Time",
  },
  columnDate: {
    id: "Appointment.AppointmentsTableGrid.columnDate",
    defaultMessage: "Date",
  },
  columnBookedTime: {
    id: "Appointment.AppointmentsTableGrid.columnBookedTime",
    defaultMessage: "Booked time",
  },
  columnBookedDate: {
    id: "Appointment.AppointmentsTableGrid.columnBookedDate",
    defaultMessage: "Booked date",
  },
  columnLocation: {
    id: "Appointment.AppointmentsTableGrid.columnLocation",
    defaultMessage: "Location",
  },
  columnLocationState: {
    id: "Appointment.AppointmentsTableGrid.columnLocationState",
    defaultMessage: "Location state",
  },
  columnLocationZip: {
    id: "Appointment.AppointmentsTableGrid.columnLocationZip",
    defaultMessage: "Location ZIP",
  },
  columnOffering: {
    id: "Appointment.AppointmentsTableGrid.columnOffering",
    defaultMessage: "Offering",
  },
  columnOfferingStudies: {
    id: "Appointment.AppointmentsTableGrid.columnOfferingStudies",
    defaultMessage: "Studies",
  },
  columnFullOfferings: {
    id: "Appointment.AppointmentsTableGrid.columnFullOfferings",
    defaultMessage: "Offerings",
  },
  columnFullPackages: {
    id: "Appointment.AppointmentsTableGrid.columnFullPackages",
    defaultMessage: "Packages",
  },
  columnFullAddOns: {
    id: "Appointment.AppointmentsTableGrid.columnFullAddOns",
    defaultMessage: "Add-ons",
  },
  columnCustomer: {
    id: "Appointment.AppointmentsTableGrid.columnCustomer",
    defaultMessage: "Customer",
  },
  columnPayments: {
    id: "Appointment.AppointmentsTableGrid.columnPayments",
    defaultMessage: "Transactions",
  },
  columnBookingScenario: {
    id: "Appointment.AppointmentsTableGrid.columnBookingScenario",
    defaultMessage: "Scenario",
  },
  columnBookedBy: {
    id: "Appointment.AppointmentsTableGrid.columnBookedBy",
    defaultMessage: "Booked by",
  },
  columnCreatedAt: {
    id: "Appointment.AppointmentsTableGrid.columnCreatedAt",
    defaultMessage: "Created at",
  },
  columnPatientAddress: {
    id: "Appointment.AppointmentsTableGrid.columnPatientAddress",
    defaultMessage: "Patient address",
  },
  columnPatientState: {
    id: "Appointment.AppointmentsTableGrid.columnPatientState",
    defaultMessage: "Patient state",
  },
  columnPatientAddressLine1: {
    id: "Appointment.AppointmentsTableGrid.columnPatientAddressLine1",
    defaultMessage: "Patient address 1",
  },
  columnPatientAddressLine2: {
    id: "Appointment.AppointmentsTableGrid.columnPatientAddressLine2",
    defaultMessage: "Patient address 2",
  },
  columnPatientAge: {
    id: "Appointment.AppointmentsTableGrid.columnPatientAge",
    defaultMessage: "Patient age (yrs)",
  },
  columnPatientZip: {
    id: "Appointment.AppointmentsTableGrid.columnPatientZip",
    defaultMessage: "Patient ZIP",
  },
  columnAlert: {
    id: "Appointment.AppointmentsTableGrid.columnAlert",
    defaultMessage: "Flags",
  },
  columnFlags: {
    id: "Appointment.AppointmentsTableGrid.columnFlags",
    defaultMessage: "Flags",
  },
  columnBtnOptions: {
    id: "Appointment.AppointmentsTableGrid.columnBtnOptions",
    defaultMessage: "Options button",
  },
  columnBtnView: {
    id: "Appointment.AppointmentsTableGrid.columnBtnView",
    defaultMessage: "View button",
  },

  statusCheckout: {
    id: "Appointment.AppointmentsTableGrid.statusCheckout",
    defaultMessage: "Checkout",
  },
  statusIncomplete: {
    id: "Appointment.AppointmentsTableGrid.statusIncomplete",
    defaultMessage: "Incomplete",
  },
  statusAbandoned: {
    id: "Appointment.AppointmentsTableGrid.statusAbandoned",
    defaultMessage: "Abandoned",
  },
  statusConfirmed: {
    id: "Appointment.AppointmentsTableGrid.statusConfirmed",
    defaultMessage: "Confirmed",
  },
  statusCanceled: {
    id: "Appointment.AppointmentsTableGrid.statusCanceled",
    defaultMessage: "Canceled",
  },
  statusIntakeComplete: {
    id: "Appointment.AppointmentsTableGrid.statusIntakeComplete",
    defaultMessage: "Intake complete",
  },
  statusCheckedIn: {
    id: "Appointment.AppointmentsTableGrid.statusCheckedIn",
    defaultMessage: "Checked in",
  },
  statusNoShow: {
    id: "Appointment.AppointmentsTableGrid.statusNoShow",
    defaultMessage: "No show",
  },
  statusStudiesUnread: {
    id: "Appointment.AppointmentsTableGrid.statusStudiesUnread",
    defaultMessage: "Studies unread",
  },
  statusStudiesRead: {
    id: "Appointment.AppointmentsTableGrid.statusStudiesRead",
    defaultMessage: "Studies read",
  },
  statusReportsSent: {
    id: "Appointment.AppointmentsTableGrid.statusReportsSent",
    defaultMessage: "Reports sent",
  },
  statusReportsError: {
    id: "Appointment.AppointmentsTableGrid.statusReportsError",
    defaultMessage: "Reports error",
  },
  statusScanComplete: {
    id: "Appointment.AppointmentsTableGrid.statusScanComplete",
    defaultMessage: "Scan complete",
  },
  alertTimer: {
    id: "Appointment.AppointmentsTableGrid.alertTimer",
    defaultMessage: "Appointment has STAT addon",
  },
  alertAssignmentLate: {
    id: "Appointment.AppointmentsTableGrid.alertAssignmentLate",
    defaultMessage: "Alert!",
  },
  alertWarningAmber: {
    id: "Appointment.AppointmentsTableGrid.alertWarningAmber",
    defaultMessage: "Patient ID not verified",
  },
  alertSupervisorAccount: {
    id: "Appointment.AppointmentsTableGrid.alertSupervisorAccount",
    defaultMessage: "Requires parent or guardian",
  },

  flagStat: {
    id: "Appointment.AppointmentsTableGrid.flagStat",
    defaultMessage: "STAT",
  },
  flagAdditionalViews: {
    id: "Appointment.AppointmentsTableGrid.flagAdditionalViews",
    defaultMessage: "Additional views",
  },
  flagHasPriority: {
    id: "Appointment.AppointmentsTableGrid.flagHasPriority",
    defaultMessage: "Priority",
  },
  flagRequiresGuardian: {
    id: "Appointment.AppointmentsTableGrid.flagRequiresGuardian",
    defaultMessage: "Requires parent/guardian",
  },
  flagUnexpectedFindings: {
    id: "Appointment.AppointmentsTableGrid.flagUnexpectedFindings",
    defaultMessage: "Unexpected findings",
  },
  flagReceiptConfirmed: {
    id: "Appointment.AppointmentsTableGrid.flagReceiptConfirmed",
    defaultMessage: "Receipt confirmed",
  },

  optionsBtnCheckIn: {
    id: "Appointment.AppointmentsTableGrid.optionsBtnCheckIn",
    defaultMessage: "Check in",
  },
  optionsBtnLogIn: {
    id: "Appointment.AppointmentsTableGrid.optionsBtnLogIn",
    defaultMessage: "Log in",
  },

  btnView: {
    id: "Appointment.AppointmentsTableGrid.btnView",
    defaultMessage: "View",
  },

  sideRight: {
    id: "Appointment.AppointmentsTableGrid.sideRight",
    defaultMessage: "Right",
  },
  sideLeft: {
    id: "Appointment.AppointmentsTableGrid.sideLeft",
    defaultMessage: "Left",
  },

  providerStripe: {
    id: "Appointment.AppointmentsTableGrid.providerStripe",
    defaultMessage: "Stripe",
  },
  providerCash: {
    id: "Appointment.AppointmentsTableGrid.providerCash",
    defaultMessage: "Cash",
  },
  providerUnknown: {
    id: "Appointment.AppointmentsTableGrid.providerUnknown",
    defaultMessage: "Unknown provider",
  },

  scenarioPatient: {
    id: "Appointment.AppointmentsTableGrid.scenarioPatient",
    defaultMessage: "Patient",
  },
  scenarioLegalGuardian: {
    id: "Appointment.AppointmentsTableGrid.scenarioLegalGuardian",
    defaultMessage: "Legal guardian",
  },
  scenarioOtherPerson: {
    id: "Appointment.AppointmentsTableGrid.scenarioOtherPerson",
    defaultMessage: "Third party",
  },
  tooltipStat: {
    id: "Appointment.AppointmentsTableGrid.tooltipStat",
    defaultMessage: "Appointment has STAT addon",
  },
  tooltipAdditionalViews: {
    id: "Appointment.AppointmentsTableGrid.tooltipAdditionalViews",
    defaultMessage: "Additional views",
  },
  tooltipHasPriority: {
    id: "Appointment.AppointmentsTableGrid.tooltipHasPriority",
    defaultMessage: "Has Priority",
  },
  tooltipRequiresGuardian: {
    id: "Appointment.AppointmentsTableGrid.tooltipRequiresGuardian",
    defaultMessage: "Requires parent/guardian",
  },
  tooltipUnexpectedFindings: {
    id: "Appointment.AppointmentsTableGrid.tooltipUnexpectedFindings",
    defaultMessage: "Unexpected findings",
  },
  tooltipReceiptConfirmed: {
    id: "Appointment.AppointmentsTableGrid.tooltipReceiptConfirmed",
    defaultMessage: "Receipt confirmed",
  },
  additionalViewsAppointment: {
    id: "Appointment.AppointmentsTableGrid.additionalViewsAppointment",
    defaultMessage: "Additional views appointment:",
  },
  years: {
    id: "Appointment.AppointmentsTableGrid.years",
    defaultMessage: "yrs",
  },
  containsAny: {
    id: "Appointment.AppointmentsTableGrid.containsAny",
    defaultMessage: "contains any",
  },
  containsAll: {
    id: "Appointment.AppointmentsTableGrid.containsAll",
    defaultMessage: "contains all",
  },
});

export default messages;
