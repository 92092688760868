import React, { FC } from "react";
import { useFormikContext } from "formik";
import { CircularProgress, DialogActions } from "@mui/material";
import DialogContent from "../../Unknown/DialogContent";
import TimeSlotAddForm from "../TimeSlotAddDialogForm";
import useTranslations from "./useTranslations";
import useStyles from "./useStyles";
import Button from "../../Unknown/Button";
import Typography from "../../Unknown/Typography";
import Box from "../../Unknown/Box";
import Grid from "../../Unknown/Grid";

export type TimeSlotAddDialogFormikProps = {
  title: string;
  onClose: () => void;
  description?: string;
  onDeleteClick?: () => void;
};

const TimeSlotAddDialogFormik: FC<TimeSlotAddDialogFormikProps> = ({
  title,
  onClose,
  description,
  onDeleteClick,
}) => {
  const { descriptionRoot } = useStyles();
  const { translations, formikTranslations } = useTranslations();

  const { isSubmitting, resetForm, handleSubmit } = useFormikContext();

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <>
      <DialogContent>
        <Box mb={4}>
          <Typography variant="h5">{title}</Typography>
          {description && (
            <Typography variant="body2" className={descriptionRoot}>
              {description}
            </Typography>
          )}
        </Box>
        <TimeSlotAddForm translations={formikTranslations} />
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          wrap="nowrap"
        >
          <Grid item flexGrow={1}>
            {onDeleteClick && (
              <Button
                disabled={isSubmitting}
                onClick={onDeleteClick}
                color="error"
                size="small"
              >
                {translations.btnDelete}
              </Button>
            )}
          </Grid>
          <Grid item container columnSpacing={2} width="fit-content">
            <Grid item>
              <Button
                disabled={isSubmitting}
                onClick={handleClose}
                size="small"
              >
                {translations.btnCancel}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={isSubmitting}
                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                size="small"
                onClick={() => handleSubmit()}
              >
                {translations.btnSave}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default TimeSlotAddDialogFormik;
