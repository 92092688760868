import { defineMessages } from "react-intl";

const messages = defineMessages({
  appointmentInPastError: {
    id: "Appointment.AppointmentRescheduleDialogContainer.appointmentInPastError",
    defaultMessage: "Cannot book additional views appointment",
  },
  statusNotSupportedError: {
    id: "Appointment.AppointmentRescheduleDialogContainer.statusNotSupportedError",
    defaultMessage: "Cannot reschedule appointment",
  },
  timeSlotNotAvailableError: {
    id: "Appointment.AppointmentRescheduleDialogContainer.timeSlotNotAvailableError",
    defaultMessage:
      "Time slot is not available. Please reload page and try again",
  },
});

export default messages;
