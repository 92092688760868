import { defineMessages } from "react-intl";

const messages = defineMessages({
  headerName: {
    id: "AppointmentReport.AppointmentReportGrid.headerName",
    defaultMessage: "Name",
  },
  headerStatus: {
    id: "AppointmentReport.AppointmentReportGrid.headerStatus",
    defaultMessage: "Status",
  },
  btnDownload: {
    id: "AppointmentReport.AppointmentReportGrid.btnDownload",
    defaultMessage: "Download",
  },
  btnResend: {
    id: "AppointmentReport.AppointmentReportGrid.btnResend",
    defaultMessage: "Resend",
  },
  btnUnexpectedFindings: {
    id: "AppointmentReport.AppointmentReportGrid.btnUnexpectedFindings",
    defaultMessage: "Unexpected findings",
  },
  btnConfirmUnexpectedFindings: {
    id: "AppointmentReport.AppointmentReportGrid.btnConfirmUnexpectedFindings",
    defaultMessage: "Confirm Unexpected findings",
  },
  tooltipReceiptConfirmed: {
    id: "AppointmentReport.AppointmentReportGrid.tooltipReceiptConfirmed",
    defaultMessage: "Unexpected findings receipt confirmed",
  },
  tooltipUnexpectedFindings: {
    id: "AppointmentReport.AppointmentReportGrid.tooltipUnexpectedFindings",
    defaultMessage: "Has unexpected findings",
  },
  statusSent: {
    id: "AppointmentReport.AppointmentReportGrid.statusSent",
    defaultMessage: "Sent",
  },
  statusUnsent: {
    id: "AppointmentReport.AppointmentReportGrid.statusUnsent",
    defaultMessage: "Unsent",
  },
  statusError: {
    id: "AppointmentReport.AppointmentReportGrid.statusError",
    defaultMessage: "Sending failed",
  },
});

export default messages;
