import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentOfferingColumnItemBtnView,
  AppointmentOfferingRowItem,
} from "../types";
import Button from "../../../Unknown/Button";
import Box from "../../../Unknown/Box";

type ColumnValue = {
  appointmentId: string;
};

export type ColumnBtnViewTranslations = {
  btnView: string;
};

const getColumnBtnView = (params: {
  columnItem: AppointmentOfferingColumnItemBtnView;
  translations: ColumnBtnViewTranslations;
  handleClick: (id: string) => void;
  headerClassName?: string;
}): GridColDef<AppointmentOfferingRowItem, ColumnValue> => {
  const { columnItem, translations, handleClick, headerClassName } = params;
  const { btnView } = translations;

  return {
    field: columnItem.field,
    headerName: "",
    disableColumnMenu: true,
    minWidth: 180,
    getApplyQuickFilterFn: () => null,
    sortable: false,
    headerClassName,
    valueGetter: (getterParams) => {
      return { appointmentId: getterParams.row.appointment.id };
    },
    renderCell: (renderParams) => {
      const { value } = renderParams;

      if (!value) return null;

      const { appointmentId } = value;

      return (
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            sx={{ fontSize: 13 }}
            onClick={() => handleClick(appointmentId)}
          >
            {btnView}
          </Button>
        </Box>
      );
    },
  };
};

export default getColumnBtnView;
