import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      labelName: formatMessage(messages.labelName),
      labelMrn: formatMessage(messages.labelMrn),
      labelPhone: formatMessage(messages.labelPhone),
      labelEmail: formatMessage(messages.labelEmail),
      labelBirthDate: formatMessage(messages.labelBirthDate),
      labelLegalGuardian: formatMessage(messages.labelLegalGuardian),
    };

    return { translations };
  }, [formatMessage]);

  return result;
};

export default useTranslations;
