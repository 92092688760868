import { defineMessages } from "react-intl";

const messages = defineMessages({
  title: {
    id: "TimeSlot.TimeSlotDeletionConfirmationDialog.title",
    defaultMessage: "Delete Event",
  },
  description: {
    id: "TimeSlot.TimeSlotDeletionConfirmationDialog.description",
    defaultMessage: 'Are you sure you want to delete event "{name}"?',
  },
  btnCancel: {
    id: "TimeSlot.TimeSlotDeletionConfirmationDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  btnDelete: {
    id: "TimeSlot.TimeSlotDeletionConfirmationDialog.btnDelete",
    defaultMessage: "Delete",
  },
});

export default messages;
