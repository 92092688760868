import React from "react";
import Paper from "../../Unknown/Paper";
import Box from "../../Unknown/Box";
import Typography from "../../Unknown/Typography";
import AuditLogList from "../AuditLogList";
import Grid from "../../Unknown/Grid";
import { AuditLogListProps } from "../AuditLogList/types";

interface PatientAuditLogCardProps {
  title: string;
  auditLogsData: AuditLogListProps;
}

const PatientAuditLogCard = ({
  title,
  auditLogsData,
}: PatientAuditLogCardProps) => {
  return (
    <Grid item xs>
      <Paper>
        <Box p={3}>
          <Typography variant="h5">{title}</Typography>
          <Box
            mt={3}
            maxHeight={300}
            sx={{ overflow: "hidden", overflowY: "auto" }}
          >
            <AuditLogList {...auditLogsData} />
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default PatientAuditLogCard;
