import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { SupabaseClient } from "@supabase/supabase-js";
import addDays from "date-fns/addDays";
import formatISO from "date-fns/formatISO";
import subDays from "date-fns/subDays";

type SupabaseCenterPackage = Pick<DatabaseRow<"center_packages">, "name">;

type SupabaseAppointmentPackage = {
  center_package: SupabaseCenterPackage;
};

type SupabaseCenterOffering = Pick<DatabaseRow<"center_offerings">, "name">;

type SupabaseAppointmentOffering = Pick<
  DatabaseRow<"appointment_offerings">,
  "side"
> & {
  center_offering: SupabaseCenterOffering;
};

type SupabaseAppointmentPatientDetails = Pick<
  DatabaseRow<"appointment_patient_details">,
  "first_name" | "last_name"
>;

type SupabaseCenter = Pick<DatabaseRow<"centers">, "timezone">;

type SupabaseAppointment = Pick<
  DatabaseRow<"appointments">,
  "id" | "status"
> & {
  appointment_patient_details: SupabaseAppointmentPatientDetails[];
  appointment_offerings: SupabaseAppointmentOffering[];
  appointment_packages: SupabaseAppointmentPackage[];
};

export type SupabaseCalendarTimeSlot = Pick<
  DatabaseRow<"center_time_slots">,
  "id" | "started_at" | "ended_at" | "center_id" | "name" | "type"
> & {
  appointments?: SupabaseAppointment[];
  center: SupabaseCenter;
};

const supabaseTimeSlotsWithoutAppointments = `
id,
name,
type,
started_at,
ended_at,
center_id,
center:center_id ( timezone )
`;

const supabaseTimeSlotWithAppointments = `
${supabaseTimeSlotsWithoutAppointments},
appointments!inner (
  id,
  status,
  appointment_patient_details (
    first_name,
    last_name
  ),
  appointment_offerings (
    side,
    center_offering: center_offering_id (
      name
    )
  ),
  appointment_packages (
    center_package: center_package_id (
      name
    )
  )
)
`;

const getCalendarTimeSlots = async (params: {
  supabase: SupabaseClient;
  start: Date;
  end: Date;
  centerIds: string[];
}): Promise<SupabaseCalendarTimeSlot[]> => {
  const { supabase, start, end, centerIds } = params;

  const startDate = formatISO(subDays(start, 1));
  const endDate = formatISO(addDays(end, 1));

  const timeSlotsWithAppointments = await supabase
    .from<SupabaseCalendarTimeSlot>("center_time_slots")
    .select(supabaseTimeSlotWithAppointments)
    .in("center_id", centerIds)
    .neq("type", "planned_closures")
    .in("appointments.status" as keyof SupabaseCalendarTimeSlot, [
      "checkout",
      "incomplete",
      "confirmed",
      "checked_in",
      "intake_complete",
      "studies_unread",
      "studies_read",
      "reports_sent",
      "reports_error",
    ])
    .is(
      "appointments.appointment_offerings.appointment_package_id" as keyof SupabaseCalendarTimeSlot,
      null,
    )
    .gte("started_at", startDate)
    .lte("ended_at", endDate);

  const baseRangeFilter = `and(started_at.gte.${startDate},ended_at.lte.${endDate})`;
  const startRangeFilter = `and(started_at.lte.${startDate}, ended_at.gte.${startDate})`;
  const endRangeFilter = `and(started_at.lte.${endDate}, ended_at.gte.${endDate})`;

  const timeSlotsWithoutAppointments = await supabase
    .from<SupabaseCalendarTimeSlot>("center_time_slots")
    .select(supabaseTimeSlotsWithoutAppointments)
    .in("center_id", centerIds)
    .eq("type", "planned_closures")
    .or([baseRangeFilter, startRangeFilter, endRangeFilter].join(","));

  if (timeSlotsWithAppointments.error) {
    throw new Error(timeSlotsWithAppointments.error.message);
  }

  if (timeSlotsWithoutAppointments.error) {
    throw new Error(timeSlotsWithoutAppointments.error.message);
  }

  return [
    ...timeSlotsWithAppointments.data,
    ...timeSlotsWithoutAppointments.data,
  ];
};

export default getCalendarTimeSlots;
