import useTranslations from "./useTranslations";

const useCustomerEditFormContainer = (params: {
  customerId?: string;
  isOtherPerson?: boolean;
}) => {
  const { customerId, isOtherPerson } = params;

  const { formLabelTranslations } = useTranslations();

  if (!customerId) {
    return null;
  }

  return {
    translations: formLabelTranslations,
    customerId,
    isOtherPerson,
    namePrefix: "information",
    isPhoneShown: !isOtherPerson,
  };
};

export default useCustomerEditFormContainer;
