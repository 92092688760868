import React, { FC } from "react";
import Typography from "../Typography";
import useStyles from "./useStyles";
import { InformationGridItemData } from "./types";

export type InformationGridItemProps = {
  item: InformationGridItemData;
};

const InformationGridItem: FC<InformationGridItemProps> = ({ item }) => {
  const { labelRoot, linkRoot } = useStyles();

  switch (item.type) {
    case "text": {
      return (
        <>
          <Typography className={labelRoot}>{item.label}</Typography>
          <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
            {item.text}
          </Typography>
        </>
      );
    }
    case "link": {
      return (
        <>
          <Typography className={labelRoot}>{item.label}</Typography>
          <a href={item.href} className={linkRoot}>
            <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
              {item.text}
            </Typography>
          </a>
        </>
      );
    }
    case "click": {
      return (
        <>
          <Typography className={labelRoot}>{item.label}</Typography>
          <Typography
            variant="body2"
            className={linkRoot}
            onClick={item.onClick}
            sx={{ wordBreak: "break-word" }}
          >
            {item.text}
          </Typography>
        </>
      );
    }
    case "clickList": {
      return (
        <>
          <Typography className={labelRoot}>{item.label}</Typography>
          {item.clickList.map((clickItem) => (
            <Typography
              key={clickItem.text}
              variant="body2"
              className={linkRoot}
              onClick={clickItem.onClick}
              sx={{ wordBreak: "break-word" }}
            >
              {clickItem.text}
            </Typography>
          ))}
        </>
      );
    }
    default: {
      return null;
    }
  }
};

export default InformationGridItem;
