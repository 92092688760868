import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const translations = useMemo(() => {
    return {
      description: formatMessage(messages.description),
      registerButtonTitle: formatMessage(messages.registerButtonTitle),
    };
  }, [formatMessage]);

  return translations;
};
export default useTranslations;
