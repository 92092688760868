import { red } from "@mui/material/colors";
import { alpha } from "@mui/material";

const errorColor = red["700"];
const labelColor = alpha("#000000", 0.6);
const styles = (isError: boolean) => ({
  formControl: {
    "& .special-label": {
      color: isError ? errorColor : labelColor,
    },
    "& .react-tel-input .special-label": {
      fontSize: 16,
      top: 0,
      left: 0,
      transform: "translate(10px, -9px) scale(0.75)",
      transformOrigin: "top left",
    },
    "& .react-tel-input .form-control:focus": {
      boxShadow: isError ? `0 0 0 1px ${errorColor}` : undefined,
    },
    "& .react-tel-input .form-control": {
      paddingTop: "15px",
      paddingBottom: "15px",
    },
  },
  phoneInput: {
    width: "100%",
    borderColor: isError ? errorColor : undefined,
  },
});

export default styles;
