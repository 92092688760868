import { InformationDrawerProps } from "../../Unknown/InformationDrawer";
import useTranslations from "./useTranslations";

const useInformationDrawerActionButtons = (params: {
  isEditMode: boolean;
  isButtonsDisabled: boolean;
  handleEditModeChange: (value: boolean) => void;
  handleSubmit: () => void;
  resetForm: () => void;
}) => {
  const {
    isEditMode,
    isButtonsDisabled,
    handleEditModeChange,
    handleSubmit,
    resetForm,
  } = params;

  const { translations } = useTranslations();

  const { btnEdit, btnCancel, btnSave } = translations;

  if (!isEditMode) {
    const viewModeButtons: InformationDrawerProps["actionButtons"] = [
      {
        text: btnEdit,
        props: {
          disabled: isButtonsDisabled,
          variant: "outlined",
          onClick: () => handleEditModeChange(true),
        },
      },
    ];

    return viewModeButtons;
  }

  const editModeButtons: InformationDrawerProps["actionButtons"] = [
    {
      text: btnCancel,
      props: {
        disabled: isButtonsDisabled,
        variant: "text",
        onClick: () => {
          resetForm();
          handleEditModeChange(false);
        },
      },
    },
    {
      text: btnSave,
      props: {
        disabled: isButtonsDisabled,
        variant: "contained",
        onClick: handleSubmit,
      },
    },
  ];

  return editModeButtons;
};

export default useInformationDrawerActionButtons;
