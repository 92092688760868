import { useLocation } from "react-router-dom";

const activeTabsMap: Record<string, string> = {
  "": "dashboard",
  appointment: "dashboard",
  dashboard: "dashboard",
  calendar: "calendar",
  admin: "admin",
  search: "search",
};

const useActiveActionId = (): string | undefined => {
  const location = useLocation();
  const [, mainPageName] = location.pathname.split("/").filter(Boolean);

  const activeTab = activeTabsMap[mainPageName || ""];

  return activeTab;
};

export default useActiveActionId;
