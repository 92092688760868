import { GridProps } from "../../Unknown/Grid";

export const inputSpacing = 4;

export const defaultGridItemProps: Partial<GridProps> = {
  item: true,
  flexGrow: 1,
  flexBasis: 1,
  xs: true,
};

export const formikNamePrefix = "information";

export const formNames = [
  "firstName",
  "lastName",
  "phoneNumber",
  "email",
  "confirmationCode",
] as const;
