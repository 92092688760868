import { useCallback, useEffect, useMemo, useState } from "react";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import {
  Offering,
  OfferingsData,
} from "../../Center/CenterSettingsContainer/types";
import { useUIContext } from "../../Unknown/UIContext";
import { CenterOfferingFormValues } from "../CenterOfferingForm/types";
import { CenterOfferingFormikProps } from "../CenterOfferingFormik";
import useTranslations from "./useTranslations";

type EditFormHookParams = {
  isOpened: boolean;
  editedOfferingId: string | null;
  onClose: () => void;
  offeringsData: OfferingsData;
  refetchCenterData?: () => void | Promise<void>;
};

type EditFormHookResult = {
  editFormikProps: CenterOfferingFormikProps | null;
};

const useEditForm = (params: EditFormHookParams): EditFormHookResult => {
  const {
    isOpened,
    editedOfferingId,
    onClose,
    offeringsData,
    refetchCenterData,
  } = params;
  const { offerings, minPrice, currencyCode } = offeringsData;

  const updateCenterOffering = useFirebaseAppFunction("updateCenterOffering");

  const { editModalTitle, defaultError } = useTranslations();

  const { setAlert } = useUIContext();

  const [centerOfferingData, setCenterOfferingData] = useState<Offering | null>(
    null,
  );

  useEffect(() => {
    if (!isOpened) return;

    if (!editedOfferingId) {
      setCenterOfferingData(null);
      onClose();
      return;
    }

    const selectedOffering = offerings.find(
      (offering) => offering.id === editedOfferingId,
    );

    if (!selectedOffering) {
      setCenterOfferingData(null);
      onClose();
      return;
    }

    setCenterOfferingData(selectedOffering);
  }, [editedOfferingId, isOpened, offerings, onClose]);

  const initialValues = useMemo<CenterOfferingFormValues | null>(() => {
    if (!centerOfferingData) return null;

    return {
      name: centerOfferingData.name,
      modalityName: centerOfferingData.modalityName,
      description: centerOfferingData.description,
      durationMinutes: centerOfferingData.durationMinutes,
      price: centerOfferingData.price,
      depositAmount: centerOfferingData.depositAmount,
      scanSide: centerOfferingData.scanSide || "",
      visibility: centerOfferingData.visibility,
    };
  }, [centerOfferingData]);

  const handleSubmit = useCallback(
    async (values: CenterOfferingFormValues) => {
      try {
        if (!centerOfferingData) throw new Error(defaultError);

        const {
          name,
          modalityName,
          description,
          scanSide,
          visibility,
          durationMinutes,
          price,
          depositAmount,
        } = values;

        if (
          scanSide === "" ||
          visibility === "" ||
          durationMinutes === "" ||
          price === "" ||
          depositAmount === ""
        ) {
          throw new Error(defaultError);
        }

        await updateCenterOffering({
          id: centerOfferingData.id,
          name,
          modalityName,
          description,
          scanSide,
          visibility,
          durationMinutes,
          price,
          depositAmount,
        });

        if (refetchCenterData) await refetchCenterData();

        onClose();
      } catch (error) {
        setAlert({
          message: defaultError,
          isShown: true,
          severity: "error",
        });
      }
    },
    [
      centerOfferingData,
      defaultError,
      onClose,
      refetchCenterData,
      setAlert,
      updateCenterOffering,
    ],
  );

  return {
    editFormikProps: initialValues
      ? {
          title: editModalTitle,
          isOpened,
          onModalClose: onClose,
          initialValues,
          handleSubmit,
          currencyCode,
          minPrice,
        }
      : null,
  };
};

export default useEditForm;
