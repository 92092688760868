import { useMemo } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../../../common/messages";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const cancelTranslations = {
      defaultError: formatMessage(commonMessages.defaultError),
      cancelingAppointmentInPastError: formatMessage(
        messages.cancelingAppointmentInPastError,
      ),
      statusNotSupportedToCancelError: formatMessage(
        messages.statusNotSupportedToCancelError,
      ),
    };

    const markAsNoShowTranslations = {
      defaultError: formatMessage(commonMessages.defaultError),
      statusNotSupportedError: formatMessage(messages.statusNotSupportedError),
    };

    const actionBarTranslations = {
      btnCancel: formatMessage(messages.btnCancel),
      btnReschedule: formatMessage(messages.btnReschedule),
      btnPriority: formatMessage(messages.btnPriority),
      btnNoShow: formatMessage(messages.btnNoShow),
      btnViewAuditLogs: formatMessage(messages.btnViewAuditLogs),
      btnLogin: formatMessage(messages.btnLogin),
      btnCheckIn: formatMessage(messages.btnCheckIn),
      btnBookAdditionalViews: formatMessage(messages.btnBookAdditionalViews),
      btnScreeningForm: formatMessage(messages.btnScreeningForm),
      btnConfirmAppointment: formatMessage(messages.btnConfirmAppointment),
    };

    const markPriorityTranslations = {
      defaultError: formatMessage(commonMessages.defaultError),
      statusNotSupportedError: formatMessage(
        messages.statusNotSupportedPriorityError,
      ),
    };

    const customerLoginTranslations = {
      defaultError: formatMessage(commonMessages.defaultError),
      errorCustomerNotDefined: formatMessage(messages.errorCustomerNotDefined),
    };

    const paymentsTranslations = {
      cash: formatMessage(messages.cash),
      card: formatMessage(messages.card),
    };

    const translations = {
      appointmentNotFound: formatMessage(messages.appointmentNotFound),
    };

    return {
      cancelTranslations,
      markAsNoShowTranslations,
      actionBarTranslations,
      markPriorityTranslations,
      translations,
      customerLoginTranslations,
      paymentsTranslations,
    };
  }, [formatMessage]);
  return result;
};
export default useTranslations;
