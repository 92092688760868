import { useCallback } from "react";
import { useIntl } from "react-intl";
import commonMessages from "../common/messages";

const useDefaultErrorWrapper = () => {
  const { formatMessage } = useIntl();

  const runAsyncFunction = useCallback(
    async <R, A>(func: (args: A) => Promise<R>, args: A): Promise<R> => {
      try {
        return await func(args);
      } catch (error) {
        throw new Error(formatMessage(commonMessages.defaultError));
      }
    },
    [formatMessage],
  );

  const runSyncFunction = useCallback(
    <R, A>(func: (args: A) => Promise<R>, args: A): Promise<R> => {
      try {
        return func(args);
      } catch (error) {
        throw new Error(formatMessage(commonMessages.defaultError));
      }
    },
    [formatMessage],
  );

  return { runAsyncFunction, runSyncFunction };
};

export default useDefaultErrorWrapper;
