import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelId: {
    id: "Patient.PatientInformationCard.labelId",
    defaultMessage: "Patient ID",
  },
  labelBirthDate: {
    id: "Patient.PatientInformationCard.labelBirthDate",
    defaultMessage: "Date of birth",
  },
  labelPhoneNumber: {
    id: "Patient.PatientInformationCard.labelPhoneNumber",
    defaultMessage: "Phone",
  },
  labelEmail: {
    id: "Patient.PatientInformationCard.labelEmail",
    defaultMessage: "Email",
  },
  labelAddress: {
    id: "Patient.PatientInformationCard.labelAddress",
    defaultMessage: "Address",
  },
  labelLegalGuardian: {
    id: "Patient.PatientInformationCard.labelLegalGuardian",
    defaultMessage: "Legal guardian",
  },
  labelRace: {
    id: "Patient.PatientInformationCard.labelRace",
    defaultMessage: "Race",
  },
  labelSex: {
    id: "Patient.PatientInformationCard.labelSex",
    defaultMessage: "Sex",
  },
});

export default messages;
