import { Patient } from "./types";

const getPatientAddressByType = (
  patientAddresses: Patient["patient_addresses"],
  type: string,
) => {
  const foundAddress = patientAddresses.find(
    (address) => address.type === type,
  );

  return foundAddress?.address;
};

export default getPatientAddressByType;
