import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemBtnView, AppointmentRowItem } from "../types";
import Button from "../../../Unknown/Button";

type ColumnValue = {
  id: string;
};

export type ColumnBtnViewTranslations = {
  columnBtnView: string;
  btnView: string;
};

const getColumnBtnView = (params: {
  columnItem: AppointmentColumnItemBtnView;
  translations: ColumnBtnViewTranslations;
  handleClick: (id: string) => void;
  headerClassName?: string;
}): GridColDef<AppointmentRowItem, ColumnValue> => {
  const { columnItem, translations, handleClick, headerClassName } = params;
  const { btnView } = translations;

  return {
    field: columnItem.field,
    headerName: "",
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    disableExport: false,
    headerClassName,
    valueGetter: ({ row }) => {
      const { id } = row;
      return { id };
    },
    renderCell: (renderParams) => {
      const { value } = renderParams;

      if (!value) return null;

      const { id } = value;

      if (["checkout", "abandoned"].includes(renderParams.row.status)) {
        return null;
      }

      return (
        <Button sx={{ fontSize: 13 }} onClick={() => handleClick(id)}>
          {btnView}
        </Button>
      );
    },
  };
};

export default getColumnBtnView;
