import { useCallback, useMemo, useState } from "react";
import { AppointmentReportConfirmReceiptDialogProps } from ".";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { AppointmentReport } from "../AppointmentReportGrid/types";

const useAppointmentReportConfirmReceiptDialog = (params: {
  appointmentId: string;
  reports: AppointmentReport[];
  refetch: () => Promise<void>;
}): {
  props: AppointmentReportConfirmReceiptDialogProps | null;
  isOpened: boolean;
  onOpenClick: (id: string) => void;
} => {
  const { reports, appointmentId, refetch } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [reportId, setReportId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { runAsyncFunction } = useDefaultErrorWrapper();
  const confirmAppointmentReportReceipt = useFirebaseAppFunction(
    "confirmAppointmentReportReceipt",
  );

  const onOpenClick = (id: string) => setReportId(id);
  const handleClose = () => setReportId(null);

  const report = useMemo(() => {
    if (!reportId) return null;
    return reports.find(({ id }) => id === reportId);
  }, [reportId, reports]);

  const handleSubmit = useCallback(async () => {
    if (!reportId) return;

    setIsLoading(true);
    try {
      await runAsyncFunction(confirmAppointmentReportReceipt, {
        appointmentId,
        reportId,
      });

      await refetch();
      handleClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [
    reportId,
    appointmentId,
    runAsyncFunction,
    confirmAppointmentReportReceipt,
    refetch,
  ]);

  const isOpened = !!reportId;

  return {
    isOpened,
    onOpenClick,
    props: report
      ? {
          isOpened,
          filename: report.name || "",
          isLoading,
          errorMessage,
          handleClose,
          handleSubmit,
        }
      : null,
  };
};

export default useAppointmentReportConfirmReceiptDialog;
