import React, { FC } from "react";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";

interface PatientFormHeaderItem {
  title: string;
  value?: string;
}

interface PatientFormHeaderProps {
  headerTitle: string;
  headerItems: Array<PatientFormHeaderItem>;
}

const AppoinmentScreeningFormHeader: FC<PatientFormHeaderProps> = ({
  headerTitle,
  headerItems,
}) => {
  return (
    <Grid container flexDirection="column" gap={3.2}>
      <Grid item>
        <Typography variant="h6" fontWeight={500}>
          {headerTitle}
        </Typography>
      </Grid>
      <Grid
        container
        flexDirection={{ xs: "column", md: "row" }}
        gap={4}
        flexWrap="nowrap"
      >
        {headerItems.map(({ title, value }) => {
          return (
            <Grid
              key={`form_header_item-${title}`}
              container
              flexDirection="column"
            >
              <Typography variant="subtitle2" textTransform="uppercase">
                {title}
              </Typography>
              <Typography variant="body2">{value}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default AppoinmentScreeningFormHeader;
