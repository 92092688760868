import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemBookedBy, AppointmentRowItem } from "../types";

export type ColumnBookedByTranslations = {
  columnBookedBy: string;
};

const getColumnBookedBy = (params: {
  columnItem: AppointmentColumnItemBookedBy;
  translations: ColumnBookedByTranslations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;
  const { field } = columnItem;
  return {
    field,
    disableColumnMenu: true,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnBookedBy,
    valueGetter: (getterParams) => {
      const { appointmentCustomer } = getterParams.row;

      if (!appointmentCustomer) return "";

      const { firstName, lastName } = appointmentCustomer;

      if (!lastName && !firstName) return "";

      return `${lastName}, ${firstName}`;
    },
  };
};

export default getColumnBookedBy;
