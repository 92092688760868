import { Formik } from "formik";
import React, { FC } from "react";
import CenterContactInformationForm, {
  CenterContactInformationFormValues,
} from "../CenterContactInformationForm";
import { ContactInformation } from "../CenterSettingsContainer/types";
import CenterSettingsFormikDialog from "../CenterSettingsFormikDialog";
import useCenterContactInformationForm from "./useCenterContactInformationForm";
import useTranslations from "./useTranslations";

export type CenterContactInformationFormikProps = {
  isOpened: boolean;
  onModalClose: () => void;
  centerId: string;
  centerContactInformationData: ContactInformation;
  handlePostSubmit: (
    values: CenterContactInformationFormValues,
  ) => void | Promise<void>;
};

const CenterContactInformationFormik: FC<
  CenterContactInformationFormikProps
> = ({
  isOpened,
  onModalClose,
  centerId,
  centerContactInformationData,
  handlePostSubmit,
}) => {
  const { title } = useTranslations();

  const { formikConfig } = useCenterContactInformationForm({
    centerId,
    centerContactInformationData,
    handlePostSubmit,
  });

  return (
    <Formik {...formikConfig}>
      <CenterSettingsFormikDialog
        title={title}
        isOpened={isOpened}
        onClose={onModalClose}
      >
        <CenterContactInformationForm />
      </CenterSettingsFormikDialog>
    </Formik>
  );
};

export default CenterContactInformationFormik;
