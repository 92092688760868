import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    nameText: {
      fontSize: 14,
      color: palette.primary.main,
    },
    linkTypographyRoot: {
      color: palette.primary.main,
      textDecoration: "none",
      textOverflow: "ellipsis",
    },
  };
});

export default useStyles;
