import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import {
  AppointmentOfferingColumnItemLocation,
  AppointmentOfferingRowItem,
} from "../types";
import Typography from "../../../Unknown/Typography";

type ColumnValue = string;

export type ColumnStatusTranslations = {
  columnLocation: string;
};

const getColumnLocation = (params: {
  columnItem: AppointmentOfferingColumnItemLocation;
  translations: ColumnStatusTranslations;
}): GridColDef<AppointmentOfferingRowItem, ColumnValue> => {
  const { columnItem, translations } = params;

  return {
    field: columnItem.field,
    disableColumnMenu: true,
    flex: 1,
    headerName: translations.columnLocation,
    getApplyQuickFilterFn: () => null,
    valueGetter: (getterParams) => {
      const { name } = getterParams.row.center;

      return name;
    },
    renderCell: (renderParams) => {
      if (!renderParams.value) return null;

      return <Typography variant="body2">{renderParams.value}</Typography>;
    },
  };
};

export default getColumnLocation;
