import { useIntl } from "react-intl";
import objectKeys from "../../../common/objectKeys";
import useTranslations, { WeekDayTranslations } from "./useTranslations";
import {
  OperatingHours,
  OperatingHoursWeekDay,
} from "../CenterSettingsContainer/types";
import { CenterInformationSettingsBlockProps } from "../CenterInformationSettingsBlock";

const weekDays: Record<OperatingHoursWeekDay, keyof WeekDayTranslations> = {
  "1": "shortMonday",
  "2": "shortTuesday",
  "3": "shortWednesday",
  "4": "shortThursday",
  "5": "shortFriday",
  "6": "shortSaturday",
  "7": "shortSunday",
} as const;

const useOperatingHoursBlockProps = (params: {
  weekOperatingHours: OperatingHours["weekOperatingHours"];
  onClick?: () => void;
}): CenterInformationSettingsBlockProps => {
  const { weekOperatingHours, onClick } = params;

  const { formatTime } = useIntl();

  const translations = useTranslations();

  const operatingHoursData = objectKeys(weekDays).map((dayKey) => {
    const weekDayTranslationKey = weekDays[dayKey];
    const weekDayTranslation = translations[weekDayTranslationKey];

    const dayTime = weekOperatingHours[dayKey];

    if (!dayTime) {
      return { key: weekDayTranslation, value: translations.dayClosed };
    }

    const fromTimeString = formatTime(dayTime.fromMinutesAtCenterTZDate);
    const toTimeString = formatTime(dayTime.toMinutesAtCenterTZDate);

    const value = `${fromTimeString} — ${toTimeString}`;

    return { key: weekDayTranslation, value };
  });

  return {
    title: translations.headerOperatingHours,
    buttonText: translations.btnEdit,
    onClick,
    data: operatingHoursData,
  };
};

export default useOperatingHoursBlockProps;
