import FullCalendar, {
  DatesSetArg,
  DayHeaderContentArg,
  EventContentArg,
  EventInput,
  EventSourceFunc,
  EventSourceInput,
  SlotLabelContentArg,
  EventClickArg,
} from "@fullcalendar/react";

export default FullCalendar;

export type {
  DatesSetArg,
  DayHeaderContentArg,
  EventContentArg,
  EventInput,
  EventSourceFunc,
  EventSourceInput,
  SlotLabelContentArg,
  EventClickArg,
};
