import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentColumnItemLocation, AppointmentRowItem } from "../types";
import { getStateByCodeAndCountry } from "../../../../common/regionHelpers";
import { DEFAULT_COLUMN_MIN_WIDTH } from "../../../../common/constants";

export type ColumnStatusTranslations = {
  columnLocation: string;
};

const getColumnLocation = (params: {
  columnItem: AppointmentColumnItemLocation;
  translations: ColumnStatusTranslations;
}): GridColDef<AppointmentRowItem, string> => {
  const { columnItem, translations } = params;
  const { isAddressShown } = columnItem;

  return {
    field: columnItem.field,
    disableColumnMenu: true,
    flex: 1,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    headerName: translations.columnLocation,
    valueGetter: (getterParams) => {
      const { center } = getterParams.row;
      const { name, postalCode, state, country } = center;

      const centerState = getStateByCodeAndCountry({
        countryCode: country,
        stateCode: state,
      });

      const textName = isAddressShown
        ? `${name} (${centerState} ${postalCode})`
        : name;

      return textName;
    },
  };
};

export default getColumnLocation;
