import React from "react";
import { GridColDef } from "../../../Unknown/DataGrid";
import Typography from "../../../Unknown/Typography";
import Box from "../../../Unknown/Box";
import { AppointmentPayment } from "../types";

type ColumnTranslations = {
  headerReceived: string;
};

type ColumnParams = {
  translations: ColumnTranslations;
};
const getColumnStudies = ({
  translations,
}: ColumnParams): GridColDef<AppointmentPayment, Date> => {
  const { headerReceived } = translations;

  return {
    field: "confirmedAt",
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    headerName: headerReceived,
    renderCell: ({ row }) => {
      const { confirmedAt, locale } = row;

      if (!confirmedAt) return null;

      const confirmedAtDate = confirmedAt.toLocaleDateString(locale);

      const confirmedAtTime = confirmedAt
        .toLocaleTimeString(locale, {
          hour: "numeric",
          minute: "numeric",
        })
        .toLocaleLowerCase()
        .replace(/\s/, "");

      return (
        <Box>
          <Typography variant="body2" color="text.primary">
            {confirmedAtDate}
          </Typography>
          <Typography variant="body2" fontSize={13} color="text.secondary">
            {confirmedAtTime}
          </Typography>
        </Box>
      );
    },
  };
};

export default getColumnStudies;
