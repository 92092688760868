import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentPayment } from "../types";

type ColumnTranslations = {
  headerPaidBy: string;
};

const getColumnOptions = (params: {
  translations: ColumnTranslations;
}): GridColDef<AppointmentPayment, string> => {
  const { headerPaidBy } = params.translations;

  return {
    field: "customerName",
    disableColumnMenu: true,
    sortable: false,
    headerName: headerPaidBy,
    flex: 1,
  };
};

export default getColumnOptions;
