import { defineMessages } from "react-intl";

const messages = defineMessages({
  errorMinName: {
    id: "TimeSlot.TimeSlotAddDialog.errorMinName",
    defaultMessage: "Name must be at least {minNameLength} characters",
  },
  errorMaxName: {
    id: "TimeSlot.TimeSlotAddDialog.errorMaxName",
    defaultMessage: "Name must be up to {maxNameLength} characters",
  },
  errorRequired: {
    id: "TimeSlot.TimeSlotAddDialog.errorRequired",
    defaultMessage: "Required",
  },
  errorStartMinDate: {
    id: "TimeSlot.TimeSlotAddDialog.errorStartMinDate",
    defaultMessage: "Start date must be in the future",
  },
  errorEndMinDate: {
    id: "TimeSlot.TimeSlotAddDialog.errorEndMinDate",
    defaultMessage: "End date must be after start",
  },
  errorStartMinTime: {
    id: "TimeSlot.TimeSlotAddDialog.errorStartMinTime",
    defaultMessage: "Start time must be in the future",
  },
  errorEndMinTime: {
    id: "TimeSlot.TimeSlotAddDialog.errorEndMinTime",
    defaultMessage: "End time must be after start",
  },
  dateInvalid: {
    id: "TimeSlot.TimeSlotAddDialog.dateInvalid",
    defaultMessage: "Invalid date",
  },
  timeInvalid: {
    id: "TimeSlot.TimeSlotAddDialog.timeInvalid",
    defaultMessage: "Invalid time",
  },
});

export default messages;
