import { useCallback, useState } from "react";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { AppointmentMarkNoShowDialogProps } from "../AppointmentMarkNoShowDialog";

const useAppointmentCheckInDialog = ({
  onSuccess,
}: {
  onSuccess: () => void;
}): {
  handleOpen: (appointmentId: string) => void;
  props: AppointmentMarkNoShowDialogProps;
} => {
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const centerAppCheckInAppointment = useFirebaseAppFunction(
    "centerAppCheckInAppointment",
  );

  const [currentAppointmentId, setCurrentAppointmentId] = useState<
    string | null
  >(null);

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleOpen = (appointmentId: string) =>
    setCurrentAppointmentId(appointmentId);

  const handleClose = useCallback(() => {
    setCurrentAppointmentId(null);
    setErrorMessage(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!currentAppointmentId) return;
    setIsLoading(true);
    try {
      await runAsyncFunction(centerAppCheckInAppointment, {
        appointmentId: currentAppointmentId,
      });

      handleClose();
      onSuccess();
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [
    currentAppointmentId,
    runAsyncFunction,
    centerAppCheckInAppointment,
    handleClose,
    onSuccess,
  ]);

  return {
    handleOpen,
    props: {
      isOpen: !!currentAppointmentId,
      handleClose,
      handleSubmit,
      isLoading,
      errorMessage,
    },
  };
};

export default useAppointmentCheckInDialog;
