import { defineMessages } from "react-intl";

const messages = defineMessages({
  requiresParentGuardian: {
    id: "Appointment.AppointmentTableFilterFlags.requiresParentGuardian",
    defaultMessage: "Requires parent/guardian",
  },
  isStat: {
    id: "Appointment.AppointmentTableFilterFlags.isStat",
    defaultMessage: "Is STAT",
  },
  additionalViews: {
    id: "Appointment.AppointmentTableFilterFlags.additionalViews",
    defaultMessage: "Additional views",
  },
  hasPriority: {
    id: "Appointment.AppointmentTableFilterFlags.hasPriority",
    defaultMessage: "Has priority",
  },
  hasUnexpectedFindings: {
    id: "Appointment.AppointmentTableFilterFlags.hasUnexpectedFindings",
    defaultMessage: "Has unexpected findings",
  },
  receiptConfirmed: {
    id: "Appointment.AppointmentTableFilterFlags.receiptConfirmed",
    defaultMessage: "Receipt confirmed",
  },
  value: {
    id: "Appointment.AppointmentTableFilterFlags.value",
    defaultMessage: "Value",
  },
});

export default messages;
