import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const result = useMemo(() => {
    const translations = {
      headerName: formatMessage(messages.headerName),
      headerStatus: formatMessage(messages.headerStatus),
      btnDownload: formatMessage(messages.btnDownload),
      btnResend: formatMessage(messages.btnResend),
      btnUnexpectedFindings: formatMessage(messages.btnUnexpectedFindings),
      btnConfirmUnexpectedFindings: formatMessage(
        messages.btnConfirmUnexpectedFindings,
      ),
      tooltipReceiptConfirmed: formatMessage(messages.tooltipReceiptConfirmed),
      tooltipUnexpectedFindings: formatMessage(
        messages.tooltipUnexpectedFindings,
      ),
      statusSent: formatMessage(messages.statusSent),
      statusUnsent: formatMessage(messages.statusUnsent),
      statusError: formatMessage(messages.statusError),
    };

    return { translations };
  }, [formatMessage]);

  return result;
};
export default useTranslations;
