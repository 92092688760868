import { SettingsCenter } from "./getSettingsCenterData";
import { ContactInformation } from "./types";

const normalizeContactInformation = (
  center: SettingsCenter,
): ContactInformation => {
  return {
    phone: center.phone_number || undefined,
    fax: center.fax_number || undefined,
    email: center.email || undefined,
    countryCode: "US",
  };
};

export default normalizeContactInformation;
