import { useMemo } from "react";
import { useIntl } from "react-intl";
import messages from "./messages";

const useValidationTranslations = (params: {
  minNameLength: number;
  maxNameLength: number;
}) => {
  const { formatMessage } = useIntl();

  const translationsResult = useMemo(() => {
    const { minNameLength, maxNameLength } = params;
    const translations = {
      errorMinName: formatMessage(messages.errorMinName, { minNameLength }),
      errorMaxName: formatMessage(messages.errorMaxName, { maxNameLength }),
      errorRequired: formatMessage(messages.errorRequired),
      errorStartMinDate: formatMessage(messages.errorStartMinDate),
      errorStartMinTime: formatMessage(messages.errorStartMinTime),
      errorEndMinDate: formatMessage(messages.errorEndMinDate),
      errorEndMinTime: formatMessage(messages.errorEndMinTime),
      dateInvalid: formatMessage(messages.dateInvalid),
      timeInvalid: formatMessage(messages.timeInvalid),
    };

    return translations;
  }, [formatMessage, params]);

  return translationsResult;
};
export default useValidationTranslations;
