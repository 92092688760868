import { useMemo } from "react";
import { MAX_STRING_LEN } from "../../../common/constants";
import getFormSchema from "./getFormSchema";
import {
  CenterOfferingFormHookParams,
  CenterOfferingFormHookResult,
} from "./types";
import {
  MAX_DESCRIPTION_LEN,
  MAX_DURATION_MINUTES,
  MAX_PRICE,
} from "./constants";
import useLabelTranslations from "./useLabelTranslations";

const useCenterOfferingForm = (
  params: CenterOfferingFormHookParams,
): CenterOfferingFormHookResult => {
  const { initialValues, minPrice, handleSubmit } = params;
  const labelTranslations = useLabelTranslations();

  const validationSchema = useMemo(() => {
    return getFormSchema({
      labelTranslations,
      maxStringLength: MAX_STRING_LEN,
      maxDescriptionLength: MAX_DESCRIPTION_LEN,
      maxDurationMinutes: MAX_DURATION_MINUTES,
      minPrice,
      maxPrice: MAX_PRICE,
    });
  }, [labelTranslations, minPrice]);

  return {
    formikConfig: {
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit: handleSubmit,
    },
  };
};

export default useCenterOfferingForm;
