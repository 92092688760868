import { defineMessages } from "react-intl";

const messages = defineMessages({
  labelName: {
    id: "Offering.CenterOfferingFormik.labelName",
    defaultMessage: "Name",
  },
  labelModalityName: {
    id: "Offering.CenterOfferingFormik.labelModalityName",
    defaultMessage: "Study Protocol",
  },
  labelScanSide: {
    id: "Offering.CenterOfferingFormik.labelScanSide",
    defaultMessage: "Sides",
  },
  labelDurationMinutes: {
    id: "Offering.CenterOfferingFormik.labelDurationMinutes",
    defaultMessage: "Duration",
  },
  labelPrice: {
    id: "Offering.CenterOfferingFormik.labelPrice",
    defaultMessage: "Price",
  },
  labelDepositAmount: {
    id: "Offering.CenterOfferingFormik.labelDepositAmount",
    defaultMessage: "Deposit amount",
  },
  labelVisibility: {
    id: "Offering.CenterOfferingFormik.labelVisibility",
    defaultMessage: "Type",
  },
  labelDescription: {
    id: "Offering.CenterOfferingFormik.labelDescription",
    defaultMessage: "Description",
  },
  maxDepositAmountError: {
    id: "Offering.CenterOfferingFormik.maxDepositAmountError",
    defaultMessage: "Deposit amount must be less than price",
  },
});

export default messages;
