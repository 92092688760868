import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import Button from "../../Unknown/Button";
import Card from "../../Unknown/Card";
import Typography from "../../Unknown/Typography";
import CardContent from "../../Unknown/CardContent";
import CardHeader from "../../Unknown/CardHeader";
import useTranslations from "./useTranslations";

export type AppointmentTechnologistNoteCardProps = {
  text: string;
  handleAddClick: () => void;
};

const AppointmentTechnologistNoteCard: FC<
  AppointmentTechnologistNoteCardProps
> = ({ text, handleAddClick }) => {
  const { role } = useParams<{ role?: DatabaseEnum["center_user_role"] }>();

  const { translations } = useTranslations();

  const { title, btnEdit } = translations;

  const minHeight = !text.length ? 150 : undefined;

  const headerAction =
    role === "technologist" ? (
      <Button variant="contained" onClick={handleAddClick}>
        {btnEdit}
      </Button>
    ) : undefined;

  return (
    <Card sx={{ minHeight }}>
      <CardHeader title={title} action={headerAction} />
      <CardContent sx={{ pt: 0 }}>
        <Typography variant="body2" whiteSpace="pre-line">
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AppointmentTechnologistNoteCard;
